import { applyMixins } from '../../../../../../core/mixins';
import { merge } from '../../helpers/merge';
import { proxyHandler } from '../../helpers/proxyHandler';
import Entity from '../Entity';
import ResultDuplicateMixin from './ResultDuplicateMixin';
import ResultMockMixin from './ResultMockMixin';
import ResultSaveMixin from './ResultSaveMixin';

import type { Content } from '../Content';
import type Dict from '../types/Dict';

const ResultPayloadFields = {
  required: ['title'],
  allowed: [
    'title',
    'background',
    'desc',
    'luv',
    'url',
    'url_text',
    'search',
    'search2',
    'min_c',
    'max_c',
    'is_default',
  ],
};

const ResultDuplicatableFields = [
  'title',
  'background',
  'desc',
  'luv',
  'url',
  'url_text',
  'search',
  'search2',
  'min_c',
  'max_c',
  'is_default',
];

const isResult = (result: any): result is Result => result?._uid !== undefined;

interface Result
  extends Entity,
    ResultMockMixin,
    ResultDuplicateMixin<Result>,
    ResultSaveMixin {}

class Result {
  _duplicatableFields: string[];
  _payloadFields: any;

  // reference to parent
  _content: Content;

  // changes list
  _changes: Dict = {};
  // errors list
  _errors: Dict = {};

  // BEGIN: data properties
  id?: number;

  title?: string;
  background?: string;
  desc?: string;
  url?: string;
  url_text?: string;
  youtube_id?: string;
  lead_id?: number;
  pixel_code_id?: number;
  counter?: number;
  luv?: string;
  min_c?: number; // Min correct answer (or calculator score) to get this result
  max_c?: number; // Maximum correct answer or calculator score to get this result
  is_default?: boolean | 0 | 1; // Defines if the result can be a catchall default result
  // END

  constructor(data: any, parent: Content) {
    this._payloadFields = ResultPayloadFields;
    this._duplicatableFields = ResultDuplicatableFields;

    this._content = parent;

    merge(this, data);
  }

  public create(data: any, ref?: Content): Result {
    return New(data, ref || this._content);
  }
}

applyMixins(Result, [
  Entity,
  ResultMockMixin,
  ResultDuplicateMixin<Result>,
  ResultSaveMixin,
]);

export function New(result: Result, content: Content): Result {
  if (result instanceof Result) {
    return result;
  }
  if (isResult(result)) {
    return result;
  }

  if (!content) {
    throw new Error('Content ref is required');
  }

  const klass = new Result(result, content);
  const proxy = new Proxy<Result>(klass, proxyHandler);

  return proxy;
}

export type { Result };
export default New;
