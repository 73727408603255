import React, { useContext, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { EditorCTX } from '../../../context';
import api from '../../../../../api/client';
import { TextAreaInput } from '../../../../Common/Form';
import Button from '../../../../Common/Button';
import { CalculatorFormulaValueInputForm } from './CalculatorFormulaInput';
import messages from '../messages';

const CalculatorFormula = ({ intl: { formatMessage } }) => {
  const { data, set } = useContext(EditorCTX);

  let [result, setResult] = useState(0);

  let [isLoading, setIsLoading] = useState(false);
  let [inputs, setInputs] = useState({});

  useEffect(() => {
    setIsLoading(true);

    // generate inputs
    let _inputs = {};
    Array.from(data.questions || []).forEach((question, index) => {
      let _val = question?.cal_val_default || 0;
      _val = !isNaN(_val) ? _val : 0;

      _inputs[`Q${index + 1}`] = _val;
    });

    setInputs({ ..._inputs });
    setIsLoading(false);
  }, [data.questions]);

  const evaluate = () => {
    if (!data.attributes.cal_formula) {
      setResult(0);
      return;
    }

    const payload = {
      formula: data.attributes.cal_formula,
      vars: [],
    };

    const variables = [
      ...String(data.attributes.cal_formula).matchAll(/Q(\d*)/g),
    ];
    // validate variables
    for (const index in variables) {
      const variable = variables[index];
      const variableIDX = String(variable[1]);

      if (
        !variableIDX ||
        variableIDX > (data.questions || []).length ||
        variableIDX <= 0
      ) {
        // bad question idx
        return;
      }

      // check if variable exists
      if (inputs[variable[0]] === undefined) return;

      let QX = {};
      QX[variable[0]] = inputs[variable[0]];

      payload.vars.push(QX);
    }

    api.post('/platform/content/calculator-test', payload).then((response) => {
      setResult(response.data.result);
    });
  };

  const valueChangeHandler = (name, val) => {
    let next = inputs;
    next[name] = val;
    setInputs(next);
  };

  return (
    <>
      <div className="py-3">
        <TextAreaInput
          name="cal_formula"
          label={formatMessage(messages.CalculatorFormula)}
          type="text"
          value={data?.attributes?.cal_formula || ''}
          min={0}
          rows={3}
          debounce={{ timeout: 1000 }}
          onChange={(value) => set('options.attributes.cal_formula', value)}
        />
        <CalculatorFormulaValueInputForm
          isLoading={isLoading}
          inputs={inputs}
          valueChangeHandler={valueChangeHandler}
        />
      </div>
      <div className="flex flex-col">
        <p className="text-sm mt-1">{formatMessage(messages.CalFormulaTest)}</p>

        <div className="flex mt-2">
          <Button.Secondary onClick={evaluate}>{'Calculate'}</Button.Secondary>
          <p className="ml-10 text-lg mt-1">
            {formatMessage(messages.Result)}: {result}
          </p>
        </div>
      </div>
    </>
  );
};

export default injectIntl(CalculatorFormula);
