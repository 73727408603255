import React, { useMemo } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import routes from '../../routes';
import { NavButton } from '../Button';
// import OnboardingQuiz from '../../Navigation/OnboardingQuiz';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const PrivateRoutes = ({ user, token }) => {
  let location = useLocation();
  let [searchParams] = useSearchParams();

  const privateRoutes = useMemo(
    () =>
      routes.reduce(
        (accumulator, currentValue) => [
          ...accumulator,
          currentValue,
          ...(currentValue.children ? currentValue.children : []),
        ],
        []
      ),
    []
  );

  const _createRoutes = (arr) => {
    return (
      <>
        {arr.map((route, index) => {
          const props = Object.assign(
            {
              key: `private-route-${index}`,
              path: route.path,
            },
            route?.props ? route.props : {},
            route?.element
              ? {
                  element: <route.element user={user} />,
                }
              : {},
            {
              children:
                route?.children !== undefined
                  ? _createRoutes(route.children)
                  : undefined,
            }
          );

          return React.createElement(Route, props);
        })}
      </>
    );
  };

  return token ? (
    <Routes>{_createRoutes(privateRoutes)}</Routes>
  ) : (
    <Navigate
      to={`/login?return_url=${encodeURIComponent(`${location.pathname}`)}${
        searchParams.size ? `&${location.search.slice(1)}` : ''
      }`}
    />
  );
};

export const RoutesList = ({
  className,
  goToRoute,
  routes,
  canCollapse,
  formatMessage,
}) => {
  return (
    <nav className={classNames('poltio-nav', className, 'px-2 space-y-1')}>
      {routes.map((item) => {
        return (
          <NavButton
            key={item.name}
            item={item}
            onClick={goToRoute}
            canCollapse={canCollapse}
            formatMessage={formatMessage}
          />
        );
      })}
      {/* <OnboardingQuiz userId={`${user.id}`} /> */}
    </nav>
  );
};

export default PrivateRoutes;
