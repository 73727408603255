import React, { Dispatch, FC, SetStateAction } from 'react';

import messages from '../messages';

import { widgetLeadImagePath } from '../../../core/constants';
import ImageInput from '../../Common/ImageInput';
import { TextInput, Label, SwitchInput } from '../../Common/Form';
import LeadFormBodyCouponCode from './LeadFormBodyCouponCode';
import { CouponCode, Lead } from '../../../core/types';

type Props = {
  data: Lead;
  setData: Dispatch<SetStateAction<Lead>>;
  formatMessage: any;
  code: CouponCode;
  setCode: Dispatch<SetStateAction<CouponCode>>;
  panelOpen: boolean;
};

const LeadFormBodyImageDisplay: FC<Props> = ({
  data,
  setData,
  formatMessage,
  code,
  setCode,
  panelOpen,
}) => {
  return (
    <div className="pt-6 pb-5 space-y-6">
      <div className="">
        <TextInput
          name="name"
          font="semibold"
          text={formatMessage(messages.NameLabel)}
          debounce={false}
          value={data.name || ''}
          onChange={(event: any) =>
            setData((prev) => ({ ...prev, name: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.NameDesc)}
        </p>
      </div>
      <div className="">
        <TextInput
          name="name"
          font="semibold"
          text={formatMessage(messages.Title)}
          debounce={false}
          value={data.title || ''}
          onChange={(event: any) =>
            setData((prev) => ({ ...prev, title: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.TitleDesc)}
        </p>
      </div>

      <div className="">
        <TextInput
          name="msg"
          font="semibold"
          text={formatMessage(messages.Message)}
          debounce={false}
          value={data.msg || ''}
          onChange={(event: any) =>
            setData((prev) => ({ ...prev, msg: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.MessageDesc)}
        </p>
      </div>

      <div className="">
        <Label
          htmlFor="lead-image"
          text={formatMessage(messages.LeadImage)}
          font="semibold"
        />
        <div className="h-56 mt-1">
          <ImageInput
            type="cover"
            title={formatMessage(messages.LeadImage)}
            src={data.image ? `${widgetLeadImagePath}/${data.image}` : ''}
            callback={(url) => setData((prev) => ({ ...prev, image: url }))}
            modal
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.LeadImageDesc)}
        </p>
      </div>
      <div className="">
        <TextInput
          name="delay"
          type="number"
          font="semibold"
          debounce={false}
          min={0}
          text={formatMessage(messages.Delay)}
          value={data.delay || 0}
          step={500}
          onChange={(event: any) =>
            setData((prev) => ({
              ...prev,
              delay: event.target.valueAsNumber,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.DelayWorkDesc)}
        </p>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.DelayAppearDesc)}
        </p>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.MstoSec)}
        </p>
      </div>
      <div className="font-semibold">
        <SwitchInput
          value={!!data.open_minimized}
          onSwitch={(value) =>
            setData((prev) => ({
              ...prev,
              open_minimized: value ? true : false,
            }))
          }
        >
          {formatMessage(messages.Minimized)}
        </SwitchInput>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.DelayWorkDesc)}
        </p>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.MinimizedWorkDesc)}
        </p>
      </div>
      <div className="">
        <TextInput
          name="button_value"
          font="semibold"
          text={formatMessage(messages.ButtonText)}
          debounce={false}
          placeholder={formatMessage(messages.ButtonTextPlaceholder)}
          value={data.button_value || ''}
          onChange={(event: any) =>
            setData((prev) => ({
              ...prev,
              button_value: event.target.value,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.ButtonTextDesc)}
        </p>
      </div>

      <div className="">
        <TextInput
          name="youtube_id"
          text={formatMessage(messages.YouTubeID)}
          font="semibold"
          // placeholder={formatMessage(messages.YouTubeIDPlaceholder)}
          debounce={false}
          value={data.youtube_id || ''}
          onChange={(event: any) =>
            setData((prev) => ({
              ...prev,
              youtube_id: event.target.value,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.YouTubeIDDesc)}
        </p>
      </div>

      <div className="pt-2 border-t font-semibold">
        <SwitchInput
          value={!!data.stop_set}
          onSwitch={(value) =>
            setData((prev) => ({
              ...prev,
              stop_set: value ? 1 : 0,
            }))
          }
        >
          {formatMessage(messages.StopSet)}
        </SwitchInput>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.StopSetRedirect)}
        </p>
      </div>
      <LeadFormBodyCouponCode
        data={data}
        formatMessage={formatMessage}
        code={code}
        setCode={setCode}
        panelOpen={panelOpen}
      />
    </div>
  );
};

export default LeadFormBodyImageDisplay;
