import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loginLinkRequest } from '../../api';
import { validateEmail } from '../../core/helper';
import { injectIntl } from 'react-intl';

import LoadingDialog from '../Common/Loading/LoadingDialog';
import { AuthHeading } from './Heading';
import { NotificationsCTX } from '../../contexts/Notification';
import messages from './messages';

const LoginLinkRequest = ({ intl: { formatMessage } }) => {
  let navigate = useNavigate();
  let { email } = useParams();
  const { error } = useContext(NotificationsCTX);
  const [sending, setSending] = useState(false);
  const [msg, setMsg] = useState(formatMessage(messages.WeHaveSent));

  useEffect(() => {
    const onFormSubmit = async () => {
      if (!validateEmail(email)) {
        error(formatMessage(messages.InvalidEmail));
        return;
      }

      setSending(true);

      try {
        await loginLinkRequest(email);
        setSending(false);
      } catch (e) {
        error(formatMessage(messages.ErrorLink));
        if (e?.response?.status === 429) {
          setMsg(formatMessage(messages.TooMany));
          setSending(false);
        } else {
          navigate('/login');
        }
      }
    };

    onFormSubmit();
  }, [email, error, navigate, formatMessage]);

  return (
    <>
      <LoadingDialog show={sending} />
      <div className="flex flex-col justify-center min-h-screen py-12 bg-alice-blue sm:px-6 lg:px-8">
        {sending ? null : <AuthHeading msg={msg} />}
      </div>
    </>
  );
};

export default injectIntl(LoginLinkRequest);
