import React, { useContext, useEffect, useMemo, useState } from 'react';
import { overrideAnswers } from '../../../../../helpers/overrideAnswers';
import { EditorCTX } from '../../../../../context';
import { Dropdown } from '../../../../../../Common/Form';
import { AllowedCloneableTypes } from '../../../../../constants';

const ImportAnswersFromQuestion = () => {
  const {
    data,
    activeQuestionIdx: qidx,
    get,
    set,
    delAnswerAt,
  } = useContext(EditorCTX);

  const [displayedItemIndex, setDisplayedItemIndex] = useState(0);
  const [list, setList] = useState([{ title: 'none' }]);

  const questions = useMemo(() => data.questions ?? [], [data]);
  const question = questions[qidx];

  useEffect(() => {
    let _list = [...questions];
    _list = _list.slice(0, qidx);
    _list = _list.filter((el) =>
      AllowedCloneableTypes.includes(el.answer_type)
    );
    setList([{ title: 'none' }, ..._list]);

    const _curr = questions?.[qidx];
    const _idx = (_curr?.getCloneAnswersFromIdx() ?? -1) + 1;
    setDisplayedItemIndex(_idx);
  }, [questions, qidx]);

  const onChange = (listIndex) => {
    setDisplayedItemIndex(listIndex);

    const curr = get(`questions.${qidx}`);

    // index 0 is always none
    set(`questions.${qidx}.use_answers`, true);
    // override answers should only delete if used like this
    overrideAnswers(
      curr,
      [],
      qidx,
      () => null,
      (aidx, _qidx) => {
        // delete should delete pre-existing answers if they belong to this object
        if (curr.hasAnswerIdx(aidx)) {
          delAnswerAt(aidx, _qidx);
        }
      }
    );

    if (listIndex === 0) {
      // call to clear out
      set(`questions.${qidx}.clone_answers_from_uid`, undefined);
    } else {
      const target = list.at(listIndex);
      const targetIndex = questions.findIndex(
        (element) => element.__uid === target.__uid
      );
      const from = get(`questions.${targetIndex}`);

      set(`questions.${qidx}.answer_type`, from.answer_type);
      set(`questions.${qidx}.clone_answers_from_uid`, from.__uid);
    }
  };

  const isDisabled = question?.answers.reduce((acc, answer) => {
    return acc || !!answer.id;
  }, false);

  return (
    <div className="px-1">
      <Dropdown
        data={list}
        selectedIdx={displayedItemIndex}
        onChange={onChange}
        disabled={isDisabled}
      />
    </div>
  );
};

export default ImportAnswersFromQuestion;
