import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';
import PixelCodeSearch from '../SearchField/PixelCodeSearch';
import messages from './messages';

const _PixelCodeInput = ({
  pixelCode,
  onSelect,
  onDelete,
  onNew,
  clickPixelCode,
  hideButton,
  intl: { formatMessage },
  fromResult,
}) => {
  return (
    <div className="flex flex-col">
      {fromResult
        ? clickPixelCode
          ? formatMessage(messages.AddPixelCodeOC)
          : formatMessage(messages.AddPixelCodeOW)
        : formatMessage(messages.AddPixelCode)}

      <div className="flex items-center pt-1 pb-2 absolute w-3/4 mt-6">
        <div className="w-full">
          <PixelCodeSearch
            pixelCodeId={pixelCode?.id}
            pixelCodeName={pixelCode?.name}
            onSelect={onSelect}
          />
        </div>
        <button
          className="poltio-btn-xs text-red-500 mx-1 p-2 rounded border hover:text-white hover:bg-red-500 focus:ring-red-500 focus:ring-offset-1"
          onClick={onDelete}
        >
          <TrashIcon className="h-5" />
        </button>
        {hideButton ? null : (
          <button
            className="poltio-btn-xs text-poltio-blue-500 mx-1 p-2 rounded border hover:text-white hover:bg-poltio-blue-500 focus:ring-poltio-blue-500 focus:ring-offset-1"
            onClick={onNew}
          >
            <PlusIcon className="h-5" />
          </button>
        )}
      </div>
      <div className="mt-14">
        {!clickPixelCode && (
          <div className="text-xs py-1 mb-1 text-gray-400">
            {formatMessage(messages.PixelCodeDesc)}
          </div>
        )}
      </div>
    </div>
  );
};

const PixelCodeInput = injectIntl(_PixelCodeInput);

export { PixelCodeInput };
export default PixelCodeInput;
