import api from './client';

export function getLeadsPaginated(last_id, q) {
  let query = null;
  query = `?page=${last_id || ''}&per_page=${q || ''}`;
  return api.get(`/platform/leads${query || ''}`);
}

export function getInputs(lead_id, page, size) {
  return api.get(
    `/platform/leads/${lead_id}/inputs?page=${page}&per_page=${size}`
  );
}

export function getLeadLogs(lead_id, page, size) {
  return api.get(
    `/platform/leads/${lead_id}/logs?page=${page}&per_page=${size}`
  );
}

export function getLeadCoupons(lead_id, page, size) {
  return api.get(
    `/platform/leads/${lead_id}/codes?page=${page}&per_page=${size}`
  );
}

export function leadSave(params) {
  return params.id
    ? api.put(`/platform/leads/${params.id}`, params)
    : api.post('/platform/leads', params);
}

export function getLead(id) {
  return api.get(`/platform/leads/${id}`);
}

// view call, subject to change
export function leadEdit(id) {
  return api.put(`/platform/leads/${id}`);
}

export function leadDelete(id) {
  return api.delete(`/platform/leads/${id}`);
}

export function addCouponCode(id, code) {
  return code.id
    ? api.put(`/platform/leads/${id}/codes/${code.id}`, code)
    : api.post(`/platform/leads/${id}/codes`, code);
}

export function deleteCouponCodes(id) {
  return api.delete(`/platform/leads/${id}/codes`);
}

export function deleteSingleCode(id, code_id) {
  return api.delete(`/platform/leads/${id}/codes/${code_id}`);
}
