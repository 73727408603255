import messages from '../messages';
import { TextAreaInput, SwitchInput } from '../../Common/Form';
import { useCallback, useEffect, useState, useContext } from 'react';
import { getLeadCoupons, deleteSingleCode } from '../../../api/leads';
import Button from '../../Common/Button';
import CouponCodes from '../CouponCodes';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { NotificationsCTX } from '../../../contexts/Notification';
import Pagination from '../../Common/Pagination';

const LeadFormBodyCouponCode = ({
  data,
  formatMessage,
  code,
  setCode,
  panelOpen,
}) => {
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 12,
    lastPage: null,
  });
  const [coupons, setCoupons] = useState([]);
  const { success, error } = useContext(NotificationsCTX);
  const [removeExistingCode, setRemoveExistingCode] = useState(false);
  const [openCoupon, setOpenCoupon] = useState(false);

  const fetchData = useCallback(async () => {
    if (data.has_codes) {
      const resp = await getLeadCoupons(
        data.id,
        pageData.page,
        pageData.sizePerPage
      );
      setCoupons(resp.data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: resp.data.total,
          lastPage: resp.data.last_page,
        };
      });
    }
  }, [pageData.page, pageData.sizePerPage, data]);

  useEffect(() => {
    if (data && data.has_codes && !openCoupon && panelOpen) {
      try {
        fetchData(pageData.page, pageData.sizePerPage);
      } catch (e) {
        console.log(e);
      }
    }
  }, [
    data,
    pageData.page,
    pageData.sizePerPage,
    fetchData,
    openCoupon,
    panelOpen,
  ]);

  const _deleteSingleCode = async (code_id) => {
    if (window.confirm(formatMessage(messages.AreuSureSingle))) {
      try {
        await deleteSingleCode(data.id, code_id);
        success(formatMessage(messages.SuccessDeleteSingle));
        fetchData();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
        console.log(e);
      }
    }
  };

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };
  return (
    <div className="border-t pt-4 flex justify-center">
      <div>
        <TextAreaInput
          name="button_value"
          rows={3}
          font="semibold"
          label={formatMessage(messages.CouponCodes)}
          placeholder="code1&#13;&#10;code2&#13;&#10;code3"
          type="text"
          value={code.codes}
          onChange={(event) => {
            setCode((prev) => ({
              ...prev,
              codes: event.target.value,
            }));
          }}
        />
        <p className="mt-2 text-xs text-gray-500">
          {formatMessage(messages.EveryLine)}
        </p>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.CouponCodesDesc)}
        </p>
        <div className="pt-4">
          <SwitchInput
            name="remove_existing"
            value={!!removeExistingCode}
            onSwitch={(value) => {
              setRemoveExistingCode(!removeExistingCode);
              setCode((prev) => ({
                ...prev,
                remove_existing: value ? 1 : 0,
              }));
            }}
          >
            {formatMessage(messages.RemoveExisting)}
          </SwitchInput>
        </div>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.RemoveExistingDesc)}
        </p>
        <div className="pt-4">
          <SwitchInput
            name="single_use"
            value={!!code?.single_use}
            onSwitch={(value) =>
              setCode((prev) => ({
                ...prev,
                single_use: value ? 1 : 0,
              }))
            }
          >
            {formatMessage(messages.SingleUse)}
          </SwitchInput>
        </div>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.SingleUseDesc)}
        </p>
        {coupons.length > 0 ? (
          <div className="mt-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Code)}
                  </th>

                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                  >
                    {formatMessage(messages.IsUsed)}
                  </th>
                  <th
                    scope="col"
                    className="p-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase"
                  >
                    {formatMessage(messages.SingleUse)}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {coupons.map((code, i) => (
                  <tr
                    key={i}
                    className={
                      i % 2 === 0
                        ? 'bg-white text-center'
                        : 'bg-gray-50 text-center'
                    }
                  >
                    <td className="p-3 max-w-36 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {code.code.length > 6
                          ? code.code?.substring(0, 6) + '...'
                          : code.code}
                      </div>
                    </td>

                    <td className="p-3 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {code.is_used
                          ? formatMessage(messages.Used)
                          : formatMessage(messages.NotUsed)}
                      </div>
                    </td>
                    <td className="p-3 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {code.single_use
                          ? formatMessage(messages.True)
                          : formatMessage(messages.False)}
                      </div>
                    </td>
                    <td className="text-sm text-gray-500 whitespace-nowrap">
                      <div className="z-0 ">
                        <span className="relative inline-flex">
                          <div>
                            <Button
                              className="-m-1 inline-flex p-1 text-gray-400 hover:text-gray-600 border-0 focus:ring-0"
                              onClick={() => {
                                setCode((prev) => ({
                                  ...prev,
                                  code: code.code,
                                  id: code.id,
                                  single_use: code.single_use,
                                  is_used: code.is_used,
                                }));
                                setOpenCoupon(true);
                              }}
                              tooltip={true}
                              message={formatMessage(messages.UpdateCode)}
                            >
                              <PencilIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>

                          <div className="px-7">
                            <Button
                              className="-m-2 inline-flex p-2 text-red-400 hover:text-red-600 border-0 focus:ring-0"
                              onClick={() => {
                                _deleteSingleCode(code.id);
                              }}
                              tooltip={true}
                              message={formatMessage(messages.RemoveCode)}
                            >
                              <TrashIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
              totalSize={pageData.totalSize}
            />
            {coupons ? (
              <CouponCodes
                open={openCoupon}
                setOpen={setOpenCoupon}
                activeLead={data}
                couponCodes={coupons}
                activeCouponCode={code}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LeadFormBodyCouponCode;
