import { defaultDropAnimationSideEffects } from '@dnd-kit/core';

export const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};
