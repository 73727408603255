import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { getContents } from '../../../api';
import SearchField from '../SearchField';

const ContentSearchRowRenderer = ({ item }) => `${item.title}`;

export const ContentSearch = ({
  onSelect,
  contentTypes,
  selectedContent = {},
}) => {
  const inputRef = useRef();
  const { type: content_type, id: content_id } = selectedContent;

  const [dbVal, setDbVal] = useState('');

  const debounced = useDebouncedCallback((value) => setDbVal(value), 1000);

  const getData = useCallback(
    async (isDirty, setDisplayContents, setDisplayText) => {
      try {
        if (!isDirty && content_type && content_id) {
          const { data } = await getContents(
            1,
            1,
            'created_at',
            'desc',
            [content_type],
            content_id
          );
          const selectedItem = data.data[0];
          setDisplayContents([selectedItem]);
          setDisplayText(
            `${selectedItem.type} | ${selectedItem.original_id} | ${selectedItem.title}`
          );
        } else {
          const { data } = await getContents(
            1,
            12,
            'created_at',
            'desc',
            contentTypes,
            dbVal
          );
          setDisplayContents(data.data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [contentTypes, dbVal, content_id, content_type]
  );

  useEffect(() => inputRef?.current?.focus?.(), []);

  const onItemChange = (item, setDisplayText) => {
    setDisplayText(item.title);
    setDbVal(item.title);
    onSelect(item);
  };

  const onInputChange = (value, setDisplayText) => {
    setDisplayText(value);
    debounced(value);
  };

  return (
    <div className="w-72">
      <SearchField
        ref={inputRef}
        fetchData={getData}
        onItemChange={onItemChange}
        onInputChange={onInputChange}
        rowRenderer={ContentSearchRowRenderer}
      />
    </div>
  );
};

export default ContentSearch;
