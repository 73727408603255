abstract class AnswerDuplicateMixin<T> {
  // get AnswerClass as T to prevent circular dependency

  _duplicatableFields: string[] = [];

  public abstract create(data: any, ref?: any): T;

  public duplicate(parent?: any): T {
    const fields = this._duplicatableFields;

    const next = {
      ...Object.keys(this)
        .filter((key) => fields.includes(key))
        .reduce(
          (acc, field) => ({ ...acc, [field]: (this as any)[field] }),
          {}
        ),
    };

    return this.create(next, parent);
  }
}

export default AnswerDuplicateMixin;
