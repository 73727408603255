import React from 'react';
import { appVersion } from '../../../core/constants';
const d = new Date();
const Footer = () => (
  <div className="mt-8 leading-normal text-xs text-gray-500 space-y-1 pb-4">
    <p className="mt-8 text-xs text-center text-80">
      {`© ${d.getFullYear()} Poltio `}
      <span className="px-1">·</span>
      Built with ❤️ & ☕<span className="px-1">·</span>
      {`Platform Version: ${appVersion}`}
    </p>
  </div>
);

export default Footer;
