import { createContext } from 'react';
import { EditorState, ConfTypes } from './enums';

type ContentData = {
  [K: string]: any;
};

type FocusOptions = { save: boolean };

export const EditorCTX = createContext({
  data: {} as ContentData,

  setAnswerType: () => {},
  setOptions: () => {},

  activeQuestionIdx: 0,
  setActiveQuestion: (index: number) => {},

  setContentType: (next: any) => {},
  setQuestionAnswerType: (answerType: any) => {},
  changeQuestionAnswerType: (answerType: any) => {},

  activeResultIdx: 0,
  setActiveResult: (index: number) => {},

  get: (key: string) => {},
  set: (key: string, value: any, set_api: boolean) => {},
  del: (key: string) => {},
  save: async () => {},

  addNewResult: () => {},
  addNewQuestion: () => {},
  addNewAnswer: () => {},

  delResultAt: (idx: number) => {},
  delQuestionAt: (idx: number) => {},
  delAnswerAt: (idx: number) => {},

  focus: (key: any, field: any, options?: FocusOptions) => {},

  confType: ConfTypes.Cover,
  confField: '',
  getMockData: (target: any) => {},

  editorStatus: EditorState.Syncing,

  showCreateNewLead: () => {},
  showCreateNewPixelCode: () => {},

  isLoading: false,
});
