import { injectIntl } from 'react-intl';

const LanguageSelect = ({ intl }) => {
  const changeLanguage = (e, l) => {
    e.preventDefault();
    if (l === intl.locale) {
      return;
    } else {
      window.localStorage.setItem('lang', l);
      window.location.reload();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="text-lg inline">
      <button className="" onClick={(e) => changeLanguage(e, 'tr')}>
        {'🇹🇷'}
      </button>
      {'  '}
      <button className="" onClick={(e) => changeLanguage(e, 'en')}>
        {'🇺🇸'}
      </button>
    </a>
  );
};

export default injectIntl(LanguageSelect);
