import Change from '../../classes/Change';
import { processAnswers } from './processAnswers';
import { processContent } from './processContent';
import { processDelete } from './processDelete';
import { processProperties } from './processProperties';
import { processQuestions } from './processQuestions';
import { processResults } from './processResults';

const changes_reducer = (acc, change, index) => {
  // mark change as queued up
  // skip enqueued changes
  if (change.enqueued) {
    return { ...acc };
  }
  change.enqueued = true;

  const change_type = change.type ?? 'rest';
  if (!acc?.[change_type]) {
    acc[change_type] = [];
  }
  acc[change_type].push(change);
  acc.call_c += 1;

  return { ...acc };
};

/**
 *
 * @param {Array} content
 * @param {Array<Change>} changes
 */
export async function saveProcess(content, changes, options) {
  const items = changes.reduce(changes_reducer, {
    call_c: 0,
    base: [],
    rest: [],
  });

  if (options?.isCreateCall) {
    // force save on `base` if change list for base is empty
    if (items?.base?.length === 0) {
      items.base.push(new Change(''));
      items.call_c += 1;
    }
  }

  // nothing to do
  if (items.call_c === 0) {
    return;
  }

  await processContent(content, items);
  await processQuestions(content, items);
  await processAnswers(content, items);
  await processResults(content, items);
  processProperties(content, items);
  processDelete(content, items);
}
