import {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
  FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { injectIntl } from 'react-intl';
import * as Sentry from '@sentry/react';
import { Dialog, Transition } from '@headlessui/react';
import {
  getLeadCoupons,
  addCouponCode,
  deleteCouponCodes,
  deleteSingleCode,
} from '../../api/leads';
import { useNavigate } from 'react-router-dom';

import Pagination from '../Common/Pagination';
import Button from '../Common/Button';
import Loading from '../Common/Loading';
import messages from './messages';
import { SwitchInput, TextAreaInput, TextInput } from '../Common/Form';
import { NotificationsCTX } from '../../contexts/Notification';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { PencilIcon, TrashIcon as TrashIconX } from '@heroicons/react/24/solid';
import { CouponCode, Lead } from '../../core/types';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  activeLead: Lead;
  couponCodes?: Array<CouponCode>;
  activeCouponCode?: CouponCode;
  intl: any;
};

const CouponCodes: FC<Props> = ({
  open = false,
  setOpen,
  activeLead,
  couponCodes,
  activeCouponCode,
  intl: { formatMessage, formatDate },
}) => {
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: 1,
  });
  const [coupons, setCoupons] = useState<Array<CouponCode>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<CouponCode>({
    single_use: 1,
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [removeExistingCode, setRemoveExistingCode] = useState<boolean>(false);
  const { success, error } = useContext(NotificationsCTX);

  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (activeLead.id) {
      const { data } = await getLeadCoupons(
        activeLead.id,
        pageData.page,
        pageData.sizePerPage
      );
      setCoupons(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [pageData.page, pageData.sizePerPage, activeLead]);

  useEffect(() => {
    const data = activeLead;
    setVisible(false);
    if (data) {
      if (couponCodes && activeCouponCode) {
        setCoupons(couponCodes);
        setCode(activeCouponCode);
        setVisible(true);
      } else {
        try {
          fetchData();
        } catch (e) {
          navigate('/leads');
        }
      }
    } else {
      navigate('/leads');
    }
  }, [
    activeLead,
    pageData.page,
    pageData.sizePerPage,
    fetchData,
    navigate,
    activeCouponCode,
    couponCodes,
  ]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  const submitData = async (activeLead: Lead) => {
    try {
      await addCouponCode(activeLead.id, code);
      success(
        code.id
          ? formatMessage(messages.EditSuccess)
          : formatMessage(messages.AddSuccess),
        ''
      );

      fetchData(); // reload
      setVisible(false);
      setCode({ single_use: 1 });
    } catch (err) {
      Sentry.captureException(err);
      error(formatMessage(messages.ErrorOccured));
      return;
    }
  };

  const _deleteCouponCodes = async (l: Lead) => {
    if (window.confirm(formatMessage(messages.AreuSureAll))) {
      try {
        await deleteCouponCodes(l.id);
        success(formatMessage(messages.SuccessDeleteAll));
        fetchData();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
      }
    }
  };

  const _deleteSingleCode = async (code_id: number) => {
    if (window.confirm(formatMessage(messages.AreuSureSingle))) {
      try {
        await deleteSingleCode(activeLead.id, code_id);
        success(formatMessage(messages.SuccessDeleteSingle));
        setCode({ single_use: 1 });
        fetchData();
      } catch (e) {
        error(formatMessage(messages.ErrorOccured));
        console.log(e);
      }
    }
  };

  const closeCodes = () => {
    setVisible(!visible);
    setCode({ single_use: 1 });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div>
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  handlePageChange={handlePageChange}
                  totalSize={pageData.totalSize}
                  showTotal
                />
                {!visible ? (
                  <div className="mt-5 sm:mt-6 flex justify-end">
                    <div>
                      <Button.Danger
                        type="button"
                        className="-m-2 inline-flex p-2 border-0 focus:ring-0"
                        onClick={() => _deleteCouponCodes(activeLead)}
                        disabled={coupons.length <= 0}
                        tooltip={true}
                        message={formatMessage(messages.RemoveAll)}
                      >
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      </Button.Danger>
                    </div>
                    <div className="px-7">
                      <Button.Primary
                        type="button"
                        className="-m-2 inline-flex p-2 border-0 focus:ring-0"
                        onClick={() => setVisible(!visible)}
                        tooltip={true}
                        message={formatMessage(messages.AddCouponCode)}
                      >
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      </Button.Primary>
                    </div>
                  </div>
                ) : null}
                {visible ? (
                  <div>
                    <div className="mt-5 sm:mt-6 flex justify-center">
                      <div>
                        {code.code ? (
                          <>
                            <TextInput
                              name="button_value"
                              label={formatMessage(messages.EditCoupon)}
                              type="text"
                              value={code.code}
                              debounce={false}
                              onChange={(event: any) => {
                                setCode((prev) => ({
                                  ...prev,
                                  code: event.target.value,
                                }));
                              }}
                            />
                            <p className="mt-2 text-sm text-gray-500">
                              {formatMessage(messages.CouponCodesDesc)}
                            </p>
                          </>
                        ) : (
                          <>
                            <TextAreaInput
                              name="button_value"
                              rows={3}
                              font="semibold"
                              label={formatMessage(messages.CouponCodes)}
                              placeholder="code1&#13;&#10;code2&#13;&#10;code3"
                              type="text"
                              value={code.codes}
                              debounce={false}
                              onChange={(event: any) => {
                                setCode((prev) => ({
                                  ...prev,
                                  codes: event.target.value,
                                }));
                              }}
                            />
                            <p className="mt-2 text-xs text-gray-500">
                              {formatMessage(messages.EveryLine)}
                            </p>
                            <p className="mt-2 text-sm text-gray-500">
                              {formatMessage(messages.CouponCodesDesc)}
                            </p>
                            <div className="pt-2">
                              <SwitchInput
                                value={!!removeExistingCode}
                                onSwitch={(value: boolean) => {
                                  setRemoveExistingCode(!removeExistingCode);
                                  setCode((prev) => ({
                                    ...prev,
                                    remove_existing: value ? 1 : 0,
                                  }));
                                }}
                              >
                                {formatMessage(messages.RemoveExisting)}
                              </SwitchInput>
                            </div>
                          </>
                        )}

                        <div className="pt-2">
                          <SwitchInput
                            value={!!code.single_use}
                            onSwitch={(value: boolean) =>
                              setCode((prev) => ({
                                ...prev,
                                single_use: value ? 1 : 0,
                              }))
                            }
                          >
                            {formatMessage(messages.SingleUse)}
                          </SwitchInput>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex justify-end">
                      <Button.Secondary
                        type="button"
                        className="w-30 mr-5"
                        onClick={closeCodes}
                      >
                        {formatMessage(messages.SubmitCancel)}
                      </Button.Secondary>
                      <Button.Primary
                        type="button"
                        className="w-30"
                        onClick={() => submitData(activeLead)}
                      >
                        {formatMessage(messages.SubmitSave)}
                      </Button.Primary>
                    </div>
                  </div>
                ) : null}
                {loading ? (
                  <div className="max-w-7xl mx-auto px-2">
                    <Loading />
                  </div>
                ) : (
                  <Fragment>
                    <div className="flex flex-col py-4">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Id)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Code)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.CreatedAt)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.UpdatedAt)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.IsUsed)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.SingleUse)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.UserId)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.LeadDate)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative px-6 py-5"
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {coupons.map((code, i) => (
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                    }
                                  >
                                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.id}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.code}
                                      </div>
                                    </td>

                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {formatDate(code.created_at)}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.updated_at?.toString()}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.is_used
                                          ? formatMessage(messages.Used)
                                          : formatMessage(messages.NotUsed)}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.single_use
                                          ? formatMessage(messages.True)
                                          : formatMessage(messages.False)}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.session_id}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {code.used_at?.toString()}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="z-0 w-full py-2">
                                        <span className="relative inline-flex">
                                          <div>
                                            <Button
                                              className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-600 border-0 focus:ring-0"
                                              onClick={() => {
                                                setVisible(true);
                                                setCode((prev) => ({
                                                  ...prev,
                                                  code: code.code,
                                                  id: code.id,
                                                  single_use: code.single_use,
                                                  is_used: code.is_used,
                                                }));
                                              }}
                                              tooltip={true}
                                              type="button"
                                              disabled={false}
                                              href={null}
                                              showSpinner={false}
                                              message={formatMessage(
                                                messages.UpdateCode
                                              )}
                                            >
                                              <PencilIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </Button>
                                          </div>

                                          <div className="px-7">
                                            <Button
                                              className="-m-2 inline-flex p-2 text-red-400 hover:text-red-600 border-0 focus:ring-0"
                                              onClick={() => {
                                                if (code.id) {
                                                  _deleteSingleCode(code.id);
                                                }
                                              }}
                                              tooltip={true}
                                              type="button"
                                              disabled={false}
                                              href={null}
                                              showSpinner={false}
                                              message={formatMessage(
                                                messages.RemoveCode
                                              )}
                                            >
                                              <TrashIconX
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </Button>
                                          </div>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      last={pageData.lastPage}
                      active={pageData.page}
                      size={pageData.sizePerPage}
                      handlePageChange={handlePageChange}
                      totalSize={pageData.totalSize}
                    />
                  </Fragment>
                )}
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <div>
                  <Button.Secondary
                    type="button"
                    className="w-full"
                    onClick={() => setOpen(false)}
                  >
                    {formatMessage(messages.Close)}
                  </Button.Secondary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default injectIntl(CouponCodes);
