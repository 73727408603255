import { useState, useEffect, useContext } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import logo from '../../../images/poltio-logo.svg';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useParams } from 'react-router-dom';

import Button from '../../Common/Button';
import Loading from '../../Common/Loading';
import { verify, reSendVerify } from '../../../api';
import messages from '../messages';
import { NotificationsCTX } from '../../../contexts/Notification';

const VerifyAccount = () => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [errStatus, setErrStatus] = useState(0);

  const { email, resetCode } = useParams();

  const { formatMessage } = useIntl();

  const { success, error } = useContext(NotificationsCTX);

  useEffect(() => {
    async function verifyEmail() {
      if (email && resetCode) {
        try {
          await verify(email, resetCode);
          setLoading(false);
        } catch (e: any) {
          const status = e?.response?.status ?? -1;
          switch (status) {
            case 400:
              setErrMsg(formatMessage(messages.Error400));
              break;

            case 403:
              setErrMsg(formatMessage(messages.Error403));
              break;

            default:
              setErrMsg(formatMessage(messages.VaError));
              break;
          }
          setErrStatus(status);
          setLoading(false);
          setHasError(true);
        }
      }
    }
    verifyEmail();
  }, [email, resetCode]);

  const onSendClick = async (event: any) => {
    event.preventDefault();
    try {
      await reSendVerify();
      success('', formatMessage(messages.Success));
    } catch (e: any) {
      const status = e?.response?.status ?? -1;
      switch (status) {
        case 403:
          error(formatMessage(messages.Error403));
          break;

        default:
          error(formatMessage(messages.VaError));
          break;
      }
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-500 sm:px-6 lg:px-8 bg-opacity-75">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-6">
          <img className="w-auto h-12 mx-auto" src={logo} alt="Workflow" />

          {hasError ? (
            <div>
              <XCircleIcon
                className="h-20 w-20 text-red-400 mx-auto mt-6"
                aria-hidden="true"
              />
              <p className="text-lg leading-6 mt-3 text-center sm:mt-5">
                {errMsg}
              </p>
              {errStatus === 400 ? (
                <p className="mt-2 text-gray-500">
                  {formatMessage(messages.Error400Note)}
                </p>
              ) : null}
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                {errStatus === 400 ? (
                  <>
                    <Button.Primary
                      type="button"
                      className=""
                      onClick={onSendClick}
                    >
                      {formatMessage(messages.ReSend)}
                    </Button.Primary>
                  </>
                ) : null}
                <Button.Secondary
                  className={''}
                  type="submit"
                  href={`/`}
                  disabled={hasError}
                >
                  {formatMessage(messages.ReturnHome)}
                </Button.Secondary>
              </div>
            </div>
          ) : (
            <div>
              <CheckCircleIcon
                className="h-20 w-20 text-green-400 mx-auto mt-6"
                aria-hidden="true"
              />
              <p className="text-lg leading-6 mt-3 text-center sm:mt-5">
                {formatMessage(messages.VaSuccess)}
              </p>
              <div className="mt-5 sm:mt-6 grid justify-items-center">
                <Button.Primary
                  className={''}
                  type="submit"
                  href={`/`}
                  disabled={hasError}
                >
                  {formatMessage(messages.ReturnHome)}
                </Button.Primary>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(VerifyAccount);
