import { useState, useEffect, useCallback, useContext } from 'react';
import { Dialog } from '@headlessui/react';
import { injectIntl } from 'react-intl';
import {
  WrenchScrewdriverIcon,
  ArrowRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import Modal from '../../../../../Common/Modal';

import {
  getSearchableFields,
  getSearchResult,
} from '../../../../../../api/editor/content';
import Button from '../../../../../Common/Button';
import { EditorCTX } from '../../../../context';
import { NotificationsCTX } from '../../../../../../contexts/Notification';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ResultModal = ({ open, setOpen, data, contentId }) => {
  const [answers, setAnswers] = useState(data[0].answers || []);
  const [selectedAnswer, setSelectedAnswer] = useState(answers[0]);
  const [queryButtonDisable, setQueryButtonDisable] = useState(false);
  const [foundedQuery, setFoundedQuery] = useState(-1);
  const [filterButtonDisable, setFilterButtonDisable] = useState(false);
  const [foundedFilter, setFoundedFilter] = useState(-1);
  const [fields, setFields] = useState([]);
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [filterVal, setFilterVal] = useState('');
  const [msg, setMsg] = useState('');
  const [hasMpFilter, setHasMpFilter] = useState(false);
  const [hasMath, setHasMath] = useState(false);
  const [math, setMath] = useState('>');
  const [qResult, setQResult] = useState([]);
  const [fResult, setFResult] = useState([]);
  const [qidx, setQidx] = useState(0);
  const [aidx, setAidx] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { get, set, save, editorStatus } = useContext(EditorCTX);
  const { success, error } = useContext(NotificationsCTX);

  // TODO: improve this method
  useEffect(() => {
    let fltr = selectedAnswer.search_filter;
    let qry = selectedAnswer.search_query;
    if (fltr) {
      if (fltr.includes('&&') || fltr.includes('||')) {
        setHasMpFilter(true);
        setFilterVal(selectedAnswer.search_filter);
        setHasMath(false);
      } else {
        let res = fltr.split(':')[0];
        if (
          (fltr.includes('>') ||
            fltr.includes('<') ||
            fltr.includes('>=') ||
            fltr.includes('<=')) &&
          fields.filter(
            (field) => field.name === res && field.type === 'filter_numeric'
          ).length > 0
        ) {
          setFilterVal(fltr.split(' ')[2]);
          setMath(fltr.split(' ')[1]);
          setHasMath(true);
        } else {
          setHasMath(false);
          setFilterVal(fltr.substring(res.length + 1));
        }
        setHasMpFilter(false);
        setFilter(res);
      }
      setFoundedFilter(-1);
      setFResult([]);
      setFoundedQuery(-1);
      setQResult([]);
    } else {
      // setFilter(fields[0].name);
      setHasMpFilter(false);
      setFilterVal('');
      setFoundedFilter(-1);
      setFResult([]);
      setFoundedQuery(-1);
      setQResult([]);
    }
    if (qry) {
      setQuery(qry);
    } else {
      setQuery('');
    }
  }, [selectedAnswer, fields]);

  useEffect(() => {
    const filtr = fields?.filter((field) => field.name === filter);
    filtr[0]?.type === 'filter_numeric' ? setHasMath(true) : setHasMath(false);
  }, [filter]);

  const fetchSearchableFields = useCallback(async () => {
    const { data } = await getSearchableFields(contentId);
    setFields(data);
  }, [contentId]);

  useEffect(() => {
    fetchSearchableFields();
  }, [fetchSearchableFields]);

  let qQuestions = [];
  let qAnswers = [];
  let fQuestions = [];
  let fAnswers = [];

  for (let i = 0; i < data.length; i++) {
    let a = i;

    let index = data[i].answers.length;

    for (let i = 0; i < index; i++) {
      if (data[a]?.answers && data[a]?.answers[i]?.search_query) {
        qQuestions.push(data[a].answers[i].question_id);
      }
      if (data[a]?.answers && data[a]?.answers[i]?.search_filter) {
        fQuestions.push(data[a].answers[i].question_id);
      }
    }
  }

  for (let i = 0; i < answers.length; i++) {
    if (answers && answers[i]?.search_filter) {
      fAnswers.push(answers[i]?.id);
    }
    if (answers && answers[i]?.search_query) {
      qAnswers.push(answers[i]?.id);
    }
  }

  const onQueryClick = async () => {
    setQueryButtonDisable(true);
    // const q = [...queryList, query];
    // setQueryList(q);
    try {
      const resp = await getSearchResult(contentId, [query], []);
      setFoundedQuery(resp.data.found);
      setQResult(resp.data.hits);
      setQueryButtonDisable(false);
      setMsg('');
    } catch (e) {
      const errorStatus = e?.response?.status;
      switch (errorStatus) {
        case 400:
          setMsg(e.response.data.msg);
          break;
        case 404:
          setMsg('Content not found');
          break;
        case 423:
          setMsg(e.response.data.msg);
          break;
        case 403:
          setMsg('You dont have permission.');
          break;
        case 429:
          setMsg('Too many request, please try again 5 mins later.');
          break;
        default:
          return setMsg(e.response.data.msg);
      }
      setQueryButtonDisable(false);
    }
  };

  const finalFilter = hasMpFilter
    ? [`${filterVal}`]
    : hasMath
      ? [`${filter}: ${math} ${filterVal}`]
      : [`${filter}: ${filterVal}`];

  const onFilterClick = async () => {
    setFilterButtonDisable(true);
    try {
      const resp = await getSearchResult(contentId, [], finalFilter);
      setFoundedFilter(resp.data.found);
      setFResult(resp.data.hits);
      setFilterButtonDisable(false);
      setMsg('');
    } catch (e) {
      const errorStatus = e?.response?.status;
      switch (errorStatus) {
        case 400:
          setMsg(e.response.data.msg);
          break;
        case 404:
          setMsg('Content not found');
          break;
        case 423:
          setMsg(e.response.data.msg);
          break;
        case 403:
          setMsg('You dont have permission.');
          break;
        case 429:
          setMsg('Too many request, please try again 5 mins later.');
          break;
        default:
          return setMsg(e.response.data.msg);
      }
      setFilterButtonDisable(false);
    }
  };

  useEffect(() => {
    if (!filterVal) {
      set(`questions.${qidx}.answers.${aidx}.search_filter`, '');
    } else {
      set(`questions.${qidx}.answers.${aidx}.search_filter`, finalFilter[0]);
    }
  }, [finalFilter[0]]);

  const onFilterChange = (value) => {
    setFilterVal(`${value}`);
  };

  const onQueryChange = (value) => {
    set(`questions.${qidx}.answers.${aidx}.search_query`, value);
  };

  const onSaveClick = async () => {
    try {
      setButtonDisabled(true);
      await save();
      success('Success');
    } catch (e) {
      console.log(e);
      error('error');
      setButtonDisabled(false);
    }
    setButtonDisabled(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Dialog.Title
        as="h3"
        className="w-full pb-2 text-lg flex items-center leading-6 gap-x-2 font-medium text-gray-600  border-b border-gray-300"
      >
        <WrenchScrewdriverIcon className="h-7 w-7 ml-5" />
        <div className="w-full">
          <h3>Filter Results</h3>
          <span className="text-sm font-normal">
            You can add filters or queries to your answers in order to fine tune
            the result filtering.
          </span>
        </div>
        <div className="justify-self-end mr-5">
          <button
            className="hover:text-poltio-blue"
            onClick={() => setOpen(false)}
          >
            <XMarkIcon className="h-7 w-7" />
          </button>
        </div>
      </Dialog.Title>
      <div className="h-full pb-8 overflow-y-auto">
        <div className="h-screen w-full lg:grid lg:grid-cols-12 overflow-auto">
          <main className="col-span-9 lg:col-span-3 overflow-y-auto pb-2">
            {data.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setAnswers(item.answers);
                    setQidx(index);
                  }}
                  className={classNames(
                    answers[0].question_id === item.id
                      ? 'border border-poltio-blue'
                      : '',
                    'mx-2 my-2 flex rounded-md shadow-sm group cursor-pointer'
                  )}
                >
                  <div
                    className={classNames(
                      fQuestions.includes(item.id) &&
                        qQuestions.includes(item.id)
                        ? 'bg-poltio-blue'
                        : fQuestions.includes(item.id) &&
                            !qQuestions.includes(item.id)
                          ? 'bg-poltio-red'
                          : !fQuestions.includes(item.id) &&
                              qQuestions.includes(item.id)
                            ? 'bg-poltio-purple'
                            : 'bg-gray-400',
                      'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium group-hover:opacity-85 text-white'
                    )}
                  >
                    {`Q${index + 1}`}
                  </div>
                  <div className="w-full items-center justify-between rounded-r-md border-b border-r border-t group-hover:opacity-85 border-gray-200 bg-white">
                    <div className=" px-4 py-2 text-xs">
                      <span className="font-medium text-gray-700">
                        {item.title}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </main>
          <aside className="hidden lg:block lg:col-span-3 overflow-y-auto h-full min-h-screen">
            {answers.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setSelectedAnswer(item);
                    setAidx(index);
                  }}
                  className={classNames(
                    selectedAnswer.id === item.id
                      ? 'border border-poltio-blue'
                      : '',
                    'mx-2 my-2 flex rounded-md shadow-sm group cursor-pointer'
                  )}
                >
                  <div
                    className={classNames(
                      fAnswers.includes(item.id) && qAnswers.includes(item.id)
                        ? 'bg-poltio-blue'
                        : fAnswers.includes(item.id) &&
                            !qAnswers.includes(item.id)
                          ? 'bg-poltio-red'
                          : !fAnswers.includes(item.id) &&
                              qAnswers.includes(item.id)
                            ? 'bg-poltio-purple'
                            : 'bg-gray-400',
                      'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium group-hover:bg-opacity-85 text-white'
                    )}
                  >
                    {`A${index + 1}`}
                  </div>
                  <div className="w-full items-center justify-between rounded-r-md border-b border-r border-t group-hover:bg-opacity-85 border-gray-200 bg-white">
                    <div className=" px-4 py-2 text-xs">
                      <span className="font-medium text-gray-700">
                        {item.title}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </aside>
          <aside className="hidden lg:block lg:col-span-6 h-full">
            <div className="h-1/3 px-10 py-2 text-gray-600">
              <h3 className="text-xl mt-1 font-normal">Query</h3>
              <p className="text-md">
                From this you can add a query to do free text search
              </p>
              <div className="relative mt-2 flex items-center w-3/4">
                <input
                  id="query"
                  name="query"
                  type="text"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    onQueryChange(e.target.value);
                  }}
                  className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue sm:text-sm sm:leading-6"
                />
                <button
                  onClick={onQueryClick}
                  className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
                >
                  <kbd className="inline-flex items-center rounded border border-gray-200 hover:border-poltio-blue px-1 font-sans text-xs text-gray-400 hover:text-poltio-blue">
                    {queryButtonDisable ? (
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="mr-2 animate-spin"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                      </svg>
                    ) : (
                      <ArrowRightIcon className="w-5 h-5" />
                    )}
                  </kbd>
                </button>
              </div>
              <div className="justify-items-end w-3/4 text-end text-xs mt-1">
                {foundedQuery !== -1
                  ? `${foundedQuery} matching results found`
                  : msg
                    ? `${msg}`
                    : ' Please type anything to search'}
              </div>
              <div className="grid grid-cols-2">
                {qResult.length > 0
                  ? qResult.slice(0, 4).map((r, i) => {
                      return (
                        <li
                          key={i}
                          className="mx-2 my-2 h-16 flex rounded-md shadow-sm "
                        >
                          <a
                            className="flex w-16 flex-shrink-0 cursor-pointer items-center justify-center rounded-l-md text-sm font-medium text-white"
                            href={`${r.document.url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="w-32 h-16"
                              src={`https://cdn0.poltio.com/x640/${r.document.background}`}
                            />
                          </a>
                          <div className="w-full items-center justify-between rounded-r-md border-b border-r border-t  border-gray-200 bg-white">
                            <div className=" px-4 py-2 text-xs">
                              <span className="font-medium text-gray-700 line-clamp-3">
                                {r.document.title}
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  : null}
              </div>
            </div>
            <div className="w-2/3 ml-10 border border-solid border-transparent border-t-gray-400"></div>
            <div className="h-1/3 px-10 py-2 text-gray-600">
              <h3 className="text-xl mt-1 font-normal">Filters</h3>
              <p className="text-md">You can add filters</p>
              <div className="flex">
                {!hasMpFilter ? (
                  <div className="w-auto">
                    <label htmlFor="filter" className="sr-only">
                      Filters
                    </label>
                    <select
                      id="filter"
                      name="filter"
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      className="bg-white relative block w-full roundend-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-poltio-blue sm:text-sm sm:leading-6"
                    >
                      {fields.map((f, i) => {
                        return <option key={i}>{f.name}</option>;
                      })}
                    </select>
                  </div>
                ) : null}
                {hasMath ? (
                  <div className="w-16">
                    <label htmlFor="math" className="sr-only">
                      Math
                    </label>
                    <select
                      id="math"
                      name="math"
                      value={math}
                      onChange={(e) => setMath(e.target.value)}
                      className="bg-white relative block w-full roundend-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-poltio-blue sm:text-sm sm:leading-6"
                    >
                      <option>{'>'}</option>
                      <option>{'<'}</option>
                      <option>{'>='}</option>
                      <option>{'<='}</option>
                    </select>
                  </div>
                ) : null}
                <div
                  className={classNames(
                    hasMpFilter ? 'w-3/4' : 'w-1/2',
                    'relative flex items-center'
                  )}
                >
                  <input
                    id="filter"
                    name="filter"
                    type="text"
                    value={filterVal}
                    onChange={(e) => {
                      onFilterChange(e.target.value);
                    }}
                    className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-poltio-blue sm:text-sm sm:leading-6"
                  />
                  <button
                    onClick={onFilterClick}
                    className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"
                  >
                    <kbd className="inline-flex items-center rounded border border-gray-200 hover:border-poltio-blue px-1 font-sans text-xs text-gray-400 hover:text-poltio-blue">
                      {filterButtonDisable ? (
                        <svg
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="mr-2 animate-spin"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                        </svg>
                      ) : (
                        <ArrowRightIcon className="w-5 h-5" />
                      )}
                    </kbd>
                  </button>
                </div>
              </div>
              <div className="justify-items-end w-3/4 text-end text-xs mt-1">
                {foundedFilter !== -1
                  ? `${foundedFilter} matching results found`
                  : msg
                    ? `${msg}`
                    : ' Please type anything to search'}
              </div>
              <div className="grid grid-cols-2">
                {fResult.length > 0
                  ? fResult.slice(0, 4).map((r, i) => {
                      return (
                        <li
                          key={i}
                          className="mx-2 my-2 h-16 flex rounded-md shadow-sm "
                        >
                          <a
                            className="flex w-16 flex-shrink-0 cursor-pointer items-center justify-center rounded-l-md text-sm font-medium text-white"
                            href={`${r.document.url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="w-32 h-16"
                              src={`https://cdn0.poltio.com/x640/${r.document.background}`}
                            />
                          </a>
                          <div className="w-full items-center justify-between rounded-r-md border-b border-r border-t  border-gray-200 bg-white">
                            <div className=" px-4 py-2 text-xs">
                              <span className="font-medium text-gray-700 line-clamp-3">
                                {r.document.title}
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  : null}
              </div>
            </div>
          </aside>
        </div>
        <div className="border border-t border-gray-300 sticky z-50 items-center flex bg-gray-200 bottom-0 px-4 py-1 text-gray-600">
          <div className="flex mb-5 pt-3 w-full">
            <span className="inline-flex items-center gap-x-1.5 px-1.5 py-0.5 text-xs font-medium">
              <div className="h-4 w-4 rounded-md bg-poltio-blue"></div>
              Has both search query and filter.
            </span>
            <span className="inline-flex items-center gap-x-1.5 px-1.5 py-0.5 text-xs font-medium">
              <div className="h-4 w-4 rounded-md bg-poltio-red"></div>
              Has only search filter.
            </span>
            <span className="inline-flex items-center gap-x-1.5 px-1.5 py-0.5 text-xs font-medium">
              <div className="h-4 w-4 rounded-md bg-poltio-purple"></div>
              Has only search query.
            </span>
            <span className="inline-flex items-center gap-x-1.5 px-1.5 py-0.5 text-xs font-medium">
              <div className="h-4 w-4 rounded-md bg-gray-400"></div>
              No search query or search filter.
            </span>
          </div>
          <Button.Primary
            type="submit"
            onClick={onSaveClick}
            showSpinner={buttonDisabled}
            disabled={buttonDisabled || editorStatus === 2}
            className="mb-2"
          >
            Save
          </Button.Primary>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(ResultModal);
