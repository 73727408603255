const AnswerMockFields = ['title', 'background'];

class AnswerMockMixin {
  placeholder: string = '';

  public mock(idx: number = 0): any {
    return {
      ...AnswerMockFields.reduce((acc, field) => {
        let key = `answers[${idx}][${field}]`;
        let val = (this as any)[field];
        if (!val && field === 'title') {
          val = this?.placeholder ?? '';
        }

        if (val) {
          return { ...acc, [key]: val };
        } else {
          return acc;
        }
      }, {}),
    };
  }
}

export default AnswerMockMixin;
