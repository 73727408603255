import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  AccountSettingsLabel: {
    id: 'Settings.Account.index.Label',
    defaultMessage: 'AccountSettings',
  },
  AccountSettingsSave: {
    id: 'Settings.Account.index.Save',
    defaultMessage: 'Save',
  },
  AccountSettingsErrorInUse: {
    id: 'Settings.Account.index.Error.InUse',
    defaultMessage: 'There is already a user with this information',
  },
  AccountSettingsErrorUnhandled: {
    id: 'Settings.Account.index.Error.Unhandled',
    defaultMessage: 'Unhandled Error',
  },
  AccountSettingsErrorUnhandledText: {
    id: 'Settings.Account.index.Error.UnhandledText',
    defaultMessage: 'An unexpected error occured, please contact us',
  },
  AccountCoverLabel: {
    id: 'Settings.Account.Cover.Label',
    defaultMessage: 'Cover',
  },
  AccountCoverButton: {
    id: 'Settings.Account.Cover.Button',
    defaultMessage: 'Change',
  },
  AccountEmailLabel: {
    id: 'Settings.Account.Email.Label',
    defaultMessage: 'Email',
  },
  AccountEmailErrorInvalid: {
    id: 'Settings.Account.Email.Error.Invalid',
    defaultMessage: 'Invalid E-Mail',
  },
  AccountPhotoLabel: {
    id: 'Settings.Account.Photo.Label',
    defaultMessage: 'Photo',
  },
  AccountPhotoButton: {
    id: 'Settings.Account.Photo.Button',
    defaultMessage: 'Change',
  },
  AccountUsernameLabel: {
    id: 'Settings.Account.Username.Label',
    defaultMessage: 'Username',
  },
  AccountUsernameErrorTaken: {
    id: 'Settings.Account.Username.Error.Taken',
    defaultMessage: 'Username is taken',
  },
  AccountUsernameErrorInvalid: {
    id: 'Settings.Account.Username.Error.Invalid',
    defaultMessage: 'Invalid Username',
  },
  SuccessChange: {
    id: 'Settings.SuccessChange',
    defaultMessage: 'Your account information changed successfully',
  },
  UsernameTaken: {
    id: 'Settings.UsernameTaken',
    defaultMessage: 'Username is taken',
  },
  EmailTaken: {
    id: 'Settings.EmailTaken',
    defaultMessage: 'Email is taken',
  },
  UpdateProfile: {
    id: 'Settings.Account.UpdateProfile',
    defaultMessage: 'Update your profile information here.',
  },
});
