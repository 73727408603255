import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Home: {
    id: 'Home.Home',
    defaultMessage: 'Home',
  },
  NewProject: {
    id: 'Home.NewProject',
    defaultMessage: 'New Project',
  },
  Views: {
    id: 'Home.Views',
    defaultMessage: 'Views',
  },
  VoteCount: {
    id: 'Home.VoteCount',
    defaultMessage: 'Vote Count',
  },
  UniqueVoter: {
    id: 'Home.UniqueVoter',
    defaultMessage: 'Unique Voter',
  },
  Edit: {
    id: 'Home.Edit',
    defaultMessage: 'Edit',
  },
  ViewStats: {
    id: 'Home.ViewStats',
    defaultMessage: 'View Stats',
  },
  OpenInNewTab: {
    id: 'Home.OpenInNewTab',
    defaultMessage: 'Open In New Tab',
  },
  Highlights: {
    id: 'Home.Highlights',
    defaultMessage: 'Highlights',
  },
  VoteCounts: {
    id: 'Home.VoteSessionCounts',
    defaultMessage: 'Monthly Session/Vote Counts',
  },
  Month: {
    id: 'Home.Month',
    defaultMessage: 'Month',
  },
  ContentStats: {
    id: 'Home.ContentStats',
    defaultMessage: 'Content Stats',
  },
  StartDate: {
    id: 'Home.StartDate',
    defaultMessage: 'Start Date',
  },
  EndDate: {
    id: 'Home.EndDate',
    defaultMessage: 'End Date',
  },
  NumberOfContent: {
    id: 'Home.NumberOfContent',
    defaultMessage: 'Number of Content',
  },
  TotalContent: {
    id: 'Home.TotalContent',
    defaultMessage: 'Total Content',
  },
  CompletionRate: {
    id: 'Home.CompletionRate',
    defaultMessage: 'Completion Rate',
  },
  WeeklyContent: {
    id: 'Home.WeeklyContent',
    defaultMessage: 'Weekly Content',
  },
  DailyVote: {
    id: 'Home.DailyVote',
    defaultMessage: 'Daily Vote',
  },
  MonthlyVote: {
    id: 'Home.MonthlyVote',
    defaultMessage: 'Monthly Vote',
  },
  Title: {
    id: 'Home.Title',
    defaultMessage: 'Title',
  },
  Type: {
    id: 'Home.Type',
    defaultMessage: 'Type',
  },
  Vote: {
    id: 'Home.Vote',
    defaultMessage: 'Vote',
  },
  Voter: {
    id: 'Home.Voter',
    defaultMessage: 'Unique Start',
  },
  Start: {
    id: 'Home.Start',
    defaultMessage: 'Start',
  },
  Finish: {
    id: 'Home.Finish',
    defaultMessage: 'Finish',
  },
  CreatedAt: {
    id: 'Home.CreatedAt',
    defaultMessage: 'Created At',
  },
  CompRate: {
    id: 'Home.CompRate',
    defaultMessage: 'C. Rate',
  },
  NotVerify: {
    id: 'Home.NotVerify',
    defaultMessage: 'Your account is not verified!',
  },
  PleaseVerify: {
    id: 'Home.PleaseVerify',
    defaultMessage: 'Please verify your account.',
  },
  EmailSent: {
    id: 'Home.EmailSent',
    defaultMessage: 'An email has been sent to your email adress!',
  },
  ReSend: {
    id: 'Home.ReSend',
    defaultMessage: 'Click here for Resend Verification mail!',
  },
  Name: {
    id: 'Home.Name',
    defaultMessage: 'Content Internal Name',
  },
  Session: {
    id: 'Home.Session',
    defaultMessage: 'Session',
  },
  Total: {
    id: 'Home.Total',
    defaultMessage: 'Total',
  },
  TotalVote: {
    id: 'Home.TotalVote',
    defaultMessage: 'Total Vote',
  },
  ViewCount: {
    id: 'Home.ViewCount',
    defaultMessage: 'View Count',
  },
  Daily: {
    id: 'Home.Daily',
    defaultMessage: 'Daily',
  },
  Monthly: {
    id: 'Home.Monthly',
    defaultMessage: 'Monthly',
  },
  Day: {
    id: 'Home.Day',
    defaultMessage: 'Day',
  },
  VoteSessionGraphs: {
    id: 'Home.VoteSessionGraphs',
    defaultMessage: 'Vote / Session Graphs',
  },
  MetricsOverview: {
    id: 'Home.MetricsOverview',
    defaultMessage: 'Metrics Overview',
  },
  MetricsDesc: {
    id: 'Home.MetricsDesc',
    defaultMessage:
      'You can explore and compare different metrics for your account.',
  },
  CreateNew: {
    id: 'Home.CreateNew',
    defaultMessage: 'Create New Content',
  },
  StartFromScratch: {
    id: 'Home.StartFromScratch',
    defaultMessage: 'Start from scratch and create your Quiz/Survey',
  },
  DiscoverTemplates: {
    id: 'Home.DiscoverTemplates',
    defaultMessage: 'Discover Templates',
  },
  UseTemplate: {
    id: 'Home.UseTemplate',
    defaultMessage: 'Use templates we crafted for you as your starting point.',
  },
  UseAI: {
    id: 'Home.UseAI',
    defaultMessage: 'Use our creation AI to help you create a content.',
  },
  ResultView: {
    id: 'Home.ResultView',
    defaultMessage: 'Result View',
  },
  ResultClick: {
    id: 'Home.ResultClick',
    defaultMessage: 'Result Click',
  },
  UniqueResultClick: {
    id: 'Home.UniqueResultClick',
    defaultMessage: 'Unique Result Click',
  },
  ContentCreationAssistant: {
    id: 'Home.ContentCreationAssistant',
    defaultMessage: 'Content Creation Assistant',
  },
});
