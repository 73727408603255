import * as editor from '../../../../../../api/editor';
import { getReducedChanges } from '../getReducedChanges';

export function processDelete(content, changes) {
  const content_id = content?.public_id;
  if (content_id === undefined) {
    console.error('Content `public_id` is undefined at DELETE');
    return;
  }

  //#region Delete Question
  const questions = getReducedChanges(changes?.del_question ?? []);
  Object.values(questions).forEach((change) => {
    if (change.noop) {
      return;
    }
    const question_id = change?.context?.question_id;
    if (!question_id) {
      console.error('Question ID is undefined while DELETING');
      return;
    }

    editor.question.delQuestion(content_id, question_id);
  });
  //#endregion

  //#region Answer Question
  const answers = getReducedChanges(changes?.del_answer ?? []);
  Object.values(answers).forEach((change) => {
    if (change.noop) {
      return;
    }
    const question_id = change?.context?.question_id;
    const answer_id = change?.context?.answer_id;

    if (!question_id || !answer_id) {
      console.error('Question ID or Answer ID is undefined while DELETING');
      return;
    }

    editor.answer.delAnswer(content_id, question_id, answer_id);
  });
  //#endregion

  //#region Result Question
  const results = getReducedChanges(changes?.del_result ?? []);
  Object.values(results).forEach((change) => {
    if (change.noop) {
      return;
    }
    const result_id = change?.context?.result_id;

    if (!result_id) {
      console.error('Result ID is undefined while DELETING');
      return;
    }

    editor.result.delResult(content_id, result_id);
  });
  //#endregion
}
