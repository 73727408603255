import { Fragment, useRef } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import { DropdownRows } from './DropdownRows';
import ItemsList from './ItemList';

// TODO: auto-dropdown expand direction
export type DropdownData = {
  title: string;
  seletable?: boolean;

  [key: string]: any; // any other data
};

type DropdownProps = {
  data: DropdownData[];
  selectedIdx: number;

  disabled?: boolean;
  overlap?: boolean;
  modal?: boolean;
  defaultTitle?: string;
  description?: string;

  titleRenderer?: (
    data: DropdownData,
    index: number,
    array: any[],
    options: any
  ) => JSX.Element;

  renderer?: (
    data: DropdownData,
    index: number,
    array: any[],
    options: any
  ) => JSX.Element;

  onChange: (idx: number) => void;
  onFocus?: () => void;
};

export const Dropdown = ({
  data,
  selectedIdx,
  onChange,
  disabled = false,
  onFocus = () => {},
  defaultTitle = '',
  description = '',
  titleRenderer = undefined,
  renderer = undefined,
  overlap = true,
  modal = false,
}: DropdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // why did we remove 'defaultTitle' from the display value as last resort?
  let _displayValue: string | JSX.Element = '';
  if (titleRenderer) {
    _displayValue = titleRenderer(
      data?.[selectedIdx],
      selectedIdx,
      data,
      undefined
    );
  } else {
    if (data?.[selectedIdx]?.title) {
      _displayValue = data[selectedIdx].title || '';
    } else {
      _displayValue = data[selectedIdx]?.toString() || defaultTitle || '';
    }
  }

  const selectionList = data.filter((item) => item?.selectable ?? true);

  return (
    <>
      <div className="flex justify-between mt-1" ref={containerRef}>
        <Listbox value={selectedIdx} onChange={onChange} disabled={disabled}>
          <div className="flex w-full relative my-1 h-9">
            <Listbox.Button
              className="poltio-dropdown-list__btn"
              onFocus={onFocus}
            >
              <span className="block truncate">{_displayValue}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ArrowsUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="poltio-dropdown-list__options">
                {(open) => (
                  <ItemsList
                    data={selectionList}
                    renderer={renderer}
                    containerRef={containerRef}
                    overlap={overlap}
                    modal={modal}
                    onOpenCallback={onChange}
                    onCloseCallback={() => {}}
                  />
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      {description && (
        <div className="text-xs mb-2 mr-4 text-gray-400">{description}</div>
      )}
    </>
  );
};

export { DropdownRows, ItemsList };
export default Dropdown;
