import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Dialog } from '@headlessui/react';
import Button from '../Button';

const SlideOverHeader = ({ title, subtitle, setOpen, children }) => (
  <div className="py-4 px-4 bg-poltio-blue-700 sm:px-6">
    <div className="flex items-center justify-between">
      <Dialog.Title className="text-lg font-medium text-white">
        {title}
      </Dialog.Title>
      <div className="ml-3 h-7 flex items-center">
        {children}
        <Button.Primary
          type="button"
          className="bg-transparent p-0"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">{'Close Panel'}</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </Button.Primary>
      </div>
    </div>
    {subtitle && (
      <div className="mt-1">
        <p className="text-sm text-poltio-blue-300">{subtitle}</p>
      </div>
    )}
  </div>
);

export default SlideOverHeader;
