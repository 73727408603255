import React, { useContext, useEffect, useState } from 'react';

import { injectIntl } from 'react-intl';
import { TrashIcon } from '@heroicons/react/24/solid';
import messages from './messages';
import {
  SparklesIcon,
  ClipboardIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationsCTX } from '../../contexts/Notification';
import { apiSageDSRequest } from '../../api';

const DataSourceTable = ({ dataSources, handleDeleteClick, intl }) => {
  const [jobId, setJobId] = useState('');
  const [dsId, setDsId] = useState('');
  const navigate = useNavigate();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const { error, success } = useContext(NotificationsCTX);

  const onSageClick = async (id) => {
    if (!id) {
      return;
    }
    setDsId(id);
    try {
      const resp = await apiSageDSRequest(id);
      setJobId(resp.data.id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (jobId) {
      navigate(`/data-source/sage/tinker/${dsId}/job/${jobId}`, {
        state: {
          dataSourceId: dsId,
          jobId: jobId,
        },
      });
      setJobId('');
      setDsId('');
    }
  }, [jobId, dsId, navigate]);

  const statusList = [
    {
      name: 'draft',
      desc: intl.formatMessage(messages.DraftDesc),
      color: 'bg-gray-400',
    },
    {
      name: 'review',
      desc: intl.formatMessage(messages.ReviewDesc),
      color: 'bg-gray-400',
    },
    {
      name: 'removed',
      desc: intl.formatMessage(messages.RemovedDesc),
      color: 'bg-red-400',
    },
    {
      name: 'rejected',
      desc: intl.formatMessage(messages.RejectedDesc),
      color: 'bg-red-400',
    },
    {
      name: 'pending',
      desc: intl.formatMessage(messages.PendingDesc),
      color: 'bg-cyan-400',
    },
    {
      name: 'processing',
      desc: intl.formatMessage(messages.ProcessingDesc),
      color: 'bg-sky-500',
    },
    {
      name: 'failed',
      desc: intl.formatMessage(messages.FailedDesc),
      color: 'bg-red-400',
    },
    {
      name: 'synced',
      desc: intl.formatMessage(messages.SyncedDesc),
      color: 'bg-green-400',
    },
  ];

  const copySource = async (source) => {
    try {
      await navigator.clipboard.writeText(`${source}`);
      success('Source successfully copied');
    } catch (e) {
      error('Error copying source!');
    }
  };

  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {'ID'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {intl.formatMessage(messages.Name)}
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {intl.formatMessage(messages.Source)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {intl.formatMessage(messages.Type)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {intl.formatMessage(messages.Status)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {intl.formatMessage(messages.CreatedAt)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {dataSources?.map((ds, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
                      <div className="text-sm font-medium text-gray-900">
                        <Link to={`/data-source/${ds.id}#view`}>{ds.id}</Link>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {ds.name}
                      </div>
                      {ds.status === 'draft' ? (
                        <Link to={`/data-source/${ds.id}`}>
                          <span className="italic text-poltio-blue-500 truncate">
                            {'Click to continue configuration'}
                          </span>
                        </Link>
                      ) : null}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap flex flex-row">
                      <div className="text-sm font-medium text-gray-900">
                        {ds.source?.length > 30
                          ? `${ds.source?.substring(0, 30)}...`
                          : ds.source}
                      </div>
                      {ds.source ? (
                        <button onClick={() => copySource(ds.source)}>
                          <ClipboardIcon
                            className="w-5 h-5 ml-2 text-gray-400"
                            aria-hidden="true"
                          />
                        </button>
                      ) : null}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {ds.type}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 flex flex-row group relative">
                        <p
                          className={classNames(
                            `${
                              statusList.filter((s) => s.name === ds.status)[0]
                                .color
                            } px-2  rounded-md`
                          )}
                        >
                          {ds.status.toUpperCase()}
                        </p>
                        <p className="absolute bottom-7 scale-0 transition-all rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                          {
                            statusList.filter((s) => s.name === ds.status)[0]
                              .desc
                          }
                        </p>
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {`${intl.formatDate(ds.created_at)} ${intl.formatTime(
                          ds.created_at
                        )}`}
                      </div>
                      <div className="text-sm font-medium text-gray-900">
                        {`${intl.formatDate(ds.updated_at)} ${intl.formatTime(
                          ds.updated_at
                        )}`}
                      </div>
                    </td>
                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="flex justify-end z-0 w-full py-2">
                        <span className="relative inline-flex flex-row">
                          <div className="group relative">
                            {['processing', 'synced', 'failed'].includes(
                              ds.status
                            ) ? (
                              <>
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onSageClick(ds.id);
                                  }}
                                  href={''}
                                  rel="noreferrer"
                                  // to={`/data-source/sage/tinker/${ds.id}`}
                                  // state={{ dataSourceName: ds.name }}
                                >
                                  <SparklesIcon
                                    className="w-5 h-5 mr-2 -ml-1 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </a>
                                <p className="absolute bottom-7 right-0 scale-0 transition-all rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                                  {
                                    'Create engaging content with the help of SAGE AI'
                                  }
                                </p>
                              </>
                            ) : (
                              <>
                                <SparklesIcon
                                  className="w-5 h-5 mr-2 -ml-1 text-gray-100"
                                  aria-hidden="true"
                                />
                                <p className="absolute bottom-7 right-0 scale-0 transition-all rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                                  {
                                    'Finish configuration to access SAGE AI module'
                                  }
                                </p>
                              </>
                            )}
                          </div>

                          <div className="group relative">
                            <Link
                              to={`/data-source/${ds.id}#view`}
                              state={{ status: ds.status }}
                            >
                              <EyeIcon className="w-5 h-5 mr-2 -ml-1 text-gray-400" />
                            </Link>
                            <p className="absolute bottom-7 right-0 scale-0 transition-all rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                              {'View DatasSource Detail'}
                            </p>
                          </div>

                          <div className="group relative">
                            <a
                              href={''}
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteClick(ds.id);
                              }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TrashIcon
                                className="w-5 h-5 mr-2 -ml-1 text-red-400"
                                aria-hidden="true"
                              />
                            </a>
                            <p className="absolute bottom-7 right-0 scale-0 transition-all rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100">
                              {'Delete'}
                            </p>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DataSourceTable);
