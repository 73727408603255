import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Settings: {
    id: 'Navigation.Settings',
    defaultMessage: 'Settings',
  },
  Signout: {
    id: 'Navigation.Signout',
    defaultMessage: 'Sign out',
  },
  Collapse: {
    id: 'Navigation.Desktop.Collapse',
    defaultMessage: 'Collapse',
  },
  Expand: {
    id: 'Navigation.Desktop.Expand',
    defaultMessage: 'Expand',
  },
  Pin: {
    id: 'Navigation.Desktop.Pin',
    defaultMessage: 'Pin',
  },
  Unpin: {
    id: 'Navigation.Desktop.Unpin',
    defaultMessage: 'Unpin',
  },
  Resources: {
    id: 'Navigation.Resources',
    defaultMessage: 'Resources',
  },
  ContentCreationAssistant: {
    id: 'Navigation.ContentCreationAssistant',
    defaultMessage: 'Content Creation Assistant',
  },
  ContentAssistant: {
    id: 'Navigation.ContentAssistant',
    defaultMessage: 'Content Assistant',
  },
});
