import React, { useContext, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { Expander } from '../../../../../../Common/Form';
import { EditorCTX } from '../../../../../context';
import QuestionConditionRow from './QuestionConditionRow';
import messages from '../../../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const QuestionConditions = ({ intl: { formatMessage } }) => {
  const { get, set, activeQuestionIdx: qidx } = useContext(EditorCTX);

  const questions = get('.questions');
  const question = questions?.[qidx];

  const conditions = question?.conditionUIDs;
  const conditions_arr = (conditions ?? '').split(',');
  const isEveryone = !(question?.has_conditions ?? conditions);
  const isReverse = question?.condition_reverse ?? false;

  const [status, setStatus] = useState(-1);
  useEffect(() => {
    if (isEveryone) {
      setStatus(0);
    } else {
      setStatus(isReverse ? 2 : 1);
    }
  }, [isEveryone, isReverse]);

  const setEveryone = () => {
    set(`questions.${qidx}.has_conditions`, false);
    set(`questions.${qidx}.conditions`, null);
    set(`questions.${qidx}.condition_reverse`, null);
  };

  const setConditional = () => {
    set(`questions.${qidx}.has_conditions`, true);
    set(`questions.${qidx}.conditions`, conditions ?? '');
    set(`questions.${qidx}.condition_reverse`, false);
  };

  const setConditionalReverse = () => {
    set(`questions.${qidx}.has_conditions`, true);
    set(`questions.${qidx}.conditions`, conditions ?? '');
    set(`questions.${qidx}.condition_reverse`, true);
  };

  if (qidx === 0) {
    return null;
  }

  return (
    <Expander>
      <Expander.Button className="flex w-full pb-2 pt-1 px-2 hover:bg-gray-50 border-b border-[#D1D5DB]">
        {({ open }) => (
          <div className="flex w-full justify-between">
            <div className="flex w-3/4">
              <h2 className="flex text-xl font-semibold">
                {formatMessage(messages.QuestionConditions)}
              </h2>
            </div>
            <div className="flex min-w-[1.25rem]">
              <ChevronRightIcon
                className={classNames('w-5 mt-1', open ? 'rotate-90' : '')}
              />
            </div>
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="flex flex-col divide-y divide-[#D1D5DB] px-3 pb-1 mb-1">
          <div className="my-1">
            <div className="flex items-center h-5 mb-1">
              <input
                type="radio"
                checked={status === 0}
                onChange={setEveryone}
              />
              <div className="ml-1">{formatMessage(messages.Everyone)}</div>
            </div>
            <div className="flex items-center h-5 mb-1">
              <input
                type="radio"
                checked={status === 1}
                onChange={setConditional}
              />
              <div className="ml-1">{formatMessage(messages.OnlyPeopleVF)}</div>
            </div>
            <div className="flex items-center h-5">
              <input
                type="radio"
                checked={status === 2}
                onChange={setConditionalReverse}
              />
              <div className="ml-1">
                {formatMessage(messages.OnlyPeopleNVF)}
              </div>
            </div>
          </div>
          <div>
            {status === 1 || status === 2
              ? questions?.slice(0, qidx)?.map((q, index) => {
                  const title = q?.title ?? '-';

                  return (
                    <QuestionConditionRow
                      key={`condition-input-${title}-${index}`}
                      question={q}
                      conditions={conditions_arr}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(QuestionConditions);
