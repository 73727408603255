export type ReportType = 'content-sessions' | 'content-voters' | 'voter-leads';

export function makeReportRequestPayload(type: ReportType, target: any) {
  switch (type) {
    case 'voter-leads':
      return { report: type, base_id: target };
    case 'content-sessions':
    case 'content-voters':
      return { report: type, public_id: target };
    default:
      return null;
  }
}

export default makeReportRequestPayload;
