import { useEffect, useState, useCallback, type FC } from 'react';

import { getDashboardMetrics } from '../../api';
import Loading from '../Common/Loading';
import VoteStats from '../Content/Detail/Graphs/VoteStats';
import dayjs from 'dayjs';

type Props = {
  intl: any;
  messages: any;
  datesChanged: boolean;
  startDate: Date;
  endDate: Date;
};

const VoteGraph: FC<Props> = ({
  intl,
  messages,
  datesChanged,
  startDate,
  endDate,
}) => {
  const [typeValue, setTypeValue] = useState('vote');
  const [vsTypeValue, setVsTypeValue] = useState('');
  const [period, setPeriod] = useState('day');
  const [metrics, setMetrics] = useState();
  const [metricsLoading, setMetricsLoading] = useState(true);

  const getMetrics = useCallback(async () => {
    try {
      const response = await getDashboardMetrics(period, {
        start: datesChanged ? `${dayjs(startDate).format('YYYY-MM-DD')}` : null,
        end: datesChanged ? `${dayjs(endDate).format('YYYY-MM-DD')}` : null,
        metrics: vsTypeValue
          ? [`${typeValue}`, `${vsTypeValue}`]
          : [`${typeValue}`],
      });
      setMetrics(response.data);
      setMetricsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [endDate, startDate, datesChanged, period, typeValue, vsTypeValue]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return metricsLoading ? (
    <Loading />
  ) : (
    <div>
      <VoteStats
        metrics={metrics}
        typeValue={typeValue}
        setTypeValue={setTypeValue}
        vsTypeValue={vsTypeValue}
        setVsTypeValue={setVsTypeValue}
        period={period}
        setPeriod={setPeriod}
        formatMessage={intl.formatMessage}
      />
    </div>
  );
};

export default VoteGraph;
