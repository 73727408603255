import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  CreateNew: {
    id: 'DataSource.CreateNew',
    defaultMessage: 'Create New Data Source',
  },
  DataSources: {
    id: 'DataSource.DataSources',
    defaultMessage: 'Data Sources',
  },
  DataSourcesDesc: {
    id: 'DataSource.DataSourcesDesc',
    defaultMessage:
      'DataSource allows you to connect your items or services with Poltio in XML, Json or CSV format to use as AI recommendations.',
  },
  DataSource: {
    id: 'DataSource.DataSource',
    defaultMessage: 'Data Source',
  },
  Description: {
    id: 'DataSource.Description',
    defaultMessage: 'Description',
  },
  Name: {
    id: 'DataSource.Name',
    defaultMessage: 'Name',
  },
  Source: {
    id: 'DataSource.Source',
    defaultMessage: 'Source',
  },
  SourceDesc: {
    id: 'DataSource.SourceDesc',
    defaultMessage: 'URL of the {type} file.',
  },
  Type: {
    id: 'DataSource.Type',
    defaultMessage: 'Type',
  },
  Note: {
    id: 'DataSource.Note',
    defaultMessage: 'Note',
  },
  NoteDesc: {
    id: 'DataSource.NoteDesc',
    defaultMessage:
      'You can type any notes you want to tell our integration team via here.',
  },
  Cancel: {
    id: 'DataSource.Cancel',
    defaultMessage: 'Cancel',
  },
  Save: {
    id: 'DataSource.Save',
    defaultMessage: 'Save',
  },
  Notes: {
    id: 'DataSource.Notes',
    defaultMessage: 'Notes',
  },
  Status: {
    id: 'DataSource.Status',
    defaultMessage: 'Status',
  },
  CreatedAt: {
    id: 'DataSource.CreatedAt',
    defaultMessage: 'Created / Updated At',
  },
  UpdatedAt: {
    id: 'DataSource.UpdatedAt',
    defaultMessage: 'Updated At',
  },
  DraftDesc: {
    id: 'DataSource.DraftDesc',
    defaultMessage: 'submitted by user, waiting for approval.',
  },
  ReviewDesc: {
    id: 'DataSource.ReviewDesc',
    defaultMessage: 'currently in review.',
  },
  RemovedDesc: {
    id: 'DataSource.RemovedDesc',
    defaultMessage: 'request removed via user.',
  },
  RejectedDesc: {
    id: 'DataSource.RejectedDesc',
    defaultMessage: 'rejected via poltio team.',
  },
  PendingDesc: {
    id: 'DataSource.PendingDesc',
    defaultMessage: 'waiting for proccessing.',
  },
  ProcessingDesc: {
    id: 'DataSource.ProcessingDesc',
    defaultMessage: 'currently processing by the importer.',
  },
  FailedDesc: {
    id: 'DataSource.FailedDesc',
    defaultMessage: 'failed while trying to import.',
  },
  SyncedDesc: {
    id: 'DataSource.SyncedDesc',
    defaultMessage: 'up to date with no errors.',
  },
  AddDataSource: {
    id: 'DataSource.AddDataSource',
    defaultMessage: 'Add A New DataSource',
  },
  UploadFile: {
    id: 'DataSource.UploadFile',
    defaultMessage: 'Upload CSV File',
  },
  SelectType: {
    id: 'DataSource.SelectType',
    defaultMessage: 'How would you like to add your products?',
  },
  Form: {
    id: 'DataSource.Form',
    defaultMessage: 'Data Source Submission Form',
  },
  FormDesc: {
    id: 'DataSource.FormDesc',
    defaultMessage:
      'Please fill the form to submit your data source to Poltio.',
  },
  Example: {
    id: 'DataSource.Example',
    defaultMessage: 'Example CSV Template',
  },
  FeedShopify: {
    id: 'DataSource.FeedShopify',
    defaultMessage: 'How to get XML feed from your Shopify account?',
  },
  DragAndDrop: {
    id: 'DataSource.DragAndDrop',
    defaultMessage: 'or drag and drop',
  },
  ThankYou: {
    id: 'DataSource.ThankYou',
    defaultMessage:
      'Thank you! We were able to import fields from your Data Source.',
  },
  ThankYouDesc: {
    id: 'DataSource.ThankYouDesc',
    defaultMessage:
      'Now please match the fields as attributes that can be used for product recommendations.',
  },
  AllItems: {
    id: 'DataSource.AllItems',
    defaultMessage: 'All Items',
  },
  Mandatory: {
    id: 'DataSource.Mandatory',
    defaultMessage: 'Mandatory fields',
  },
  Examples: {
    id: 'DataSource.Examples',
    defaultMessage: 'Examples',
  },
  Almost: {
    id: 'DataSource.Almost',
    defaultMessage: 'You are almost there!',
  },
  ReadyPublish: {
    id: 'DataSource.ReadyPublish',
    defaultMessage: 'Your Data Source is ready to be published.',
  },
  ClickButton: {
    id: 'DataSource.ClickButton',
    defaultMessage: 'You can do this by clicking the button below.',
  },
  Publish: {
    id: 'DataSource.Publish',
    defaultMessage: 'Publish Data Source',
  },
  Published: {
    id: 'DataSource.Published',
    defaultMessage: 'Data Source Published!',
  },
  PublishMsg: {
    id: 'DataSource.PublishMsg',
    defaultMessage: 'Your Data Source has been published successfully.',
  },
  Error: {
    id: 'DataSource.Error',
    defaultMessage: 'There was an error!',
  },
  ErrorMsg: {
    id: 'DataSource.ErrorMsg',
    defaultMessage: ' Your Data Source could not be published.',
  },
  Live: {
    id: 'DataSource.Live',
    defaultMessage: 'Your Data Source is live.',
  },
  CreateWithSage: {
    id: 'DataSource.CreateWithSage',
    defaultMessage: 'Create Content with Sage',
  },
  Attribute: {
    id: 'DataSource.Attribute',
    defaultMessage: 'Attribute/Column',
  },
  AttributeDesc: {
    id: 'DataSource.AttributeDesc',
    defaultMessage: 'Attributes/Columns you select to use in your DataSource',
  },
  ItemList: {
    id: 'DataSource.ItemList',
    defaultMessage: 'DataSource Item List',
  },
  Created: {
    id: 'DataSource.Created',
    defaultMessage: 'Created At',
  },
  DataSourceForm: {
    id: 'DataSource.DataSourceForm',
    defaultMessage: 'Data Source Form',
  },
  DataSourceFormDesc: {
    id: 'DataSource.DataSourceFormDesc',
    defaultMessage: 'Fill the form for data source',
  },
  DataSourceConfig: {
    id: 'DataSource.DataSourceConfig',
    defaultMessage: 'Data Source Config',
  },
  DataSourceConfigDesc: {
    id: 'DataSource.DataSourceConfigDesc',
    defaultMessage: 'Configure details for data source',
  },
  Generate: {
    id: 'DataSource.Generate',
    defaultMessage:
      'Generate SAGE recommended questions based on your items on your DataSource',
  },
  WeRecommend: {
    id: 'DataSource.WeRecommend',
    defaultMessage: 'We recommend creating 3 to 5 questions',
  },
  GenericQuestion: {
    id: 'DataSource.GenericQuestion',
    defaultMessage: 'Generic Question',
  },
  GenericQuestionDesc: {
    id: 'DataSource.GenericQuestionDesc',
    defaultMessage:
      'Let SAGE recommend you a generic question based on your items.',
  },
  Open: {
    id: 'DataSource.Open',
    defaultMessage: 'Open',
  },
  Configure: {
    id: 'DataSource.Configure',
    defaultMessage: 'Configure',
  },
  TryAgain: {
    id: 'DataSource.TryAgain',
    defaultMessage: 'Try again?',
  },
  KeepIt: {
    id: 'DataSource.KeepIt',
    defaultMessage: 'Keep it',
  },
  ThinkingSuitable: {
    id: 'DataSource.ThinkingSuitable',
    defaultMessage: 'Thinking suitable questions to ask your audience...',
  },
  Wondering: {
    id: 'DataSource.Wondering',
    defaultMessage: 'Wondering what questions to ask...',
  },
  ThinkingHard: {
    id: 'DataSource.ThinkingHard',
    defaultMessage: 'Thinking hard... Almost there!',
  },
  JustaFew: {
    id: 'DataSource.JustaFew',
    defaultMessage: 'Just a few more seconds...',
  },
  YourQuestions: {
    id: 'DataSource.YourQuestions',
    defaultMessage: 'Your questions are being generated. Please wait.',
  },
  WeAreThinking: {
    id: 'DataSource.WeAreThinking',
    defaultMessage:
      'We are thinking hard to generate your questions. Thanks for your patience!',
  },
  GeneratingContent: {
    id: 'DataSource.GeneratingContent',
    defaultMessage: 'Generating content. This may take a few seconds.',
  },
  Creating: {
    id: 'DataSource.Creating',
    defaultMessage: 'Creating your content...',
  },
  YouCanEdit: {
    id: 'DataSource.YouCanEdit',
    defaultMessage: `You can edit your content using our editor once it's created.`,
  },
  PleaseWait: {
    id: 'DataSource.PleaseWait',
    defaultMessage: 'Generating content... Please wait.',
  },
  Working: {
    id: 'DataSource.Working',
    defaultMessage: 'Working on it... One moment.',
  },
  AlmostFinish: {
    id: 'DataSource.AlmostFinish',
    defaultMessage: 'Almost finished... Hang tight!',
  },
  ThanksFor: {
    id: 'DataSource.ThanksFor',
    defaultMessage: `We're hard at work generating your content. Thanks for your patience!`,
  },
  ThinkingQuestion: {
    id: 'DataSource.ThinkingQuestion',
    defaultMessage: 'Thinking of a new question to ask...',
  },
  SagesRecom: {
    id: 'DataSource.SagesRecom',
    defaultMessage: `Sage's recommendations`,
  },
  RecomQuestion: {
    id: 'DataSource.RecomQuestion',
    defaultMessage: 'Recommend New Question',
  },
  CreateSurvey: {
    id: 'DataSource.CreateSurvey',
    defaultMessage: 'Create Survey',
  },
  SageError: {
    id: 'DataSource.SageError',
    defaultMessage: 'An error occurred while running Sage. Please try again',
  },
  GoBack: {
    id: 'DataSource.GoBack',
    defaultMessage: 'Go to Back',
  },
  BasedOn: {
    id: 'DataSource.BasedOn',
    defaultMessage:
      'Based on your Data Source, we have generated the following questions for you to ask your audience.',
  },
  YourDataSource: {
    id: 'DataSource.YourDataSource',
    defaultMessage: 'Your Data Source',
  },
});
