import { useDraggable, useDroppable } from '@dnd-kit/core';
import { ZoomTransform } from 'd3-zoom';
import { Answer, Card } from '../../core/types';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

const AnswerPoint = ({
  a,
  pointId,
  canvasTransform,
}: {
  a: Answer;
  pointId: string;
  canvasTransform: ZoomTransform;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: pointId,
  });

  return (
    <div
      className="flex justify-between rounded-lg border border-gray-200 divide-x-2"
      key={a.id}
    >
      <div className="relative flex flex-col overflow-hidden  bg-white w-full">
        {a.background && a.background !== 'placeholder/qa.jpg' ? (
          <>
            <div className="aspect-h-3 aspect-w-3 bg-gray-200 sm:aspect-none flex max-w-32">
              <img
                src={`https://cdn0.poltio.com/460x460/${a.background}`}
                className="h-full w-full object-cover object-center sm:h-full sm:w-full"
              />
            </div>
            <div className="flex flex-1 flex-col space-y-2 p-4 text-center">
              <h3 className="text-sm font-medium text-gray-900">
                <p>{a.title}</p>
              </h3>
            </div>
          </>
        ) : (
          <div className="flex flex-col space-y-2 py-2 px-4">
            <h2 className="text-sm font-medium text-gray-900">
              <p>{a.title}</p>
            </h2>
          </div>
        )}
      </div>
      <div className="flex relative items-center">
        <div className="w-10 z-50 flex justify-center" id={`${pointId}_temp`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.88 122.88"
            className="w-1/2"
          >
            <path
              fill="#e8e8e8"
              d="M30.73,70.08h0V81.63A4.43,4.43,0,0,1,29,85.39a4.85,4.85,0,0,1-4.36.19,1.53,1.53,0,0,1-.52-.28C15.43,78.51,10.42,71.73,1.76,64.93l-.12-.1a4.32,4.32,0,0,1,0-6.78l.12-.1c8.66-6.8,13.67-13.59,22.33-20.37a1.75,1.75,0,0,1,.52-.29,4.82,4.82,0,0,1,4.35.2,4.41,4.41,0,0,1,1.77,3.76V52.8H52.88V30.73H41.25A4.43,4.43,0,0,1,37.49,29a4.85,4.85,0,0,1-.2-4.36,1.75,1.75,0,0,1,.29-.52C44.37,15.43,51.15,10.42,58,1.76l.1-.12a4.32,4.32,0,0,1,6.78,0l.1.12c6.8,8.66,13.58,13.67,20.37,22.33a1.53,1.53,0,0,1,.28.52A4.82,4.82,0,0,1,85.39,29a4.41,4.41,0,0,1-3.76,1.77H70V52.8H92.15V41.25a4.43,4.43,0,0,1,1.76-3.76,4.85,4.85,0,0,1,4.36-.2,1.88,1.88,0,0,1,.52.29c8.66,6.78,13.67,13.57,22.33,20.37l.12.1a4.32,4.32,0,0,1,0,6.78l-.12.1c-8.66,6.8-13.67,13.58-22.33,20.37a1.63,1.63,0,0,1-.52.28,4.85,4.85,0,0,1-4.36-.19,4.43,4.43,0,0,1-1.76-3.76V70.08H70V92.15H81.63a4.43,4.43,0,0,1,3.76,1.76,4.85,4.85,0,0,1,.19,4.36,1.63,1.63,0,0,1-.28.52c-6.79,8.66-13.57,13.67-20.37,22.33l-.1.12a4.32,4.32,0,0,1-6.78,0l-.1-.12c-6.8-8.66-13.58-13.67-20.37-22.33a1.88,1.88,0,0,1-.29-.52,4.85,4.85,0,0,1,.2-4.36,4.43,4.43,0,0,1,3.76-1.76H52.88V70.08Z"
            />
          </svg>
        </div>
        <div
          className="w-10"
          style={{
            left: transform ? transform?.x / canvasTransform.k : '',
            top: transform ? transform?.y / canvasTransform.k : '',
            position: 'absolute',
            zIndex: 101,
            opacity: 0,
          }}
          id={pointId}
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          onPointerDown={(e) => {
            listeners?.onPointerDown?.(e);
            e.preventDefault();
          }}
        >
          <ArrowsPointingOutIcon className="flex w-full" />
        </div>
      </div>
    </div>
  );
};

const Draggable = ({
  card,
  index,
  canvasTransform,
  isDraggedAnswer,
  questionIndex,
}: {
  card: Card;
  index: number;
  canvasTransform: ZoomTransform;
  isDraggedAnswer: boolean;
  questionIndex: number;
}) => {
  const cardAvailable = isDraggedAnswer && questionIndex < index;

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: card.id,
  });

  const { setNodeRef: setDroppableRef, isOver } = useDroppable({
    id: `${card.id}_question`,
    disabled: !cardAvailable,
  });

  let gridCount = (card.answers.length / 2).toFixed(0);

  return (
    <div
      className="flex flex-col max-w-96 w-auto cursor-grab bg-transparent drop-shadow-xl divide-y-2 divide-gray-200"
      style={{
        position: 'absolute',
        top: `${card.coordinates.y * canvasTransform.k}px`,
        left: `${card.coordinates.x * canvasTransform.k}px`,
        transformOrigin: 'top left',
        ...(transform
          ? {
              // temporary change to this position when dragging
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0px) scale(${canvasTransform.k})`,
            }
          : {
              // zoom to canvas zoom
              transform: `scale(${canvasTransform.k})`,
            }),
      }}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      // this stops the event bubbling up and triggering the canvas drag
      onPointerDown={(e) => {
        listeners?.onPointerDown?.(e);
        e.preventDefault();
      }}
    >
      <div
        className="rounded-t-lg"
        id={`${card.id}_question`}
        ref={setDroppableRef}
      >
        <div>
          <div
            className={classNames(
              'bg-poltio-blue min-h-32 text-white p-2.5 flex items-center rounded-t-lg',
              isOver ? 'opacity-70' : ''
            )}
          >
            <p
              className={classNames(
                'p-4',
                card.answers[0]?.background &&
                  card.answers[0]?.background !== 'placeholder/qa.jpg' &&
                  gridCount === '1'
                  ? 'w-56'
                  : card.answers[0]?.background &&
                      card.answers[0]?.background !== 'placeholder/qa.jpg' &&
                      gridCount >= '3'
                    ? 'w-96'
                    : 'w-72',
                isOver ? 'border-2 border-dashed' : ''
              )}
            >
              {' '}
              {`${index + 1}. ${card.title}`}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white text-black p-2.5 rounded-b-lg">
        <div
          className={classNames(
            'grid gap-y-4',
            card.answers[0]?.background &&
              card.answers[0]?.background !== 'placeholder/qa.jpg'
              ? `grid-cols-${gridCount === '1' ? 2 : gridCount >= '3' ? 3 : gridCount} gap-x-5`
              : 'grid-cols-1'
          )}
        >
          {card.answers.length > 0 ? (
            card.answers.map((a: Answer, i: number) => (
              <AnswerPoint
                key={`card_${card.id}_answerpoint_${i}`}
                a={a}
                pointId={`${a.id}_answer`}
                canvasTransform={canvasTransform}
              />
            ))
          ) : (
            <input
              defaultValue="Free Type"
              id="free"
              disabled
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Draggable;
