import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { TextInput, Expander } from '../../../../Common/Form';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import messages from '../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const CalculatorFormulaValueInput = ({
  name,
  set,
  cal_val_default = 0,
}) => {
  let [value, setValue] = useState(cal_val_default);

  const onChange = (event) => {
    let _val = event.target.value;

    if (isNaN(_val)) return;

    setValue(Number(_val));
    set(name, _val);
  };

  return (
    <div className="col-2 btn-group my-0 mx-2 px-1 py-1 justify-content-center">
      <TextInput onChange={onChange} value={value} label={name} />
    </div>
  );
};

const ValueInputForm = ({
  isLoading,
  inputs,
  valueChangeHandler,
  intl: { formatMessage },
}) => {
  return (
    <Expander
      defaultOpen={false}
      as="div"
      className="overflow-hidden bg-white rounded-lg divide-y divide-gray-200"
    >
      <Expander.Button className="flex w-full px-2 py-2 sm:px-2">
        {({ open }) => (
          <div className="flex justify-between w-full mt-1">
            <h2 className="flex text-md">
              {formatMessage(messages.QuestionsVar)}
            </h2>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        {isLoading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">
              {formatMessage(messages.Loading)}...
            </span>
          </div>
        ) : (
          <div className="row">
            {Object.keys(inputs).map((key) => (
              <CalculatorFormulaValueInput
                key={key}
                name={key}
                cal_val_default={inputs[key]}
                set={valueChangeHandler}
              />
            ))}
          </div>
        )}
      </Expander.Panel>
    </Expander>
  );
};

export const CalculatorFormulaValueInputForm = injectIntl(ValueInputForm);
