import api from '../client';

type SageQuestionResponse = {
  questions: {
    question: string;
    choices: string[];
    explanation: string;
  }[];
};
export async function question(id: string, elementId: string | number = '') {
  return api.post<SageQuestionResponse>(
    `/platform/sage/ds/${id}/question/${elementId}`
  );
}

export async function content(id: string, data: any) {
  return api.post(`/platform/sage/ds/${id}/content`, data);
}
