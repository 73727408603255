import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  SureDelete: {
    id: 'PixelCode.SureDelete',
    defaultMessage: 'Are you sure you want to delete this pixel code ?',
  },
  ErrorOccured: {
    id: 'PixelCode.ErrorOccured',
    defaultMessage: 'Error Occured!',
  },
  PixelCodeDeleted: {
    id: 'PixelCode.PixelCodeDeleted',
    defaultMessage: 'Pixel Code Deleted.',
  },
  EnterValidHTML: {
    id: 'PixelCode.EnterValidHTML',
    defaultMessage: 'Enter a Valid HTML Code!',
  },
  PixelCodeAdded: {
    id: 'PixelCode.PixelCodeAdded',
    defaultMessage: 'Pixel Code is Added!',
  },
  ErrorSaving: {
    id: 'PixelCode.ErrorSaving',
    defaultMessage: 'Error Saving Pixel Code.',
  },
  ErrorAdd: {
    id: 'PixelCode.ErrorAdd',
    defaultMessage: 'Error Adding Pixel Code',
  },
  PixelCodeSave: {
    id: 'PixelCode.SaveMsg',
    defaultMessage: 'Pixel Code is Saved!',
  },
  NewPixelCode: {
    id: 'PixelCode.NewPixelCode',
    defaultMessage: 'New Pixel Code',
  },
  EditPixelCode: {
    id: 'PixelCode.EditPixelCode',
    defaultMessage: 'You Can Edit Your Pixel Code.',
  },
  AddPixelCode: {
    id: 'PixelCode.AddPixelCode',
    defaultMessage: 'You Can Add New Pixel Code.',
  },
  Name: {
    id: 'PixelCode.Name',
    defaultMessage: 'Name',
  },
  ParentPage: {
    id: 'PixelCode.ParentPage',
    defaultMessage: 'Parent Page',
  },
  QuestionTitle: {
    id: 'PixelCode.QuestionTitle',
    defaultMessage: 'Question Title',
  },
  QuestionNumber: {
    id: 'PixelCode.QuestionNumber',
    defaultMessage: 'Question Number',
  },
  QuestionID: {
    id: 'PixelCode.QuestionID',
    defaultMessage: 'Question ID',
  },
  AnswerNumber: {
    id: 'PixelCode.AnswerNumber',
    defaultMessage: 'Answer Number',
  },
  AnswerTitle: {
    id: 'PixelCode.AnswerTitle',
    defaultMessage: 'Answer Title',
  },
  AnswerID: {
    id: 'PixelCode.AnswerID',
    defaultMessage: 'Answer ID',
  },
  Cancel: {
    id: 'PixelCode.Cancel',
    defaultMessage: 'Cancel',
  },
  Save: {
    id: 'PixelCode.Save',
    defaultMessage: 'Save',
  },
  FirePixelCount: {
    id: 'PixelCode.FirePixelCount',
    defaultMessage: 'Fire Pixel Count',
  },
  Search: {
    id: 'PixelCode.Search',
    defaultMessage: 'Search',
  },
  CreateNew: {
    id: 'PixelCode.CreateNew',
    defaultMessage: 'Create New Pixel Code',
  },
  EditCode: {
    id: 'PixelCode.EditCode',
    defaultMessage: 'Edit Pixel Code',
  },
  ClosePanel: {
    id: 'PixelCode.ClosePanel',
    defaultMessage: 'Close Panel',
  },
  DynamicParams: {
    id: 'PixelCode.DynamicParams',
    defaultMessage: 'Dynamic Parameters',
  },
  DynamicParamsDesc: {
    id: 'PixelCode.DynamicParamsDesc',
    defaultMessage:
      'You can use the following parameters to get information about the question number or answer number for the event trigger.',
  },
  PageUrlHelp: {
    id: 'PixelCode.PageUrlHelp',
    defaultMessage:
      'If you want to recieve the page url you embedded the Poltio Widget, you can use this parameter. The url will be automatically escaped. If you use widget url directly instead of embedding it to your page OR use in-app browser to load our Widget, the widget direct url with all the Query String parameters you used will be returned.',
  },
  QuestionTitleHelp: {
    id: 'PixelCode.QuestionTitleHelp',
    defaultMessage: 'The Question Title that the Pixel Code triggered.',
  },
  QuestionNumberHelp: {
    id: 'PixelCode.QuestionNumberHelp',
    defaultMessage:
      'Question number for the trigger question. (3rd question or 4th question)',
  },
  QuestionIdHelp: {
    id: 'PixelCode.QuestionIdHelp',
    defaultMessage:
      'Poltio internal Unique identifier for the Question. You can use this parameter to uniquely identify the questions in your system if you have that need. This will be an unsigned big integer',
  },
  AnswerTitleHelp: {
    id: 'PixelCode.AnswerTitleHelp',
    defaultMessage: 'The Answer Title that the Pixel Code triggered.',
  },
  AnswerNumberHelp: {
    id: 'PixelCode.AnswerNumberHelp',
    defaultMessage:
      'Answer number for the trigger question. (3rd answer or 4th answer)',
  },
  AnswerIdHelp: {
    id: 'PixelCode.AnswerIdHelp',
    defaultMessage:
      'Poltio internal Unique identifier for the Answer. You can use this parameter to uniquely identify the answers in your system if you have that need. This will be an unsigned big integer',
  },
  ResultTitle: {
    id: 'PixelCode.ResultTitle',
    defaultMessage: 'Result Title',
  },
  ResultTitleHelp: {
    id: 'PixelCode.ResultTitleHelp',
    defaultMessage: 'The Result Title that the Pixel Code triggered.',
  },
  ResultID: {
    id: 'PixelCode.ResultID',
    defaultMessage: 'Result ID',
  },
  ResultIdHelp: {
    id: 'PixelCode.ResultIdHelp',
    defaultMessage:
      'Poltio internal Unique identifier for the Result. You can use this parameter to uniquely identify the results in your system if you have that need. This will be an unsigned big integer',
  },
  ResultNumber: {
    id: 'PixelCode.ResultNumber',
    defaultMessage: 'Result Number',
  },
  ResultNumberHelp: {
    id: 'PixelCode.ResultNumberHelp',
    defaultMessage:
      'Result number for the trigger result. (3rd result or 4th result)',
  },
  PixelCodes: {
    id: 'PixelCode.PixelCodes',
    defaultMessage: 'Pixel Codes',
  },
  PixelCodesDesc: {
    id: 'PixelCode.PixelCodesDesc',
    defaultMessage:
      'PixelCodes allows you to share Widget events with external systems via javascript, iframe or image pixels real time. (Eg: Facebook Pixel, GTM, etc...)',
  },
  MoreInfo: {
    id: 'PixelCode.MoreInfo',
    defaultMessage: 'For More Information',
  },
  Docs: {
    id: 'PixelCode.Docs',
    defaultMessage: 'Documentation',
  },
  VoterIdHelp: {
    id: 'PixelCode.VoterIdHelp',
    defaultMessage:
      'Poltio internal Unique identifier for the Voter. You can use this parameter to uniquely identify the voters in your system if you have that need. This will be an unsigned big integer',
  },
  UuidHelp: {
    id: 'PixelCode.UuidHelp',
    defaultMessage:
      'It allows you to use the UUID parameter added by you in the url with pixelcode.',
  },
});
