import api from '../client';
import { generatePayload } from '../helpers/generatePayload';
import { validatePayload } from '../helpers/validatePayload';
import { ensureARGs } from '../helpers/ensureARGs';

const QuestionFields = {
  required: ['answer_type', 'title'],
  allowed: {
    answer_type: 'string',
    title: 'string',
    desc: 'string',
    name: 'string',
    background: 'string',
    allow_multiple_answers: 'boolean',
    max_multi_punch_answer: 'number',
    recommended_popular_answer: 'number',
    rotate_answers: 'boolean',
    rotate_answers_last: 'boolean',
    luv: 'string',
    is_searchable: 'boolean',
    is_skippable: 'boolean',
    cal_val_default: 'string',
    autocomplete_help: 'string',
    autocomplete_placeholder: 'string',
    position: 'number',
    conditions: 'string',
    condition_reverse: 'boolean',
    clone_answers_from: 'number',
  },
};

function makePayload(question) {
  validatePayload(question, QuestionFields.required);
  return generatePayload(question, QuestionFields.allowed);
}

// create & update call
export function saveQuestion(question, contentId, questionId) {
  ensureARGs(contentId);

  const payload = makePayload(question);

  if (Object.keys(payload).length === 0) {
    throw Error('PAYLOAD_EMPTY');
  }

  return questionId
    ? api.put(`/platform/content/${contentId}/question/${questionId}`, payload)
    : api.post(`/platform/content/${contentId}/question`, payload);
}

export const save = saveQuestion;

// delete call
export function delQuestion(contentId, questionId) {
  ensureARGs(contentId, questionId);

  return api.delete(`/platform/content/${contentId}/question/${questionId}`);
}

// attribute calls
export function saveQuestionLeads(contentId, questionId, leadId) {
  ensureARGs(contentId, questionId);

  return leadId
    ? api.put(`/platform/content/${contentId}/question/${questionId}/lead`, {
        lead_id: leadId,
      })
    : api.delete(`/platform/content/${contentId}/question/${questionId}/lead`);
}

export function saveQuestionPixelCode(contentId, questionId, pixelCodeId) {
  ensureARGs(contentId, questionId);

  return pixelCodeId
    ? api.put(
        `/platform/content/${contentId}/question/${questionId}/pixel-code`,
        { pixel_code_id: pixelCodeId }
      )
    : api.delete(
        `/platform/content/${contentId}/question/${questionId}/pixel-code`
      );
}

export function saveQuestionAnswerOrder(contentId, question) {
  const questionId = question.id;

  ensureARGs(contentId, questionId);

  const order = question.answers
    .filter((answer) => answer?.id)
    .map((answer, index) => ({ id: answer.id, position: index }));

  if (order.length !== question.answers.length) {
    throw Error('Answer ID cannot be empty');
  }

  return api.put(`/platform/content/${contentId}/order/answers/${questionId}`, {
    answers: order,
  });
}

export function saveCloneAnswersFrom(contentId, fromId, toId) {
  ensureARGs(contentId, fromId, toId);

  if (!fromId) {
    throw Error('Source Question ID cannot be empty');
  }
  if (!toId) {
    throw Error('Target Question ID cannot be empty');
  }

  return api.get(
    `/platform/content/${contentId}/question/${fromId}/answer/clone/${toId}`
  );
}
