import { useContext, useEffect, useState } from 'react';

import { EditorCTX } from '../../../../context';
import { SortableList } from '../../../../../Common/SortableList';
import { Item } from './ListItem';
import Question from '../../../../EditorAPI/EditorDataHandler/classes/Question';
import Answer from '../../../../EditorAPI/EditorDataHandler/classes/Answer';

export const QuestionsList = () => {
  const {
    data,
    activeQuestionIdx: aidx,
    setActiveQuestion,
    set,
    delQuestionAt,
    save,
  } = useContext(EditorCTX);

  const [questions, setQuestions] = useState(data?.questions || []);

  useEffect(() => {
    setQuestions(data.questions || []);
  }, [data.questions]);

  const onDuplicate = (idx) => {
    const sourceQuestion = questions[idx];
    const destinationIndex = questions.length;
    const lastPosition =
      questions[destinationIndex - 1].position ?? questions.length - 1;

    const nextQ = Question.New(
      {
        title: `${sourceQuestion.title} (Copy)`,
        desc: sourceQuestion.desc,
        background: sourceQuestion.background,
        position: lastPosition + 1,
        answer_type: sourceQuestion.answer_type,
      },
      data
    );
    set(`questions.${destinationIndex}`, nextQ);

    for (let i = 0; i < sourceQuestion.answers?.length; i++) {
      set(
        `questions.${destinationIndex}.answers.${i}`,
        Answer.New(
          {
            position: i,
            title: sourceQuestion.answers[i].title,
            background: sourceQuestion.answers[i].background,
          },
          nextQ
        ),
        {
          save: true,
        }
      );
    }

    setActiveQuestion(destinationIndex, { save: false });

    /**
     * Invoke save to force save after duplication.
     *
     * This wil go through the active changes list, and process it.
     * A delayed invocation of save can be considered to ensure that
     *   all changes are added to the changes list.
     */
    save();
  };

  const onSave = (next, overIndex) => {
    set(`properties.questions.order`, next);
    setActiveQuestion(overIndex);
  };

  return (
    <div className="max-h-96 overflow-y-auto fancy-scrollbar">
      <SortableList
        items={questions}
        onSave={onSave}
        component={Item}
        componentProps={{
          activeIdentifier: aidx,
          setActive: setActiveQuestion,
          removable: true,
          onDelete: delQuestionAt,
          onDuplicate: onDuplicate,
        }}
      />
    </div>
  );
};
