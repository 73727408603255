import { type FC, useState, useEffect, useContext } from 'react';

import { TextInput } from '../Form';
import Loading from '../Loading';
import Pagination from '../Pagination';
import { pexelsApiKey } from '../../../core/constants';
import { NotificationsCTX } from '../../../contexts/Notification';
import * as Sentry from '@sentry/react';
import messages from './messages';
import { useIntl } from 'react-intl';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

type Props = {
  onSelect: (image: any) => void;
};

const ImageSearch: FC<Props> = ({ onSelect }) => {
  const { error } = useContext(NotificationsCTX);

  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 12,
    total: 1,
    totalPage: 1,
  });
  const { formatMessage } = useIntl();

  const searchImage = async () => {
    if (query.length < 3) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search?query=${query}&per_page=12&page=${pageData.page}&orientation=square`,
        {
          headers: {
            Authorization: pexelsApiKey,
          },
        }
      );
      if (response.ok) {
        const returnedPhotos = await response.json();

        setPhotos(returnedPhotos.photos);
        setPageData({
          page: returnedPhotos.page,
          perPage: returnedPhotos.per_page,
          total: returnedPhotos.total_results,
          totalPage: Math.ceil(returnedPhotos.total_results / pageData.perPage),
        });
      } else if (response.status === 429) {
        error(
          formatMessage(messages.RateLimit),
          formatMessage(messages.TryAgain)
        );
      } else {
        throw new Error(response.status.toString());
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchImage();
  }, [pageData.page, query]);

  return (
    <div className="my-4">
      <div>
        <TextInput
          name="imagequery"
          debounce={{ timeout: 1000 }}
          value={query}
          onChange={(val: string) => setQuery(val)}
          placeholder={formatMessage(messages.SearchImage)}
        />
      </div>
      <div className="text-xs py-1 mb-1 text-gray-400 flex gap-x-2">
        <InformationCircleIcon className="h-4 w-4 text-poltio-blue-500" />
        {formatMessage(messages.SearchDesc)}
      </div>
      {photos.length ? (
        <Pagination
          last={pageData.totalPage}
          active={pageData.page}
          size={pageData.perPage}
          totalSize={pageData.total}
          handlePageChange={(p) => {
            setPageData({ ...pageData, page: p });
          }}
          noTotal
        />
      ) : null}

      {loading ? (
        <Loading />
      ) : photos.length ? (
        <>
          <div className="mt-2 p-1 grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
            {photos.map((p: any, i) => (
              <div
                key={`photo${i}`}
                className="rounded overflow-hidden shadow-lg cursor-pointer relative group"
                onClick={() => {
                  onSelect(p.src.large);
                  document
                    .getElementById('imageinputslideover')
                    ?.scrollTo(0, 0);
                }}
              >
                <img
                  className="w-full hover:opacity-50"
                  src={p.src.small}
                  alt="River"
                />
                <div className="opacity-0 group-hover:opacity-100 duration-300 text-sm absolute inset-x-0 bottom-0 flex justify-center items-end bg-white text-gray-500">
                  {formatMessage(messages.Use)}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center my-2 text-sm">
            <a
              href="https://www.pexels.com"
              target="_blank"
              rel="noreferrer"
              className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
            >
              {formatMessage(messages.PhotosProvide)}
            </a>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ImageSearch;
