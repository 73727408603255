import React from 'react';

import { sage } from '../../../api';
import { genuid } from '../../Editor/EditorAPI/EditorDataHandler/helpers/genuid';

async function handleNewQuestion(
  dsId: string | null,
  elementId: string | number | null,
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>
) {
  if (!dsId || dsId === 'new') return;

  // indicate loading
  setQuestions((prev) => {
    if (prev === null) {
      return prev;
    }

    // replace 'create' button with loading
    const last = prev[prev.length - 1];
    if (Object.keys(last).length === 0 || last.loading) {
      prev.pop();
    }

    return [...prev, { id: genuid(), loading: true }];
  });

  try {
    const response = await sage.question(dsId, elementId ?? '');
    const newQuestion = response?.data?.questions?.[0];

    if (!newQuestion) {
      return;
    }

    setQuestions((prev) => {
      if (prev === null) {
        return prev;
      }

      // remove last element of prev if it is empty, as it is 'create' button
      const last = prev[prev.length - 1];
      if (Object.keys(last).length === 0 || last.loading) {
        prev.pop();
      }

      // add new question and 'create' button at the end of the array
      return [
        ...prev,
        { id: genuid(), elementId: elementId, ...newQuestion },
        {},
      ];
    });
  } catch (err) {
    console.error(err);
  }
}

export default handleNewQuestion;
