import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  OnboardingShort: {
    id: 'Editor.OnboardingShort',
    defaultMessage: 'Onboarding Short',
  },
  OnboardingLong: {
    id: 'Editor.OnboardingLong',
    defaultMessage: 'Onboarding long',
  },
  ProductRecommender: {
    id: 'Editor.ProductRecommender',
    defaultMessage: 'Product Recommender',
  },
  QuizKnow: {
    id: 'Editor.QuizKnow',
    defaultMessage: 'Quiz (General Knowledge)',
  },
  QuizSports: {
    id: 'Editor.QuizSports',
    defaultMessage: 'Quiz (Sports)',
  },
  NoDraft: {
    id: 'Editor.NoDraft',
    defaultMessage: 'You have no Draft',
  },
  CreateEmpty: {
    id: 'Editor.CreateEmpty',
    defaultMessage: 'Create Empty',
  },
  CreateStratch: {
    id: 'Editor.CreateStratch',
    defaultMessage: 'You can create a content from scratch',
  },
  Continue: {
    id: 'Editor.Continue',
    defaultMessage: 'Continue where you left off',
  },
  UseThisTemplete: {
    id: 'Editor.UseThisTemplete',
    defaultMessage: 'Use This Template',
  },
  TryThis: {
    id: 'Editor.TryThis',
    defaultMessage: 'Try This',
  },
  GoToDrafts: {
    id: 'Editor.GoToDrafts',
    defaultMessage: 'Go To Drafts',
  },
});
