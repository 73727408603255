import React, { useRef, useState } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FormHelpText = ({ helpText = '', className = '' }) => {
  const container = useRef(null);

  const upwards = useRef(false);
  const toRight = useRef(false);

  const [open, setOpen] = useState(false);

  const isUpwards = () => {
    if (!open) {
      return upwards.current;
    }

    if (container && container.current) {
      const bottomClearence =
        window.innerHeight - container.current.getBoundingClientRect().bottom;
      upwards.current = window.innerHeight / 2 > bottomClearence;
    }

    return upwards.current;
  };

  const isToRight = () => {
    if (!open) {
      return toRight.current;
    }

    if (container && container.current) {
      toRight.current =
        window.innerWidth / 2 > container.current.getBoundingClientRect().right;
    }

    return toRight.current;
  };

  return (
    <div
      className={classNames('absolute top-0 right-0', className)}
      ref={container}
    >
      <button
        className="relative text-poltio-blue-400 px-2"
        onClick={() => setOpen((prev) => !prev)}
      >
        <QuestionMarkCircleIcon className="w-6 h-6" />
      </button>
      <div className="relative w-full flex">
        <div
          className={classNames(
            'absolute',
            'w-40 z-50',
            open ? '' : 'hidden',
            'bg-white text-gray-500 rounded text-sm p-2',
            isUpwards()
              ? 'origin-bottom bottom-2 mb-2'
              : 'origin-top top-2 mt-2',
            isToRight() ? '' : 'right-0'
          )}
          style={{ filter: 'drop-shadow(0px 0px 4px #aaacaf)' }}
        >
          <span className="h-full font-semibold">{helpText}</span>
        </div>
      </div>
    </div>
  );
};

export { FormHelpText };
export default FormHelpText;
