import React from 'react';

import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
import messages from './messages';
import Button from '../Common/Button';

const TriviaTable = ({
  intl: { formatMessage, formatDate, formatTime },
  historyData,
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {'ID'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.QuizID)}
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.PlayerCount)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.GameType)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Tier)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Cost)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Elimination)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.CreatedAt)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Status)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {historyData.map((h, i) => (
                  <tr key={i} className="even:bg-gray-50 odd:bg-white">
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        <Button.Secondary
                          type="button"
                          className="px-2.5 py-1.5 text-xs"
                          href={`/trivia/player-list/${h.id}`}
                        >
                          {h.id}
                        </Button.Secondary>
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.quiz_id}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.player_count}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.game_type}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.game_tier}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.credit_cost}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.elimination}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {formatDate(h.created_at)} {formatTime(h.created_at)}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {h.status === 'finished' ? (
                          <Button.Secondary
                            type="button"
                            className="w-20 text-xs"
                            secondary
                          >
                            {h.status}
                          </Button.Secondary>
                        ) : (
                          <Button
                            type="button"
                            className={`${
                              h.status === 'ready'
                                ? 'poltio-btn-primary'
                                : 'poltio-btn-secondary'
                            } w-20 text-xs`}
                            href={`/manage-trivia/${h.id}`}
                          >
                            {h.status}
                            <ChevronDoubleRightIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TriviaTable);
