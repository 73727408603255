import React from 'react';

import { sage } from '../../../api';
import { genuid } from '../../Editor/EditorAPI/EditorDataHandler/helpers/genuid';

async function handleReplaceQuestion(
  qid: string | null,
  dsId: string | null,
  elementId: string | number | null,
  questions: any[],
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>
) {
  if (!dsId || dsId === 'new') {
    return;
  }
  if (!qid) {
    return;
  }

  // get index of quesiton to be replaced
  const index = questions.findIndex((q) => q.id === qid);

  // indicate loading
  setQuestions((prev) => {
    if (prev === null) {
      return prev;
    }

    // replace the element
    prev[index] = { id: genuid(), loading: true };

    return prev;
  });

  try {
    const response = await sage.question(dsId, elementId ?? '');
    const newQuestion = response?.data?.questions?.[0];

    if (!newQuestion) {
      return;
    }
    setQuestions((prev) => {
      if (prev === null) {
        return prev;
      }

      // find the index of the element to be replaced
      if (index === -1) {
        return prev;
      }

      // replace the element
      prev[index] = { id: genuid(), elementId: elementId, ...newQuestion };

      // add 'create' button at the end of the array
      return [...prev, {}];
    });
  } catch (err) {
    console.error(err);
  }
}

export default handleReplaceQuestion;
