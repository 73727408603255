import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Question: {
    id: 'Editor.ContentDetail.Question',
    defaultMessage: 'Question',
  },
  AnswerImage: {
    id: 'Editor.ContentDetail.AnswerImage',
    defaultMessage: 'Answer Image',
  },
  QuestionImage: {
    id: 'Editor.ContentDetail.QuestionImage',
    defaultMessage: 'Question Image',
  },
  AddAnswer: {
    id: 'Editor.ContentDetail.AddAnswer',
    defaultMessage: 'Add Answer',
  },
  QuestionTitle: {
    id: 'Editor.ContentDetail.QuestionTitle',
    defaultMessage: 'Question Title',
  },
  Title: {
    id: 'Editor.ContentDetail.Title',
    defaultMessage: 'External Name (For End User)',
  },
  Desc: {
    id: 'Editor.ContentDetail.Desc',
    defaultMessage: 'Description',
  },
  CoverImage: {
    id: 'Editor.ContentDetail.CoverImage',
    defaultMessage: 'Cover Image',
  },
  Result: {
    id: 'Editor.ContentDetail.Result',
    defaultMessage: 'Result',
  },
  ResultDesc: {
    id: 'Editor.ContentDetail.ResultDesc',
    defaultMessage: 'Result Description',
  },
  ResultImage: {
    id: 'Editor.ContentDetail.ResultImage',
    defaultMessage: 'Result Image',
  },
  Cover: {
    id: 'Editor.ContentDetail.Cover',
    defaultMessage: 'Cover',
  },
  Results: {
    id: 'Editor.ContentDetail.Result',
    defaultMessage: 'Results',
  },
  Name: {
    id: 'Editor.ContentDetail.Name',
    defaultMessage: 'Internal Name (For Platform)',
  },
  QuestionDescription: {
    id: 'Editor.QuestionDescription',
    defaultMessage: 'Question Description',
  },
  Answers: {
    id: 'Editor.ContentDetail.Answers',
    defaultMessage: 'Answers',
  },
  EveryLine: {
    id: 'Editor.ContentDetail.EveryLine',
    defaultMessage: 'Every line will be a separate answer',
  },
  Preserve: {
    id: 'Editor.ContentDetail.Preserve',
    defaultMessage: 'Preserve Existing Answers',
  },
  PreserveDesc: {
    id: 'Editor.ContentDetail.PreverseDesc',
    defaultMessage:
      'Deletes previous answers and replaces them with the new ones of turned off',
  },
  AllowMp: {
    id: 'Editor.ContentDetail.AllowMp',
    defaultMessage: 'Allow Multiple Answers',
  },
  AllowMpDesc: {
    id: 'Editor.ContentDetail.AllowMpDesc',
    defaultMessage: 'Allows users to select more than one answer',
  },
  Autocomplete: {
    id: 'Editor.ContentDetail.Autocomplete',
    defaultMessage: 'Autocomplete',
  },
  AutocompleteDesc: {
    id: 'Editor.ContentDetail.AutocompleteDesc',
    defaultMessage: 'Allows user to type an input and see answer suggestions',
  },
  Save: {
    id: 'Editor.ContentDetail.Save',
    defaultMessage: 'Save',
  },
  Cancel: {
    id: 'Editor.ContentDetail.Cancel',
    defaultMessage: 'Cancel',
  },
  AddMultipleAnswers: {
    id: 'Editor.ContentDetail.AddMultipleAnswers',
    defaultMessage: 'Add Multiple Answers',
  },
  CorrectAnswer: {
    id: 'Editor.ContentDetail.CorrectAnswer',
    defaultMessage: 'Correct Answer',
  },
  CorrectAnswerDesc: {
    id: 'Editor.ContentDetail.CorrectAnswerDesc',
    defaultMessage:
      'Marks this answer as the correct answer for this question for points.',
  },
  AnswerTitle: {
    id: 'Editor.ContentDetail.AnswerTitle',
    defaultMessage: 'Answer Title',
  },
});
