import api from '../client';

import * as answer from './answer';
import * as content from './content';
import * as question from './question';
import * as result from './result';
import * as templates from './templates';

export function setPublished(content_id) {
  return api.get(`/platform/content/${content_id}/publish`);
}

export { answer, content, question, result, templates };
