import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  IfYouWant: {
    id: 'CustomDomain.IfYouWant',
    defaultMessage: 'If you want to use',
  },
  YouShouldEnter: {
    id: 'CustomDomain.YouShouldEnter',
    defaultMessage:
      'domain, you should enter as your custom domain for poltio widgets, please enter it without typing the',
  },
  AnyPath: {
    id: 'CustomDomain.AnyPath',
    defaultMessage: 'and any path parameters after the domain.',
  },
  CompanyDomain: {
    id: 'CustomDomain.CompanyDomain',
    defaultMessage: 'Company Domain',
  },
  ViewDetail: {
    id: 'CustomDomain.ViewDetail',
    defaultMessage: 'View Detail',
  },
  Verified: {
    id: 'CustomDomain.Verified',
    defaultMessage: 'Verified',
  },
  Active: {
    id: 'CustomDomain.Active',
    defaultMessage: 'Active',
  },
  Default: {
    id: 'CustomDomain.Default',
    defaultMessage: 'Default',
  },
  Status: {
    id: 'CustomDomain.Status',
    defaultMessage: 'Status',
  },
  NewDomain: {
    id: 'CustomDomain.NewDomain',
    defaultMessage: 'New Domain',
  },
  CustomDomain: {
    id: 'CustomDomain.CustomDomain',
    defaultMessage: 'Custom Domain',
  },
  Updated: {
    id: 'CustomDomain.Updated',
    defaultMessage: 'Domain Updated',
  },
  Error403: {
    id: 'CustomDomain.Error403',
    defaultMessage: 'You dont have permission to access this custom domain',
  },
  Error404: {
    id: 'CustomDomain.Error404',
    defaultMessage: 'This custom domain is not found',
  },
  Error409: {
    id: 'CustomDomain.Error409',
    defaultMessage: 'This custom domain already exist',
  },
  Error422: {
    id: 'CustomDomain.Error422',
    defaultMessage: 'This url alreadt taken',
  },
  Error: {
    id: 'CustomDomain',
    defaultMessage:
      'Error occurred while saving domain. Please try again later.',
  },
  SureDelete: {
    id: 'CustomDomain.SureDelete',
    defaultMessage: 'Are you sure to delete this domain?',
  },
  Removed: {
    id: 'CustomDomain.Removed',
    defaultMessage: 'Domain Removed',
  },
  Setup: {
    id: 'CustomDomain.Setup',
    defaultMessage: 'custom Domain Setup',
  },
  CreateNew: {
    id: 'CustomDomain.CreateNew',
    defaultMessage: 'Create New Custom Domain',
  },
  WillLook: {
    id: 'CustomDomain.WillLook',
    defaultMessage: 'This is how your domains will look:',
  },
  YouNeed: {
    id: 'CustomDomain.YouNeed',
    defaultMessage:
      'You need to add 2 new records in order to complete the verification process and start using your domain.',
  },
  OneCname: {
    id: 'CustomDomain.OneCname',
    defaultMessage:
      'One CNAME record to verify your ownership of the domain so we can create and use SSL Certificates with your custom domain and one A record to point your custom domain to Poltio Servers',
  },
  DnsRecord: {
    id: 'CustomDomain.DnsRecord',
    defaultMessage:
      'DNS records might take up to couple of hours for us to verify depending on your provider',
  },
  CnameRecord: {
    id: 'CustomDomain.CnameRecord',
    defaultMessage: 'CNAME Record',
  },
  TimeTo: {
    id: 'CustomDomain.TimeTo',
    defaultMessage: 'time to live',
  },
  ARecord: {
    id: 'CustomDomain.ARecord',
    defaultMessage: 'A Record',
  },
});
