import { useEffect, useCallback, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as editor from '../../api/editor';
import { NotificationsCTX } from '../../contexts/Notification';
import LoadingDialog from '../Common/Loading/LoadingDialog';

const PublishFromTemplate = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { error } = useContext(NotificationsCTX);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const activeQuestion = searchParams.get('active_question');
    try {
      const { data } = await editor.templates.use(id);
      if (activeQuestion) {
        navigate(`/editor/${data.public_id}?active_question=${activeQuestion}`);
      } else {
        navigate(`/preview/${data.public_id}`);
      }
    } catch (e) {
      error('Error occured! Please try again.');
      navigate('/');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return <LoadingDialog show={true} />;
};

export default PublishFromTemplate;
