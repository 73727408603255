import React, { useState, useEffect, useCallback } from 'react';

import { getContents } from '../../api';
import { injectIntl } from 'react-intl';
import messages from './messages';

import ContentCard from '../Common/ContentCard';
import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import ContentTable from './ContentTable';
import Filters from './Filters';
import { isMobile } from '../../core/helper';
import usePersistentStore from '../../core/usePersistentStore';
import Heading from '../Common/Page/Heading';
import { Helmet } from 'react-helmet';

const Content = ({ intl: { formatMessage } }) => {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sizePerPage, setSizePerPage] = usePersistentStore(12, 'spp-content');
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    lastPage: null,
    order: 'created_at',
    sort: 'desc',
    type: [],
    searchText: '',
  });
  const [isList, setIsList] = useState(
    !isMobile(window.innerWidth)
      ? localStorage.getItem('content_view') !== 'grid'
      : false
  );

  const fetchContents = useCallback(async () => {
    setLoading(true);
    const { data } = await getContents(
      pageData.page,
      sizePerPage,
      pageData.order,
      pageData.sort,
      pageData.type,
      pageData.searchText
    );
    setContents(data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        ...p,
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
  }, [
    pageData.page,
    sizePerPage,
    pageData.order,
    pageData.sort,
    pageData.type,
    pageData.searchText,
  ]);

  const handlePageChange = (page, sizePerPage) => {
    setSizePerPage(sizePerPage);
    setPageData({ ...pageData, page });
  };

  const sortChanged = (val) => {
    setPageData({
      ...pageData,
      order: val.order,
      sort: val.sort,
      page: 1,
    });
  };

  const typeChanged = (name) => {
    if (pageData.type.includes(name)) {
      setPageData({
        ...pageData,
        type: pageData.type.filter((t) => t !== name),
        page: 1,
      });
    } else {
      setPageData({
        ...pageData,
        type: [...pageData.type, name],
        page: 1,
      });
    }
  };

  const setSearchText = (val) => {
    setPageData({
      ...pageData,
      searchText: val,
      page: 1,
    });
  };

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  const toggleViewType = () => {
    setIsList(!isList);
    localStorage.setItem('content_view', isList ? 'grid' : 'list');
  };

  return (
    <div>
      <Helmet>
        <title>Contents | Poltio Platform</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.Content)}
          body={formatMessage(messages.Desc)}
        >
          <Filters
            sortChanged={sortChanged}
            typeChanged={typeChanged}
            contentTypes={pageData.type}
            setSearchText={setSearchText}
            toggleViewType={toggleViewType}
            isList={isList}
          />
        </Heading>
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          {isList ? (
            <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
              <ContentTable contents={contents} fetchContents={fetchContents} />
            </div>
          ) : (
            <div className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
              {contents.map((content) => {
                return (
                  <ContentCard
                    key={`content$-${content.type}-${content.public_id}`}
                    content={content}
                    fetchContents={fetchContents}
                  />
                );
              })}
            </div>
          )}

          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={sizePerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(Content);
