import { isObject } from '../../../helpers/isObject';

export function merge(target, ...sources) {
  if (sources.length === 0) {
    // nothing to merge into
    return target;
  }

  /**
   * Removes the first element from an array and returns it.
   *  If the array is empty, undefined is returned and the array is not modified.
   * */
  const source = sources.shift();

  if (!isObject(target) || !isObject(source)) {
    merge(target, ...sources);
  }

  for (const prop in source) {
    if (isObject(source[prop])) {
      // object assignment
      if (!target[prop]) {
        // target does not contain said property, create
        Object.assign(target, { [prop]: {} });
      }
      merge(target[prop], source[prop]);
    } else {
      // non-object assignment
      Object.assign(target, { [prop]: source[prop] });
    }
  }

  return merge(target, ...sources);
}
