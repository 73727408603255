export const defaultContentObj = Object.freeze({
  title: '',
  lead: {},
  questions: [],
  results: [],
});

export const defaultQuestionObj = Object.freeze({
  title: '',
  answers: [{ title: '', background: '' }],
});

export const defaultTextAnswers = Object.freeze([
  {
    title: 'Answer 1',
  },
  {
    title: 'Answer 2',
  },
]);

export const defaultTextQuestion = Object.freeze({
  title: '',
  background: '',
  answer_type: 'text',
  answers: [...defaultTextAnswers],
});

export const defaultMediaAnswers = Object.freeze([
  {
    title: 'Answer 1',
    background: '',
  },
  {
    title: 'Answer 2',
    background: '',
  },
]);

export const defaultMediaQuestion = Object.freeze({
  title: '',
  background: '',
  answer_type: 'media',
  answers: [...defaultMediaAnswers],
});

export const defaultScoreAnswers = Object.freeze([
  {
    title: '1',
  },
  {
    title: '2',
  },
  {
    title: '3',
  },
  {
    title: '4',
  },
  {
    title: '5',
  },
]);

export const defaultResult = Object.freeze({
  title: 'Result',
  background: '',
});

export const AllowedCloneableTypes = ['text', 'media', 'score'];

export const ContentTypePresets = {
  survey: {
    type: 'set',
    is_calculator: false,
    is_searchable: false,
  },
  quiz: {
    type: 'quiz',
    is_calculator: false,
    is_searchable: false,
    skip_start: false,
    attributes: {
      show_timer: true,
      gives_feedback: true,
    },
  },
  test: {
    type: 'test',
    is_calculator: false,
    is_searchable: false,
    result_loading: true,
    attributes: {
      show_timer: false,
      gives_feedback: false,
      display_results: false,
    },
  },
  calculator_set: {
    type: 'set',
    is_calculator: true,
    is_searchable: false,
  },
  searchable_set: {
    type: 'set',
    is_calculator: false,
    is_searchable: true,
  },
};

export const ContentTypePresetsInitialLoad = {
  survey: {
    type: 'set',
    is_calculator: false,
    is_searchable: false,
  },
  quiz: {
    type: 'quiz',
    is_calculator: false,
    is_searchable: false,
  },
  test: {
    type: 'test',
    is_calculator: false,
    is_searchable: false,
  },
  calculator_set: {
    type: 'set',
    is_calculator: true,
    is_searchable: false,
  },
  searchable_set: {
    type: 'set',
    is_calculator: false,
    is_searchable: true,
  },
};
