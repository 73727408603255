import React, { useEffect, useState } from 'react';
import SwitchInput from './SwitchInput';

const ConditionalSection = ({
  label = '',
  checked = false,
  onSwitch = () => {},
  children,
  description,
  disabled,
}) => {
  const [open, setOpen] = useState(checked);
  useEffect(() => setOpen(checked), [checked]);

  const handleSwitch = (value) => {
    setOpen(value);
    onSwitch(value);
  };

  return (
    <div className="">
      <SwitchInput value={open} onSwitch={handleSwitch} disabled={disabled}>
        {label}
      </SwitchInput>
      {description && (
        <div className="text-xs text-gray-400">{description}</div>
      )}
      {open ? <div className="px-2">{!!children && children}</div> : null}
    </div>
  );
};

export { ConditionalSection };
export default ConditionalSection;
