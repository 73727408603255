import Answer from '../../EditorDataHandler/classes/Answer';

export async function NewAnswer(data, qidx, set, save) {
  if (!data?.public_id) {
    return;
  }

  await save();

  const question = data.questions[qidx];
  const aidx = question?.answers?.length ?? 0;

  const next = Answer.New(
    {
      placeholder: `Answer ${aidx + 1}`,
      position: aidx,
    },
    question
  );

  // update max_multi_punch_answer value, if allow mp
  if (question?.allow_multiple_answers) {
    const cmp_c = question?.max_multi_punch_answer ?? 1; // current multipunch count
    const ans_c = question?.answers?.length ?? 0; // answer count
    if (cmp_c > 1 || cmp_c === ans_c) {
      // match max_mp to answer count
      // incr by 1 because we will add 1 question
      // increment on answer count, instead of mp count because... that is the lenght?
      set(`questions.${qidx}.max_multi_punch_answer`, ans_c + 1);
    }
  }

  set(`questions.${qidx}.answers.${aidx}`, next, { save: false });
}
