import {
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ErrorAlert = ({ title, body }) => (
  <div className="rounded-md bg-red-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">{title}</h3>
        <div className="mt-2 text-sm text-red-700">{body}</div>
      </div>
    </div>
  </div>
);

export const WarningAlert = ({ title, body, footer }) => (
  <div className="rounded-md bg-yellow-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationCircleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-yellow-800">{title}</h3>

        <div
          className={classNames(title ? 'mt-2' : '', 'text-sm text-yellow-700')}
        >
          {body}
        </div>
        <div className="mt-4">
          <div className="-mx-2 -my-1.5 flex">{footer}</div>
        </div>
      </div>
    </div>
  </div>
);

export const InfoAlert = ({ title, body, footer }) => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-blue-700 hover:text-blue-600">
          {title}
        </h3>
        <div className="mt-2 text-sm text-blue-700 hover:text-blue-600">
          {body}
        </div>
        <div className="mt-4">
          <div className="-mx-2 -my-1.5 flex">{footer}</div>
        </div>
      </div>
    </div>
  </div>
);

export const SuccessAlert = ({ title }) => (
  <div className="rounded-md bg-green-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-green-800">{title}</p>
      </div>
    </div>
  </div>
);
