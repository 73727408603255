import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Unsaved: {
    id: 'Editor.Preview.Unsaved',
    defaultMessage: 'Unsaved',
  },
  Syncing: {
    id: 'Editor.Preview.Syncing',
    defaultMessage: 'Syncing',
  },
  Saved: {
    id: 'Editor.Preview.Saved',
    defaultMessage: 'Saved',
  },
  Error: {
    id: 'Editor.Preview.Error',
    defaultMessage: 'Error',
  },
  SkipStartWarn: {
    id: 'Editor.Preview.SkipStartWarn',
    defaultMessage:
      "The user will directly see the first question as 'skip start' option is selected",
  },
  SkipResultWarn: {
    id: 'Editor.Preview.SkipResultWarn',
    defaultMessage:
      "The user will not see the result(s) as 'skip result' option is selected",
  },
  ResultImageOptions: {
    id: 'Editor.Preview.ResultImageOptions',
    defaultMessage: 'Result Image Options',
  },
  ResultFit: {
    id: 'Editor.Preview.ResultFit',
    defaultMessage: 'Result Fit',
  },
  Off: {
    id: 'Editor.Preview.Off',
    defaultMessage: 'Off',
  },
  ImageCrop: {
    id: 'Editor.Preview.ImageCrop',
    defaultMessage: 'Image Crop (Default)',
  },
  Fit: {
    id: 'Editor.Preview.Fit',
    defaultMessage: 'Fit',
  },
  VerticalFit: {
    id: 'Editor.Preview.VerticalFit',
    defaultMessage: 'Vertical Fit',
  },
  DocsDesc: {
    id: 'Editor.Preview.DocsDesc',
    defaultMessage: 'To learn how to use these options, you can checkout ',
  },
  OurDocs: {
    id: 'Editor.Preview.OurDocs',
    defaultMessage: 'our documentation.',
  },
});
