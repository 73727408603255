import { generatePayload } from '../../../../../../api/helpers/generatePayload';
import { validatePayload } from '../../../../../../api/helpers/validatePayload';

type PayloadFields = {
  required?: string[];
  allowed: { [K: string]: string };
};

abstract class ResultSaveMixin {
  _payloadFields?: PayloadFields;

  public makePayload() {
    if (!this._payloadFields) {
      return null;
    }

    try {
      validatePayload(this, this._payloadFields?.required ?? []);
    } catch (error) {
      console.error(error);
      return;
    }

    return generatePayload(this, this._payloadFields.allowed);
  }

  public payload() {
    return this.makePayload();
  }
}

export default ResultSaveMixin;
