import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/24/outline';
import SaveButton from './SaveButton';
import { serialize } from '../../../../core/helper';
import { ConfTypes } from '../../enums';
import { EditorCTX } from '../../context';
import messages from './messages';
import { embedUrlPrefix } from '../../../../core/constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const VIEWPORT_WIDTH_TRESHOLD = 480;

function eventHandler(event, ref) {
  if (!ref?.current) {
    return null;
  }

  const container = ref.current;
  const viewport = container?.getBoundingClientRect();
  if (!viewport) {
    return null;
  }

  const _w = viewport.width;

  if (_w < VIEWPORT_WIDTH_TRESHOLD) {
    return { disable: true, isMobile: true };
  }

  return { disable: false, isMobile: undefined };
}

const EditorPreview = ({ intl: { formatMessage } }) => {
  const intl = useIntl();
  const { confType, getMockData, data } = useContext(EditorCTX);

  const [isMobile, setIsMobile] = useState(false);
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const [resultfit, setResultFit] = useState('sc');
  const containerRef = useRef();

  useEffect(() => {
    const listener = (event) => {
      const { disable, isMobile } = eventHandler(event, containerRef);
      setIsMobile((prev) => isMobile ?? prev);
      setToggleDisabled((prev) => disable ?? prev);
    };

    listener();

    const elem = document.getElementById('frame_elem');

    const myObserver = new ResizeObserver(() => {
      listener();
    });

    myObserver.observe(elem);

    return () => {
      myObserver.disconnect();
    };
  }, [containerRef]);

  const theme = data?.theme;
  const bg = theme?.background_color;

  const confTypeAdjusted =
    confType === ConfTypes.Options ? ConfTypes.Cover : confType;
  let iframeSRC = `https://${embedUrlPrefix}/mock/${confTypeAdjusted}?loc=${intl.locale}&disclaimer=off&resultfit=${resultfit}&`;
  if (theme) {
    iframeSRC += `theme=yes&${serialize(theme)}&`;
  }
  const mockData = getMockData(confTypeAdjusted, intl.locale);
  if (mockData) {
    iframeSRC += `${serialize(mockData)}`;
  }

  const shouldBlurCover = useMemo(
    () => data.skip_start && confTypeAdjusted === ConfTypes.Cover,
    [confTypeAdjusted, data.skip_start]
  );
  const shouldBlurResult = useMemo(
    () => data.skip_result && confTypeAdjusted === ConfTypes.Result,
    [confTypeAdjusted, data.skip_result]
  );
  const shouldAddOpacity = useMemo(
    () => shouldBlurCover || shouldBlurResult,
    [shouldBlurCover, shouldBlurResult]
  );

  const showResultImageOptions = confType === ConfTypes.Result;

  return (
    <div
      className="relative h-full"
      style={{
        backgroundColor: bg,
        boxShadow: 'inset 0px 0px 16px rgba(197, 199, 215, 0.38)',
      }}
    >
      <div className="sticky top-0 w-full">
        <div className="relative flex items-center justify-center">
          <div className="flex items-center justify-center py-5">
            <button
              className={classNames(
                'px-1 mx-1',
                isMobile ? '' : 'text-poltio-blue-500',
                'disabled:text-gray-400'
              )}
              disabled={toggleDisabled}
              onClick={() => setIsMobile(false)}
            >
              <ComputerDesktopIcon className="h-8" />
            </button>
            <button
              className={classNames(
                'px-1 mx-1',
                isMobile ? 'text-poltio-blue-500' : '',
                'disabled:text-gray-400'
              )}
              disabled={toggleDisabled}
              onClick={() => setIsMobile(true)}
            >
              <DevicePhoneMobileIcon className="h-8" />
            </button>
          </div>
          <div className="absolute right-0 pr-2">
            <SaveButton />
          </div>
        </div>
        {showResultImageOptions ? (
          <div className="relative flex items-center justify-center">
            <fieldset className="m-3 col-span-1">
              <div>
                <legend className="text-base font-medium text-gray-900">
                  {formatMessage(messages.ResultImageOptions)}
                </legend>
              </div>
              <div className="mt-2 space-y-2">
                <div className="flex items-center">
                  <input
                    id="rfsc"
                    name="resultfit"
                    value="sc"
                    type="radio"
                    checked={resultfit === 'sc'}
                    onChange={(e) => setResultFit(e.target.value)}
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
                  />
                  <label
                    htmlFor="aligncenter"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {formatMessage(messages.ImageCrop)}
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="rfoff"
                    name="resultfit"
                    value="off"
                    type="radio"
                    checked={resultfit === 'off'}
                    onChange={(e) => setResultFit(e.target.value)}
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
                  />
                  <label
                    htmlFor="alignleft"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {formatMessage(messages.Off)}
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="rffit"
                    name="resultfit"
                    value="fit"
                    type="radio"
                    checked={resultfit === 'fit'}
                    onChange={(e) => setResultFit(e.target.value)}
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
                  />
                  <label
                    htmlFor="alignright"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {formatMessage(messages.Fit)}
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="rfvf"
                    name="resultfit"
                    value="vf"
                    type="radio"
                    checked={resultfit === 'vf'}
                    onChange={(e) => setResultFit(e.target.value)}
                    className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
                  />
                  <label
                    htmlFor="aligncenter"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {formatMessage(messages.VerticalFit)}
                  </label>
                </div>
              </div>
              <p className="mt-1 text-sm font-normal leading-6 text-gray-900">
                {formatMessage(messages.DocsDesc)}
                <a
                  className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                  href="https://platform.poltio.com/docs/options/#result-fit"
                  target="_blank"
                  rel="noreferrer"
                >
                  {formatMessage(messages.OurDocs)}
                </a>
              </p>
            </fieldset>
          </div>
        ) : null}
        <div className="flex justify-center" ref={containerRef} id="frame_elem">
          <div
            className={classNames(
              'flex justify-center w-full',
              isMobile ? 'max-w-xs' : 'max-w-full',
              'mx-4'
            )}
          >
            <iframe
              id="poltio-embed-mock"
              className={classNames(
                'outline-none poltio-widget',
                shouldAddOpacity ? 'opacity-10' : ''
              )}
              src={iframeSRC}
              width={'100%'}
              allowFullScreen="allowfullscreen"
              title="Embed"
            />
            {shouldBlurCover ? (
              <div className="absolute inset-y-15 h-48 flex justify-center items-center z-10">
                <p className="m-6 text-center text-2xl font-bold">
                  {formatMessage(messages.SkipStartWarn)}
                </p>
              </div>
            ) : null}
            {shouldBlurResult ? (
              <div className="absolute inset-y-15 h-48 flex justify-center items-center z-10">
                <p className="m-6 text-center text-2xl font-bold">
                  {formatMessage(messages.SkipResultWarn)}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EditorPreview);
