import { defineMessages, IntlFormatters } from 'react-intl';

type IntlFormatMessageFn = IntlFormatters['formatMessage'];

type PoltioAPIErrorResponseErrors = {
  [key: string]: string[];
};

type PoltioAPIErrorResponse = {
  msg: string;
  errors: PoltioAPIErrorResponseErrors;
};

type IntlMessages = {
  [key: string]: any;
};

const humanReadableErrorMessages: IntlMessages = defineMessages({
  validation_required: {
    id: 'errors.validation.required',
    defaultMessage: 'This field is required',
  },
});

const humanReadableFieldNames: IntlMessages = defineMessages({
  desc: {
    id: 'fields.desc',
    defaultMessage: 'Description',
  },
});

export function errorToHumanReadable(
  formatMessage: IntlFormatMessageFn,
  response: PoltioAPIErrorResponse
): string[] {
  const errors = response.errors ?? {};

  let messages: string[] = [];

  Object.keys(errors).forEach((field) => {
    const field_errors = errors[field];

    field_errors.forEach((error) => {
      const error_key = error.replace('.', '_');

      let hrError;
      if (humanReadableErrorMessages[error_key]) {
        hrError = formatMessage(humanReadableErrorMessages[error_key]);
      } else {
        hrError = error_key;
      }

      let hrField;
      if (humanReadableFieldNames[field]) {
        hrField = formatMessage(humanReadableFieldNames[field]);
      } else {
        hrField = field;
      }

      messages.push(`${hrField}: ${hrError}`);
    });
  });

  return messages;
}
