import api from '../client';
import { generatePayload } from '../helpers/generatePayload';
import { validatePayload } from '../helpers/validatePayload';
import { ensureARGs } from '../helpers/ensureARGs';

const ResultFields = {
  required: [],
  allowed: [
    'title',
    'background',
    'desc',
    'luv',
    'url',
    'url_text',
    'search',
    'search2',
    'min_c',
    'max_c',
    'is_default',
  ],
};

function makePayload(result) {
  validatePayload(result, ResultFields.required);
  return generatePayload(result, ResultFields.allowed);
}

// get call
export function getResults(contentId, page = 1, perPage = 10) {
  return api.get(
    `/platform/content/${contentId}/results?page=${page}&per_page=${perPage}`
  );
}

// create & update call
export function saveResult(result, contentId, resultId) {
  ensureARGs(contentId);

  const payload = makePayload(result);

  if (Object.keys(payload).length === 0) {
    throw Error('PAYLOAD_EMPTY');
  }

  return resultId
    ? api.put(`/platform/content/${contentId}/result/${resultId}`, payload)
    : api.post(`/platform/content/${contentId}/result`, payload);
}

// delete call
export function delResult(contentId, resultId) {
  ensureARGs(contentId, resultId);

  return api.delete(`/platform/content/${contentId}/result/${resultId}`);
}

// attribute calls
export function saveResultLeads(contentId, resultId, leadId) {
  ensureARGs(contentId, resultId);

  return leadId
    ? api.put(`/platform/content/${contentId}/result/${resultId}/lead`, {
        lead_id: leadId,
      })
    : api.delete(`/platform/content/${contentId}/result/${resultId}/lead`);
}

export function saveResultPixelCode(contentId, resultId, pixelCodeId) {
  ensureARGs(contentId, resultId);

  return pixelCodeId
    ? api.put(`/platform/content/${contentId}/result/${resultId}/pixel-code`, {
        pixel_code_id: pixelCodeId,
      })
    : api.delete(
        `/platform/content/${contentId}/result/${resultId}/pixel-code`
      );
}
export function saveResultClickPixelCode(
  contentId,
  resultId,
  clickPixelCodeId
) {
  ensureARGs(contentId, resultId);

  return clickPixelCodeId
    ? api.put(
        `/platform/content/${contentId}/result/${resultId}/click-pixel-code`,
        {
          pixel_code_id: clickPixelCodeId,
        }
      )
    : api.delete(
        `/platform/content/${contentId}/result/${resultId}/click-pixel-code`
      );
}
