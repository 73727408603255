import { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import * as Sentry from '@sentry/react';

import { uploadImage } from '../../../api';

import messages from './messages';

import { NotificationsCTX } from '../../../contexts/Notification';
import ImageInputContent from './ImageInputContent';
import ImageButton from './ImageButton';
import Modal from './DisplayModal';
import SlideOver from './DisplaySlideOver';
import {
  feedAvatarPathRetina,
  feedDailyImagePathRetina,
  feedImagePathRetina,
  widgetAnswerImagePath,
  widgetCoverImagePath,
  widgetImagePath,
  widgetResultImagePath,
} from '../../../core/constants';

import type { WrappedComponentProps } from 'react-intl';

type Props = {
  src?: string;
  title?: string;
  callback?: (image_url: string) => void;
  callbackB64?: (image_b64: string) => void;
  modal?: boolean;
  avatar?: boolean;
  hasIcon?: boolean;
  fallback?: string;
  aspectRatio?: number;
  forceCrop?: boolean;
  onClick?: (next: boolean) => void;

  type:
    | 'cover'
    | 'question'
    | 'answer'
    | 'result'
    | 'avatar'
    | 'feed'
    | 'account-cover'
    | '';
} & WrappedComponentProps;

const ImageInput = (props: Props) => {
  const {
    title = '',
    src = '',
    callback = undefined, //(image_url) => {},
    modal = false,
    avatar = false,
    hasIcon = true,
    fallback = '',
    aspectRatio = 8 / 5,
    forceCrop = false,
    onClick = (next) => {},
    type: _type = '',
    intl: { formatMessage },
  } = props;

  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { error } = useContext(NotificationsCTX);

  function setOpen(state: boolean) {
    if (state) {
      if (onClick) {
        onClick(state);
      }
    }
    setShow(state);
  }

  async function onSubmit(imgB64: string) {
    if (!callback) {
      // if callback is not defined, just ignore
      setShow(false);
      return;
    }

    // if callback is defined, upload image to server

    if (imgB64 === '' || imgB64 === undefined || imgB64 === null) {
      // if empty, resolve to 'fallback'
      callback(fallback);
      return;
    }

    setLoading(true);

    // find extension
    const ext = imgB64.match(/data:image\/(.*);base64,/)?.[1] ?? 'jpeg';

    let imgsrc = '';
    try {
      const response = await uploadImage(imgB64, ext);
      imgsrc = response.data.file;
    } catch (err: any) {
      const status = err?.response?.status ?? -1;
      switch (status) {
        case 500:
          error(
            formatMessage(messages.ServerError),
            formatMessage(messages.ServerErrorDesc),
            5000
          );
          break;

        case 400:
          error(
            formatMessage(messages.NotAnImage),
            formatMessage(messages.NotAnImageDesc),
            5000
          );
          break;

        default:
          error(formatMessage(messages.ErrorWhileUploading));
          break;
      }

      Sentry.captureException(err);
      return;
    }

    let type = _type;
    let imgpath = '';

    if (avatar) {
      type = 'avatar';
    }

    switch (type) {
      case 'cover':
      case 'question':
        imgpath = `${widgetCoverImagePath}/${imgsrc}`;
        break;
      case 'answer':
        imgpath = `${widgetAnswerImagePath}/${imgsrc}`;
        break;
      case 'result':
        imgpath = `${widgetResultImagePath}/${imgsrc}`;
        break;
      case 'avatar':
        imgpath = `${feedAvatarPathRetina}/${imgsrc}`;
        break;
      case 'feed':
        imgpath = `${feedImagePathRetina}/${imgsrc}`;
        break;
      case 'account-cover':
        imgpath = `${feedDailyImagePathRetina}/${imgsrc}`;
        break;
      default:
        imgpath = `${widgetImagePath}/${imgsrc}`;
        break;
    }

    const img = new Image();
    img.addEventListener(
      'load',
      () => {
        callback(imgsrc);

        setLoading(false);
        setShow(false);
      },
      false
    );
    img.src = imgpath;
  }

  const _title = title || formatMessage(messages.ImageButtonMessage);

  return (
    <>
      <ImageButton
        image={src}
        onClick={() => setOpen(true)}
        avatar={avatar}
        hasIcon={hasIcon}
        isLoading={isLoading}
      >
        {formatMessage(messages.ImageButtonMessage)}
      </ImageButton>
      {modal ? (
        <Modal
          title={_title}
          open={show}
          setOpen={setOpen}
          isLoading={isLoading}
        >
          <ImageInputContent
            src={src}
            callback={onSubmit}
            aspect={aspectRatio}
            forceCrop={forceCrop}
          />
        </Modal>
      ) : (
        <SlideOver
          title={_title}
          open={show}
          setOpen={setOpen}
          isLoading={isLoading}
        >
          <ImageInputContent
            src={src}
            callback={onSubmit}
            aspect={aspectRatio}
            forceCrop={forceCrop}
          />
        </SlideOver>
      )}
    </>
  );
};

export default injectIntl(ImageInput);
