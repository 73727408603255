import { useCallback, useEffect, useState } from 'react';
import SearchField from '.';
import { getDefaultTheme, getThemes } from '../../../api';

const ThemeSearchRowRenderer = ({ item }) => {
  return (
    <span className="flex">
      <span className="w-1/6">{item.id}</span>
      <span className="truncate">{item.name}</span>
    </span>
  );
};

const ThemeSearch = ({
  themeId,
  themeName,
  onSelect = () => {},
  isLoading,
}) => {
  const [name, setName] = useState(themeName);
  const [themes_c, setThemesC] = useState(-1);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 100,
    lastPage: null,
  });
  const [themes, setThemes] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getThemes(pageData.page, pageData.sizePerPage);
      if (data.data.length) {
        setThemes(data.data);
        setName(data?.data[0]?.name);
        onSelect(data?.data[0]);
        setThemesC(data.data.length);
        setPageData((p) => {
          return {
            page: p.page,
            sizePerPage: p.sizePerPage,
            totalSize: data.total,
            lastPage: data.last_page,
          };
        });
      } else {
        const { data: defaultTheme } = await getDefaultTheme();
        setName(defaultTheme?.name);
        onSelect(defaultTheme);
        setThemesC(1);
      }
    } catch (err) {
      console.error(err);
    }
  }, [pageData.page, pageData.sizePerPage, onSelect]);

  useEffect(() => {
    (async () => {
      if (themeName) {
        setName(themeId ? `${themeId} | ${themeName}` : `${themeName}`);
        return;
      }

      if (!themeId && themes_c > 0 && !isLoading) {
        fetchData();
      }
    })();
  }, [themeId, themeName, fetchData, themes_c, isLoading]);

  const onItemChange = (theme, setDisplayText) => {
    setDisplayText(theme.name);
    onSelect(theme);
  };

  const onInputChange = (value, setDisplayText) => {
    setDisplayText(value);
  };

  function filterCallback(item, query) {
    return item.name.toLowerCase().includes(query.toLowerCase());
  }

  return (
    <SearchField
      type={'theme'}
      data={themes}
      rowRenderer={ThemeSearchRowRenderer}
      filterCallback={filterCallback}
      defaultMessage={''}
      displayValue={name}
      fetchData={fetchData}
      clickThemes={true}
      onItemChange={onItemChange}
      onInputChange={onInputChange}
    />
  );
};

export default ThemeSearch;
