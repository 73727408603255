import { proxyHandler } from '../../helpers/proxyHandler';
import { New as NewQuestion } from '../Question';
import { New as NewResult } from '../Result';

import type { Question } from '../Question';
import type { Result } from '../Result';
import { validatePayload } from '../../../../../../api/helpers/validatePayload';
import { generatePayload } from '../../../../../../api/helpers/generatePayload';
import { merge } from '../../helpers/merge';

import Entity from '../Entity';
import { applyMixins } from '../../../../../../core/mixins';
import type Dict from '../types/Dict';

const ContentPayloadFields = {
  required: ['title', 'type'],
  allowed: {
    play_once: 'boolean',
    play_once_strategy: 'string',
    play_once_img: 'string',
    play_once_link: 'string',
    play_once_btn: 'string',
    play_once_msg: 'string',
    title: 'string',
    type: 'string',
    background: 'string',
    end_date_day: 'number',
    end_date_hour: 'number',
    end_date_minute: 'number',
    desc: 'string',
    embed_background: 'string',
    skip_start: 'boolean',
    skip_result: 'boolean',
    hide_results: 'boolean',
    hide_counter: 'boolean',
    display_repeat: 'boolean',
    is_searchable: 'boolean',
    is_calculator: 'boolean',
    search_results_per_page: 'number',
    theme_id: 'string,nullable',
    name: 'string,nullable',
    loading_result_label: 'string,nullable',
    loading_next_question_label: 'string,nullable',
    attributes: {
      show_timer: 'boolean',
      time_limit: 'number',
      pool_question_count: 'number',
      gives_feedback: 'boolean',
      cal_formula: 'string',
      display_results: 'boolean',
      recom_title: 'string',
    },
  },
};

const CoverMockFields = [
  'background',
  'title',
  't',
  'desc',
  'end_date',
  'end_date_relative',
  'end_date_human',
  'hide_counter',
  'vote_count',
];

type Theme = any;

const isContent = (content: any): content is Content =>
  content instanceof Content;

interface Content extends Entity {}

class Content {
  private _payloadFields = ContentPayloadFields;

  // changes list
  _changes: Dict = {};
  // errors list
  _errors: Dict = {};

  private _theme?: Theme;

  private _questions: Question[];
  private _results: Result[];

  public type_identifier: string = '';

  // BEGIN: data properties
  _id?: any; // TODO: deprecate
  original_id?: number; // TODO: deprecate // Content type unique id for the Old format.

  private _public_id?: string; // has getter/setter
  type?: 'pool' | 'set' | 'quiz' | 'test'; //Defines the content type. Can be poll, set, quiz or test.

  title?: string; // Content title
  background?: string; // Content main cover image path. Can be used with iproxy.
  desc?: string; // Content description

  skip_start?: boolean; // Skip start page
  skip_result?: boolean; // Skip result page
  hide_counter?: boolean; // Hide counter
  hide_results?: boolean; // Hide results
  display_repeat?: boolean; // Display repeat button
  lang?: string; // Content language

  play_once?: boolean; // Defines if the content is only available for vote/play once per user.
  play_once_strategy?: string; // Indicates how the play once restrictions should be applied if enabled.
  play_once_msg?: string; // Defines a msg for play once errors.
  play_once_img?: string; // Defines an image to show if the user sees a play once error screen
  play_once_btn?: string; // Indiciates the button text for the play once error screen
  play_once_link?: string; // Indicates the button link for play once error screen

  vote_count?: number; // Vote count for the content
  voter_count?: number; // Unique voter count for the content

  start_count?: number; // Start count for the content
  finish_count?: number; // Finish count for the content
  view_count?: number; // View count for the content

  end_date?: string; // Content end date
  is_ended?: boolean; // Indicates if the content is ended or not

  created_at?: string; // Content creation date
  updated_at?: string; // Content last update date

  theme_id?: number;
  // END

  constructor(data: any) {
    this._questions = [];
    this._results = [];

    merge(this, data);
  }

  public create(data: any): Content {
    return New(data);
  }

  public set id(id: string | undefined) {
    this._id = id;
  }
  public get id(): string | undefined {
    const val = this._id ?? this.public_id;

    return typeof val === 'number' ? val.toString() : val;
  }

  public set public_id(id: string | undefined) {
    this._public_id = id;
    this._id = id;
  }
  public get public_id(): string | undefined {
    return this._public_id;
  }

  public set theme(value: Theme) {
    this._theme = value;
    this.theme_id = value?.id;
  }
  public get theme(): Theme {
    return this._theme;
  }

  public set questions(questions: (any | Question)[]) {
    this._questions = questions.map((question) => NewQuestion(question, this));
  }
  public get questions(): Question[] {
    return this._questions;
  }

  public set results(results: (any | Result)[]) {
    this._results = results.map((result) => NewResult(result, this));
  }
  public get results(): Result[] {
    return this._results;
  }

  public mock(): any {
    return {
      ...Object.keys(this)
        .filter((key) => !key.startsWith('_')) // skip private keys
        .filter((key) => CoverMockFields.includes(key))
        .reduce((acc, key) => ({ ...acc, [key]: (this as any)[key] }), {}),
    };
  }

  _makePayload(): any {
    try {
      validatePayload(this, this._payloadFields.required);
    } catch (error) {
      console.error(error);
      return;
    }

    return generatePayload(this, this._payloadFields.allowed);
  }

  public payload() {
    return this._makePayload();
  }

  public updateQuestionOrder(questions: (any | Question)[]) {
    this._questions.forEach((question, index) => {
      question.position = questions.find((q) => q.id === question.id)?.position;
    });
  }
}

applyMixins(Content, [Entity]);

export function New(content: Content): Content {
  if (content instanceof Content) {
    return content;
  }
  if (isContent(content)) {
    return content;
  }

  const klass = new Content(content);
  const proxy = new Proxy<Content>(klass, proxyHandler);

  return proxy;
}

export type { Content };
export default New;
