import type { SVGProps } from 'react';

export default function PoltioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 114 36" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g>
          <path
            d="M109.8,12.8 L109.8,12.8 C109.8,8.6 106.8,5.2 102.6,5.2 C98.4,5.2 95.5,8.6 95.5,12.7 L95.5,12.8 C95.5,16.9 98.5,20.3 102.7,20.3 C106.8,20.2 109.8,16.9 109.8,12.8 L109.8,12.8 Z M91.5,12.8 L91.5,12.8 C91.5,6.8 96.1,1.8 102.6,1.8 C109.1,1.8 113.7,6.7 113.7,12.7 L113.7,12.8 C113.7,18.8 109.1,23.8 102.6,23.8 C96,23.7 91.5,18.7 91.5,12.8 L91.5,12.8 Z M68.4,5.6 L63.3,5.6 C62.3,5.6 61.6,4.8 61.6,3.9 C61.6,3 62.4,2.2 63.3,2.2 L77.4,2.2 C78.3,2.1 79,2.9 79,3.8 C79,4.7 78.2,5.5 77.3,5.5 L72.1,5.5 L72.1,21.5 C72.1,22.5 71.3,23.3 70.2,23.3 C69.2,23.3 68.3,22.5 68.3,21.5 L68.4,5.6 Z M47.5,3.8 C47.5,2.8 48.3,2 49.4,2 C50.4,2 51.2,2.8 51.2,3.8 L51.2,19.9 L60.8,19.9 C61.7,19.9 62.5,20.7 62.5,21.6 C62.5,22.5 61.7,23.3 60.8,23.3 L49.4,23.3 C48.3,23.3 47.5,22.5 47.5,21.4 L47.5,3.8 Z M0,4 C0,2.9 0.8,2.1 1.9,2.1 L8.4,2.1 C13.4,2.1 16.5,4.9 16.5,9.2 L16.5,9.3 C16.5,14.1 12.7,16.5 8,16.5 L3.7,16.5 L3.7,21.6 C3.7,22.6 2.9,23.4 1.9,23.4 C0.8,23.4 0,22.6 0,21.6 L0,4 Z M8.1,13.1 C10.9,13.1 12.7,11.5 12.7,9.3 C12.7,6.8 10.9,5.5 8.1,5.5 L3.7,5.5 L3.7,13.1 L8.1,13.1 Z"
            id="Fill-1"
            fill="#009EEC"
          ></path>
          <path
            d="M39.3999,7.0999 C38.5999,7.5999 38.2999,8.5999 38.7999,9.3999 C40.1999,11.6999 40.2999,14.4999 39.2999,16.9999 L39.2999,17.0999 L39.1999,17.1999 C38.2999,19.1999 36.6674805,20.581543 34.5999,21.4999 C31.0335043,23.0839857 30.1253662,25.9418945 29.6999,26.8999 C29.2999,27.7999 29.7999,28.7999 30.5999,29.0999 C30.6999,29.1999 30.8999,29.1999 30.9999,29.1999 C31.7999,29.2999 32.4999,28.8999 32.7999,28.1999 C33.0999,27.4999 33.7425537,25.4466553 35.7999,24.5999 C38.6999,23.4999 40.8999,21.2999 42.1999,18.4999 C42.1999,18.3999 42.1999,18.3999 42.2999,18.2999 C42.2999,18.1999 42.3999,18.1999 42.3999,18.0999 C43.8999,14.7999 43.5999,10.8999 41.6999,7.6999 C41.1999,6.8999 40.1999,6.5999 39.3999,7.0999 Z"
            id="Fill-3"
            fill="#FFC000"
          ></path>
          <path
            d="M27.1999,24.7996 C27.0999,24.7996 26.8999,24.6996 26.7999,24.6996 C23.2999,23.1996 20.7999,20.2996 19.8999,16.5996 C19.6999,15.6996 20.1999,14.7996 21.0999,14.4996 C21.9999,14.2996 22.8999,14.7996 23.1999,15.6996 C23.8999,18.2996 25.6999,20.4006 28.1999,21.4996 C29.0999,21.9006 29.4999,22.9006 29.0999,23.6996 C28.6999,24.4996 27.8999,24.9006 27.1999,24.7996"
            id="Fill-5"
            fill="#FF6644"
          ></path>
          <path
            d="M21.4001,12.1999 C21.3001,12.1999 21.2001,12.1999 21.1001,12.0999 C20.3001,11.8999 19.8001,10.8999 20.1001,9.9999 C20.2001,9.6999 20.3001,9.3999 20.5001,8.9999 C22.6001,3.8999 27.9001,0.9999 33.4001,1.7999 C34.3001,1.8999 35.3001,2.1999 36.1001,2.5999 C36.4001,2.6999 36.7001,2.8999 37.0001,2.9999 C37.8001,3.3999 38.2001,4.4999 37.7001,5.2999 C37.3001,6.0999 36.2001,6.4999 35.4001,5.9999 C35.2001,5.8999 35.0001,5.7999 34.7001,5.6999 C34.1001,5.3999 33.4001,5.1999 32.7001,5.0999 C28.8001,4.4999 25.0001,6.5999 23.5001,10.1999 C23.4001,10.3999 23.3001,10.6999 23.2001,10.8999 C23.0001,11.8999 22.2001,12.3999 21.4001,12.1999"
            id="Fill-9"
            fill="#D66DDD"
          ></path>
          <path
            d="M83.0002,9.8 C83.0002,8.8 83.8002,8 84.8992,8 C85.8992,8 86.7002,8.8 86.7002,9.8 L86.7002,21.6 C86.7002,22.6 85.8992,23.4 84.8992,23.4 C83.8002,23.4 83.0002,22.6 83.0002,21.6 L83.0002,9.8 Z"
            id="Fill-11"
            fill="#009EEC"
          ></path>
          <path
            d="M84.8999939,5.8 C83.5249939,5.8 82.3999939,4.675 82.3999939,3.3 C82.3999939,1.925 83.5249939,0.8 84.8999939,0.8 C86.2749939,0.8 87.3999939,1.8 87.3999939,3.3 C87.3999939,4.675 86.2749939,5.8 84.8999939,5.8 Z"
            id="Fill-13"
            fill="#009EEC"
          ></path>
          <path
            d="M33.5999985,33.0999985 C33.5999985,34.4749985 32.4749985,35.5999985 31.0999985,35.5999985 C29.7249985,35.5999985 28.5999985,34.4749985 28.5999985,33.0999985 C28.5999985,31.7249985 29.7249985,30.5999985 31.0999985,30.5999985 C32.4749985,30.5999985 33.5999985,31.5999985 33.5999985,33.0999985"
            id="Fill-13-Copy"
            fill="#009EEC"
          ></path>
        </g>
      </g>
    </svg>
  );
}
