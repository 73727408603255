import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';

import { CheckBoxInput } from '../../../../../../Common/Form';
import { EditorCTX } from '../../../../../context';
import messages from '../../../messages';

const QuestionConditionRow = ({ question, intl: { formatMessage } }) => {
  const { data, set, activeQuestionIdx: qidx } = useContext(EditorCTX);

  const QuestionTitle = question?.title ?? '-';
  /** @type {Array} */
  const answers = question?.answers ?? [];

  const conditions = data?.questions?.[qidx]?.conditionUIDs ?? [];

  const all_checked = answers.reduce((acc, answer) => {
    return acc && conditions.includes(`${answer?.__uid}`);
  }, true);

  const checkAll = (event) => {
    const checked = event.target.checked;

    let currArr = (data?.questions?.[qidx]?.conditionUIDs ?? '').split(',');
    let nextArr = question.answers.reduce((acc, answer) => {
      const uid = answer.__uid;
      if (checked) {
        if (!acc.includes(uid)) {
          acc.push(uid);
        }
      } else {
        acc = acc.filter((value) => value !== `${uid}`);
      }

      return acc;
    }, currArr);
    const next = nextArr.filter((value) => value !== '').join(',');

    set(`questions.${qidx}.conditionUIDs`, next);
  };

  const check = (event, answer) => {
    const checked = event.target.checked;

    let currArr = (data?.questions?.[qidx]?.conditionUIDs ?? '').split(',');

    const uid = answer.__uid;
    if (checked) {
      if (!currArr.includes(uid)) {
        currArr.push(uid);
      }
    } else {
      currArr = currArr.filter((value) => value !== `${uid}`);
    }
    const next = currArr.filter((value) => value !== '').join(',');

    set(`questions.${qidx}.conditionUIDs`, next);
  };

  return (
    <>
      {question.is_skippable === 0 ? (
        <div className="flex flex-col">
          <div className="truncate">
            <span>{QuestionTitle}</span>
          </div>
          {answers?.length === 0 ? (
            <div className="w-full text-center">
              <i>{formatMessage(messages.NoAnswers)}</i>
            </div>
          ) : (
            <div className="mx-1 px-1">
              <CheckBoxInput
                text={'All'}
                checked={all_checked}
                onChange={checkAll}
              />
              {answers?.map((answer, index) => {
                const checked = conditions.includes(`${answer?.__uid}`);

                const AnswerTitle = answer?.title
                  ? answer.title
                  : `"Answer ${index + 1}"`;

                return (
                  <CheckBoxInput
                    key={`${QuestionTitle}-${index}`}
                    text={AnswerTitle}
                    checked={checked}
                    onChange={(event) => check(event, answer)}
                  />
                );
              })}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default injectIntl(QuestionConditionRow);
