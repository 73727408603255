import { useState, useEffect, useCallback, useContext } from 'react';
import { getDomains, removeDomain, saveDomain } from '../../api';
import { injectIntl } from 'react-intl';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

import Loading from '../Common/Loading';
import DomainTable from './DomainTable';
import { NotificationsCTX } from '../../contexts/Notification';
import Button from '../Common/Button';
import DomainForm from './DomainForm';
import messages from './messages';
import Heading from '../Common/Page/Heading';
import { Spinner, CheckIcon, XMarkIcon } from '../../images';
import { Helmet } from 'react-helmet';

const Domains = ({ intl: { formatMessage } }) => {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeDomain, setActiveDomain] = useState({});

  const { success, error } = useContext(NotificationsCTX);

  const fetchDomains = useCallback(async () => {
    setLoading(true);

    const { data } = await getDomains();
    setDomains(data);
    setLoading(false);
  }, []);

  const statusList = [
    {
      name: 'pending',
      color: 'bg-gray-400',
    },
    {
      name: 'auth',
      color: 'bg-poltio-yellow',
      icon: Spinner,
    },
    {
      name: 'failed_auth',
      color: 'bg-red-400',
      icon: XMarkIcon,
    },
    {
      name: 'live',
      color: 'bg-green-400',
      icon: CheckIcon,
    },
  ];

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  const handleToggleClick = async (d, type) => {
    try {
      type === 'active'
        ? await saveDomain({
            ...d,
            is_active: !d.is_active,
          })
        : await saveDomain({
            ...d,
            is_default: !d.is_default,
          });

      success(formatMessage(messages.Updated));
      fetchDomains();
    } catch (e) {
      const errorStatus = e?.response?.status;
      switch (errorStatus) {
        case 403:
          error(formatMessage(messages.Error403));
          break;
        case 404:
          error(formatMessage(messages.Error404));
          break;
        default:
          return error(formatMessage(messages.Error));
      }
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.SureDelete))) {
      try {
        await removeDomain(id);
        success(formatMessage(messages.Removed));
        fetchDomains();
      } catch (e) {
        const errorStatus = e?.response?.status;
        switch (errorStatus) {
          case 403:
            error(formatMessage(messages.Error403));
            break;
          case 404:
            error(formatMessage(messages.Error404));
            break;
          default:
            return error(formatMessage(messages.Error));
        }
      }
    }
  };

  const onEditClick = (d) => {
    setOpen(true);
    setActiveDomain(d);
  };

  const onAddClick = () => {
    setActiveDomain({});
    setOpen(true);
  };

  const handleSubmit = () => {
    fetchDomains();
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Helmet>
          <title>Domains | Poltio Platform</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Heading
          title={formatMessage(messages.CustomDomain)}
          body={'You can add and use your own domain for Poltio Widgets'}
        >
          <Button.Primary
            className="md:self-end w-full md:w-1/3"
            onClick={onAddClick}
          >
            <PlusCircleIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {formatMessage(messages.NewDomain)}
          </Button.Primary>
        </Heading>
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <DomainTable
              domains={domains}
              handleToggleClick={handleToggleClick}
              handleDeleteClick={handleDeleteClick}
              openEdit={onEditClick}
              statusList={statusList}
            />
          </div>
        </>
      )}
      <DomainForm
        open={open}
        setOpen={setOpen}
        domain={activeDomain}
        success={success}
        error={error}
        handleSubmit={handleSubmit}
        statusList={statusList}
      />
    </div>
  );
};

export default injectIntl(Domains);
