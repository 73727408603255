import { EditorState } from '../../../enums';
import Change from '../classes/Change';

export function helper_del(key, options = { set_api: true }) {
  let content = this.state.content;

  let change = new Change();
  change.field = key;
  change.del(content);

  if (options.set_api) {
    this.change_list.push(change);
  }

  this.setState(
    (prev, props) => ({
      ...prev,
      content: content,
      save_state: EditorState.Dirty,
    }),
    () => {}
  );
}
