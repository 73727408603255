const ResultMockFields = [
  'background',
  'title',
  't',
  'desc',
  'end_date',
  'end_date_relative',
  'end_date_human',
  'vote_count',
  'url',
  'url_text',
];

class ResultMockMixin {
  public mock() {
    return {
      ...Object.keys(this)
        .filter((key) => ResultMockFields.includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: (this as any)[key] }), {}),
    };
  }
}

export default ResultMockMixin;
