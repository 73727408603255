import type {
  EditorDataHandler,
  EditorDataHandlerProps,
  EditorDataHandlerState,
} from '../..';

import { EditorState } from '../../../../enums';
import { saveProcess } from './process';

type saveOptions = {
  isLoadingHook?: (isOk: boolean) => void;
};

export async function helper_save(
  this: EditorDataHandler,
  options: saveOptions = {}
) {
  this.setState(
    (prev: EditorDataHandlerState, props: EditorDataHandlerProps) => ({
      ...prev,
      save_state: EditorState.Syncing,
    })
  );

  if (this.change_list === undefined || this.change_list === null) {
    this.change_list = [];
  }

  // Get `end` index value
  const cursor_next: number = this.change_list.length;

  // Get changes since the last cursor point till the end, without removing.
  // .slice boundaries: [start, end)
  const changes = this.change_list.slice(this.changes_cursor, cursor_next);

  // move cursor to `end`
  this.changes_cursor = cursor_next;

  // change list is too big, reduce it's size
  // if more than "1000" items are present
  // -- this.change_list should be defined as well
  if (this.change_list.length > 1000) {
    // start from 0 and delete "HALF" the items
    const half = this.change_list.length / 2;
    this.change_list.splice(0, half);

    // decrease cursor value by amount of removed items (HALF)
    this.changes_cursor = this.changes_cursor - half;
  }

  let content = this.state.content;
  let isCreateCall = this.props?.router?.params?.id === 'new';

  const post_save = () => {
    const { id: content_id } = this.props?.router?.params;
    if (content_id === 'new' && content?.public_id) {
      this.props?.router?.navigate?.(`/editor/${content.public_id}`);
    }
    options?.isLoadingHook?.(false);
  };

  // use then to make it non-blocking
  saveProcess(content, changes, { isCreateCall: isCreateCall }).then(() => {
    this.setState(
      (prev: EditorDataHandlerState, props: EditorDataHandlerState) => ({
        ...prev,
        content: content,
        content_id: content?.public_id,
        save_state:
          prev.save_state === EditorState.Error
            ? EditorState.Error
            : EditorState.Saved,
      }),
      post_save
    );
  });
}
