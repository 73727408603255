import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dialog } from '@headlessui/react';
import { DropdownRows } from './DropdownRows';

function clsxu(...args: string[]) {
  return args.filter(Boolean).join(' ');
}

type TItemsListProps = {
  data: Array<any>;
  renderer?: any | undefined;
  containerRef: React.RefObject<HTMLDivElement | undefined> | undefined;
  overlap?: boolean;
  modal?: boolean;
  onOpenCallback?: (index: number) => void;
  onCloseCallback?: Function;
  initialFocus?: React.MutableRefObject<any>;
  [key: string]: any; // any other data
};
type ItemsListExpanderProps = React.PropsWithChildren<TItemsListProps>;
export const ItemsListExpander: React.FC<ItemsListExpanderProps> = (props) => {
  const {
    data,
    renderer,
    containerRef = undefined,
    overlap = true,
    modal = false,
    onOpenCallback = (index) => {},
    onCloseCallback = () => {},
    initialFocus = undefined,
    children,
    dialogProps = {},
    ...rest
  } = props;

  const listRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(true);

  const close = useCallback(() => {
    setOpen(false);
    onCloseCallback?.();
  }, [onCloseCallback]);

  useEffect(() => {
    const scrollhandler = (event: WheelEvent) => {
      if (!(event.target instanceof Node)) {
        return;
      }

      const isEventOnList = listRef.current?.contains(event.target);
      if (isEventOnList) {
        return;
      }

      close();
    };
    window.addEventListener('wheel', scrollhandler);

    return () => {
      window.removeEventListener('wheel', scrollhandler);
    };
  }, [close]);

  const childrenWP = children
    ? React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        return React.cloneElement(child as JSX.Element, {
          data,
          options: { renderer, modal, onOpenCallback, ...rest },
        });
      })
    : React.createElement(DropdownRows, {
        data,
        options: { renderer, modal, onOpenCallback, ...rest },
      });

  if (!modal) {
    return <>{childrenWP}</>;
  }

  const viewport = containerRef?.current?.getBoundingClientRect();

  const isUpwards =
    window.innerHeight / 2 > window.innerHeight - (viewport?.bottom ?? 0);

  const gap = (overlap ? viewport?.top : viewport?.bottom) || 0;

  let _t = undefined;
  let _b = undefined;
  if (isUpwards) {
    _b = window.innerHeight - gap;
  } else {
    _t = gap;
  }

  const _l = viewport?.left || 0;
  const _w = viewport?.width || 100;

  const _style = Object.assign(
    {},
    { left: `${_l}px`, width: `${_w}px` },
    _t ? { top: `${_t}px` } : {},
    _b ? { bottom: `${_b}px` } : {}
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      className={clsxu(
        'absolute z-50 overflow-auto text-base shadow-lg focus:outline-none sm:text-sm rounded-lg',
        'fancy-scrollbar',
        dialogProps?.className
      )}
      style={_style}
      initialFocus={initialFocus}
      ref={listRef}
    >
      <>{childrenWP}</>
    </Dialog>
  );
};

type Props = React.PropsWithChildren<TItemsListProps>;
export const DropdownItemsList: React.FC<Props> = (props) => {
  return <ItemsListExpander {...props}>{props.children}</ItemsListExpander>;
};

export const ItemList = DropdownItemsList;

export default DropdownItemsList;
