import { FC, useCallback, useState, useEffect } from 'react';
import { getConditions } from '../../../../api';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { LinkIcon } from '@heroicons/react/20/solid';

type Props = {
  public_id: string;
};

type Conditions = {
  condition?: Array<Condition>;
  question: Question;
};

type Question = {
  conditions?: string;
  id: number;
  name?: string;
  title: string;
  condition_reverse: boolean;
  position: number;
};

type Answer = {
  id: number;
  question_id: number;
  background?: string;
  title: string;
  position: number;
};

type Condition = {
  answer: Answer;
  question: Question;
};

const QuestionsCondReports: FC<Props> = ({ public_id }) => {
  const [data, setData] = useState([]);

  const { formatMessage } = useIntl();

  const fetchData = useCallback(() => {
    getConditions(public_id).then((response) => {
      setData(response.data);
    });
  }, [public_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="divide-y divide-gray-200">
      {data.map((row: Conditions, i) => (
        <div key={i}>
          <div className="px-4 mt-2 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              <span className="text-gray-500 text-xs font-normal">
                {`Q${i + 1}`}: ({row.question.id}){' '}
              </span>
              {row.question.title}
            </h3>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-600">
                  Visible to{' '}
                  {row.question.condition_reverse ? (
                    <span className="text-gray-900">NOT</span>
                  ) : (
                    ''
                  )}{' '}
                  {row.condition ? 'people voted for:' : ''}
                </dt>
                {row.condition ? (
                  <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <ul
                      role="list"
                      className="divide-y divide-gray-100 rounded-md border border-gray-200"
                    >
                      {row.condition.map((con: Condition, i: number) => (
                        <li
                          key={i}
                          className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                        >
                          <div className="flex w-0 flex-1 items-center">
                            <LinkIcon
                              aria-hidden="true"
                              className="h-5 w-5 flex-shrink-0 text-gray-400"
                            />
                            <div className="ml-4 flex min-w-0 flex-1 gap-12">
                              <div className="truncate font-medium flex flex-col w-1/2">
                                <span className="text-xs text-gray-500">
                                  Answer title
                                </span>
                                <div className="flex gap-x-2">
                                  <img
                                    className="w-8 h-8"
                                    src={
                                      con.answer.background
                                        ? `https://cdn0.poltio.com/x640/${con.answer.background}`
                                        : 'https://cdn0.poltio.com/160x160/placeholder/profile/6.png'
                                    }
                                  />
                                  <span className="text-poltio-purple">
                                    A{i + 1}:{' '}
                                  </span>
                                  <span>{con.answer.title}</span>
                                </div>
                              </div>
                              <div className="truncate font-medium flex flex-col">
                                <span className="text-xs text-gray-500">
                                  Question title
                                </span>
                                <div>
                                  <span className="text-poltio-blue">
                                    Q{con.question.position + 1}:{' '}
                                  </span>
                                  <span>{con.question.title}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </dd>
                ) : (
                  <span className="text-sm font-medium leading-6 text-gray-600">
                    everyone
                  </span>
                )}
              </div>
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionsCondReports;
