import { useEffect, useState } from 'react';

import { Chart } from 'react-google-charts';
import { getSankeyMetrics } from '../../../api';
import Loading from '../../Common/Loading';

import SankeyModal from './SankeyModal';

const SankeyChart = ({ public_id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [modalData, setModalData] = useState(['', '', '']);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const response = await getSankeyMetrics(public_id);
      if (!ignore) {
        setData([['From', 'To', 'Vote'], ...response.data.rows]);
        setLoading(false);
      }
    }

    fetchData();
    return () => {
      ignore = true;
    };
  }, [public_id]);

  const openSankeyModal = (data) => {
    if (data) setModalData(data);
    setModalOpen(true);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <SankeyModal
        data={{ public_id }}
        modalData={modalData}
        open={modalOpen}
        setOpen={setModalOpen}
      />
      <Chart
        chartType="Sankey"
        loader={<Loading />}
        width="100%"
        height="700px"
        data={data}
        options={{
          sankey: {
            link: {
              interactivity: true,
            },
          },
        }}
        chartEvents={[
          {
            eventName: 'select',
            callback({ chartWrapper }) {
              const rowNumber = chartWrapper.getChart().getSelection()[0].row;
              openSankeyModal(data[rowNumber + 1]);
            },
          },
        ]}
      />
    </>
  );
};

export default SankeyChart;
