import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import messages from './messages';
import Button from '../../Common/Button';
import { Link } from 'react-router-dom';

import { feedAvatarPathRetina } from '../../../core/constants';
dayjs.extend(relativeTime);

const DraftContentTable = ({
  DraftContents,
  intl: { formatMessage },
  deleteContent,
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 uppercase"
                  >
                    {formatMessage(messages.Title)}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 uppercase"
                  >
                    {formatMessage(messages.Type)}
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray-500 sm:pl-6 lg:flex uppercase"
                  >
                    {formatMessage(messages.Dates)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {DraftContents.map((c, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50'}
                  >
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-16 h-16">
                          <Link
                            to={`/editor/${c.public_id}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              className="w-16 h-16 rounded-md"
                              src={`${feedAvatarPathRetina}/${
                                c.background || 'placeholder/question/1.png'
                              }`}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="ml-4">
                          <Link
                            to={`/editor/${c.public_id}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="text-sm font-medium text-gray-900">
                              {c.title?.length > 30
                                ? `${c.title?.substring(0, 30)}...`
                                : c.title}
                            </div>
                            <div className="text-sm text-gray-500">
                              {c.desc?.length > 30
                                ? `${c.desc?.substring(0, 30)}...`
                                : c.desc}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-3.5 pl-4 pr-3text-sm text-gray-500">
                      <div className="text-sm font-medium text-gray-900">
                        {c.type}
                      </div>
                    </td>
                    <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:inline-block">
                      <div className="text-sm font-medium text-gray-900">
                        {formatMessage(messages.Created)}:{' '}
                        {dayjs().to(dayjs(c.created_at)).toString()}
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatMessage(messages.LastUpdated)}:{' '}
                        {dayjs().to(dayjs(c.updated_at)).toString()}
                      </div>
                      {c.end_date ? (
                        <div className="text-sm text-gray-500">
                          {formatMessage(messages.EndDate)}:{' '}
                          {dayjs().to(dayjs(c.end_date)).toString()}
                        </div>
                      ) : null}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-right">
                      <Button.Primary href={`/editor/${c.public_id}`}>
                        {formatMessage(messages.ContinueEdit)}
                      </Button.Primary>
                      <Button.Danger
                        className="ml-3"
                        onClick={() => deleteContent(c)}
                      >
                        {' '}
                        {formatMessage(messages.Delete)}
                      </Button.Danger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DraftContentTable);
