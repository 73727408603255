import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { EditorCTX } from '../../context';
import { ConfTypes, EditorState } from '../../enums';

import Leads from '../../../Leads';
import PixelCodes from '../../../PixelCodes';
import { ContentTypePresets, defaultContentObj } from '../../constants';
import { getMockData as editor_getMockData, isObject } from '../../helpers';
import EditorContent from '../../Content';
import LoadingDialog from '../../../Common/Loading/LoadingDialog';
import {
  NewAnswer,
  NewQuestion,
  NewResult,
  focusReducer,
  setQuestionAnswerType as helper_setQuestionAnswerType,
} from './helpers';

const EditorCTXHandler = ({ editor }) => {
  const c_emptyData = useMemo(() => {
    const _data = JSON.parse(JSON.stringify(defaultContentObj));
    _data.title = 'New Project';
    _data.type = 'set';

    return _data;
  }, []);

  const { id: content_id } = useParams();

  const data = useMemo(() => editor?.content, [editor]);
  const editorStatus = editor?.status || EditorState.Dirty;

  const [activeQuestionIdx, setActiveQuestionIDX] = useState(0);
  const [activeResultIdx, setActiveResultIDX] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const dataFetched = useRef(false);

  const getLessResults = useCallback(async () => {
    await editor.getMoreResults({
      isLoadingHook: setIsLoading,
      increment: false,
    });
  }, [editor]);

  const getMoreResults = useCallback(async () => {
    await editor.getMoreResults({
      isLoadingHook: setIsLoading,
      increment: true,
    });
  }, [editor]);

  const hasMoreResults = editor.hasMoreResults;
  const hasLessResults = editor.hasLessResults;

  /**
   * -------------------- Save
   *
   * Debounces invoke of save function when using.
   *
   * */
  const save = useDebouncedCallback(
    async () => {
      await editor.save({ isLoadingHook: setIsLoading });
    },
    1000,
    { leading: true, trailing: false }
  );

  // -------------------- EFFECTS --------------------

  useEffect(() => {
    async function _load(options = {}) {
      if (!dataFetched.current) {
        dataFetched.current = true;
        await editor.fetch({ isLoadingHook: setIsLoading, ...options });
        dataFetched.current = false;
      }
    }

    if (editor.content?.public_id) {
      // public_id is from fetched data.
      //
      // if exists, content is already created,
      // saved andfetched, don't fetch again.
      return;
    }
    if (!editor.content_id) {
      // content_id is from browser route.
      //
      // if empty, it is new.
      return;
    }

    _load();
  }, [editor]);

  // -------------------- FOCUS --------------------
  //#region Focus
  // focus state handler
  const [focusState, _focus] = useReducer(focusReducer, {
    type: ConfTypes.Cover,
    field: '',
    options: { save: true },
  });
  const focus = useCallback(
    (key, field, options = { save: true }) => {
      _focus({ type: key, field: field, options: options });
    },
    [_focus]
  );
  const prevFocusState = useRef({
    type: 'init',
    field: '',
    options: { save: true },
  });
  useEffect(() => {
    const isInitialLoad = prevFocusState.current?.type === 'init';
    const typeChanged = prevFocusState.current?.type !== focusState.type;
    const fieldChanged = prevFocusState.current?.field !== focusState.field;

    const options = focusState.options;

    // if state has changed
    if ((typeChanged || fieldChanged) && !isInitialLoad) {
      if (options.save) {
        save();
      }
    }

    prevFocusState.current = focusState;
  }, [content_id, focusState, save]);
  //#endregion

  // -------------------- DATA --------------------

  useEffect(() => {
    if (content_id) {
      // content exists
      return;
    }

    if (data?.title && data?.type) {
      return;
    }

    editor.set('.title', c_emptyData.title);
    editor.set('.type', 'set');
  }, [content_id, c_emptyData, data, editor]);

  const getMockData = (target, lang) => {
    return editor_getMockData(
      target,
      data,
      activeQuestionIdx,
      activeResultIdx,
      lang
    );
  };

  // -------------------- NAVIGATION --------------------
  //#region default(collapsed) navigation
  //#region default(collapsed) setActiveQuestion
  const setActiveQuestion = useCallback(
    (index, options = { save: true }) => {
      setActiveQuestionIDX(index);
      focus(ConfTypes.Question, `questions.${index}`, options);
    },
    [focus]
  );
  //#endregion

  //#region default(collapsed) setActiveResult
  const setActiveResult = useCallback(
    (index, options = { save: true }) => {
      setActiveResultIDX(index);
      focus(ConfTypes.Result, `results.${index}`, options);
    },
    [focus]
  );
  //#endregion
  //#endregion

  const addNewQuestion = useDebouncedCallback(
    (atIndex = null) => {
      NewQuestion(atIndex, data, editor.set, save, setActiveQuestion);
    },
    500,
    { leading: true, trailing: false }
  );

  //#region Delete A Question
  const delQuestionAt = async (qidx) => {
    // calculate next index to move to
    const a_qidx = activeQuestionIdx;
    const n_qidx =
      0 <= a_qidx && a_qidx < editor.content.questions.length - 1 ? a_qidx : 0;

    if (qidx === a_qidx) {
      setActiveQuestion(n_qidx, { save: false });
    }

    editor.del(`questions.${qidx}`);
  };
  //#endregion

  const addNewAnswer = async () =>
    NewAnswer(data, activeQuestionIdx, editor.set, save);

  //#region Delete An Answer
  const delAnswerAt = async (aidx, _qidx = -1) => {
    const qidx = _qidx === -1 ? activeQuestionIdx : _qidx;

    editor.del(`questions.${qidx}.answers.${aidx}`);
    if (editor.content.questions[qidx].allow_multiple_answers) {
      const currentMP_c =
        editor.content.questions[qidx].max_multi_punch_answer ?? 0;
      const currentAnswers_c = editor.content.questions[qidx].answers.length;
      if (currentMP_c > currentAnswers_c) {
        editor.set(
          `questions.${qidx}.max_multi_punch_answer`,
          currentAnswers_c
        );
      }
    }
  };
  //#endregion

  const addNewResult = async () =>
    NewResult(data, editor.set, save, setActiveResult);

  //#region Delete A Result
  const delResultAt = (ridx) => {
    // calculate next result index
    const a_ridx = activeResultIdx;
    const n_ridx =
      0 <= a_ridx && a_ridx < editor.content.results.length - 1 ? a_ridx : 0;

    setActiveResult(n_ridx);

    editor.del(`results.${ridx}`);
  };
  //#endregion

  const setQuestionAnswerType = (_next) => {
    helper_setQuestionAnswerType(
      _next,
      data,
      activeQuestionIdx,
      editor.set,
      delAnswerAt
    );
  };

  /**
   * -------------------- Content Type Change Handler
   *
   * Loads presents when content type changes.
   *
   */
  const setContentType = (nextType, preserveSettings) => {
    let preset = null;
    for (const key in ContentTypePresets) {
      if (key === nextType) {
        preset = ContentTypePresets[key];
        break;
      }
    }

    const _process = (_preset, prefix = '') => {
      for (const key in _preset) {
        if (isObject(_preset[key])) {
          _process(_preset[key], `${prefix}.${key}`);
        } else {
          editor.set(`${prefix}.${key}`, _preset[key]);
        }
      }
    };

    editor.set('.type_identifier', nextType);
    if (!preserveSettings) {
      _process(preset);
    }
  };

  // -------------------- CREATE LEAD --------------------
  const [showCreateNewLead, _setShowCreateNewLead] = useState(false);

  const _onCreateNewLeadClick = () => {
    _setShowCreateNewLead(true);
  };

  // -------------------- CREATE LEAD --------------------
  const [showCreateNewPixelCode, _setShowCreateNewPixelCode] = useState(false);

  const _onCreateNewPixelCodeClick = () => {
    _setShowCreateNewPixelCode(true);
  };

  // -------------------- CTX PAYLOAD --------------------

  const ctx = {
    data: data,
    getMoreResults: getMoreResults,
    getLessResults: getLessResults,
    hasMoreResults: hasMoreResults,
    hasLessResults: hasLessResults,

    activeQuestionIdx: activeQuestionIdx,
    setActiveQuestion: setActiveQuestion,

    activeResultIdx: activeResultIdx,
    setActiveResult: setActiveResult,

    fetch: editor.fetch,
    get: editor.get,
    set: editor.set,
    del: editor.del,
    save: async (...args) => {
      await save(...args);
    },

    addNewResult: addNewResult,
    addNewQuestion: addNewQuestion,
    addNewAnswer: addNewAnswer,

    setContentType: setContentType,
    setQuestionAnswerType: setQuestionAnswerType,

    delResultAt: delResultAt,
    delQuestionAt: delQuestionAt,
    delAnswerAt: delAnswerAt,

    focus: focus,

    confType: focusState.type,
    confField: focusState.field,
    getMockData: (target, lang) => getMockData(target, lang),

    editorStatus: editorStatus,

    showCreateNewLead: _onCreateNewLeadClick,
    showCreateNewPixelCode: _onCreateNewPixelCodeClick,

    isLoading: isLoading,
  };

  return (
    <>
      <EditorCTX.Provider value={ctx}>
        <EditorContent title={data.name} />
      </EditorCTX.Provider>
      <Leads
        showUI={false}
        showCreateNewLead={showCreateNewLead}
        onCloseNewLead={() => {
          _setShowCreateNewLead(false);
        }}
      />
      <PixelCodes
        showUI={false}
        showCreateNewPixelCode={showCreateNewPixelCode}
        onCloseNewPixelCode={() => {
          _setShowCreateNewPixelCode(false);
        }}
      />
      <LoadingDialog show={isLoading} />
    </>
  );
};

export default EditorCTXHandler;
