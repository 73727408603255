import { Fragment, useState, useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getSheethookLogs } from '../../api';
import { injectIntl } from 'react-intl';

import Pagination from '../Common/Pagination';
import Loading from '../Common/Loading';
import PayloadModal from './PayloadModal';
import messages from './messages';
import Button, { HealthButton } from '../Common/Button';

const LogsTable = ({
  open,
  setOpen,
  hook,
  intl: { formatMessage, formatDate },
}) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const [payloadOpen, setPayloadOpen] = useState(false);
  const [activePayload, setActivePayload] = useState({});

  const fetchData = useCallback(async () => {
    if (hook.id) {
      setLoading(true);
      const { data } = await getSheethookLogs(
        hook.id,
        pageData.page,
        pageData.sizePerPage
      );
      setLogs(data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        };
      });
      setLoading(false);
    }
  }, [pageData.page, pageData.sizePerPage, hook]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
              <div>
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  handlePageChange={handlePageChange}
                  totalSize={pageData.totalSize}
                  showTotal
                />
                {loading ? (
                  <div className="max-w-7xl mx-auto px-2">
                    <Loading />
                  </div>
                ) : (
                  <Fragment>
                    <div className="flex flex-col py-4">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                                  >
                                    {'ID'}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Payload)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.CreatedAt)}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                  >
                                    {formatMessage(messages.Health)}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {logs.map((l, i) => (
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                    }
                                  >
                                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {l.id}
                                      </div>
                                    </td>
                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        <Button.Primary
                                          type="button"
                                          className="w-full"
                                          onClick={() => {
                                            setActivePayload(l.logs);
                                            setPayloadOpen(true);
                                          }}
                                        >
                                          {formatMessage(messages.ViewPayload)}
                                        </Button.Primary>
                                      </div>
                                    </td>

                                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {formatDate(l.created_at)}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                      <HealthButton
                                        health={l.health}
                                      ></HealthButton>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      last={pageData.lastPage}
                      active={pageData.page}
                      size={pageData.sizePerPage}
                      handlePageChange={handlePageChange}
                      totalSize={pageData.totalSize}
                    />
                  </Fragment>
                )}
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <div>
                  <Button.Primary
                    type="button"
                    className="w-full"
                    onClick={() => setOpen(false)}
                  >
                    {formatMessage(messages.Close)}
                  </Button.Primary>
                </div>
              </div>
              <PayloadModal
                open={payloadOpen}
                setOpen={setPayloadOpen}
                payload={activePayload}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default injectIntl(LogsTable);
