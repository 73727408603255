import { Switch } from '@headlessui/react';
import {
  TrashIcon,
  EyeIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
import Tooltip from '../Common/Button/Tooltip';

import messages from './messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DomainTable = ({
  domains,
  handleToggleClick,
  handleDeleteClick,
  openEdit,
  statusList,
  intl: { formatMessage },
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                  >
                    {'ID'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {'DOMAIN'}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Verified)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Active)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Default)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.Status)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {domains.map((d, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
                      <div className="text-sm font-medium text-gray-900">
                        {d.id}
                      </div>
                    </td>

                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {d.domain}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {d.is_verified ? (
                          <CheckIcon
                            className="w-7 h-7 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <XMarkIcon
                            className="w-7 h-7 text-red-500"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <Switch
                        checked={d.is_active}
                        onChange={() => handleToggleClick(d, 'active')}
                        className={classNames(
                          d.is_active ? 'bg-poltio-blue-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                        )}
                      >
                        <span className="sr-only">{'Active'}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            d.is_active ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <Switch
                        checked={d.is_default}
                        onChange={() => handleToggleClick(d)}
                        className={classNames(
                          d.is_default ? 'bg-poltio-blue-600' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                        )}
                      >
                        <span className="sr-only">{'Default'}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            d.is_default ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 flex flex-row group relative">
                        <p
                          className={classNames(
                            `${
                              statusList.filter((s) => s.name === d.status)[0]
                                .color
                            } px-2  rounded-md`
                          )}
                        >
                          {d.status.toUpperCase()}
                        </p>
                      </div>
                    </td>
                    <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="z-0 w-full py-2">
                        <span className="relative inline-flex gap-x-2">
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              openEdit(d);
                            }}
                            target="_blank"
                            rel="noreferrer"
                            className="group cursor-pointer relative inline-block border-gray-400 text-center"
                          >
                            <EyeIcon
                              className="w-5 h-5 mr-4 -ml-1 text-gray-400"
                              aria-hidden="true"
                            />
                            <Tooltip
                              title={formatMessage(messages.ViewDetail)}
                            />
                          </a>
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteClick(d.id);
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <TrashIcon
                              className="w-5 h-5 mr-2 -ml-1 text-red-400"
                              aria-hidden="true"
                            />
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DomainTable);
