import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import api from '../../../api/client';
import { genuid } from '../../Editor/EditorAPI/EditorDataHandler/helpers/genuid';
import { Dropdown } from '../../Common/Form';
import Loading from '../../Common/Loading';
import Button from '../../Common/Button';

import { DSConfigCTX, type TDSConfigRouteParams } from '.';

import messages from '../messages';
import { DSAttributeTypes, type DataSourceElementType } from '../types';

type DataSourceAttributesResponse = {
  columns: string[];
  examples: {
    [key: string]: string;
  }[];
};
function getDataSourceAttributes(id: string) {
  return api.get<DataSourceAttributesResponse>(
    `/platform/data-sources/${id}/attributes`
  );
}

type DSAttributeItem = {
  id: string;
  name: string;
  examples: string[];
  isSelected: boolean;
};

const DSRequiredAttributeTypes: DataSourceElementType[] = [
  'id',
  'name',
  'url',
  'image',
];

type DSSetElement = {
  element: string;
  type: DataSourceElementType;
};
type DSSetElementsRequestPayload = DSSetElement[];
type DSSetElementsResponse = {
  id: number;
  data_source_id: number;
  data_source_item_namespace_id: number | null;
  element: string;
  slug: string;
  type: DataSourceElementType;
  created_at: string;
  deleted_at: string;
  is_array: boolean | 0 | 1;
  save_all_matched_arrays: boolean | 0 | 1;
  array_first_level: any | null;
  array_first_path: any | null;
  array_first_key: any | null;
  array_first_value: any | null;
  attribute: any | null;
  attribute_value_match: any | null;
}[];
function apiDSSetElementsRequest(
  id: string | number,
  payload: DSSetElementsRequestPayload
) {
  return api.post<DSSetElementsResponse>(
    `/platform/data-sources/${id}/elements`,
    {
      elements: payload,
    }
  );
}

type AttributeItemViewProps = {
  item: DSAttributeItem;
  onSelect: (item: DSAttributeItem, type?: DataSourceElementType) => void;
};
function AttributeItemViewGrid(props: AttributeItemViewProps) {
  const { item, onSelect } = props;
  const isSelected = item.isSelected ?? false;

  const [selectedTypeIdx, setSelectedTypeIdx] = useState(0);

  function toggleSelected(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;

    onSelect(
      item,
      isChecked ? DSAttributeTypes[selectedTypeIdx].value : undefined
    );
  }

  function setItemSelected(index: number) {
    setSelectedTypeIdx(index);
    onSelect(item, DSAttributeTypes[index].value);
  }

  return (
    <li className="rounded-xl border border-gray-200">
      <div className="flex items-center justify-between gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="text-sm font-medium leading-6 text-gray-900">
          {item.name}
        </div>
        {/* ------- */}
        <div className="flex flex-none items-center gap-x-4">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={toggleSelected}
            className="
            h-5 w-5
            rounded border-poltio-blue-800
            text-poltio-blue-600 focus:ring-poltio-blue-600
            cursor-pointer
          "
          />
        </div>
        {/* ------- */}
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div className="grid grid-cols-12 justify-between gap-x-4 py-3">
          <dt className="col-span-3 text-gray-500">{}</dt>
          <dd className="col-span-9 text-gray-700">
            <p className="space-x-1 truncate">
              <span>{item.examples.join(', ')}</span>
            </p>
          </dd>
        </div>
        <div className="grid grid-cols-12 justify-between gap-x-4 py-3">
          <dt className="col-span-3 flex text-gray-500 items-center justify-start">
            {'Type'}
          </dt>
          <dd className="col-span-9 gap-x-2 -mt-1 w-full">
            <Dropdown
              data={DSAttributeTypes}
              selectedIdx={selectedTypeIdx}
              onChange={setItemSelected}
            />
          </dd>
        </div>
      </dl>
    </li>
  );
}

export default function ConfigPanel() {
  const { value: dsId } = useParams<TDSConfigRouteParams>();
  const { formatMessage } = useIntl();
  const { elements: dsElements } = useContext(DSConfigCTX);

  const navigate = useNavigate();

  const checkAllRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<DSAttributeItem[]>([]);
  const [elements, setElements] = useState<DSSetElement[]>([]);
  const [missingElements, setMissingElements] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (!dsId || dsId === 'new') {
      // no data source selected, exit
      return;
    }

    setLoading(true);
    getDataSourceAttributes(dsId)
      .then((response) => {
        const data = response.data;

        // process data to fit the proper format
        let examples: { [key: string]: string[] };
        examples = data.examples.reduce(
          (acc, example) => {
            Object.entries(example).forEach(([key, value]) => {
              acc[key] = acc[key] || [];

              if (!value) {
                return;
              }
              if (acc[key].includes(value)) {
                return;
              }
              acc[key].push(value);
            });

            return acc;
          },
          {} as typeof examples
        );

        const items: DSAttributeItem[] = data.columns.map((col) => ({
          id: genuid(),
          name: col,
          examples: examples[col],
          isSelected: false,
        }));
        setData(items);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dsId]);

  useEffect(() => {
    if (!dsElements) {
      return;
    }

    // mark matching data elements as selected
    setData((prev) => {
      if (prev === null) {
        return prev;
      }

      const next = prev.map((item) => {
        const match = dsElements.find(
          (element) => element.element === item.name
        );

        if (match) {
          return { ...item, isSelected: true };
        }

        return item;
      });

      return [...next];
    });
  }, [dsElements]);

  useEffect(() => {
    const missing = DSRequiredAttributeTypes.filter(
      (type) => !elements.some((element) => element.type === type)
    );

    setMissingElements((prev) => {
      if (prev.length === missing.length) {
        return prev;
      }

      return [
        ...missing.map(
          (item) =>
            DSAttributeTypes.find((t) => t.value === item)?.title ?? item
        ),
      ];
    });
  }, [data, elements]);

  useEffect(() => {
    if (!checkAllRef?.current) {
      return;
    }

    const checkbox = checkAllRef.current;

    checkbox.indeterminate =
      elements.length > 0 && elements.length < data.length;
    checkbox.checked = elements.length === data.length;
  }, [elements, data, checkAllRef.current]);

  function onSelectAllClick(event: React.MouseEvent<HTMLInputElement>) {
    const isChecked = event.currentTarget.checked;

    setElements((prev) => {
      if (prev === null) {
        return prev;
      }

      // if checked, add all
      if (isChecked) {
        return data.map((item) => ({ element: item.name, type: 'generic' }));
      }

      // otherwise remove all
      return [];
    });
    setData((prev) => {
      if (prev === null) {
        return prev;
      }

      // if checked, add all
      if (isChecked) {
        return prev.map((item) => ({ ...item, isSelected: true }));
      }

      // otherwise uncheck all
      return prev.map((item) => ({ ...item, isSelected: false }));
    });
  }

  function onDSAttrSelect(item: DSAttributeItem, type?: DataSourceElementType) {
    setElements((prev) => {
      if (prev === null) {
        return prev;
      }

      // ensure that item does not exist on next
      const next = prev.filter((value) => value.element !== item.name);

      // if type exists, assign
      if (type) {
        // add new version
        return [...next, { element: item.name, type: type }];
      }

      // otherwise set with item with matching name removed
      return [...next];
    });
    setData((prev) => {
      if (prev === null) {
        return prev;
      }

      return prev.map((value) => {
        if (value.name !== item.name) {
          return value;
        }

        return { ...value, isSelected: type !== undefined };
      });
    });
  }

  async function onSubmit() {
    if (!dsId || dsId === 'new') {
      // no data source selected, exit
      return;
    }

    if (elements.length === 0) {
      // no elements selected, exit
      return;
    }

    setLoading(true);

    try {
      setButtonDisabled(true);
      const response = await apiDSSetElementsRequest(dsId, elements);

      navigate(`/data-source/${dsId}#publish`);
      setLoading(false);
    } catch (error) {
      setButtonDisabled(false);
      console.error(error);
    }
  }

  return (
    <div className="space-y-8 divide-y divide-gray-200 max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <div className="space-y-8 divide-y divide-gray-200 relative sm:space-y-5">
        <div className="">
          <div className="mt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {formatMessage(messages.ThankYou)}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {formatMessage(messages.ThankYouDesc)}
            </p>
          </div>
          <div className="mt-4 flex w-full justify-between">
            <div className="flex items-center justify-center gap-x-2 text-sm">
              {missingElements.length > 0 ? (
                <p>
                  <span>{`${formatMessage(messages.Mandatory)}: `}</span>
                  {(missingElements ?? []).map((item) => (
                    <Fragment key={`${item}`}>
                      <span className="text-red-600">{`"${item}"`}</span>
                      <span className="last:hidden px-1">{','}</span>
                    </Fragment>
                  ))}
                </p>
              ) : null}
            </div>
            <div className="flex h-6 items-center gap-2">
              <div className="text-sm leading-6">
                <label>{formatMessage(messages.AllItems)}</label>
              </div>
              <input
                ref={checkAllRef}
                className="
                h-4 w-4
                rounded border-poltio-blue-600
                text-poltio-blue-600 focus:ring-poltio-blue-600
                cursor-pointer
                "
                type="checkbox"
                onClick={onSelectAllClick}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="absolute backdrop-blur-sm w-full h-full z-30 flex justify-center">
            <Loading />
          </div>
        ) : null}
        <ul className="pt-2 px-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
          {data.map((item) => (
            <AttributeItemViewGrid
              key={item.id}
              item={item}
              onSelect={onDSAttrSelect}
            />
          ))}
        </ul>

        <div className="py-5 px-2 ">
          <div className="flex justify-end">
            <Button.Secondary
              type="button"
              onClick={() => navigate('/data-source')}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2"
            >
              {formatMessage(messages.Cancel)}
            </Button.Secondary>
            <Button.Primary
              type="submit"
              onClick={onSubmit}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-poltio-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-poltio-blue-700 focus:outline-none focus:ring-2 focus:ring-poltio-blue-500 focus:ring-offset-2 disabled:pointer-events-none"
              disabled={missingElements.length > 0 || buttonDisabled}
              showSpinner={buttonDisabled}
            >
              {formatMessage(messages.Save)}
            </Button.Primary>
          </div>
        </div>
      </div>
    </div>
  );
}
