import { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { logoutSessions } from '../../../api';
import Button from '../../Common/Button';
import { NotificationsCTX } from '../../../contexts/Notification';
import messages from './messages';

const LogoutSessions = ({ intl: { formatMessage } }) => {
  const { alert, success } = useContext(NotificationsCTX);
  const [pass, setPass] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onLogoutSessions = async () => {
    if (window.confirm(formatMessage(messages.Sure))) {
      setButtonDisabled(true);
      try {
        await logoutSessions(pass);

        setPass('');
        setButtonDisabled(false);
        success(formatMessage(messages.Success));
      } catch (error) {
        setButtonDisabled(false);

        if (error.response.status === 422) {
          alert(formatMessage(messages.IncoPw));
        } else {
          alert(formatMessage(messages.Error));
        }
      }
    }
  };

  return (
    <div className="divide-y divide-white/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {formatMessage(messages.Logout)}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            {formatMessage(messages.PleaseEnter)}
          </p>
        </div>

        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="current-password"
                className="block text-sm font-medium leading-6 "
              >
                {formatMessage(messages.YourPw)}
              </label>
              <div className="mt-2">
                <input
                  id="logout-password"
                  name="logout-password"
                  type="password"
                  autoComplete="logout-password"
                  className={
                    'block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6'
                  }
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex">
            <Button.Primary
              type="submit"
              disabled={pass.length < 8 || buttonDisabled}
              onClick={onLogoutSessions}
            >
              {formatMessage(messages.LogoutOther)}
            </Button.Primary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(LogoutSessions);
