import { injectIntl } from 'react-intl';

import messages from './messages';

import { defaultAVATAR, feedAvatarPathRetina } from '../../../core/constants';
import ImageInput from '../../Common/ImageInput';

const AccountPhoto = ({ photo, setPhoto, intl: { formatMessage } }) => {
  return (
    <div className="col-span-full flex items-center gap-x-8">
      <div className="h-24 w-24 flex-none rounded-lg object-cover">
        <ImageInput
          title={formatMessage(messages.AccountPhotoLabel)}
          src={photo ? `${feedAvatarPathRetina}/${photo}` : ''}
          fallback={defaultAVATAR}
          callback={setPhoto}
          aspectRatio={1}
          modal
          avatar
        />
      </div>
      <div>
        <button
          type="button"
          className="rounded-md  px-3 py-2 text-sm font-semibold shadow-sm "
        >
          {formatMessage(messages.AccountPhotoLabel)}
        </button>
        <p className="mt-2 text-xs leading-5 text-gray-400">
          JPG, GIF or PNG. 1MB max.
        </p>
      </div>
    </div>
  );
};

export default injectIntl(AccountPhoto);
