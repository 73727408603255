import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Width: {
    id: 'ContentDetail.Width',
    defaultMessage: 'Width',
  },
  Height: {
    id: 'ContentDetail.Height',
    defaultMessage: 'Height',
  },
  DisplayShare: {
    id: 'ContentDetail.DisplayShare',
    defaultMessage: 'Display Share Buttons',
  },
  DisplayDisclaimer: {
    id: 'ContentDetail.DisplayDisclaimer',
    defaultMessage: 'Display Disclaimer',
  },
  ResultFit: {
    id: 'ContentDetail.ResultFit',
    defaultMessage: 'Result Fit',
  },
  Off: {
    id: 'ContentDetail.Off',
    defaultMessage: 'Off',
  },
  ResultImageCrop: {
    id: 'ContentDetail.ResultImageCrop',
    defaultMessage: 'Result Image Crop',
  },
  Fit: {
    id: 'ContentDetail.Fit',
    defaultMessage: 'Fit',
  },
  Alignment: {
    id: 'ContentDetail.Alignment',
    defaultMessage: 'Horizontal Alignment',
  },
  Left: {
    id: 'ContentDetail.Left',
    defaultMessage: 'Left',
  },
  Center: {
    id: 'ContentDetail,Center',
    defaultMessage: 'Center',
  },
  Right: {
    id: 'ContentDetail.Right',
    defaultMessage: 'Right',
  },
  VerticalAlignment: {
    id: 'ContentDetail.VerticalAlignment',
    defaultMessage: 'Vertical Alignment',
  },
  Top: {
    id: 'ContentDetail.Top',
    defaultMessage: 'Top',
  },
  Bottom: {
    id: 'ContentDetail.Bottom',
    defaultMessage: 'Bottom',
  },
  Language: {
    id: 'ContentDetail.Language',
    defaultMessage: 'Language',
  },
  DeviceLanguage: {
    id: 'ContentDetail,DeviceLanguage',
    defaultMessage: 'Device Language',
  },
  English: {
    id: 'ContentDetail.English',
    defaultMessage: 'English',
  },
  Turkish: {
    id: 'ContentDetail.Turkish',
    defaultMessage: 'Turkish',
  },
  German: {
    id: 'ContentDetail.German',
    defaultMessage: 'German',
  },
  French: {
    id: 'ContentDetail.French',
    defaultMessage: 'French',
  },
  Italian: {
    id: 'ContentDetail.Italian',
    defaultMessage: 'Italian',
  },
  Spanish: {
    id: 'ContentDetail.Spanish',
    defaultMessage: 'Spanish',
  },
  Hebrew: {
    id: 'ContentDetail.Hebrew',
    defaultMessage: 'Hebrew',
  },
  Romanian: {
    id: 'ContentDetail.Romanian',
    defaultMessage: 'Romanian',
  },
  Dutch: {
    id: 'ContentDetail.Dutch',
    defaultMessage: 'Dutch',
  },
  Portuguese: {
    id: 'ContentDetail.Portuguese',
    defaultMessage: 'Portuguese',
  },
  Thai: {
    id: 'ContentDetail.Thai',
    defaultMessage: 'Thai',
  },
  Arabic: {
    id: 'ContentDetail.Arabic',
    defaultMessage: 'Arabic',
  },
  Hungarian: {
    id: 'ContentDetail.Hungarian',
    defaultMessage: 'Hungarian',
  },
  Austrian: {
    id: 'ContentDetail.Austrian',
    defaultMessage: 'Austrian',
  },
  Algerian: {
    id: 'ContentDetail.Algerian',
    defaultMessage: 'Algerian',
  },
  Egyptian: {
    id: 'ContentDetail.Egyptian',
    defaultMessage: 'Egyptian',
  },
  Moroccan: {
    id: 'ContentDetail.Moroccan',
    defaultMessage: 'Moroccan',
  },
  UtmSource: {
    id: 'ContentDetail.UtmSource',
    defaultMessage: 'Utm Source',
  },
  UtmDesc: {
    id: 'ContentDetaul.UtmDesc',
    defaultMessage:
      'Identify the advertiser, site, publication, etc. that is sending traffic to your property, for example: google, newsletter4, billboard.',
  },
  UtmMedium: {
    id: 'ContentDetail.UtmMedium',
    defaultMessage: 'Utm Medium',
  },
  UtmMDesc: {
    id: 'ContentDetail.UtmMDesc',
    defaultMessage:
      'The advertising or marketing medium, for example: cpc, banner, email newsletter.',
  },
  UtmCampaign: {
    id: 'ContentDetail.UtmCampaign',
    defaultMessage: 'Utm Campaign',
  },
  UtmCDesc: {
    id: 'ContentDetail.UtmCDesc',
    defaultMessage:
      'The individual campaign name, slogan, promo code, etc. for a product.',
  },
  MobileView: {
    id: 'ContentDetail.MobileView',
    defaultMessage: 'Mobile View',
  },
  Options: {
    id: 'ContentDetail.Options',
    defaultMessage: 'Options',
  },
  VerticalFit: {
    id: 'ContentDetail.VerticalFit',
    defaultMessage: 'Vertical Fit',
  },

  SameWindow: {
    id: 'ContentDetail.SameWindow',
    defaultMessage: 'Open the result link in the same window',
  },
  DontUseCookie: {
    id: 'ContentDetail.DontUseCookie',
    defaultMessage: `Don't Use Cookie`,
  },
  Webhook: {
    id: 'ContentDetail.Webhook',
    defaultMessage: 'Webhook',
  },
  Sheethooks: {
    id: 'ContentDetail.Sheethooks',
    defaultMessage: 'Sheethooks',
  },
  Pixelcodes: {
    id: 'ContentDetail.Pixelcodes',
    defaultMessage: 'PixelCodes',
  },
  GoogleTagManager: {
    id: 'ContentDetail.GoogleTagManager',
    defaultMessage: 'Google Tag Manager',
  },
  DynamicWidgets: {
    id: 'ContentDetail.DynamicWidgets',
    defaultMessage: 'Dynamic Widgets',
  },
  WebhookDesc: {
    id: 'ContentDetail.WebhookDesc',
    defaultMessage:
      'Get widget sessions real time in json format to an endpoint you want.',
  },
  SheethooksDesc: {
    id: 'ContentDetail.SheethooksDesc',
    defaultMessage:
      'Connect your Google Sheet to your widget to get sessions real time.',
  },
  PixelcodesDesc: {
    id: 'ContentDetail.PixelcodesDesc',
    defaultMessage:
      'Connect any DSP or Pixel tracking code you have. (eg: Facebook pixel, Adobe Audience Manager, Linkedin Tracking Pixel etc...)',
  },
  GoogleTagManagerDesc: {
    id: 'ContentDetail.GoogleTagManagerDesc',
    defaultMessage:
      'Connect your GTM to your widget to get all the events in the widget from your GTM to use as triggers for other integrations.',
  },
  DynamicWidgetsDesc: {
    id: 'ContentDetail.DynamicWidgetsDesc',
    defaultMessage:
      'Dynamic Widgets allows you to display different content without changing the SDK/iFrame code in your page depending on the URL of the page.',
  },
  StandardIframe: {
    id: 'ContentDetail.StandardIframe',
    defaultMessage: 'Standard Iframe',
  },
  StandardIframeDesc: {
    id: 'ContentDetail.StandardIframeDesc',
    defaultMessage: 'Embed a spesific content',
  },
  Sdk: {
    id: 'ContentDetail.Sdk',
    defaultMessage: 'Poltio SDK',
  },
  SdkDesc: {
    id: 'ContentDetail.SdkDesc',
    defaultMessage: 'To dynamically add content to your site/app',
  },
  UrlSuccess: {
    id: 'ContentDetail.UrlSuccess',
    defaultMessage: 'Url successfully copied',
  },
  UrlError: {
    id: 'ContentDetail.UrlError',
    defaultMessage: 'Error copying Url!',
  },
  ContentPreview: {
    id: 'ContentDetail.ContentPreview',
    defaultMessage: 'Content Preview',
  },
  ContentPreviewDesc: {
    id: 'ContentDetail.ContentPreviewDesc',
    defaultMessage:
      'Your content is ready, you can experience the widget and see different options for embedding',
  },
  Stats: {
    id: 'ContentDetail.Stats',
    defaultMessage: 'Stats',
  },
  Edit: {
    id: 'ContentDetail.Edit',
    defaultMessage: 'Edit',
  },
  Ready: {
    id: 'ContentDetail.Ready',
    defaultMessage: 'Your content is ready',
  },
  ReadyDesc: {
    id: 'ContentDetail.ReadyDesc',
    defaultMessage:
      'You can configure and see diffrent embedding options or integrations below:',
  },
  EmbedOptions: {
    id: 'ContentDetail.EmbedOptions',
    defaultMessage: 'Embed Options',
  },
  Integrations: {
    id: 'ContentDetail.Integrations',
    defaultMessage: 'Integrations',
  },
  DontUseResize: {
    id: 'ContentDetail.DontUseResize',
    defaultMessage: "Don't use auto resize",
  },
  CustomID: {
    id: 'ContentDetail.CustomID',
    defaultMessage: 'Custom ID',
  },
  DirectLink: {
    id: 'ContentDetail.DirectLink',
    defaultMessage: 'Direct Link',
  },
  Back: {
    id: 'ContentDetail.Back',
    defaultMessage: 'Back',
  },
  ContentName: {
    id: 'ContentDetail.ContentName',
    defaultMessage: 'Internal Name',
  },
  ContentTitle: {
    id: 'ContentDetail.ContentTitle',
    defaultMessage: 'External Name',
  },
  Open: {
    id: 'ContentDetail.Open',
    defaultMessage: 'Open',
  },
  Flying: {
    id: 'ContentDetail.Flying',
    defaultMessage: 'Flying Widget',
  },
  FlyingDesc: {
    id: 'ContentDetail.FlyingDesc',
    defaultMessage:
      'Poltio Flying Widget helps you go online with Poltio Widgets faster by eliminating the task for you to design a place where Poltio Widgets appear in your web page.',
  },
});
