import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  SecuritySettingsLabel: {
    id: 'Settings.Security.index.Label',
    defaultMessage: 'Security',
  },
  SecuritySettingsPasswordLabel: {
    id: 'Settings.Security.Password.Label',
    defaultMessage: 'Password',
  },
  SecuritySettingsPasswordCurrent: {
    id: 'Settings.Security.Password.Current',
    defaultMessage: 'Current Password',
  },
  SecuritySettingsPasswordNew: {
    id: 'Settings.Security.Password.New',
    defaultMessage: 'New Password',
  },
  SecuritySettingsPasswordRepeat: {
    id: 'Settings.Security.Password.Repeat',
    defaultMessage: 'Repeat Password',
  },
  SecuritySettingsPasswordSave: {
    id: 'Settings.Security.Password.Save',
    defaultMessage: 'Save',
  },
  SecuritySettingsPasswordErrorCurrentFalse: {
    id: 'Settings.Security.Password.Error.CurrentFalse',
    defaultMessage: 'Current password is wrong',
  },
  SecuritySettingsPasswordErrorDontMatch: {
    id: 'Settings.Security.Password.Error.DontMatch',
    defaultMessage: "Passwords don't match",
  },
  SecuritySettingsPasswordErrorNoLowercase: {
    id: 'Settings.Security.Password.Error.NoLowercase',
    defaultMessage: 'Must contain at least one lowercase letter',
  },
  SecuritySettingsPasswordErrorNoUppercase: {
    id: 'Settings.Security.Password.Error.NoUppercase',
    defaultMessage: 'Must contain at least one UPPERCASE letter',
  },
  SecuritySettingsPasswordErrorNoDigit: {
    id: 'Settings.Security.Password.Error.NoDigit',
    defaultMessage: 'Must contain at least one digit',
  },
  SecuritySettingsPasswordErrorNoSpecialCharacter: {
    id: 'Settings.Security.Password.Error.NoSpecialCharacter',
    defaultMessage: 'Must contain at least one special character',
  },
  SecuritySettingsPasswordErrorWhitespace: {
    id: 'Settings.Security.Password.Error.Whitespace',
    defaultMessage: 'Must not contain whitespace',
  },
  SuccessPassword: {
    id: 'Settings.Security.SuccessPassword',
    defaultMessage: 'Your password changed successfully',
  },
  TooShort: {
    id: 'Settings.Security.TooShort',
    defaultMessage: 'Too Short',
  },
  ChangePw: {
    id: 'Settings.Security.ChangePw',
    defaultMessage: 'Change your password here.',
  },
});
