import { useEffect, useMemo, useState } from 'react';

function LoadingArticleMessageDisplay() {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const loadingMessages = useMemo(
    () => ['', 'Thinking of a new question to ask...'],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      // clamp to max, so that we don't show the first message
      setLoadingMessageIndex((i) =>
        Math.max((i + 1) % loadingMessages.length, 1)
      );
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return <span>{loadingMessages[loadingMessageIndex]}</span>;
}

export default LoadingArticleMessageDisplay;
