import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { triviaInit, getTriviaCredit } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { NotificationsCTX } from '../../../contexts/Notification';
import Button from '../../Common/Button';
import Timezone from './Timezone';
import RegisterFields from './RegisterFields';
import messages from '../messages';
import ContentSearch from '../../Common/ContentSearch';
import Loading from '../../Common/Loading';

const initialTriviaState = {
  mode: 'normal',
  tier: 'free',
  player_leaderboard: 0,
  elimination: 0,
  start_date: null,
  start_date_timezone: -3,
  register_fields: [
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      active: true,
      is_optional: 0,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      active: false,
      is_optional: 0,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
      active: false,
      is_optional: 0,
    },
    {
      name: 'comment',
      label: 'Comment',
      type: 'text',
      active: false,
      is_optional: 0,
    },
    {
      name: 'tc',
      label: 'Terms and Conditions',
      type: 'checkbox',
      active: false,
      is_optional: 0,
    },
  ],
  domain_id: null,
};

dayjs.extend(localizedFormat);

const NewTrivia = ({ intl, intl: { formatMessage } }) => {
  const contentTypes = useMemo(() => ['quiz'], []);
  const [code, setCode] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [startDisabled, setStartDisabled] = useState(false);
  const [credit, setCredit] = useState(0);
  const [triviaState, setTriviaState] = useState(initialTriviaState);
  const [initialLoading, setInitialLoading] = useState(true);
  const [hasStartDate, setHasStartDate] = useState(false);
  const { error } = useContext(NotificationsCTX);
  const navigate = useNavigate();

  const minD = new Date();
  const maxD = new Date(Date.now() + 30 * 86400000);

  const getCredit = useCallback(async () => {
    const d = new Date();
    const n = d.getTimezoneOffset();
    const timezone = n / 60;

    const { data } = await getTriviaCredit();
    setCredit(data.credits_available);
    setTriviaState((s) => ({
      ...s,
      start_date_timezone: timezone,
      tier: data.credits_available > 0 ? 'credit' : 'free',
    }));
    setInitialLoading(false);
  }, []);

  useEffect(() => {
    getCredit();
  }, [getCredit]);

  const startTrivia = async () => {
    try {
      setStartDisabled(true);
      const activeFields = triviaState.register_fields.filter((r) => r.active);
      const { data } = await triviaInit(parseInt(code, 10), {
        ...triviaState,
        start_date: dayjs(startDate, 'yyyy-MM-dd HH:mm:ss'),
        register_fields: activeFields,
      });
      navigate(`/manage-trivia/${data.id}`);
    } catch (e) {
      setStartDisabled(false);
      if (e?.response?.status === 403) {
        error(formatMessage(messages.QuizNotYours));
      } else if (e?.response?.status === 410) {
        error(formatMessage(messages.QuizUnpublished));
      } else {
        error(formatMessage(messages.QuizNotFound));
      }
    }
  };
  const onSelect = (item) => {
    setCode(item.original_id);
  };

  return initialLoading ? (
    <Loading />
  ) : (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <h1 className="text-2xl pb-6 font-semibold text-gray-600">
          {formatMessage(messages.NewTrivia)}
        </h1>

        <div className="space-y-6">
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {formatMessage(messages.Options)}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {formatMessage(messages.BasicTriviaOptions)}
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-6">
                  <div className="col-span-1 lg:col-span-1 pb-4">
                    <label
                      htmlFor="asdf"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {formatMessage(messages.QuizID)}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <input
                          type="text"
                          name="quizid"
                          id="quizid"
                          autoComplete="new-password"
                          className="focus:ring-poltio-blue-500 focus:border-poltio-blue-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                          placeholder={formatMessage(messages.EnterQuiz)}
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          onKeyPress={(e) => {
                            if (code !== '' && e.key === 'Enter') {
                              startTrivia();
                            } else if (!/^[0-9]$/i.test(e.key)) {
                              e.preventDefault();
                              return false;
                            }
                          }}
                        />
                      </div>
                      <Button.Primary
                        className="-ml-px relative space-x-2 focus:ring-1 rounded-l-none"
                        onClick={startTrivia}
                        disabled={startDisabled || code?.length < 2}
                      >
                        {formatMessage(messages.Start)}
                      </Button.Primary>
                    </div>
                  </div>
                  <div className="col-span-1 lg:col-span-1 pb-4">
                    <ContentSearch
                      onSelect={onSelect}
                      contentTypes={contentTypes}
                    />
                  </div>
                </div>

                <fieldset>
                  <legend className="text-base font-medium text-gray-900">{`${formatMessage(
                    messages.AvailableCredits
                  )} ${credit}`}</legend>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="tier"
                          name="tier"
                          type="checkbox"
                          className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                          checked={triviaState.tier === 'credit' ? true : false}
                          onChange={(e) =>
                            setTriviaState((s) => ({
                              ...s,
                              tier: e.target.checked ? 'credit' : 'free',
                            }))
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-700"
                        >
                          {formatMessage(messages.Paid)}
                        </label>
                        <p className="text-gray-500">
                          {formatMessage(messages.UncheckThis)}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="player_leaderboard"
                          name="player_leaderboard"
                          type="checkbox"
                          className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                          checked={triviaState.player_leaderboard}
                          onChange={(e) =>
                            setTriviaState((s) => ({
                              ...s,
                              player_leaderboard: e.target.checked ? 1 : 0,
                            }))
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="player_leaderboard"
                          className="font-medium text-gray-700"
                        >
                          {formatMessage(messages.ShowBoard)}
                        </label>
                        <p className="text-gray-500">
                          {formatMessage(messages.ShowBoardDesc)}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="elimination"
                          name="elimination"
                          type="checkbox"
                          className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                          checked={triviaState.elimination}
                          onChange={(e) =>
                            setTriviaState((s) => ({
                              ...s,
                              elimination: e.target.checked ? 1 : 0,
                            }))
                          }
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="elimination"
                          className="font-medium text-gray-700"
                        >
                          {formatMessage(messages.Elimination)}
                        </label>
                        <p className="text-gray-500">
                          {formatMessage(messages.EliminationDesc)}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="start_date_on"
                          name="start_date_on"
                          type="checkbox"
                          className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                          checked={hasStartDate}
                          onChange={(e) => setHasStartDate(e.target.checked)}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="start_date_on"
                          className="font-medium text-gray-700"
                        >
                          {formatMessage(messages.SetStartDate)}
                        </label>
                        <p className="text-gray-500">
                          {formatMessage(messages.SetStartDateDesc)}
                        </p>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          {hasStartDate ? (
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {formatMessage(messages.StartDate)}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {formatMessage(messages.StartDateDesc)}
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-2 pb-4">
                      <DatePicker
                        className="form-control"
                        customInput={<input type="text" />}
                        locale={intl.locale.substring(0, 2)}
                        selected={startDate}
                        onChange={(date) => {
                          setTriviaState((s) => ({
                            ...s,
                            start_date: date,
                          }));
                          setStartDate(date);
                        }}
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={15}
                        dateFormat="Pp"
                        minDate={minD}
                        maxDate={maxD}
                      />
                      <Timezone
                        timezone={triviaState.start_date_timezone}
                        onTimezoneChange={(e) =>
                          setTriviaState((s) => ({
                            ...s,
                            start_date_timezone: parseFloat(e.target.value, 10),
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {formatMessage(messages.RegisterFields)}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {formatMessage(messages.RegisterFieldsDesc)}
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 pb-4">
                    <RegisterFields
                      fields={triviaState.register_fields}
                      setTriviaState={setTriviaState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <Button.Primary
              type="submit"
              onClick={startTrivia}
              disabled={startDisabled || code.length < 2}
            >
              {formatMessage(messages.Start)}
            </Button.Primary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(NewTrivia);
