import { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { injectIntl } from 'react-intl';
import messages from './messages';
import Button from '../../Common/Button';

const AnswerVotersModal = ({
  intl: { formatMessage },
  open,
  setOpen,
  data,
}) => {
  const navigate = useNavigate();
  const [checkedAnswers, setCheckedAnswers] = useState([]);

  const getReport = () => {
    navigate(`/reports`, {
      state: { id: checkedAnswers, type: 'answer-voters' },
    });
  };

  const checkboxChanged = (checked, id) => {
    if (checked) {
      setCheckedAnswers([...checkedAnswers, id]);
    } else {
      setCheckedAnswers(checkedAnswers.filter((a) => a !== id));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {formatMessage(messages.UsersByAnswer)}
              </Dialog.Title>
              <div>
                {data.questions.map((q, i) => (
                  <div className="mt-2" key={i}>
                    <h6>{`${i + 1}. ${q.title}`}</h6>
                    {q.answers.map((a, ind) => (
                      <div className="flex items-start" key={ind}>
                        <div className="flex items-center h-5">
                          <input
                            id={`checkbox-${a.id}`}
                            name={`checkbox-${a.id}`}
                            type="checkbox"
                            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                            checked={checkedAnswers.includes(a.id)}
                            onChange={(e) =>
                              checkboxChanged(e.target.checked, a.id)
                            }
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={`checkbox-${a.id}`}
                            className="font-medium text-gray-700"
                          >
                            {a.title}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center gap-4">
                <div>
                  <Button.Primary
                    type="button"
                    className="w-full"
                    onClick={() => setOpen(false)}
                  >
                    {'Close'}
                  </Button.Primary>
                </div>
                <div>
                  <Button.Primary
                    type="button"
                    className="w-full"
                    onClick={getReport}
                  >
                    {formatMessage(messages.GetReport)}
                  </Button.Primary>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default injectIntl(AnswerVotersModal);
