import { genuid } from '../helpers/genuid';

export type PayloadFields =
  | {
      required: string[];
      allowed: { [K: string]: string | number | boolean };
    }
  | string[];

export type DuplicatableFields = string[];

export type MockFields = string[];

class Entity {
  __changes: { [key: string]: any } = {};
  public get _changes() {
    return this.__changes ?? (this.__changes = {});
  }
  public set _changes(value) {
    this.__changes = value;
  }
  public changes() {
    return this._changes;
  }

  __isDirty: boolean = false;
  public get _isDirty(): boolean {
    return this.__isDirty ?? (this.__isDirty = false);
  }
  public set _isDirty(value: boolean) {
    this.__isDirty = value;
  }
  public isDirty(): boolean {
    return this._isDirty;
  }

  _uid: string = genuid();
  public get __uid(): string {
    return this._uid ?? (this._uid = genuid());
  }
  public set __uid(value: string) {
    this._uid = value;
  }
  public uid(): string {
    return this.__uid;
  }
}

export default Entity;
