type T = {
  [key: string | symbol]: any;
} & object;

export const proxyHandler: ProxyHandler<T> = {
  /**
   * A trap for getting a property value.
   * @param target    The original object which is being proxied.
   * @param key       The name or `Symbol` of the property to get.
   * @param receiver  The proxy or an object that inherits from the proxy.
   */
  get(target: T, key: string | symbol, receiver: any): any {
    return target?.[key] ?? null;
  },
  /**
   * A trap for setting a property value.
   * @param target    The original object which is being proxied.
   * @param prop      The name or `Symbol` of the property to set.
   * @param receiver  The object to which the assignment was originally directed.
   * @returns A `Boolean` indicating whether or not the property was set.
   */
  set(target: T, prop: string | symbol, value: any, receiver: any): boolean {
    // check if there is no previous change, check for change
    if (!target?._changes?.[prop]) {
      target._changes[prop] = target?.[prop] !== value;
    }

    // if there is a change, mark change
    if (target._changes[prop]) {
      target['_isDirty'] = true;
    }

    // update value regardless
    target[prop] = value;

    return true;
  },
};
