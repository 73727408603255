import { New } from '../../EditorDataHandler/classes/Result';

export async function NewResult(data, set, save, setActiveResult) {
  if (!data?.public_id) {
    return;
  }

  // save everything so far
  await save();

  const ridx = data?.results?.length ?? 0;

  set(
    `results.${ridx}`,
    New(
      {
        title: `Result ${ridx + 1}`,
        desc: 'Your result description here',
      },
      data
    )
  );

  setActiveResult(ridx, { save: false });
}
