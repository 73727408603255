import React, { useState, useEffect, useCallback, useContext } from 'react';

import { getThemes, deleteTheme, getDefaultTheme } from '../../api';

import { injectIntl } from 'react-intl';

import { useNavigate } from 'react-router-dom';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import ThemeTable from './ThemeTable';
import { NotificationsCTX } from '../../contexts/Notification';
import ThemeCreateEdit from './ThemeCreateEdit';
import Button from '../Common/Button';
import messages from './messages';
import Heading from '../Common/Page/Heading';
import { Helmet } from 'react-helmet';

const Theme = ({ intl: { formatMessage } }) => {
  const [themes, setThemes] = useState([]);
  const [defaultTheme, setDefaultTheme] = useState({});
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const [open, setOpen] = useState(false);
  const [activeTheme, setActiveTheme] = useState({});
  const { success, error } = useContext(NotificationsCTX);

  const fetchTheme = useCallback(async () => {
    const dt = await getDefaultTheme();
    setDefaultTheme(dt.data);
    setLoading(true);
    const response = await getThemes(pageData.page, pageData.sizePerPage);
    setThemes(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.SureDelete))) {
      try {
        await deleteTheme(id);
        success(formatMessage(messages.Success));
        fetchTheme();
      } catch (e) {
        error('Error');
      }
    }
  };

  useEffect(() => {
    fetchTheme();
  }, [fetchTheme]);

  const onEditClick = (t) => {
    setOpen(true);
    if (t) {
      setActiveTheme(t);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      {!open ? (
        <div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Helmet>
              <title>Themes | Poltio Platform</title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Heading
              title={formatMessage(messages.Themes)}
              body={formatMessage(messages.ThemesDesc)}
            >
              <Button.Primary
                className="self-end"
                onClick={() => {
                  navigate(`/theme/new`, {
                    state: {
                      data: defaultTheme,
                      count: themes.length,
                    },
                  });
                  setOpen(true);
                }}
              >
                {formatMessage(messages.AddNewTheme)}
              </Button.Primary>
            </Heading>
          </div>
          {loading ? (
            <div className="max-w-7xl mx-auto px-2">
              <Loading />
            </div>
          ) : (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <ThemeTable
                  themes={themes}
                  openEdit={onEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
                <Pagination
                  last={pageData.lastPage}
                  active={pageData.page}
                  size={pageData.sizePerPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <ThemeCreateEdit
          open={open}
          aTheme={activeTheme}
          success={success}
          error={error}
        />
      )}
    </>
  );
};

export default injectIntl(Theme);
