import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

import api from '../../../api/client';
import Loading from '../../Common/Loading';
import Pagination from '../../Common/Pagination';
import { DSConfigCTX, type TDSConfigRouteParams } from '.';
import { FormattedDate, useIntl } from 'react-intl';

import type { AxiosError } from 'axios';

import type {
  DataSourceElement,
  DataSourceItems,
  DataSourceViewResponse,
  DataSourceStatus,
} from '../types';
import messages from '../messages';

type PublishResponse = {
  msg: string;
};
function apiDSPublishRequest(id: string | number) {
  return api.post(`/platform/data-sources/${id}/publish`);
}
function getItems(id: string | number, page: number, per_page: number) {
  return api.get<DataSourceItems>(
    `/platform/data-sources/${id}/items?page=${page}&per_page=${per_page}`
  );
}

function apiDSGet(id: string | number) {
  return api.get<DataSourceViewResponse>(`/platform/data-sources/${id}`);
}

type PublishStatus =
  | 'draft'
  | 'loading'
  | 'success'
  | 'error'
  | 'published'
  | DataSourceStatus;
export default function PublishPanel() {
  const { value: dsId } = useParams<TDSConfigRouteParams>();

  const { elements, status: dsStatus } = useContext(DSConfigCTX);

  const { formatMessage } = useIntl();
  const [dsElements, setDsElements] = useState<DataSourceElement[]>([]);

  const [status, setStatus] = useState<PublishStatus>('loading');
  const [message, setMessage] = useState<string>('');
  const [items, setItems] = useState<DataSourceItems[]>([]);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: 1,
  });

  useEffect(() => {
    if (!dsId || dsId === 'new') {
      return;
    }
    if (elements && elements.length > 0) {
      setDsElements(elements);
      return;
    }

    (async () => {
      try {
        const response = await apiDSGet(dsId);
        const data = response.data;
        setStatus(data.status);
        setDsElements((prev) => [...(data.data_source_item_elements ?? [])]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const onPublish = async () => {
    if (!dsId || dsId === 'new') {
      return;
    }

    setStatus('loading');

    try {
      const response = await apiDSPublishRequest(dsId);

      setMessage(response?.data?.msg ?? '');
      setStatus('success');
    } catch (err) {
      const error = err as AxiosError<PublishResponse>;

      setMessage(error?.response?.data?.msg ?? '');
      setStatus('error');
    }
  };

  const getDsItems = useCallback(async () => {
    if (!dsId || dsId === 'new') {
      return;
    }

    setStatus('loading');

    try {
      const response = await getItems(
        dsId,
        pageData.page,
        pageData.sizePerPage
      );
      setItems(response.data.data);
      setPageData((p) => {
        return {
          ...p,
          page: p.page,
          sizePerPage: p.sizePerPage,
          totalSize: response.data.total,
          lastPage: response.data.last_page,
        };
      });
      setStatus('published');
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  }, [dsId, pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    if (dsStatus === 'loading') {
      setStatus('loading');
      return;
    }
    if (dsStatus !== 'draft') {
      setStatus('published');
      return;
    }
    if (dsStatus === 'draft') {
      setStatus('draft');
      return;
    }
    // otherwise do nothing
  }, [dsStatus]);

  useEffect(() => {
    if (dsStatus !== ('synced' || 'processing')) {
      return;
    }
    getDsItems();
  }, [dsStatus, getDsItems]);

  return (
    <div className="relative">
      {status === 'loading' ? (
        <div className="absolute backdrop-blur-sm w-full h-full z-30 flex justify-center">
          <Loading />
        </div>
      ) : null}

      {status === 'draft' ? (
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {formatMessage(messages.Almost)}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {formatMessage(messages.ReadyPublish)}
              <br />
              {formatMessage(messages.ClickButton)}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                onClick={onPublish}
                className="rounded-md bg-poltio-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-poltio-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-poltio-blue-600"
              >
                {formatMessage(messages.Publish)}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {status === 'success' ? (
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <div className="mt-10 mb-6 flex items-center justify-center">
              <div className="flex h-24 w-24 items-center justify-center rounded-full border-2 border-green-600 bg-green-600">
                <CheckIcon className="h-20 w-20 text-white" />
              </div>
            </div>

            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {formatMessage(messages.Published)}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {formatMessage(messages.PublishMsg)}
            </p>
          </div>
        </div>
      ) : null}
      {status === 'error' ? (
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <div className="mt-10 mb-6 flex items-center justify-center">
              <div className="flex h-24 w-24 items-center justify-center rounded-full border-2 border-red-700 bg-red-700">
                <XMarkIcon className="h-20 w-20 text-white" />
              </div>
            </div>

            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {formatMessage(messages.Error)}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {formatMessage(messages.ErrorMsg)}
            </p>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {message}
            </p>
          </div>
        </div>
      ) : null}

      {status === 'published' ? (
        <div className="flex justify-between mx-4 my-12">
          <div className="flex justify-center items-center">
            <p className="max-w-xl text-lg leading-8 text-gray-600">
              {formatMessage(messages.Live)}
            </p>
          </div>
          <div className="flex justify-center items-center">
            <Link
              to={`/data-source/${dsId}/sage`}
              className="poltio-btn poltio-btn-primary"
            >
              {formatMessage(messages.CreateWithSage)}
            </Link>
          </div>
        </div>
      ) : null}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 mt-6">
            {formatMessage(messages.Attribute)}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {formatMessage(messages.AttributeDesc)}
          </p>
        </div>
      </div>
      <div className="flex flex-col py-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                    >
                      {'ID'}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      {formatMessage(messages.Type)}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      {'Element'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(dsElements ?? []).map((element, elementIdx) => (
                    <tr
                      key={elementIdx}
                      className={
                        elementIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      }
                    >
                      <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
                        <div className="text-sm font-medium text-gray-900">
                          {element.id}
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {element.type}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {element.element}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {dsStatus === ('synced' || 'processing') ? (
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900 mt-6">
                Items
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {formatMessage(messages.ItemList)}
              </p>
            </div>
          </div>
          <div className="flex flex-col py-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                        >
                          {'ID'}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          {formatMessage(messages.Name)}
                        </th>
                        <th
                          scope="col"
                          className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:flex"
                        >
                          {formatMessage(messages.Created)}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          {formatMessage(messages.UpdatedAt)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(items ?? []).map((item, itemIdx) => (
                        <tr
                          key={itemIdx}
                          className={
                            itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
                            <div className="text-sm font-medium text-gray-900">
                              {item.id}
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                            {item.name}
                          </td>
                          <td className="hidden px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:flex">
                            <div className="text-sm font-medium text-gray-900">
                              <FormattedDate value={item.created_at} />
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                            <FormattedDate value={item.updated_at} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 max-w-7xl mx-auto">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
              totalSize={pageData.totalSize}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
