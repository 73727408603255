import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getPlayerList, getTriviaActions } from '../../api';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import { injectIntl } from 'react-intl';
import messages from './messages';
import Button from '../Common/Button';

const PlayerList = ({ intl: { formatMessage, formatDate, formatTime } }) => {
  const { id } = useParams();
  const [playerList, setPlayerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });
  const [actions, setActions] = useState([]);
  const [winnersOnly, setWinnersOnly] = useState(0);

  const fetchPlayerListAndActions = useCallback(async () => {
    setLoading(true);
    const response = await getPlayerList(
      id,
      pageData.page,
      pageData.sizePerPage,
      winnersOnly
    );
    const response2 = await getTriviaActions(id);
    setPlayerList(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
    setActions(response2.data);
  }, [pageData.page, pageData.sizePerPage, id, winnersOnly]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchPlayerListAndActions();
  }, [fetchPlayerListAndActions]);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-600">
          {formatMessage(messages.GameDetails)} #{id}
        </h1>
      </div>

      <div className="grid justify-items-stretch max-w-7xl mx-auto px-2 py-6 sm:px-6 md:px-8">
        <div className="flex justify-self-start justify-end items-stretch">
          <Button.Primary className="self-end" href={`/trivia`}>
            <ArrowLeftIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {formatMessage(messages.BacktoGameList)}
          </Button.Primary>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-1">
            <h3 className="py-1 text-lg leading-6 font-medium text-gray-600">
              {formatMessage(messages.Actions)}
            </h3>
          </div>
        </div>
        <div className="flex flex-col py-4">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                      >
                        {formatMessage(messages.Action)}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        {formatMessage(messages.CreatedAt)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {actions.map((row, i) => (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {row.action}
                          </div>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {formatDate(row.created_at)}{' '}
                            {formatTime(row.created_at)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-1">
            <h3 className="py-1 text-lg leading-6 font-medium text-gray-600">
              {formatMessage(messages.PlayerList)}
            </h3>
          </div>
        </div>

        <div className="relative flex items-start py-4">
          <div className="flex items-center h-5">
            <input
              id="winners"
              aria-describedby="winners-description"
              name="winners"
              type="checkbox"
              className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
              checked={winnersOnly === 1 ? true : false}
              onChange={(e) => setWinnersOnly(e.target.checked ? 1 : 0)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              {formatMessage(messages.ShowWinnersOnly)}
            </label>
          </div>
        </div>

        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-col py-4">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                          >
                            {formatMessage(messages.UserID)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.UserName)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.Points)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.Email)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.Phone)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.Comment)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.TCAccepted)}
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {formatMessage(messages.CreatedAt)}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerList.map((row, i) => (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                          >
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.user_id}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.nick}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.points}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.email}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.phone}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.comment}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.tc}
                              </div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {row.created_at}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default injectIntl(PlayerList);
