type ReducedChanges = {
  [key: string]: any;
};

export function getReducedChanges(changes: any[]): ReducedChanges {
  return changes.reduce((acc, change, index) => {
    // if item is present
    if (acc[change.key]) {
      return acc;
    }

    acc[change.key] = change;

    return { ...acc };
  }, {});
}
