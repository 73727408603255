import { useContext, useEffect, useState } from 'react';
import {
  CheckIcon,
  ArrowPathIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import messages from './messages';

import { updatePassword } from '../../../api';
import { NotificationsCTX } from '../../../contexts/Notification';
import Button from '../../Common/Button';
import PasswordStrengthBar from 'react-password-strength-bar';

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const Status = {
  NoChanges: 0,
  Ok: 1,
  Changed: 2,
  Submitted: 3,
  Error: 4,
};

const SecurityPassword = ({ intl: { formatMessage } }) => {
  const { alert, success } = useContext(NotificationsCTX);

  const [_old, setOld] = useState('');
  const [_new, setNew] = useState('');
  const [_repeat, setRepeat] = useState('');
  const [status, setStatus] = useState(Status.NoChanges);
  const [isCurrentPasswordWrong, setCurrentPasswordWrong] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const tooShort = _new.length < 8;
    const enableSave = _old.length > 0 && _new.length > 0 && _repeat.length > 0;
    const passwordsMatch = _new === _repeat;

    setButtonDisabled(tooShort || !enableSave || !passwordsMatch);
  }, [_old, _new, _repeat]);

  useEffect(() => {
    setCurrentPasswordWrong(false);
  }, [_old]);

  const onSave = async () => {
    setStatus(Status.Submitted);

    try {
      await updatePassword(_old, _new, _repeat);

      setTimeout(() => {
        setOld('');
        setNew('');
        setRepeat('');

        setStatus(Status.Ok);
      }, 500);
      success(formatMessage(messages.SuccessPassword));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setStatus(Status.Error);
      setTimeout(() => {
        setStatus(Status.NoChanges);
      }, 5000);

      // 422 | old password wrong
      if (error.response.status === 422) {
        setCurrentPasswordWrong(true);

        return;
      }

      alert(
        formatMessage(messages.AccountSettingsErrorUnhandled),
        formatMessage(messages.AccountSettingsErrorUnhandledText)
      );
    }
  };

  return (
    <>
      <div className="col-span-full">
        <label
          htmlFor="current-password"
          className="block text-sm font-medium leading-6 "
        >
          {formatMessage(messages.SecuritySettingsPasswordCurrent)}
        </label>
        <div className="mt-2">
          <input
            id="current-password"
            name="current-password"
            type="password"
            autoComplete="current-password"
            className={classNames(
              'block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6',
              isCurrentPasswordWrong
                ? 'ring-red-600 border-red-600 focus:ring-red-600 focus:border-red-600'
                : 'focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm border-gray-300 rounded-md'
            )}
            value={_old}
            onChange={(event) => setOld(event.target.value)}
          />
        </div>
        <div className="flex text-red-600 absolute">
          <span className="pl-3 text-xs align-middle">
            {isCurrentPasswordWrong
              ? formatMessage(
                  messages.SecuritySettingsPasswordErrorCurrentFalse
                )
              : ''}
          </span>
        </div>
      </div>

      <div className="col-span-full">
        <label
          htmlFor="new-password"
          className="block text-sm font-medium leading-6 "
        >
          {formatMessage(messages.SecuritySettingsPasswordNew)}
        </label>
        <div className="mt-2">
          <input
            id="new-password"
            name="new-password"
            type="password"
            autoComplete="new-password"
            className={classNames(
              'block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6'
            )}
            value={_new}
            onChange={(event) => setNew(event.target.value)}
          />
        </div>
        <PasswordStrengthBar
          password={_new}
          minLength={8}
          className="max-w-lg block w-full  sm:max-w-xs sm:text-sm  rounded-md"
        />
      </div>

      <div className="col-span-full">
        <label
          htmlFor="repeat-password"
          className="block text-sm font-medium leading-6 "
        >
          {formatMessage(messages.SecuritySettingsPasswordRepeat)}
        </label>
        <div className="mt-2">
          <input
            id="repeat-password"
            name="repeat-password"
            type="password"
            autoComplete="repeat-password"
            className={classNames(
              'block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6',
              _new !== _repeat
                ? 'ring-red-600 border-red-600 focus:ring-red-600 focus:border-red-600'
                : 'focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm border-gray-300 rounded-md'
            )}
            value={_repeat}
            onChange={(event) => setRepeat(event.target.value)}
          />
        </div>
        <div className="flex text-red-600 absolute">
          <span className="pl-3 text-xs align-middle">
            {_new === _repeat
              ? ''
              : formatMessage(messages.SecuritySettingsPasswordErrorDontMatch)}
          </span>
        </div>
      </div>

      <div className="mt-8 flex">
        <Button.Primary
          type="submit"
          onClick={onSave}
          disabled={buttonDisabled}
        >
          {formatMessage(messages.SecuritySettingsPasswordSave)}
        </Button.Primary>
        <label className="inline-flex px-2 align-middle">
          {status === Status.Ok && (
            <CheckIcon className="h-4 w-4 text-green-600" />
          )}
          {status === Status.Submitted && (
            <ArrowPathIcon className="h-4 w-4 text-yellow-500" />
          )}
          {status === Status.Error && (
            <XMarkIcon className="h-4 w-4 text-red-600" />
          )}
        </label>
      </div>
    </>
  );
};

export default injectIntl(SecurityPassword);
