import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  ThemeSaved: {
    id: 'Gtm.ThemeSaved',
    defaultMessage: 'Theme saved!',
  },
  ErrorSaveTheme: {
    id: 'Gtm.ErrorSaveTheme',
    defaultMessage: 'Error saving theme!',
  },
  GtmIntegration: {
    id: 'Gtm.GtmIntegration',
    defaultMessage: 'Google Tag Manager Integration',
  },
  GtmDesc: {
    id: 'Gtm.GtmDesc',
    defaultMessage:
      'Recieve your users realtime interactions with Poltio Widgets on your Google Tag Manager or Google Analytics',
  },
  Assign: {
    id: 'Gtm.Assign',
    defaultMessage: 'Assign Gtm to your themes',
  },
  AssignDesc: {
    id: 'Gtm.AssignDesc',
    defaultMessage:
      'Poltio Widgets trigger events when user interacts with it. By setting your GTM ID and dataLayer name you can start sending those interactions events to your GTM or Google Analytics directly.',
  },
  AssignDesc2: {
    id: 'Gtm.AssignDesc2',
    defaultMessage:
      'To see a list of events and properties we send to GTM you can check out',
  },
  OurDoc: {
    id: 'Gtm.OurDoc',
    defaultMessage: 'our documentation here.',
  },
  SelectTheme: {
    id: 'Gtm.SelectTheme',
    defaultMessage: 'Select Your Theme to integrate with GTM ',
  },
  GtmIdDesc: {
    id: 'Gtm.GtmIdDesc',
    defaultMessage:
      'The GTM ID that you want to use when poltio widgets opened not embedded forms (eg: in-app browsers or direct link)',
  },
  DlDesc: {
    id: 'Gtm.DlDesc',
    defaultMessage:
      'If you are using custom `dataLayer` name you can enter it here.',
  },
  Save: {
    id: 'Gtm.Save',
    defaultMessage: 'Save',
  },
});
