import { useState } from 'react';
import { serialize } from '../../core/helper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ThemePreview = ({ isMobile, theme }) => {
  const [openTab, setOpenTab] = useState('cover');

  const mockData =
    openTab === 'cover'
      ? `title=Pick%20The%20Right%20Housewarming%20Gift!&background=content%2Fgcp%2F1581037710.6719.png&desc=Take%20our%20test%20to%20discover%20home%20decor%20finds%20that%20you%20will%20love&vote_count=259`
      : openTab === 'question'
        ? `answer_type=text&title=What%20is%20your%20budget%3F&background=content%2Fgcp%2F1581090423.0599.jpg&vote_count=154&answers%5B0%5D%5Btitle%5D=Under%20%2425&answers%5B1%5D%5Btitle%5D=%2425%20to%20%2450&answers%5B2%5D%5Btitle%5D=%2450%20to%20%24100&answers%5B3%5D%5Btitle%5D=Over%20%24100&answers%5B4%5D%5Btitle%5D=No%20Preferance`
        : `title=Checkout%20items%20we%20handpicked%20for%20you&background=content%2Fgcp%2F1581090894.474.jpg&desc=%20%20&url=https%3A%2F%2Fwww.etsy.com%2Fc%2Fhome-and-living&url_text=Check%20Out%20My%20Recommendations`;

  return (
    <div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <div className="-mb-px flex">
            <button
              className={classNames(
                openTab === 'cover'
                  ? 'border-poltio-blue-500 text-poltio-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'text-center border-b-2 font-medium text-md  w-1/3 py-2 px-1'
              )}
              onClick={() => setOpenTab('cover')}
            >
              Cover
            </button>

            <button
              className={classNames(
                openTab === 'question'
                  ? 'border-poltio-blue-500 text-poltio-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'text-center border-b-2 font-medium text-md w-1/3 py-2 px-1'
              )}
              onClick={() => setOpenTab('question')}
            >
              Question{' '}
            </button>

            <button
              className={classNames(
                openTab === 'result'
                  ? 'border-poltio-blue-500 text-poltio-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'text-center border-b-2 font-medium text-md w-1/3 py-2 px-1'
              )}
              onClick={() => setOpenTab('result')}
            >
              Result
            </button>
          </div>
        </div>
        <div className="mt-6 ">
          <div className={'flex w-full justify-center'}>
            <iframe
              id="poltio-embed-mock"
              className="poltio-embed"
              src={`https://poltio.com/mock/${openTab}?disclaimer=off&share=on&notify=on&align=center&loc=en&theme=yes&${serialize(
                theme
              )}&${mockData}`}
              width={isMobile ? '70%' : '100%'}
              height="700"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              scrolling="yes"
              title="Embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemePreview;
