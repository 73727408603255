import api from '../../client';
import { ensureARGs } from '../../helpers/ensureARGs';

import { save, saveAnswer } from './save';

export { save, saveAnswer };

// delete call
export function delAnswer(contentId, questionId, answerId) {
  ensureARGs(contentId, questionId, answerId);

  return api.delete(
    `/platform/content/${contentId}/question/${questionId}/answer/${answerId}`
  );
}

export function saveMultipleAnswers(contentId, questionId, answerIds, re) {
  return api.post(
    `/platform/content/${contentId}/question/${questionId}/answer/multi`,
    {
      answers: answerIds,
      remove_existing: re,
    }
  );
}

// attribute calls
export function saveAnswerLeads(contentId, questionId, answerId, leadId) {
  ensureARGs(contentId, questionId, answerId);

  return leadId
    ? api.put(
        `/platform/content/${contentId}/question/${questionId}/answer/${answerId}/lead`,
        { lead_id: leadId }
      )
    : api.delete(
        `/platform/content/${contentId}/question/${questionId}/answer/${answerId}/lead`
      );
}

export function saveAnswerPixelCode(
  contentId,
  questionId,
  answerId,
  pixelCodeId
) {
  ensureARGs(contentId, questionId, answerId);

  return pixelCodeId
    ? api.put(
        `/platform/content/${contentId}/question/${questionId}/answer/${answerId}/pixel-code`,
        { pixel_code_id: pixelCodeId }
      )
    : api.delete(
        `/platform/content/${contentId}/question/${questionId}/answer/${answerId}/pixel-code`
      );
}

export function saveAnswerResultRelation(
  contentId,
  questionId,
  answerId,
  data
) {
  const data_id = data?.id;
  const data_pt = data?.point || data?.pivot?.point || 0;

  ensureARGs(contentId, questionId, answerId, data_id, data_pt);

  return api.put(
    `/platform/content/${contentId}/question/${questionId}/answer/${answerId}/results`,
    {
      content_result_id: data.id,
      point: data_pt,
    }
  );
}
