export function getResult(content, change) {
  /** @type {Array<object>} */
  const results = content.results;
  const result = results[change.ridx];
  if (result === undefined) {
    console.info(`Could not find result at \`${change.ridx}\``);
    return undefined;
  }

  return result;
}
