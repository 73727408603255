import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import messages from '../messages';

import { widgetLeadImagePath } from '../../../core/constants';
import ImageInput from '../../Common/ImageInput';
import {
  CheckBoxInput,
  Label,
  TextAreaInput,
  SwitchInput,
} from '../../Common/Form';
import { TextInput } from '../../Common/Form';

import LeadFormBodyCouponCode from './LeadFormBodyCouponCode';
import { CouponCode, Lead } from '../../../core/types';

type CBProps = {
  text?: string;
  accessor: string;
  data?: Lead;
  setData: Dispatch<SetStateAction<Lead>>;
};

type TACProps = {
  data?: Lead;
  setData: Dispatch<SetStateAction<Lead>>;
  formatMessage: any;
};

type Props = {
  data: Lead;
  setData: Dispatch<SetStateAction<Lead>>;
  formatMessage: any;
  code: CouponCode;
  setCode: Dispatch<SetStateAction<CouponCode>>;
  panelOpen: boolean;
};

const CheckBoxIns: FC<CBProps> = ({ text, accessor, data: _data, setData }) => {
  const isChecked = (_data?.fields || '').split(',').indexOf(accessor) >= 0;

  const set = (val: any) => {
    setData((prev: Lead) => {
      let fields = (prev.fields || '').split(',');

      if (val) {
        // enable field
        if (fields.indexOf(accessor) < 0) {
          // unique
          fields.push(accessor);
        }
      } else {
        // disable field
        fields = fields.filter((item: any) => item !== accessor);
      }
      const fieldsNext = fields.join(',');

      return { ...prev, fields: fieldsNext };
    });
  };

  return (
    <CheckBoxInput
      name={accessor}
      text={text}
      onChange={(event) => set(event.target.checked)}
      checked={isChecked}
      className=""
      label=""
    />
  );
};

const TermsAndConditions: FC<TACProps> = ({ data, setData, formatMessage }) => {
  const [openTC1, setOpenTC1] = useState(false);
  const [openTC2, setOpenTC2] = useState(false);

  useEffect(() => {
    if (data?.terms_conditions) {
      setOpenTC1(true);
    }
    if (data?.terms_conditions && data?.terms_conditions2) {
      setOpenTC2(true);
    }
  }, [openTC1, openTC2, setData, data]);

  return (
    <>
      <div className="border-t space-y-3 pt-6 font-semibold">
        <SwitchInput onSwitch={(value) => setOpenTC1(value)} value={openTC1}>
          {formatMessage(messages.RequireTC1)}
        </SwitchInput>
        <div className="font-normal">
          {openTC1 && (
            <div className="pl-2">
              <SwitchInput
                onSwitch={(value) => {
                  setData((prev) => ({
                    ...prev,
                    tc_optional: value ? 1 : 0,
                  }));
                }}
                value={!!data?.tc_optional}
              >
                {formatMessage(messages.Optional)}
              </SwitchInput>
              <p className="mt-2 text-sm text-gray-500">
                {formatMessage(messages.OptionalDesc)}
              </p>

              <TextAreaInput
                autogrow
                name="terms-and-conditions-1-text"
                rows={1}
                debounce={false}
                value={data?.terms_conditions || ''}
                onChange={(event: any) =>
                  setData((prev) => ({
                    ...prev,
                    terms_conditions: event.target.value,
                  }))
                }
              />
              <div className="mt-2 text-sm font-semibold text-gray-500 flex flex-col gap-y-1">
                <span className="text-md text-gray-800">
                  {formatMessage(messages.UseLinks)}
                </span>
                <code className="text-xs">
                  [Poltio](https://www.poltio.com)
                </code>
                <div className="flex flex-col gap-y-1 bg-gray-200 rounded-md p-2">
                  <span className="max-w-max text-center">
                    You can visit [Poltio](https://www.poltio.com)
                  </span>
                  <span className="font-bold ml-12">{' ⇓ '}</span>
                  <span className="max-w-max text-center">
                    You can visit{' '}
                    <a
                      className="font-bold text-poltio-blue hover:text-sky-400 w-10"
                      href="https://www.poltio.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {'Poltio'}
                    </a>
                    .
                  </span>
                </div>
              </div>
              <div className="mt-2 text-sm font-semibold text-gray-500 flex flex-col gap-y-1">
                <span className="text-md text-gray-800">
                  {formatMessage(messages.UseBold)}
                </span>
                <code className="text-xs"> **bold text**.</code>
                <div className="flex flex-col gap-y-1 bg-gray-200 rounded-md p-2">
                  <span className="max-w-max rounded-md text-center">
                    This is an example of **bold text**
                  </span>
                  <span className="font-bold ml-12">{' ⇓ '}</span>
                  <span className="max-w-max rounded-md text-center">
                    This is an example of{' '}
                    <span className="font-bold text-gray-800">
                      {'bold text'}
                    </span>
                  </span>
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                {formatMessage(messages.TCPlaceholder)}
                <a
                  className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                  href="https://www.markdownguide.org/basic-syntax/#links"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.markdownguide.org
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
      {openTC1 && (
        <div className="space-y-3 font-semibold">
          <SwitchInput onSwitch={(value) => setOpenTC2(value)} value={openTC2}>
            {formatMessage(messages.RequireTC2)}
          </SwitchInput>
          <div className="font-normal">
            {openTC2 && (
              <div className="pl-2">
                <SwitchInput
                  onSwitch={(value) => {
                    setData((prev) => ({
                      ...prev,
                      tc2_optional: value ? 1 : 0,
                    }));
                  }}
                  value={!!data?.tc2_optional}
                >
                  {formatMessage(messages.Optional)}
                </SwitchInput>
                <p className="mt-2 text-sm text-gray-500">
                  {formatMessage(messages.OptionalDesc)}
                </p>

                <TextAreaInput
                  autogrow
                  name="terms-and-conditions-2-text"
                  rows={1}
                  debounce={false}
                  value={data?.terms_conditions2 || ''}
                  onChange={(event: any) =>
                    setData((prev) => ({
                      ...prev,
                      terms_conditions2: event.target.value,
                    }))
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const LeadFormBodyInputType: FC<Props> = ({
  data,
  setData,
  formatMessage,
  code,
  setCode,
  panelOpen,
}) => {
  return (
    <div className="space-y-6 pt-6 pb-5">
      <div className="">
        <TextInput
          name="name"
          font="semibold"
          text={formatMessage(messages.NameLabel)}
          debounce={false}
          value={data.name || ''}
          onChange={(event: any) =>
            setData((prev) => ({ ...prev, name: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.NameDesc)}
        </p>
      </div>
      <div className="">
        <TextInput
          name="name"
          font="semibold"
          text={formatMessage(messages.Title)}
          debounce={false}
          value={data.title || ''}
          onChange={(event: any) =>
            setData((prev) => ({ ...prev, title: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.TitleDesc)}
        </p>
      </div>

      <div className="">
        <TextInput
          name="msg"
          font="semibold"
          text={formatMessage(messages.Message)}
          debounce={false}
          value={data.msg || ''}
          onChange={(event: any) =>
            setData((prev) => ({ ...prev, msg: event.target.value }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.MessageDesc)}
        </p>
      </div>

      <div className="">
        <Label
          htmlFor="lead-image"
          text={formatMessage(messages.LeadImage)}
          font="semibold"
        />
        <div className="mt-1 h-56">
          <ImageInput
            type="cover"
            title={formatMessage(messages.LeadImage)}
            src={data.image ? `${widgetLeadImagePath}/${data.image}` : ''}
            callback={(url) => setData((prev) => ({ ...prev, image: url }))}
            modal
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.LeadImageDesc)}
        </p>
      </div>

      <div className="">
        <Label
          htmlFor="user-input"
          text={formatMessage(messages.UserInput)}
          font="semibold"
        />
        <div className="grid grid-cols-3 mt-1">
          <CheckBoxIns
            text={formatMessage(messages.CheckBoxInsGSM)}
            accessor="gsm"
            data={data}
            setData={setData}
          />
          <CheckBoxIns
            text={formatMessage(messages.CheckBoxInsEmail)}
            accessor="email"
            data={data}
            setData={setData}
          />
          <CheckBoxIns
            text={formatMessage(messages.CheckBoxInsName)}
            accessor="name"
            data={data}
            setData={setData}
          />
          <CheckBoxIns
            text={formatMessage(messages.CheckBoxInsComment)}
            accessor="comment"
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div className="">
        <TextInput
          name="delay"
          type="number"
          font="semibold"
          debounce={false}
          min={0}
          text={formatMessage(messages.Delay)}
          value={data.delay || 0}
          step={500}
          onChange={(event: any) =>
            setData((prev) => ({
              ...prev,
              delay: event.target.valueAsNumber,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.DelayWorkDesc)}
        </p>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.DelayAppearDesc)}
        </p>
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.MstoSec)}
        </p>
      </div>
      <div className="font-semibold">
        <SwitchInput
          value={!!data.open_minimized}
          onSwitch={(value) =>
            setData((prev) => ({
              ...prev,
              open_minimized: value ? true : false,
            }))
          }
        >
          {formatMessage(messages.Minimized)}
        </SwitchInput>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.DelayWorkDesc)}
        </p>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.MinimizedWorkDesc)}
        </p>
      </div>
      <div className="">
        <Label
          htmlFor="user-input"
          text={formatMessage(messages.ButtonText)}
          font="semibold"
        />
        <TextInput
          name="button_value"
          // text={formatMessage(messages.ButtonText)}
          placeholder={formatMessage(messages.ButtonTextPlaceholder)}
          value={data.button_value || ''}
          debounce={false}
          onChange={(event: any) =>
            setData((prev) => ({
              ...prev,
              button_value: event.target.value,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.ButtonTextDesc)}
        </p>
      </div>
      <div className="">
        <TextInput
          name="youtube_id"
          text={formatMessage(messages.YouTubeID)}
          font="semibold"
          debounce={false}
          // placeholder={formatMessage(messages.YouTubeIDPlaceholder)}
          value={data.youtube_id || ''}
          onChange={(event: any) =>
            setData((prev) => ({
              ...prev,
              youtube_id: event.target.value,
            }))
          }
        />
        <p className="mt-2 text-sm text-gray-500">
          {formatMessage(messages.YouTubeIDDesc)}
        </p>
      </div>
      <TermsAndConditions
        formatMessage={formatMessage}
        data={data}
        setData={setData}
      />

      <div className="border-t pt-2 font-semibold">
        <SwitchInput
          value={!!data.mandatory}
          onSwitch={(value) =>
            setData((prev) => ({
              ...prev,
              mandatory: value ? 1 : 0,
            }))
          }
        >
          {formatMessage(messages.Mandatory)}
        </SwitchInput>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.MandatoryText)}
        </p>
      </div>

      <div className="border-t pt-2 font-semibold">
        <SwitchInput
          value={!!data.stop_set}
          onSwitch={(value) =>
            setData((prev) => ({
              ...prev,
              stop_set: value ? 1 : 0,
            }))
          }
        >
          {formatMessage(messages.StopSet)}
        </SwitchInput>
        <p className="mt-2 text-sm text-gray-500 font-normal">
          {formatMessage(messages.StopSetText)}
        </p>
      </div>
      <LeadFormBodyCouponCode
        data={data}
        formatMessage={formatMessage}
        code={code}
        setCode={setCode}
        panelOpen={panelOpen}
      />
    </div>
  );
};

export default LeadFormBodyInputType;
