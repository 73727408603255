import { sage } from '../../../api';

type SageContentRequestBody = {
  questions: {
    title: string;
    answers: {
      title: string;
    }[];
    data_source_item_element_id?: string | number;
  }[];
};
async function handleCreateContent(id?: string, questions?: any[]) {
  if (!id) return;

  if (!questions || questions.length === 0) return;

  // convert to content format
  let body: SageContentRequestBody = {
    questions: [],
  };

  questions.forEach((question) => {
    if (Object.keys(question).length === 0) {
      return;
    }

    const { question: title, choices: answers } = question;
    body.questions.push({
      title: title ?? '',
      answers: answers?.map((answer) => ({ title: answer })) ?? [],
      data_source_item_element_id: question?.elementId,
    });
  });

  let publicId = null;
  try {
    const response = await sage.content(id, body);
    publicId = response?.data?.public_id;
  } catch (err) {
    console.error(err);
  }

  return publicId;
}

export default handleCreateContent;
