import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/outline';

import { Expander } from '../../../../../Common/Form';
import { EditorCTX } from '../../../../context';
import { ConfTypes } from '../../../../enums';

import { QuestionsList } from '../List';
import messages from '../../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const _AddNewButton = ({ intl: { formatMessage } }) => {
  const { data, addNewQuestion } = useContext(EditorCTX);

  const nextQIdx = data?.questions?.length ?? 0;

  return (
    <div className="flex justify-center py-2 mt-4">
      <button
        className="px-2 poltio-btn poltio-btn-secondary"
        onClick={() => addNewQuestion(nextQIdx)}
        disabled={!addNewQuestion}
      >
        {formatMessage(messages.AddNewQuestion)}
        <PlusIcon className="h-6 ml-2" />
      </button>
    </div>
  );
};

const AddNewButton = injectIntl(_AddNewButton);

const QuestionList = ({ intl: { formatMessage } }) => {
  const {
    focus,
    confType: activeType,
    activeQuestionIdx: qidx,
  } = useContext(EditorCTX);

  return (
    <Expander
      open={activeType === ConfTypes.Question}
      as="div"
      className="overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <Expander.Button
        className="flex w-full px-2 py-2 sm:px-2"
        onClick={() =>
          focus(ConfTypes.Question, `questions.${qidx}`, { save: false })
        }
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-2 mt-1 mb-2">
            <h2 className="flex text-xl">
              {formatMessage(messages.Questions)}
            </h2>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-5 sm:p-6">
          <QuestionsList />
          <AddNewButton />
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(QuestionList);
