/**
 *
 * @param {object} content
 * @param {*} change
 * @returns {(object|undefined)}
 */
export function getQuestion(content, change) {
  /** @type {Array<object>} */
  const questions = content.questions;
  const question = questions?.find((value) => value.__uid === change.q_uid);
  if (question === undefined) {
    console.info(`Could not find question with UID \`${change.q_uid}\`.`);
    return undefined;
  }

  return question;
}
