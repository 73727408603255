import { useContext } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import { SortableList } from '../../../../../../Common/SortableList';

import { EditorCTX } from '../../../../../context';

import Answer from './Answer';
import ImportAnswersFromQuestion from './ImportAnswersFromQuestion';
import { isQuestionMediaType } from '../../../../../helpers/isQuestionMediaType';

import messages from '../../../messages';

const Answers = ({ intl: { formatMessage } }) => {
  const {
    focus,
    set,
    get,
    activeQuestionIdx: qidx,
    addNewAnswer,
    delAnswerAt,
  } = useContext(EditorCTX);
  const active_question = get(`questions.${qidx}`);

  const answer_type = active_question?.answer_type;
  const use_answers = active_question?.isUseAnswers();
  const active_answer_type = active_question?.getAnswerType();
  const display_answer_type = active_question?.getDisplayAnswerType();

  const answers = active_question?.answers ?? [];

  const onSave = (next) => {
    set(`properties.questions.${qidx}.answers.order`, next);
  };

  const onSaveImage = (value, qidx, aidx) => {
    const isMediaType = isQuestionMediaType(answers, aidx, value);

    set(`questions.${qidx}.answers.${aidx}.background`, value);
    if (isMediaType && answer_type !== 'media') {
      set(`questions.${qidx}.answer_type`, 'media');
    }
    if (!isMediaType && answer_type !== 'text') {
      set(`questions.${qidx}.answer_type`, 'text');
    }
  };

  return (
    <div className="space-y-1">
      {use_answers ? <ImportAnswersFromQuestion /> : null}
      {active_answer_type?.listable ? (
        <div className="relative divide-y-2">
          <SortableList
            items={Array.from(answers)}
            onSave={onSave}
            component={Answer}
            componentProps={{
              answer_type: display_answer_type,
              qidx: qidx,
              set: set,
              focus: focus,
              onSaveImage: onSaveImage,
              delAnswerAt: delAnswerAt,
              length: answers.length,
            }}
          />
          {use_answers ? (
            <div className="absolute top-0 z-40  w-full h-full bg-gray-300 opacity-25 rounded" />
          ) : null}
        </div>
      ) : null}
      <div className="flex justify-center">
        {active_answer_type?.dynamic ? (
          <button
            className="poltio-btn-xs poltio-btn-secondary"
            onClick={addNewAnswer}
          >
            <span className="inline-block my-auto align-middle">
              {formatMessage(messages.AddAnswer)}
            </span>
            <span className="ml-2">
              <PlusCircleIcon className="w-6 h-6" />
            </span>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default injectIntl(Answers);
