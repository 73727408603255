import React from 'react';

const Loading = ({ style = {} }) => (
  <div
    style={{
      width: '100%',
      padding: '5rem 0',
      borderRadius: '0.5rem',
      ...style,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      style={{ margin: 'auto', display: 'block' }}
      width="5em"
      height="5em"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="17" cy="46.1078" r="5" fill="#ffc000">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="56;44;56;56"
          keyTimes="0;0.3;0.6;1"
          dur="1s"
          begin="-0.6s"
        ></animate>
      </circle>{' '}
      <circle cx="39" cy="56" r="5" fill="#ff6644">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="56;44;56;56"
          keyTimes="0;0.3;0.6;1"
          dur="1s"
          begin="-0.45s"
        ></animate>
      </circle>{' '}
      <circle cx="61" cy="56" r="5" fill="#009eec">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="56;44;56;56"
          keyTimes="0;0.3;0.6;1"
          dur="1s"
          begin="-0.3s"
        ></animate>
      </circle>{' '}
      <circle cx="83" cy="56" r="5" fill="#d66ddd">
        <animate
          attributeName="cy"
          calcMode="spline"
          keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
          repeatCount="indefinite"
          values="56;44;56;56"
          keyTimes="0;0.3;0.6;1"
          dur="1s"
          begin="-0.15s"
        ></animate>
      </circle>
    </svg>
  </div>
);

export default Loading;
