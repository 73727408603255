import Answer from '../EditorAPI/EditorDataHandler/classes/Answer';

/**
 * @param {Array} curr
 * @param {Array} next
 * @param {Number} qidx
 * @param {Function} _set
 * @param {Function} _del
 */
export function overrideAnswers(question, next, qidx, _set, _del) {
  const curr = question.answers ?? [];

  // if curr_answers list is longer than next_answers list,
  //   remove starting from next_answers.length.
  let cull_after_index =
    curr.length > next.length ? next.length - 1 : next.length;

  // first override existing current answers
  next.forEach((answer, index) => {
    let _answer = Object.assign({}, answer);

    _answer.id = curr?.[index]?.id;
    _answer.__uid = curr?.[index]?.__uid;
    _answer.position = index; // ensure position of answer

    _set(`questions.${qidx}.answers.${index}`, Answer.New(_answer, question));
  });

  // remove remainders starting from the last one
  for (let aidx = curr.length - 1; aidx > cull_after_index; aidx--) {
    _del(aidx, qidx);
  }
}
