import { useState, useCallback, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Heading from '../../Common/Page/Heading';
import Pagination from '../../Common/Pagination';
import Loading from '../../Common/Loading';
import messages from './messages';
import usePersistentStore from '../../../core/usePersistentStore';
import { injectIntl } from 'react-intl';
import { getTokens, revokeToken } from '../../../api';
import TokensTable from './TokensTable';
import Button from '../../Common/Button';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { NotificationsCTX } from '../../../contexts/Notification';

const Tokens = ({ intl: { formatMessage } }) => {
  const [loading, setLoading] = useState(true);

  const [tokens, setTokens] = useState([]);
  const [sizePerPage, setSizePerPage] = usePersistentStore(50, 'spp-px');
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    lastPage: null,
  });

  const navigate = useNavigate();
  const { error, success } = useContext(NotificationsCTX);

  const fetchTokens = useCallback(async () => {
    setLoading(true);
    const response = await getTokens(pageData.page, sizePerPage);
    setTokens(response.data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        totalSize: response.data.total,
        lastPage: response.data.last_page,
      };
    });
  }, [pageData.page, sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setSizePerPage(sizePerPage);
    setPageData({ ...pageData, page });
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm(formatMessage(messages.Sure))) {
      try {
        await revokeToken(id);
        success(formatMessage(messages.Removed));
        fetchTokens();
      } catch (e) {
        error(formatMessage(messages.ErrorRemoved));
      }
    }
  };

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.Title)}
          body={formatMessage(messages.Subtitle)}
        >
          <Button.Primary
            className="self-end"
            onClick={() => {
              navigate(`/token/new`);
            }}
          >
            <PlusCircleIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {formatMessage(messages.CreateNew)}
          </Button.Primary>
        </Heading>
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <TokensTable
              tokens={tokens}
              handleDeleteClick={handleDeleteClick}
            />
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={sizePerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(Tokens);
