import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { RadioGroup, Switch } from '@headlessui/react';

const convertRgbString = (val) => {
  const rgbString = `rgba(${val.r}, ${val.g}, ${val.b}, ${val.a})`;
  return rgbString;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DefaultOption = ({ field, onChange, theme, formatMessage, messages }) => {
  return (
    <RadioGroup value={field.isDefault} onChange={onChange}>
      <RadioGroup.Label className="sr-only">Default Font</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        <RadioGroup.Option
          value={true}
          className={({ checked }) =>
            classNames(
              'rounded-tl-md rounded-tr-md',
              checked
                ? 'bg-indigo-50 border-indigo-200 z-10'
                : 'border-gray-200',
              'relative border p-4 flex cursor-pointer focus:outline-none'
            )
          }
        >
          {({ active, checked }) => (
            <>
              <span
                className={classNames(
                  checked
                    ? 'bg-poltio-blue-600 border-transparent'
                    : 'bg-white border-gray-300',
                  active ? 'ring-2 ring-offset-2 ring-poltio-blue-500' : '',
                  'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                )}
                aria-hidden="true"
              >
                <span className="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <div className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames(
                    checked ? 'text-poltio-blue-900' : 'text-gray-900',
                    'block text-sm font-medium'
                  )}
                >
                  {formatMessage(messages.DefaultTheme)}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames(
                    checked ? 'text-poltio-blue-700' : 'text-gray-600',
                    'block text-sm'
                  )}
                >
                  {theme[field.name]}
                  <span className="text-gray-400"> - {field.default_desc}</span>
                </RadioGroup.Description>
              </div>
            </>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option
          value={false}
          className={({ checked }) =>
            classNames(
              'rounded-bl-md rounded-br-md',
              checked
                ? 'bg-indigo-50 border-indigo-200 z-10'
                : 'border-gray-200',
              'relative border p-4 flex cursor-pointer focus:outline-none'
            )
          }
        >
          {({ active, checked }) => (
            <>
              <span
                className={classNames(
                  checked
                    ? 'bg-poltio-blue-600 border-transparent'
                    : 'bg-white border-gray-300',
                  active ? 'ring-2 ring-offset-2 ring-poltio-blue-500' : '',
                  'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                )}
                aria-hidden="true"
              >
                <span className="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <div className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames(
                    checked ? 'text-poltio-blue-900' : 'text-gray-900',
                    'block text-sm font-medium'
                  )}
                >
                  {formatMessage(messages.Custom)}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames(
                    checked ? 'text-poltio-blue-700' : 'text-gray-500',
                    'block text-sm'
                  )}
                >
                  {field.custom_desc}
                </RadioGroup.Description>
              </div>
            </>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
};

const ThemeField = ({
  field,
  fields,
  theme,
  formatMessage,
  messages,
  onChange,
  fieldChange,
  defaultTheme,
  onNumberChange,
  onSwitchChange,
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((s) => !s);
  };

  const handleColorChange = (e) => {
    onChange(field.name, convertRgbString(e.rgb));
  };

  return (
    !field.isChild && (
      <div className="mt-6">
        <div>
          <>
            <label
              htmlFor={formatMessage(messages[field.name])}
              className="block text-sm font-medium text-gray-700"
            >
              {formatMessage(messages[field.name])}
            </label>
            <DefaultOption
              field={field}
              theme={defaultTheme}
              formatMessage={formatMessage}
              messages={messages}
              onChange={(val) => fieldChange(field.name, val)}
            />
            {field.isDefault ? null : field.multiple ? (
              <div>
                {fields
                  .filter((f) => f.isChild === field.name)
                  .map((fi, i) => (
                    <div className="mt-6" key={`field-${i}`}>
                      <label
                        htmlFor={formatMessage(messages[fi.name])}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {formatMessage(messages[fi.name])}
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name={fi.name}
                          value={theme[fi.name]}
                          id={fi.name}
                          onChange={(e) =>
                            onChange(
                              e.target.name,
                              e.target.value.replace(/'/g, '"')
                            )
                          }
                          className="shadow-sm focus:ring-poltio-blue focus:border-poltio-blue block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="mt-1 flex">
                {field.number ? (
                  <input
                    type="number"
                    name={field.name}
                    min={1000}
                    max={10000}
                    step={500}
                    value={theme[field.name]}
                    id={field.name}
                    onChange={(e) =>
                      onNumberChange(
                        e.target.name,
                        e.target.value,
                        e.target.max,
                        e.target.min
                      )
                    }
                    className="shadow-sm focus:ring-poltio-blue focus:border-poltio-blue block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                ) : field.boolean ? (
                  <div className="bg-white w-full rounded-md">
                    <Switch.Group
                      as="div"
                      className="flex items-center mt-2 ml-2 mb-2"
                    >
                      <Switch
                        checked={theme[field.name]}
                        onChange={(value) => onSwitchChange(field.name, value)}
                        className={classNames(
                          theme[field.name]
                            ? 'bg-poltio-blue-600'
                            : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-poltio-blue-500'
                        )}
                      >
                        <span className="sr-only">{field.switch_desc}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            theme[field.name]
                              ? 'translate-x-5'
                              : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">
                          {field.switch_desc}
                        </span>
                      </Switch.Label>
                    </Switch.Group>
                  </div>
                ) : (
                  <input
                    type="text"
                    name={field.name}
                    value={theme[field.name] ?? ''}
                    id={field.name}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    className="shadow-sm focus:ring-poltio-blue focus:border-poltio-blue block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                )}

                <div className="">
                  {field.hasColorPicker && !field.isDefault ? (
                    <>
                      {show ? (
                        <div style={{ position: 'absolute', zIndex: '20' }}>
                          <div
                            style={{
                              position: 'fixed',
                              top: '0px',
                              right: '0px',
                              bottom: '0px',
                              left: '0px',
                            }}
                            onClick={toggleShow}
                          />
                          <SketchPicker
                            color={theme[field.name]}
                            onChange={handleColorChange}
                          />
                        </div>
                      ) : null}
                      <div
                        onClick={toggleShow}
                        className="rounded-md"
                        style={{
                          backgroundColor: theme[field.name],
                          height: 38,
                          width: 50,
                          borderStyle: 'solid',
                        }}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    )
  );
};

export default ThemeField;
