import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  AddPixelCodeOW: {
    id: 'Form.AddPixelCodeOW',
    defaultMessage: 'Add Pixel Code ( On View )',
  },
  AddPixelCodeOC: {
    id: 'Form.AddPixelCodeOC',
    defaultMessage: 'Add Pixel Code ( On Click )',
  },
  CreateNewPixelCode: {
    id: 'Form.CreateNewPixelCode',
    defaultMessage: 'Create New Pixel Code',
  },
  CreateNewLead: {
    id: 'Form.CreateNewLead',
    defaultMessage: 'Create New Lead',
  },
  AddLead: {
    id: 'Form.AddLead',
    defaultMessage: 'Add Lead',
  },
  AddTheme: {
    id: 'Form.AddTheme',
    defaultMessage: 'Add Theme',
  },
  SearchContent: {
    id: 'Form.SearchContent',
    defaultMessage: 'Search Content',
  },
  RemoveFromAll: {
    id: 'Form.RemoveFromAll',
    defaultMessage: 'Remove lead from all answers',
  },
  AddPixelCode: {
    id: 'Form.AddPixelCode',
    defaultMessage: 'Add Pixel Code',
  },
  PixelCodeDesc: {
    id: 'Form.PixelCodeDesc',
    defaultMessage:
      'PixelCodes allows you to share Widget events with external systems via javascript, iframe or image pixels real time.(eg: Facebook Conversion Pixel, Linkedin Pixel, Custom GA/GTM event)',
  },
  LeadDesc: {
    id: 'Form.LeadDesc',
    defaultMessage:
      'Leads allows you to collect feedback or personal information from your users or display additional information to them based on their selection.',
  },
});
