import React, { useContext, useEffect, useState } from 'react';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline';

import { EditorCTX } from '../../../../context';
import { Item } from './ListItem';

export const ResultsList = () => {
  const {
    data,
    activeResultIdx,
    setActiveResult,
    set,
    delResultAt,
    hasMoreResults,
    hasLessResults,
    getMoreResults,
    getLessResults,
  } = useContext(EditorCTX);

  const [results, setResults] = useState([]);

  useEffect(() => {
    setResults(data.results || []);
  }, [data.results]);

  const onDuplicate = (idx) => {
    const sourceResult = results[idx];
    const dstIndex = results.length;

    const dst = sourceResult.duplicate(data);

    set(`results.${dstIndex}`, dst);
    set(`results.${dstIndex}.title`, `${dst.title} (Copy)`);
  };

  return (
    <div className="max-h-96 overflow-y-auto fancy-scrollbar">
      <button
        className="w-full h-3 p-0 m-0 flex justify-center bg-poltio-blue-500 hover:bg-poltio-blue-600 disabled:bg-poltio-blue-400 disabled:pointer-events-none"
        disabled={!hasLessResults}
        onClick={getLessResults}
      >
        <ChevronDoubleUpIcon className="h-3 text-white" />
      </button>
      <div>
        {results.map((result, index) => {
          return (
            <Item
              key={`list-item-${index}-${result.title}`}
              handle={false}
              data={result}
              index={index}
              activeIdentifier={activeResultIdx}
              onDelete={delResultAt}
              onDuplicate={onDuplicate}
              setActive={setActiveResult}
              deleteEnabled={results.length > 1}
            />
          );
        })}
      </div>
      <button
        className="w-full h-3 p-0 m-0 flex justify-center bg-poltio-blue-500 hover:bg-poltio-blue-600 disabled:bg-poltio-blue-400 disabled:pointer-events-none"
        disabled={!hasMoreResults}
        onClick={getMoreResults}
      >
        <ChevronDoubleDownIcon className="h-3 text-white" />
      </button>
    </div>
  );
};
