import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  CalculatorFormula: {
    id: 'Editor.Settings.CalculatorFormula',
    defaultMessage: 'Calculator Formula',
  },
  CalFormulaTest: {
    id: 'Editor.Settings.CalFormulaTest',
    defaultMessage: 'This is just to testing your formula',
  },
  Result: {
    id: 'Editor.Settings.Result',
    defaultMessage: 'Result',
  },
  QuestionsVar: {
    id: 'Editor.Settings.QuestionsVar',
    defaultMessage: 'Questions Variable',
  },
  Loading: {
    id: 'Editor.Settings.Loading',
    defaultMessage: 'Loading',
  },
  AnswerResultRelation: {
    id: 'Editor.Settings.AnswerResultRelation',
    defaultMessage: 'Answer-Result Relation',
  },
  CorrectAnswer: {
    id: 'Editor.Settings.CorrectAnswer',
    defaultMessage: 'Correct Answer',
  },
  LeadUrlVar: {
    id: 'Editor.Settings.LeadUrlVar',
    defaultMessage: 'Lead URL Variable',
  },
  CalculatorValue: {
    id: 'Editor.Settings.CalculatorValue',
    defaultMessage: 'Calculator Value',
  },
  DeleteAnswer: {
    id: 'Editor.Settings.DeleteAnswer',
    defaultMessage: 'Delete Answer',
  },
  QuestionConditions: {
    id: 'Editor.Settings.QuestionConditions',
    defaultMessage: 'Question Conditions',
  },
  Everyone: {
    id: 'Editor.Settings.Everyone',
    defaultMessage: 'Everyone',
  },
  OnlyPeopleVF: {
    id: 'Editor.Settings.OnlyPeopleVF',
    defaultMessage: 'Only people voted for',
  },
  OnlyPeopleNVF: {
    id: 'Editor.Settings.OnlyPeopleNVF',
    defaultMessage: 'Only people not voted for',
  },
  NoAnswers: {
    id: 'Editor.Settings.NoAnswers',
    defaultMessage: 'No Answers',
  },
  Questions: {
    id: 'Editor.Settings.Questions',
    defaultMessage: 'Questions',
  },
  AddNewQuestion: {
    id: 'Editor.Settings.AddNewQuestion',
    defaultMessage: 'Add New Question',
  },
  AddNewResult: {
    id: 'Editor.Settings.AddNewResult',
    defaultMessage: 'Add New Result',
  },
  WentWrong: {
    id: 'Editor.Settings.WentWrong',
    defaultMessage: 'Something went wrong while saving content',
  },
  DontOwn: {
    id: 'Editor.Settings.DontOwn',
    defaultMessage: "You don't have this content",
  },
  HaveSave: {
    id: 'Editor.Settings.HaveSave',
    defaultMessage: 'You have to save content before Publish',
  },
  TechError: {
    id: 'Editor.Settings.TechError',
    defaultMessage: 'Tech error, Please try again later.',
  },
  ErrorOccured: {
    id: 'Editor.Settings.ErrorOccured',
    defaultMessage: 'Error Occured',
  },
  Cover: {
    id: 'Editor.Settings.Cover',
    defaultMessage: 'COVER',
  },
  CoverDesc: {
    id: 'Editor.Settings.CoverDesc',
    defaultMessage: 'Customize start screen for your content',
  },
  OptionsNav: {
    id: 'Editor.Settings.OptionsNav',
    defaultMessage: 'OPTIONS',
  },
  OptionsNavDesc: {
    id: 'Editor.Settings.OptionsNavDesc',
    defaultMessage: 'Set your content options',
  },
  NavQuestions: {
    id: 'Editor.Settings.NavQuestions',
    defaultMessage: 'QUESTIONS',
  },
  QuestionsDesc: {
    id: 'Editor.Settings,QuestionsDesc',
    defaultMessage: 'Enter the questions you want to ask',
  },
  NavResult: {
    id: 'Editor.Settings.NavResult',
    defaultMessage: 'RESULTS',
  },
  ResultDesc: {
    id: 'Editor.Settings.ResultDesc',
    defaultMessage: 'Customize result screen for your content',
  },
  Publish: {
    id: 'Editor.Settings.Publish',
    defaultMessage: 'PUBLISH',
  },
  PublishDesc: {
    id: 'Editor.Settings.PublishDesc',
    defaultMessage: 'If everything feels ready, you can publish your content',
  },
  Navigation: {
    id: 'Editor.Settings.Navigation',
    defaultMessage: 'Navigation',
  },
  Configure: {
    id: 'Editor.Settings.Configure',
    defaultMessage: 'Configure:  ',
  },
  Options: {
    id: 'Editor.Settings.Options',
    defaultMessage: 'Options',
  },
  EmbedFooterURL: {
    id: 'Editor.Settings.EmbedFooterURL',
    defaultMessage: 'Embed Footer URL',
  },
  SearchPerPage: {
    id: 'Editor.Settings.SearchPerPage',
    defaultMessage: 'Result Per Page',
  },
  ResultPerPageDesc: {
    id: 'Editor.Settings.ResultPerPageDesc',
    defaultMessage: 'If you save it as 0, no results will appear.',
  },
  PoolQuestionCount: {
    id: 'Editor.Settings.PoolQuestionsCount',
    defaultMessage: 'Pool Question Count',
  },
  TimeLimit: {
    id: 'Editor.Settings.TimeLimit',
    defaultMessage: 'Time Limit',
  },
  Minutes: {
    id: 'Editor.Settings.Minutes',
    defaultMessage: 'Minutes',
  },
  Hours: {
    id: 'Editor.Settings.Hours',
    defaultMessage: 'Hours',
  },
  ShowTimer: {
    id: 'Editor.Settings.ShowTimer',
    defaultMessage: 'Show Timer',
  },
  Days: {
    id: 'Editor.Settings.Days',
    defaultMessage: 'Days',
  },
  EndDate: {
    id: 'Editor.Settings.EndDate',
    defaultMessage: 'End Date',
  },
  PlayOnceImage: {
    id: 'Editor.Settings.PlayOnceImage',
    defaultMessage: 'Play Once Image',
  },
  PlayOnceButton: {
    id: 'Editor.Settings.PlayOnceButton',
    defaultMessage: 'Play Once Button',
  },
  PlayOnceLink: {
    id: 'Editor.Settings.PlayOnceLink',
    defaultMessage: 'Play Once Link',
  },
  PlayOnceStrategy: {
    id: 'Editor.Settings.PlayOnceStrategy',
    defaultMessage: 'Play Once Strategy',
  },
  PlayOnceMessage: {
    id: 'Editor.Settings.PlayOnceMessage',
    defaultMessage: 'Play Once Message',
  },
  PlayOnce: {
    id: 'Editor.Settings.PlayOnce',
    defaultMessage: 'Play Once',
  },
  ShowScore: {
    id: 'Editor.Settings.ShowScore',
    defaultMessage: 'Show Score',
  },
  ShowCorrect: {
    id: 'Editor.Settings.ShowCorrect',
    defaultMessage: 'Show Correct/Wrong Answer',
  },
  CalculatorSet: {
    id: 'Editor.Settings.CalculatorSet',
    defaultMessage: 'Calculator Set',
  },
  SkipResult: {
    id: 'Editor.Settings.SkipResult',
    defaultMessage: 'Skip Result',
  },
  SkipStart: {
    id: 'Editor.Settings.SkipStart',
    defaultMessage: 'Skip Start',
  },
  HidePerc: {
    id: 'Editor.Settings.HidePerc',
    defaultMessage: 'Hide Percentages',
  },
  HideVoteCounter: {
    id: 'Editor.Settings.HideVoteCounter',
    defaultMessage: 'Hide Vote Counter',
  },
  ShowRepeatButton: {
    id: 'Editor.Settings.ShowRepeatButton',
    defaultMessage: 'Show Repeat Button',
  },
  Results: {
    id: 'Editor.Settings.Results',
    defaultMessage: 'Results',
  },
  Question: {
    id: 'Editor.Settings.Question',
    defaultMessage: 'QUESTION',
  },
  Answer: {
    id: 'Editor.Settings.Answer',
    defaultMessage: 'ANSWER',
  },
  CustomRecomTitle: {
    id: 'Editor.Settings.CustomRecomTitle',
    defaultMessage: 'Custom Recommendation Title',
  },
  ShowRepeatButtonDesc: {
    id: 'Editor.Settings.ShowRepeatButtonDesc',
    defaultMessage: 'Displays a button to start over the session from start.',
  },
  HideVoteCounterDesc: {
    id: 'Editor.Settings.HideVoteCounterDesc',
    defaultMessage: 'Hides vote counters from widget UI.',
  },
  HidePercDesc: {
    id: 'Editor.Settings.HidePercDesc',
    defaultMessage: 'Hides vote percentages from widget UI.',
  },
  SkipStartDesc: {
    id: 'Editor.Settings.SkipStartDesc',
    defaultMessage:
      'Hides the cover screen and starts the content from the first question.',
  },
  SkipResultDesc: {
    id: 'Editor.Settings.SkipResultDesc',
    defaultMessage: 'Hides the results screen.',
  },
  CalculatorSetDesc: {
    id: 'Editor.Settings.CalculatorSetDesc',
    defaultMessage:
      'If you want to display a result based on a math function and values you enter on answers or questions, you can select this. More info',
  },
  SearchableSetDesc: {
    id: 'Editor.Settings.SearchableSetDesc',
    defaultMessage:
      'If you want to find a result based on filters and search you enter on your answers, you can select this. More info',
  },
  ShowCorrectDesc: {
    id: 'Editor.Settings.ShowCorrectDesc',
    defaultMessage:
      'If you want to give Right or Wrong feedback after user votes, you can select this and mark one of the answers as correct answer for each question.',
  },
  ShowScoreDesc: {
    id: 'Editor.Settings.ShowScoreDesc',
    defaultMessage:
      ' If you want to display how many of the questions the user answered correctly on the result screen based on Correct/Wrong feedback.',
  },
  PlayOnceDesc: {
    id: 'Editor.Settings.PlayOnceDesc',
    defaultMessage:
      'Restricts the session once per user (based on uuid, poltio session id).',
  },
  PlayOnceStrategyDesc: {
    id: 'Editor.Settings.PlayOnceStrategyDesc',
    defaultMessage:
      'Restricts who started the session or who saw the results screen.',
  },
  PlayOnceMessageDesc: {
    id: 'Editor.Settings.PlayOnceMessageDesc',
    defaultMessage:
      'The message on the screen that users with restricted sessions will see',
  },
  PlayOnceLinkDesc: {
    id: 'Editor.Settings.PlayOnceLinkDesc',
    defaultMessage:
      'The button URL on the screen that users with restricted sessions will see.',
  },
  PlayOnceButtonDesc: {
    id: 'Editor.Settings.PlayOnceButtonDesc',
    defaultMessage:
      'Button text on the screen that users with restricted sessions will see.',
  },
  PlayOnceImageDesc: {
    id: 'Editor.Settings.PlayOnceImageDesc',
    defaultMessage:
      'The image on the screen that users with restricted sessions will see.',
  },
  EndDateDesc: {
    id: 'Editor.Settings.EndDateDesc',
    defaultMessage: `After this date, new sessions/votes won't be allowed in your content.`,
  },
  ShowTimerDesc: {
    id: 'Editor.Settings.ShowTimerDesc',
    defaultMessage:
      'Displays a timer on top of the Widget UI for time based competitions.',
  },
  TimeLimitDesc: {
    id: 'Editor.Settings.TimeLimitDesc',
    defaultMessage:
      'Displays a count down timer and sets a maximum session time for the content. Users will be redirected to result screen after the timer reaches 0.',
  },
  PoolQuestionCountDesc: {
    id: 'Editor.Settings.PoolQuestionCountDesc',
    defaultMessage:
      'Only display certain number of questions randomly to each user.',
  },
  ResultPerPageDescNormal: {
    id: 'Editor.Settings.ResultPerPageDescNormal',
    defaultMessage:
      'Number of maximum result count you want to present to the user.',
  },
  ResultPerPageMaxDesc: {
    id: 'Editor.Settings.ResultPerPageMaxDesc',
    defaultMessage:
      'We recommend that this count be between 3 and 5. The most possible is',
  },
  CustomRecomTitleDesc: {
    id: 'Editor.Settings.CustomRecomTitleDesc',
    defaultMessage:
      'If you have more than one result, a recommendation title that you will show to the user.',
  },
  ShowVC: {
    id: 'Editor.Settings.ShowVC',
    defaultMessage: 'Show Vote Counter',
  },
  ShowPerc: {
    id: 'Editor.Settings.ShowPerc',
    defaultMessage: 'Show Percentages',
  },
  ContinueWith: {
    id: 'Editor.Settings.ContinueWith',
    defaultMessage: 'Continue with current settings',
  },
  CorrectAnswerDesc: {
    id: 'Editor.Settings.CorrectAnswerDesc',
    defaultMessage:
      'Marks this answer as the correct answer for this question for points.',
  },
  LeadUrlVarDesc: {
    id: 'Editor.Settings.LeadUrlVarDesc',
    defaultMessage:
      'Query string to be added to the result url after the user votes for this answer',
  },
  CalculatorValueDesc: {
    id: 'Editor.Settings.CalculatorValueDesc',
    defaultMessage:
      'The variable to use in your calculator formule to calculate the score.',
  },
  ContentType: {
    id: 'Editor.Settings.ContentType',
    defaultMessage: 'Content Type',
  },
  ProductFinder: {
    id: 'Editor.Settings.ProductFinder',
    defaultMessage: 'Product Finder',
  },
  Survey: {
    id: 'Editor.Settings.Survey',
    defaultMessage: 'Survey',
  },
  NoIndex: {
    id: 'Editor.Settings.NoIndex',
    defaultMessage: 'No Index',
  },
  NoIndexDesc: {
    id: 'Editor.Settings.NoIndexDesc',
    defaultMessage: `This prevents search engines to index your poltio widget directly (not your page that you embed the widget). If you plan on using this widget exclusively embedded and don't want this content link to appear in search results directly, you can turn this on.`,
  },
  CannotUse: {
    id: 'Editor.Settings.CannotUse',
    defaultMessage: 'This feature cannot be used if the content type is quiz.',
  },
  ResultIndicator: {
    id: 'Editor.Settings.ResultIndicator',
    defaultMessage: 'Result Loading Indicator',
  },
  ResultIndicatorDesc: {
    id: 'Editor.Settings.ResultIndicatorDesc',
    defaultMessage:
      'If you want to display a loading screen between the last question and result, you can turn this on.',
  },
  ResultMapping: {
    id: 'Editor.Settings.ResultMapping',
    defaultMessage: 'Result Mapping',
  },
  ResultMappingDesc: {
    id: 'Editor.Settings.ResultMappingDesc',
    defaultMessage: 'Add filters and queries to your content',
  },
  Mutually: {
    id: 'Editor.Settings.Mutually',
    defaultMessage: 'Mutually Exclusive Answer',
  },
  MutuallyDesc: {
    id: 'Editor.Settings.MutuallyDesc',
    defaultMessage:
      'If this option is active, when the user selects this answer in multi punch questions, the other selected answers are deselected.',
  },
});
