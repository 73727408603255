import React from 'react';

import { injectIntl } from 'react-intl';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import messages from './messages';
import Button from '../Common/Button';

const SearchRow = ({ openNew, intl: { formatMessage } }) => {
  return (
    <div>
      <div className="flex justify-self-start md:justify-self-end">
        <Button.Primary
          type="button"
          className="self-end"
          onClick={() => openNew({})}
        >
          <PlusCircleIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
          {formatMessage(messages.NewPixelCode)}
        </Button.Primary>
      </div>
    </div>
  );
};

export default injectIntl(SearchRow);
