import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  closestCenter,
  DragOverlay,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { dropAnimationConfig } from './config';
import { SortableItem } from './SortableItem';

const Sortable = (props) => {
  const {
    activationConstraint,
    getNewIndex,
    items: initialItems,

    onSave,

    component,
    componentProps,
  } = props;
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor, {
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
      sortableKeyboardCoordinates,
    })
  );

  const getIndex = (id) => items.indexOf(id);

  const activeIndex = activeId ? getIndex(activeId) : -1;

  const onDragStart = ({ active }) => {
    if (!active) {
      return;
    }

    setActiveId(active.id);
  };

  const onDragEnd = ({ over }) => {
    setActiveId(null);

    if (over) {
      const overIndex = getIndex(over.id);

      let next = items;
      if (activeIndex !== overIndex) {
        const next = arrayMove(items, activeIndex, overIndex);
        setItems(next);
      }

      // CONTEXT STUFF
      onSave?.(next, overIndex);
    }
  };

  const onDragOver = ({ active, over }) => {
    if (!active) {
      return;
    }

    if (over) {
      const overIndex = getIndex(over.id);
      if (activeIndex !== overIndex) {
        const next = arrayMove(items, activeIndex, overIndex);
        setItems(next);
      }
    }
  };

  const onDragCancel = () => setActiveId(null);

  const portal = createPortal(
    <DragOverlay adjustScale={false} dropAnimation={dropAnimationConfig}>
      {activeId
        ? React.createElement(component, {
            ...componentProps,
            data: items[activeIndex],

            isDragOverlay: true,
          })
        : null}
    </DragOverlay>,
    document.body
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      onDragOver={onDragOver}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {items.map((item, index) => {
          return (
            <SortableItem
              key={`list-item-${item._uid ?? index}`}
              id={item}
              index={index}
              getNewIndex={getNewIndex}
              component={component}
              componentProps={{
                ...componentProps,
                data: item,
                index: index,
                deleteEnabled: items.length > 1,
              }}
            />
          );
        })}
      </SortableContext>
      {portal}
    </DndContext>
  );
};

export const SortableList = Sortable;
