import React, { useState, useEffect, useCallback } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
import { getTriviaHistory } from '../../api';

import Loading from '../Common/Loading';
import Button from '../Common/Button';
import Pagination from '../Common/Pagination';
import TriviaTable from './TriviaTable';
import messages from './messages';

const Trivia = ({ intl: { formatMessage } }) => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const fetchTriviaHistory = useCallback(async () => {
    setLoading(true);
    const { data } = await getTriviaHistory(
      pageData.page,
      pageData.sizePerPage
    );
    setHistoryData(data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page, sizePerPage) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchTriviaHistory();
  }, [fetchTriviaHistory]);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-600">
          {formatMessage(messages.Trivia)}
        </h1>
      </div>
      <div className="grid justify-items-stretch max-w-7xl mx-auto px-2 py-6 sm:px-6 md:px-8">
        <div className="flex justify-self-end justify-end items-stretch">
          <Button.Primary className="self-end" href={`/new-trivia`}>
            <PlusCircleIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            {formatMessage(messages.NewTrivia)}
          </Button.Primary>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <TriviaTable historyData={historyData} />
          </div>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(Trivia);
