import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Title: {
    id: 'Content.Title',
    defaultMessage: 'Title',
  },
  Desc: {
    id: 'Content.Desc',
    defaultMessage: 'You can find, list, search or filter all your content.',
  },
  Type: {
    id: 'Content.Type',
    defaultMessage: 'Type/ID',
  },
  Stats: {
    id: 'Content.Stats',
    defaultMessage: 'Stats',
  },
  Dates: {
    id: 'Content.Dates',
    defaultMessage: 'Dates',
  },
  Edit: {
    id: 'Content.Edit',
    defaultMessage: 'Edit',
  },
  ViewStats: {
    id: 'Content.ViewStats',
    defaultMessage: 'View Stats',
  },
  Open: {
    id: 'Content.Open',
    defaultMessage: 'Open',
  },
  Vote: {
    id: 'Content.Vote',
    defaultMessage: 'Vote',
  },
  Voter: {
    id: 'Content.Voter',
    defaultMessage: 'Unique Start',
  },
  Created: {
    id: 'Content.Created',
    defaultMessage: 'Created',
  },
  LastUpdated: {
    id: 'Content.LastUpdated',
    defaultMessage: 'Last Updated',
  },
  EndDate: {
    id: 'Content.EndDate',
    defaultMessage: 'End Date',
  },
  ViewGrid: {
    id: 'Content.ViewGrid',
    defaultMessage: 'View Grid',
  },
  ViewList: {
    id: 'Content.ViewList',
    defaultMessage: 'View List',
  },
  Content: {
    id: 'Content.Content',
    defaultMessage: 'Content',
  },
  Filter: {
    id: 'Content.Filter',
    defaultMessage: 'Filter',
  },
  Sort: {
    id: 'Content.Sort',
    defaultMessage: 'Sort',
  },
  EnterText: {
    id: 'Content.EnterText',
    defaultMessage: 'Enter text...',
  },
  RecentlyCreated: {
    id: 'Content.RecentlyCreated',
    defaultMessage: 'Recently Created',
  },
  RecentlyUpdated: {
    id: 'Content.RecentlyUpdated',
    defaultMessage: 'Recently Updated',
  },
  MostVoted: {
    id: 'Content.MostVoted',
    defaultMessage: 'Most Voted',
  },
  MostVoters: {
    id: 'Content.MostVoters',
    defaultMessage: 'Most Voters',
  },
});
