import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

type CardErrorDisplayProps = {
  onThumbsDown: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onThumbsUp: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const CardErrorDisplay = (props: CardErrorDisplayProps) => {
  const { onThumbsDown, onThumbsUp } = props;

  return (
    <div className="absolute backdrop-blur-md w-full h-full z-10 rounded-lg flex items-center justify-center">
      <div className="space-y-1">
        <ExclamationTriangleIcon className="mx-auto h-14 w-14 text-red-500" />
        <p>{'We could not find a better alternative...'}</p>
        <div className="flex justify-between px-4">
          <button
            onClick={onThumbsDown}
            className="bg-white py-2 px-4 rounded text-poltio-blue disabled:bg-poltio-blue-300"
          >
            {'Try again?'}
          </button>
          <button
            onClick={onThumbsUp}
            className="bg-white py-2 px-4 rounded text-poltio-blue disabled:bg-poltio-blue-300"
          >
            {'Keep it'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardErrorDisplay;
