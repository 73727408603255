import { genuid } from '../helpers/genuid';
import { getAnswer } from '../helpers/getAnswer';
import { getQuestion } from '../helpers/getQuestion';
import { getResult } from '../helpers/getResult';
import { lookup } from '../helpers/lookup';

function getType(context, trailing) {
  if (trailing?.startsWith('pixel_code')) {
    return `${context}_pixelcode`;
  }
  if (trailing === 'lead') {
    return `${context}_lead`;
  }
  if (trailing === 'content_results') {
    return `${context}_content_results`;
  }
  if (trailing === 'click_pixel_code') {
    return `${context}_click_pixel_code`;
  }

  return context;
}

class Change {
  constructor(field = '') {
    this._field = null;

    this.args = null;
    this.noop = false;

    this.qidx = null;
    this.aidx = null;
    this.ridx = null;

    this.q_uid = null;
    this.a_uid = null;
    this.r_uid = null;

    this.enqueued = false;

    this.context = {};

    this.apply = this.apply.bind(this);
    this.save = this.save.bind(this);
    this.del = this.del.bind(this);
    this._parseFieldData = this._parseFieldData.bind(this);
    this._getProperties = this._getProperties.bind(this);

    this.field = field;
    this._parseFieldData();
  }

  _parseFieldData() {
    const args = this.field.split('.');
    this.args = args;

    switch (args[0]) {
      case '':
      case 'options':
      case 'cover':
        // .x
        // .lead.x
        this.type = getType('base', args[1]);
        break;

      case 'questions':
        this.qidx = args[1];

        // questions.$.answers.$.x
        // questions.$.answers.$.lead.x
        if (args?.[2] === 'answers') {
          this.aidx = args[3];
          this.type = getType('answer', args[4]);
          break;
        }

        // questions.$.x
        // questions.$.lead.x
        this.type = getType('question', args[2]);
        break;

      case 'results':
        // results.$.x
        // results.$.lead.x
        this.ridx = args[1];
        this.type = getType('result', args[2]);
        break;

      case 'properties':
        // properties.questions.order
        if (args?.[2] === 'order') {
          this.type = 'properties_questions_order';
          break;
        }

        // properties.questions.$.answers.order
        if (args?.[3] === 'answers' && args?.[4] === 'order') {
          this.qidx = args[2];
          this.type = 'properties_answers_order';
          break;
        }
        break;

      default:
        this.type = null;
        break;
    }
  }

  /**
   * @param {string} value
   */
  set field(value) {
    this._field = value;
    this._parseFieldData();
  }

  get field() {
    return this._field;
  }

  _getProperties(content) {
    this.q_uid = this.qidx
      ? content.questions?.[this.qidx]?.__uid ??
        (content.questions[this.qidx].__uid = genuid())
      : '';
    this.a_uid = this.aidx
      ? content.questions[this.qidx].answers?.[this.aidx]?.__uid ??
        (content.questions[this.qidx].answers[this.aidx].__uid = genuid())
      : '';
    this.r_uid = this.ridx
      ? content.results?.[this.ridx]?.__uid ??
        (content.results[this.ridx].__uid = genuid())
      : '';

    this.key = `${this.q_uid}_${this.a_uid}`;
  }

  apply(content, value) {
    let [ref, head] = lookup(content, this.args);
    Object.assign(ref, { [head]: value });

    this._getProperties(content);
  }

  del(content) {
    this._getProperties(content);

    let [ref, head] = lookup(content, this.args);

    if (!Array.isArray(ref)) {
      ref[head] = null;
      return;
    }

    let ok = false;
    if (this.q_uid !== '' && this.a_uid !== '') {
      const q = getQuestion(content, this);
      const a = getAnswer(q, this);

      if (a?.id === undefined || a?.id === null) {
        this.noop = true;
        ok = true;
      } else {
        ok = window.confirm('Are you sure you want to delete this answer?');
      }

      if (!ok) {
        return;
      }

      this.context = this.noop
        ? {}
        : {
            question_id: JSON.parse(JSON.stringify(q.id)),
            answer_id: JSON.parse(JSON.stringify(a.id)),
          };
    }
    if (this.q_uid !== '' && this.a_uid === '') {
      const q = getQuestion(content, this);
      if (q?.id === undefined || q?.id === null) {
        this.noop = true;
        ok = true;
      } else {
        ok = window.confirm('Are you sure you want to delete this question?');
      }

      if (!ok) {
        return;
      }

      this.context = this.noop
        ? {}
        : {
            question_id: JSON.parse(JSON.stringify(q.id)),
          };
    }
    if (this.r_uid !== '') {
      const r = getResult(content, this);
      if (r?.id === undefined || r?.id === null) {
        this.noop = true;
        ok = true;
      } else {
        ok = window.confirm('Are you sure you want to delete this result?');
      }

      if (!ok) {
        return;
      }

      this.context = this.noop
        ? {}
        : {
            result_id: JSON.parse(JSON.stringify(r.id)),
          };
    }

    this.type = `del_${this.type ?? ''}`;

    ref = ref.splice(head, 1);
  }

  /**
   * @param {Function} callback
   * @param {Parameters<API_CALL>} PAYLOAD
   */
  save(callback) {
    return callback({ q_uid: this.q_uid });
  }
}

export default Change;
