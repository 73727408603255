export const CoverMockFields = [
  'background',
  'title',
  't',
  'desc',
  'end_date',
  'end_date_relative',
  'end_date_human',
  'hide_counter',
  'vote_count',
];

export const QuestionMockFields = [
  'background',
  'title',
  'desc',
  't',
  'answer_type',
  'desc',
  'end_date',
  'end_date_relative',
  'end_date_human',
  'vote_count',
  'url',
  'url_text',
  'autocomplete_help',
  'autocomplete_placeholder',
  'allow_multiple_answers',
  'max_multi_punch_answer',
  'is_skippable',
];

export const AnswerMockFields = ['title', 'background'];

export const ResultMockFields = [
  'background',
  'title',
  't',
  'desc',
  'end_date',
  'end_date_relative',
  'end_date_human',
  'vote_count',
  'url',
  'url_text',
];

export const QuestionSettingsMockFields = ['hide_counter'];

export const ResultSettingsMockFields = ['hide_counter', 'display_repeat'];
