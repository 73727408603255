import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  FC,
} from 'react';
import { NotificationsCTX } from '../../../contexts/Notification';
import { getContent } from '../../../api';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loading from '../../Common/Loading';
import Heading from '../../Common/Page/Heading';
import EmbedSettings from '../../Common/EmbedSettings';
import { getDefaultDomain, getUrlList } from '../../../core/helper';
import { Dropdown } from '../../Common/Form';
import { Content, User } from '../../../core/types';
import { Helmet } from 'react-helmet';

import {
  PencilIcon,
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  ClipboardIcon,
  ChartBarSquareIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import {
  SdkIcon,
  IframeIcon,
  WebhookIcon,
  SheethookIcon,
  PixelCodeIcon,
  GtmIcon,
  DynIcon,
} from '../../../images';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  data: Content;
  user: User;
  intl: any;
};

const Preview: FC<Props> = ({ intl: { formatMessage }, user }) => {
  const [embedCode, setEmbedCode] = useState('');
  const [widgetUrl, setWidgetUrl] = useState('');
  const [finalCode, setFinalCode] = useState('');
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('800');
  const [isMobile, setIsMobile] = useState(false);
  const [data, setData] = useState<Content>({} as Content);
  const [loading, setLoading] = useState(true);
  const [embedType, setEmbedType] = useState('');
  const [url, setUrl] = useState('');
  const [selectedIdx, setSelectedIdx] = useState(0);
  const navigate = useNavigate();
  const { error, success } = useContext(NotificationsCTX);
  const [flyingQs, setFlyingQs] = useState('');

  const { id } = useParams();

  const integrations = useMemo(
    () => [
      {
        name: formatMessage(messages.Webhook),
        desc: formatMessage(messages.WebhookDesc),
        to: `/webhooks/${data.public_id}`,
        icon: WebhookIcon,
      },
      {
        name: formatMessage(messages.Sheethooks),
        desc: formatMessage(messages.SheethooksDesc),
        to: `/sheethooks/${data.public_id}`,
        icon: SheethookIcon,
      },
      {
        name: formatMessage(messages.Pixelcodes),
        desc: formatMessage(messages.PixelcodesDesc),
        to: '/pixelcodes',
        icon: PixelCodeIcon,
      },
      {
        name: formatMessage(messages.GoogleTagManager),
        desc: formatMessage(messages.GoogleTagManagerDesc),
        to: '/gtm',
        icon: GtmIcon,
      },
      {
        name: formatMessage(messages.DynamicWidgets),
        desc: formatMessage(messages.DynamicWidgetsDesc),
        to: `/widgets/${data.public_id}`,
        icon: DynIcon,
      },
    ],
    [data, formatMessage]
  );

  const embedOptions = useMemo(
    () => [
      {
        name: formatMessage(messages.StandardIframe),
        desc: formatMessage(messages.StandardIframeDesc),
        icon: IframeIcon,
        type: 'iframe',
      },
      {
        name: formatMessage(messages.Flying),
        desc: formatMessage(messages.FlyingDesc),
        icon: SdkIcon,
        type: 'flying',
      },
    ],
    [formatMessage]
  );

  const getData = useCallback(async () => {
    if (id) {
      try {
        const { data } = await getContent(id);
        setData(data.content);
        setLoading(false);
      } catch (e) {
        console.log(e);
        error('Error occured!');
      }
    } else {
      navigate('/content');
    }
  }, [error, id, navigate]);

  const urlList = getUrlList(user);

  const defaultCD = getDefaultDomain(user);

  useEffect(() => {
    setUrl(defaultCD);
  }, [defaultCD]);

  useEffect(() => {
    setSelectedIdx(urlList.indexOf(url));
  }, [urlList, url]);

  useEffect(() => {
    setEmbedCode(
      `<iframe id="poltio-embed-${data.public_id}" class="poltio-embed" src="${widgetUrl}" loading="lazy" width="${width}" height="${height}" frameBorder="0" allowFullScreen="allowfullscreen" scrolling="yes" title="${data.title}" allow="clipboard-write"></iframe><script src="https://sdk.poltio.com/widget.js" defer></script>`
    );
  }, [data, width, widgetUrl, height]);

  useEffect(() => {
    setWidgetUrl(`https://${url}/widget/${data.public_id}`);
  }, [data, url]);

  useEffect(() => {
    getData();
  }, [getData]);

  const copyUrl = async (widgetUrl: string) => {
    try {
      await navigator.clipboard.writeText(`${widgetUrl}`);
      success(formatMessage(messages.UrlSuccess));
    } catch (e) {
      error(formatMessage(messages.UrlError));
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8 col-span-2">
        <Helmet>
          <title>{`Previewing content: ${data.title} | Poltio Platform`}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Heading
          title={formatMessage(messages.ContentPreview)}
          body={formatMessage(messages.ContentPreviewDesc)}
        >
          <div className="text-center mt-3 sm:mt-0 flex justify-items-end">
            <div className="my-2">
              <Link
                to={`/c/${data.public_id}`}
                className="poltio-btn poltio-btn-secondary rounded-none rounded-l-md gap-x-1.5"
              >
                <ChartBarSquareIcon className="h-5 w-5 -ml-0.5" />

                <span className="text-sm font-medium">
                  {formatMessage(messages.Stats)}
                </span>
              </Link>
            </div>
            <div className="my-2">
              <Link
                to={`/editor/${data.public_id}`}
                className="poltio-btn poltio-btn-secondary rounded-none gap-x-1.5"
              >
                <PencilIcon className="h-4 w-4 -ml-0.5" />

                <span className="text-sm font-medium">
                  {formatMessage(messages.Edit)}
                </span>
              </Link>
            </div>
            <div className="my-2">
              <Link
                to={`${widgetUrl}`}
                target="_blank"
                rel="noreferrer"
                className="poltio-btn poltio-btn-secondary rounded-none rounded-r-md gap-x-1.5"
              >
                <ArrowTopRightOnSquareIcon className="h-4 w-4 -ml-0.5" />

                <span className="text-sm font-medium">
                  {formatMessage(messages.Open)}
                </span>
              </Link>
            </div>
          </div>
        </Heading>
      </div>

      {loading ? (
        <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
          <Loading />
        </div>
      ) : (
        <div className="grid grid-cols-2  px-2 sm:px-6 md:px-8 mt-2 mx-2 sm:mx-6 md:mx-8 py-4 max-w-7xl bg-white rounded-md">
          <div className="px-2 sm:px-6 md:px-8 col-span-2 md:col-span-1">
            <div className="flex items-center justify-center py-5">
              <button
                className={classNames(
                  'px-1 mx-1',
                  isMobile ? '' : 'text-poltio-blue-500',
                  'disabled:text-gray-400'
                )}
                onClick={() => {
                  setIsMobile(false);
                  setWidth('100%');
                }}
              >
                <ComputerDesktopIcon className="h-8" />
              </button>
              <button
                className={classNames(
                  'px-1 mx-1',
                  isMobile ? 'text-poltio-blue-500' : '',
                  'disabled:text-gray-400'
                )}
                onClick={() => {
                  setIsMobile(true);
                  setWidth('70%');
                }}
              >
                <DevicePhoneMobileIcon className="h-8" />
              </button>
            </div>
            {embedType === 'flying' ? (
              <div className="mt-6 h-full">
                <div className={'flex w-full justify-center'}>
                  <iframe
                    src={`/flying-preview.html${flyingQs}`}
                    width={isMobile ? '70%' : '100%'}
                    height={'700'}
                    allowFullScreen={true}
                    title="Flying"
                  ></iframe>
                </div>
              </div>
            ) : (
              <div
                className="flex items-center justify-center"
                dangerouslySetInnerHTML={{ __html: embedCode }}
              />
            )}
          </div>
          <div className="col-span-2 md:col-span-1">
            <div className="mx-auto max-w-md sm:max-w-3xl">
              <div>
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mx-auto h-12 w-12 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                  <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
                    {formatMessage(messages.Ready)}
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    {formatMessage(messages.ReadyDesc)}
                  </p>
                </div>
                <form className="mt-6 flex items-center" action="#">
                  <div className="grid grid-cols-1 flex-auto">
                    <div className="flex">
                      <div>
                        <Dropdown
                          data={urlList}
                          selectedIdx={selectedIdx}
                          onChange={(i) => {
                            setSelectedIdx(i);
                            setUrl(urlList[i]);
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          name="poltioWidgetUrl"
                          readOnly
                          value={widgetUrl}
                          className="peer relative col-start-1 row-start-1 border-0 bg-transparent mt-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 shadow-md"
                        />
                      </div>
                    </div>
                    <div
                      className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-poltio-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      copyUrl(widgetUrl);
                    }}
                  >
                    <ClipboardIcon
                      className="w-7 h-7 ml-1 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </form>
              </div>
              {embedType === '' ? (
                <div className="mt-10">
                  {data.name ? (
                    <>
                      <h3 className="text-sm font-medium text-gray-500">
                        {formatMessage(messages.ContentName)}
                      </h3>
                      <h4 className="text-sm font-medium text-black mb-2">
                        {data.name}
                      </h4>
                    </>
                  ) : null}
                  {data.title ? (
                    <>
                      <h3 className="text-sm font-medium text-gray-500">
                        {formatMessage(messages.ContentTitle)}
                      </h3>
                      <h4 className="text-sm font-medium text-black mb-4">
                        {data.title}
                      </h4>
                    </>
                  ) : null}

                  <h3 className="text-sm font-medium text-gray-500">
                    {formatMessage(messages.EmbedOptions)}
                  </h3>
                  <ul
                    role="list"
                    className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2"
                  >
                    {embedOptions.map((option, i) => (
                      <li key={i}>
                        <button
                          type="button"
                          onClick={() => setEmbedType(option.type)}
                          className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block flex-shrink-0">
                              {option.icon}
                            </span>
                            <span className="block min-w-0 flex-1">
                              <span className="block truncate text-sm font-medium text-gray-900">
                                {option.name}
                              </span>
                              <span className="block truncate text-xs font-medium text-gray-500">
                                {option.desc}
                              </span>
                            </span>
                          </span>
                          <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center"></span>
                        </button>
                      </li>
                    ))}
                  </ul>
                  <h3 className="text-sm font-medium text-gray-500 mt-6">
                    {formatMessage(messages.Integrations)}
                  </h3>
                  <ul
                    role="list"
                    className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2"
                  >
                    {integrations.map((integration, i) => (
                      <li key={i}>
                        <Link
                          type="button"
                          to={`${integration.to}`}
                          className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block flex-shrink-0">
                              {integration.icon}
                            </span>
                            <span className="block min-w-0 flex-1">
                              <span className="block truncate text-sm font-medium text-gray-900">
                                {integration.name}
                              </span>
                              <span className="block truncate text-xs font-medium text-gray-500">
                                {integration.desc}
                              </span>
                            </span>
                          </span>
                          <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center"></span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <>
                  <button
                    className="my-5 poltio-btn poltio-btn-secondary gap-x-1.5"
                    onClick={() => setEmbedType('')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 -ml-0.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                      />
                    </svg>
                    {formatMessage(messages.Back)}
                  </button>
                  <EmbedSettings
                    data={data}
                    embedCode={embedCode}
                    setEmbedCode={setEmbedCode}
                    widgetUrl={widgetUrl}
                    setWidgetUrl={setWidgetUrl}
                    finalCode={finalCode}
                    setFinalCode={setFinalCode}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    type={embedType}
                    setType={setEmbedType}
                    url={url}
                    selectedIdxPrew={selectedIdx}
                    user={user}
                    flyingQs={flyingQs}
                    setFlyingQs={setFlyingQs}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(Preview);
