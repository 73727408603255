import api from './client';

export function subscription(tier_id = 1, period) {
  return api.post(`/platform/subscription/${tier_id}`, { period });
}

export function deleteSubscription() {
  return api.delete('/platform/subscription');
}

export function getTiers() {
  return api.get(`/platform/subscription/tiers`);
}
