export function isQuestionMediaType(answers, aidx = -1, answerbg = '') {
  const arr = answers ?? [];
  const isMediaType = arr.reduce((prev, curr, index) => {
    let bg = '';
    if (index === aidx) {
      bg = answerbg ?? '';
    } else {
      bg = curr?.background ?? '';
    }

    return prev || (bg.startsWith('placeholder') ? false : !!bg);
  }, false);

  return isMediaType;
}
