import { useState, useContext } from 'react';
import { ThemeInput } from '../Common/Form';
import Button from '../Common/Button';
import { NotificationsCTX } from '../../contexts/Notification';
import { saveTheme } from '../../api';
import Heading from '../Common/Page/Heading';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { Helmet } from 'react-helmet';

const Gtm = ({ intl: { formatMessage } }) => {
  const { success, error } = useContext(NotificationsCTX);
  const [selectedTheme, setSelectedTheme] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(false);

  const saveChanges = async () => {
    try {
      setSaveDisabled(true);
      await saveTheme(selectedTheme, selectedTheme.id);
      setSaveDisabled(false);
      success('', formatMessage(messages.ThemeSaved), 10000);
    } catch (e) {
      setSaveDisabled(false);
      error('', formatMessage(messages.ErrorSaveTheme));
    }
  };

  return (
    <div className="max-w-7xl container mx-auto px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>GTM | Poltio Platform</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Heading
        title={formatMessage(messages.GtmIntegration)}
        body={formatMessage(messages.GtmDesc)}
      />
      <div className="divide-y divide-white/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {formatMessage(messages.Assign)}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              {formatMessage(messages.AssignDesc)}
              <br />
              {formatMessage(messages.AssignDesc2)}{' '}
              <a
                className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
                href="https://platform.poltio.com/docs/gtm/"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage(messages.OurDoc)}
              </a>
            </p>
            <div className="flex flex-row justify-around mt-4">
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 fill-[#246FDB] "
              >
                <title>Google Tag Manager</title>
                <path d="M12.003 0a3 3 0 0 0-2.121 5.121l6.865 6.865-4.446 4.541 1.745 1.836a3.432 3.432 0 0 1 .7.739l.012.011-.001.002a3.432 3.432 0 0 1 .609 1.953 3.432 3.432 0 0 1-.09.78l7.75-7.647c.031-.029.067-.05.098-.08.023-.023.038-.052.06-.076a2.994 2.994 0 0 0-.06-4.166l-9-9A2.99 2.99 0 0 0 12.003 0zM8.63 2.133L.88 9.809a2.998 2.998 0 0 0 0 4.238l7.7 7.75a3.432 3.432 0 0 1-.077-.729 3.432 3.432 0 0 1 3.431-3.431 3.432 3.432 0 0 1 .826.101l-5.523-5.81 4.371-4.373-2.08-2.08c-.903-.904-1.193-2.183-.898-3.342zm3.304 16.004a2.932 2.932 0 0 0-2.931 2.931A2.932 2.932 0 0 0 11.934 24a2.932 2.932 0 0 0 2.932-2.932 2.932 2.932 0 0 0-2.932-2.931z" />
              </svg>
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 fill-[#E37400] "
              >
                <title>Google Analytics</title>
                <path d="M22.84 2.9982v17.9987c.0086 1.6473-1.3197 2.9897-2.967 2.9984a2.9808 2.9808 0 01-.3677-.0208c-1.528-.226-2.6477-1.5558-2.6105-3.1V3.1204c-.0369-1.5458 1.0856-2.8762 2.6157-3.1 1.6361-.1915 3.1178.9796 3.3093 2.6158.014.1201.0208.241.0202.3619zM4.1326 18.0548c-1.6417 0-2.9726 1.331-2.9726 2.9726C1.16 22.6691 2.4909 24 4.1326 24s2.9726-1.3309 2.9726-2.9726-1.331-2.9726-2.9726-2.9726zm7.8728-9.0098c-.0171 0-.0342 0-.0513.0003-1.6495.0904-2.9293 1.474-2.891 3.1256v7.9846c0 2.167.9535 3.4825 2.3505 3.763 1.6118.3266 3.1832-.7152 3.5098-2.327.04-.1974.06-.3983.0593-.5998v-8.9585c.003-1.6474-1.33-2.9852-2.9773-2.9882z" />
              </svg>
            </div>
          </div>

          <div className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl">
              <div className="col-span-full">
                <ThemeInput
                  themeId={selectedTheme?.id ?? ''}
                  themeName={selectedTheme?.name ?? ''}
                  isLoading={true}
                  msg={formatMessage(messages.SelectTheme)}
                  onSelect={(theme) => {
                    if (theme) setSelectedTheme(theme);
                  }}
                />

                <label
                  htmlFor="gtmid"
                  className="block text-sm font-medium leading-6 mt-2"
                >
                  {'Gtm ID'}
                </label>
                <div className="mt-2">
                  <input
                    id="gtmid"
                    name="gtmid"
                    type="text"
                    className={
                      'block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6  focus:border-poltio-blue-500  border-gray-300 '
                    }
                    value={selectedTheme?.gtm_id || ''}
                    onChange={(e) =>
                      setSelectedTheme({
                        ...selectedTheme,
                        gtm_id: e.target.value,
                      })
                    }
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  {formatMessage(messages.GtmIdDesc)}
                </p>

                <label
                  htmlFor="datalayer"
                  className="block text-sm font-medium leading-6 mt-2"
                >
                  {'Data Layer'}
                </label>
                <div className="mt-2">
                  <input
                    id="datalayer"
                    name="datalayer"
                    type="text"
                    className={
                      'block w-full rounded-md border-0  py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-poltio-blue-500 sm:text-sm sm:leading-6  focus:border-poltio-blue-500  border-gray-300 '
                    }
                    value={selectedTheme?.gtm_dl || ''}
                    onChange={(e) =>
                      setSelectedTheme({
                        ...selectedTheme,
                        gtm_dl: e.target.value,
                      })
                    }
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  {formatMessage(messages.DlDesc)}
                </p>

                <div className="mt-8 flex">
                  <Button.Primary
                    type="submit"
                    onClick={saveChanges}
                    disabled={!selectedTheme?.name || saveDisabled}
                  >
                    {formatMessage(messages.Save)}
                  </Button.Primary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Gtm);
