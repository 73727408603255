import React, { useCallback, useEffect, useState } from 'react';
import SearchField from '.';
import { getPixelCodesPaginated } from '../../../api';

const PixelCodeSearchRowRenderer = ({ item }) => {
  return (
    <span className="flex">
      <span className="truncate">{item.name}</span>
    </span>
  );
};

const PixelCodeSearch = ({ pixelCodeName, onSelect = () => {} }) => {
  const [name, setName] = useState(pixelCodeName);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 100,
    lastPage: null,
  });

  const fetchData = useCallback(
    async (isDirty, setDisplayContents) => {
      try {
        const response = await getPixelCodesPaginated(
          pageData.page,
          pageData.sizePerPage
        );
        const pixelCodes = response.data.data;

        setDisplayContents(pixelCodes);
        setPageData((prev) => ({
          page: prev.page,
          sizePerPage: prev.sizePerPage,
          totalSize: response.data.total,
          lastPage: response.data.last_page,
        }));
      } catch (err) {
        console.error(err);
      }
    },
    [pageData.page, pageData.sizePerPage]
  );

  useEffect(() => {
    setName(pixelCodeName || '');
  }, [pixelCodeName]);

  const onItemChange = (pixelCode, setDisplayText) => {
    setDisplayText(pixelCode.name);
    onSelect(pixelCode);
  };

  const onInputChange = (value, setDisplayText) => {
    setDisplayText(value);
  };

  return (
    <SearchField
      defaultMessage={''}
      displayValue={name}
      fetchData={fetchData}
      onItemChange={onItemChange}
      onInputChange={onInputChange}
      rowRenderer={PixelCodeSearchRowRenderer}
    />
  );
};

export default PixelCodeSearch;
