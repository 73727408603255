import {
  type Dispatch as RDispatch,
  type SetStateAction as RSetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Heading from '../Common/Page/Heading';
import Container from '../Common/Container';
import * as Sentry from '@sentry/react';
import { isAxiosError, type AxiosError, type AxiosResponse } from 'axios';

import api from '../../api/client';
import Loading from '../Common/Loading';
import { serialize } from '../../core/helper';
import { genuid } from '../Editor/EditorAPI/EditorDataHandler/helpers/genuid';
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  InformationCircleIcon,
  ArrowRightIcon,
  XCircleIcon,
  ArrowTopRightOnSquareIcon,
  Cog6ToothIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import usePersistentStore from '../../core/usePersistentStore';
import messages from './messages';
import { useIntl } from 'react-intl';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type TinkerResponseQuestion = {
  id?: string;
  status?: 'loading' | 'ok' | 'error';
  loading?: boolean;
  question: string;
  choices: string[];
  explanation: string;
};

type TinkerResponse = {
  id?: string;
  status?: string;
  response?: any;
  created_at?: string;
  updated_at?: string;
  tinkerResponse?: any;
};

type JobResponse = {
  id?: string;
  status?: string;
  response?: any;
  created_at?: string;
  updated_at?: string;
};

type SageContentRequestBody = {
  questions: {
    title: string;
    answers: {
      title: string;
    }[];
  }[];
};

async function apiSageJobRequest(jobId: any) {
  return api.get(`/platform/sage/jobs/${jobId}`);
}

async function apiSageDSReplaceRequest(
  dataSourceId: string,
  questionIdx: any,
  data: any
) {
  return api.post(
    `/platform/sage/ds/${dataSourceId}/replace/${questionIdx}`,
    {
      assistant: JSON.stringify(data),
    },
    {
      timeout: 90000,
    }
  );
}

async function apiSageDSAppendRequest(jobId: string, data: any) {
  return api.post(
    `/platform/sage/jobs/${jobId}/append`,
    {
      assistant: JSON.stringify(data.response),
      recipe: 'append_question',
    },
    {
      timeout: 90000,
    }
  );
}

async function apiSageDSContentRequest(jobId: any, data: any) {
  return api.post(`/platform/sage/jobs/${jobId}/content`, data);
}

type ArticleProps = {
  index: number;
  question: TinkerResponseQuestion;
  onDelete: () => void;
  // onReplace: () => void;
  setStateOk: () => void;
};
const Article = (props: ArticleProps) => {
  const { index, question, onDelete, setStateOk } = props;

  const [thumbState, setThumbState] = useState<'up' | 'down' | 'none'>('none');
  const [iframeSRC, setIframeSRC] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    let iframeSRC;

    let mockdata = {
      title: question.question,
      answer_type: 'text',
    };
    question.choices.forEach((choice, j) => {
      const key = `answers[${j}][title]`;

      mockdata = { ...mockdata, [key]: choice };
    });

    iframeSRC = `https://poltio.com/mock/question?disclaimer=off&hide_counter=1&`;
    iframeSRC += `${serialize(mockdata)}`;

    setIframeSRC(iframeSRC);
  }, [question]);

  let isError = question?.status === 'error';
  let isLoading = (question?.loading ?? false) || loading;
  if (['error', 'ok'].includes(question?.status ?? 'none')) {
    isLoading = false;
  }
  if (question?.status === 'loading') {
    isLoading = true;
  }

  return (
    <article
      key={question?.id ?? index}
      className="flex flex-col items-start justify-start min-h-[33rem]"
    >
      {isLoading ? (
        <div
          className="
            flex flex-col w-full h-full items-center align-middle justify-center
            bg-gray-50/5 border-2 border-poltio-blue-500/60 rounded-lg
          "
        >
          <div className="-mt-24">
            <Loading style={{ padding: '0' }} />
            <p className="h-5">
              <LoadingArticleMessageDisplay />
            </p>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            isError ? 'relative border-2 border-red-500/60 rounded-lg' : ''
          )}
        >
          {isError ? (
            <div className="absolute backdrop-blur-md w-full h-full z-10 rounded-lg flex items-center justify-center">
              <div className="space-y-1">
                <ExclamationTriangleIcon className="mx-auto h-14 w-14 text-red-500" />
                <p>{'We could not find a better alternative...'}</p>
                <div className="flex justify-between px-4">
                  <button
                    onClick={() => {
                      setStateOk();
                      // handleThumbsDown();
                    }}
                    className="bg-white py-2 px-4 rounded text-poltio-blue disabled:bg-poltio-blue-300"
                  >
                    {formatMessage(messages.TryAgain)}
                  </button>
                  <button
                    onClick={() => setStateOk()}
                    className="bg-white py-2 px-4 rounded text-poltio-blue disabled:bg-poltio-blue-300"
                  >
                    {formatMessage(messages.KeepIt)}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="group relative">
            <div className="mt-2 flex items-start gap-x-2">
              <div>
                <InformationCircleIcon className="w-6 text-gray-400 group-hover:text-gray-500" />
              </div>
              <div>
                <p className="text-sm leading-6 text-gray-600">
                  {question.explanation}
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-xl">
            {/* === */}
            <div className="relative w-full h-[33rem] shadow-inner ring-1 ring-black/5 rounded-lg mt-4">
              {iframeSRC === null ? (
                <Loading />
              ) : (
                <iframe
                  id={`poltio-embed-mock-${question.id ?? index}`}
                  className="
            aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]
            outline-none poltio-widget ring-0 h-full
          "
                  src={iframeSRC}
                  width={'100%'}
                  frameBorder="0"
                  allowFullScreen={true}
                  scrolling="yes"
                  title="Embed"
                />
              )}
            </div>
            <div className="relative mt-4 flex justify-end gap-x-1 text-gray-400">
              <button onClick={() => setThumbState('up')}>
                <HandThumbUpIcon
                  className={classNames(
                    'h-6 hover:text-poltio-blue-500 -scale-x-[1]',
                    thumbState === 'up'
                      ? 'text-poltio-blue-500 fill-poltio-blue-400'
                      : ''
                  )}
                />
              </button>
              <button onClick={() => onDelete()}>
                <HandThumbDownIcon
                  className={classNames(
                    'h-6 hover:text-rose-500 -scale-x-[1]',
                    thumbState === 'down' ? 'text-rose-500 fill-rose-400' : ''
                  )}
                />
              </button>
            </div>
            {/* === */}
          </div>
        </div>
      )}
    </article>
  );
};

function LoadingMessageDisplay() {
  const { formatMessage } = useIntl();
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const loadingMessages = useMemo(
    () => [
      formatMessage(messages.ThinkingSuitable),
      formatMessage(messages.Wondering),
      formatMessage(messages.ThinkingHard),
      formatMessage(messages.JustaFew),
      formatMessage(messages.YourQuestions),
      formatMessage(messages.WeAreThinking),
    ],
    [formatMessage]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMessageIndex((i) => (i + 1) % loadingMessages.length);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return <span>{loadingMessages[loadingMessageIndex]}</span>;
}

function LoadingWidgetMessageDisplay() {
  const { formatMessage } = useIntl();
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const loadingMessages = useMemo(
    () => [
      formatMessage(messages.GeneratingContent),
      formatMessage(messages.Creating),
      formatMessage(messages.YouCanEdit),
      formatMessage(messages.PleaseWait),
      formatMessage(messages.Working),
      formatMessage(messages.AlmostFinish),
      formatMessage(messages.ThanksFor),
    ],
    [formatMessage]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingMessageIndex((i) => (i + 1) % loadingMessages.length);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return <span>{loadingMessages[loadingMessageIndex]}</span>;
}

function LoadingArticleMessageDisplay() {
  const { formatMessage } = useIntl();
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const loadingMessages = useMemo(
    () => [
      formatMessage(messages.ThinkingQuestion),
      formatMessage(messages.ThinkingSuitable),
      formatMessage(messages.Wondering),
      formatMessage(messages.ThinkingHard),
      formatMessage(messages.JustaFew),
      formatMessage(messages.YourQuestions),
      formatMessage(messages.WeAreThinking),
    ],
    [formatMessage]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      // clamp to max, so that we don't show the first message
      setLoadingMessageIndex((i) =>
        Math.max((i + 1) % loadingMessages.length, 1)
      );
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return <span>{loadingMessages[loadingMessageIndex]}</span>;
}

function DataSourceSageTinkerPage() {
  const { formatMessage } = useIntl();
  const { dataSourceId } = useParams<{ dataSourceId: string }>();
  const { jobId } = useParams<{ jobId: string }>();
  const location = useLocation();

  // persist question count.
  // we keep track of question count because we want to be able to
  //  generate the same number of questions when user refreshes the page
  //  or asks for a new set of questions.
  const [questionCount, setQuestionCount] = usePersistentStore(
    5,
    `tinker-question-count-${dataSourceId}`
  );

  const [jobResponse, setJobResponse] = useState<JobResponse | null>(null);
  const [appendResponse, setAppendResponse] = useState<JobResponse | null>(
    null
  );
  const [contentResponse, setContentResponse] = useState<JobResponse | null>(
    null
  );
  const [contentPublicID, setContentPublicID] = useState<string | null>(null);
  const [widgetDisplayState, setWidgetDisplayState] = useState<
    'hidden' | 'loading' | 'visible'
  >('hidden');

  const getJob = async () => {
    try {
      if (appendResponse && appendResponse?.status !== 'finished') {
        const data = await apiSageJobRequest(appendResponse.id);

        setAppendResponse(data.data);

        if (data.data.status === 'finished') {
          setJobResponse((prev: any) => {
            if (prev === null) {
              return prev;
            }
            const newQuestion = data.data.response.questions[0];
            const newQuestions = [...prev.response.questions];
            newQuestions.push(newQuestion);
            const newPayload = { ...prev.response, questions: newQuestions };

            const curr = newPayload.questions.filter(
              (q: any) => q.id !== 'loading-article'
            );
            const lastPayload = { ...prev.response, questions: curr };

            return { ...prev, response: lastPayload };
          });
          setAppendResponse(null);
        }
      } else if (contentResponse && contentResponse?.status !== 'finished') {
        const data = await apiSageJobRequest(contentResponse.id);
        setContentResponse(data.data);

        if (data.data.status === 'finished') {
          if (data.data.response.content === null) {
            throw new Error('response.data.public_id is null');
          }
          const publicId = data.data.response.content;
          setContentPublicID(publicId);
          setWidgetDisplayState('visible');
          const sb = document.getElementById('loading');
          sb?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'end',
          });
        }
      } else {
        if (
          jobResponse?.status === 'finished' ||
          jobResponse?.status === 'failed'
        ) {
          return;
        }
        const data = await apiSageJobRequest(jobId);

        setJobResponse(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (jobId) {
      getJob();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (jobResponse?.status !== 'finished' && jobId) ||
        appendResponse?.status !== 'finished' ||
        contentResponse?.status !== 'finished'
      ) {
        getJob();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [jobId, jobResponse, appendResponse, contentResponse]);

  useEffect(() => {
    if (jobResponse === null) {
      return;
    }

    setQuestionCount(jobResponse?.response?.questions.length);
  }, [jobResponse]);

  const handleDeleteQ = (index: number) => {
    // ommit the select question, and assume that it never happened.
    // update the question count to reflect the new number of questions.
    setJobResponse((prev: any) => {
      if (prev === null) {
        return prev;
      }

      const newQuestions = [...prev.response.questions];
      newQuestions.splice(index, 1);

      const newPayload = { ...prev.response, questions: newQuestions };

      return { ...prev, response: newPayload };
    });
  };

  const handleAddQ = async () => {
    // append a question at the end.
    if (!jobId) {
      return;
    }
    if (jobResponse === null) {
      return;
    }
    if (appendResponse && appendResponse.status !== 'finished') {
      return;
    }

    const nextCount = questionCount + 1;

    setQuestionCount(nextCount);

    const sb = document.getElementById('loading');
    sb?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    });

    setJobResponse((prev: any) => {
      // append a loading article/question
      if (prev === null) {
        return prev;
      }

      const article = {
        id: 'loading-article',
        loading: true,
        question: 'Loading...',
        choices: [],
        explanation: '',
      };

      const newPayload = {
        ...prev.response,
        questions: [...prev.response.questions, article],
      };

      return { ...prev, response: newPayload };
    });

    if (jobResponse.id) {
      try {
        const data = await apiSageDSAppendRequest(jobResponse?.id, jobResponse);

        if (data) {
          setAppendResponse(data.data);
        }

        if (appendResponse?.status === 'finished') {
          setJobResponse((prev: any) => {
            if (prev === null) {
              return prev;
            }
            const newQuestion = data.data.questions[0];
            const newQuestions = [...prev.response.questions];
            newQuestions.push(newQuestion);
            const newPayload = { ...prev.response, questions: newQuestions };

            const curr = newPayload.questions.filter(
              (q: any) => q.id !== 'loading-article'
            );
            const lastPayload = { ...prev.response, questions: curr };

            return { ...prev, response: lastPayload };
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setJobResponse(jobResponse);
    }
  };

  const handleCreateContent = () => {
    if (!jobId) {
      return;
    }
    if (jobResponse === null) {
      return;
    }

    // convert tinkerResponse to content format
    let body: SageContentRequestBody = {
      questions: [],
    };

    jobResponse.response.questions.forEach((question: any) => {
      const { question: title, choices: answers } = question;
      body.questions.push({
        title,
        answers: answers.map((answer: any) => ({ title: answer })),
      });
    });

    (async () => {
      try {
        setWidgetDisplayState('loading');
        const response = await apiSageDSContentRequest(jobResponse?.id, body);

        if (response.data === null) {
          throw new Error('response.data is null');
        }
        setContentResponse(response.data);
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            dataSourceId: dataSourceId,
            jobId: jobId,
            body: body,
          },
        });
      }
    })();
  };

  const handleSetStateOk = (index: number) => {
    setJobResponse((prev) => {
      if (prev === null) {
        return prev;
      }

      const questions = prev.response.questions;
      questions[index].loading = false;
      questions[index].status = 'ok';

      return {
        ...prev,
        questions: [...questions],
      };
    });
  };

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <Heading
          title={formatMessage(messages.SagesRecom)}
          body={
            location.state.dataSourceName ??
            formatMessage(messages.YourDataSource)
          }
        >
          {jobResponse?.status === 'finished' ? (
            <div className="flex gap-x-2">
              <button
                disabled={
                  questionCount >= 10 ||
                  (appendResponse !== null &&
                    appendResponse?.status !== 'finished') ||
                  widgetDisplayState === 'loading' ||
                  widgetDisplayState === 'visible'
                }
                onClick={() => handleAddQ()}
                className="border-gray-300 py-2 px-4 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 focus:ring-poltio-blue-500"
              >
                {formatMessage(messages.RecomQuestion)}
              </button>
              <button
                onClick={() => handleCreateContent()}
                disabled={
                  widgetDisplayState === 'loading' ||
                  widgetDisplayState === 'visible' ||
                  (appendResponse !== null &&
                    appendResponse?.status !== 'finished')
                }
                className="bg-poltio-blue-600 py-2 px-4 rounded text-white disabled:opacity-50 text-sm font-medium focus:ring-poltio-blue-500 hover:bg-poltio-blue-700"
              >
                {formatMessage(messages.CreateSurvey)}
                <ArrowRightIcon className="inline-block stroke-2 h-5 ml-2" />
              </button>
            </div>
          ) : null}
        </Heading>
        <Container>
          {widgetDisplayState === 'loading' ? (
            <div className="mx-auto grid text-center">
              <LoadingWidgetMessageDisplay />
              <Loading />
            </div>
          ) : null}
          <div>
            <div className="mt-2 text-lg leading-8 text-gray-600">
              {jobResponse?.status === 'pending' ||
              jobResponse?.status === 'running' ? (
                <div className="grid items-center text-center">
                  <LoadingMessageDisplay />
                  <Loading />
                </div>
              ) : jobResponse?.status === 'failed' ? (
                <div className="grid text-center place-items-center gap-y-5">
                  <p>{formatMessage(messages.SageError)}</p>
                  <Link
                    to={`/data-source`}
                    className="w-1/6 border-gray-300 py-2 px-4 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 focus:ring-poltio-blue-500"
                  >
                    {formatMessage(messages.GoBack)}
                  </Link>
                </div>
              ) : (
                formatMessage(messages.BasedOn)
              )}
            </div>
          </div>

          {jobResponse?.response?.questions !== null &&
          widgetDisplayState !== 'visible' ? (
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {jobResponse?.response?.questions.map((question: any, i: any) => (
                <Article
                  key={question.id}
                  index={i}
                  question={question}
                  onDelete={() => handleDeleteQ(i)}
                  // onReplace={() => handleReplaceQ(i)}
                  setStateOk={() => handleSetStateOk(i)}
                />
              ))}
            </div>
          ) : null}
          {jobResponse?.status === 'finished' ? (
            <>
              <div
                className="mt-8 px-auto pt-8 text-center border-t border-gray-300"
                id={'loading'}
              >
                {widgetDisplayState === 'visible' ? (
                  <div className="grid grid-cols-2">
                    <div className="col-span-1 my-2">
                      <a
                        className="inline-flex items-center"
                        href={`https://poltio.com/widget/${contentPublicID}`}
                        target="_blank"
                      >
                        <span className="font-medium text-lg">
                          {formatMessage(messages.Open)}
                        </span>
                        <ArrowTopRightOnSquareIcon className="h-5 w-5 mx-1" />
                      </a>
                    </div>
                    <div className="col-span-1 my-2">
                      <Link
                        to={`/editor/${contentPublicID}`}
                        className="inline-flex items-center"
                      >
                        <span>{formatMessage(messages.Configure)}</span>
                        <Cog6ToothIcon className="h-5 w-5 mx-1" />
                      </Link>
                    </div>
                    <div className="col-span-2">
                      <iframe
                        id="sage-widget"
                        src={`https://poltio.com/widget/${contentPublicID}?disclaimer=off`}
                        width="100%"
                        className="rounded outline-none poltio-widget ring-0 min-h-[35rem] h-full"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </Container>
      </div>
    </div>
  );
}

export default DataSourceSageTinkerPage;
