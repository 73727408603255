import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Title: {
    id: 'DraftContent.Title',
    defaultMessage: 'Title',
  },
  Dates: {
    id: 'DraftContent.Dates',
    defaultMessage: 'Dates',
  },
  Created: {
    id: 'DraftContent.Created',
    defaultMessage: 'Created',
  },
  LastUpdated: {
    id: 'DraftContent.LastUpdated',
    defaultMessage: 'Last Updated',
  },
  EndDate: {
    id: 'DraftContent.EndDate',
    defaultMessage: 'End Date',
  },
  DraftContent: {
    id: 'DraftContent.DraftContent',
    defaultMessage: 'Draft Contents',
  },
  ContinueEdit: {
    id: 'DraftContent.ContinueEdit',
    defaultMessage: 'Continue Edit',
  },
  Type: {
    id: 'DraftContent.Type',
    defaultMessage: 'Type',
  },
  Delete: {
    id: 'DraftContent.Delete',
    defaultMessage: 'Delete',
  },
  EnterText: {
    id: 'DraftContent.EnterText',
    defaultMessage: 'Enter Text',
  },
  ContentDeleted: {
    id: 'DraftContent.ContentDeleted',
    defaultMessage: 'Content Deleted',
  },
  ErrorOccured: {
    id: 'DraftContent.ErrorOccured',
    defaultMessage: 'Error Occured',
  },
  AreUSure: {
    id: 'DraftContent.AreUSure',
    defaultMessage: 'Are u sure you want to delete this content?',
  },
  BackToEditor: {
    id: 'DraftContent.BackToEditor',
    defaultMessage: 'Back To Editor',
  },
});
