import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';

import {
  LeadInput,
  PixelCodeInput,
  Label,
  TextInput,
} from '../../../../Common/Form';
import { EditorCTX } from '../../../context';
import messages from './messages';

const CoverConf = ({ intl: { formatMessage } }) => {
  const { data, set, showCreateNewLead, showCreateNewPixelCode } =
    useContext(EditorCTX);

  const { loading_next_question_label, loading_result_label } = data;

  return (
    <>
      <PixelCodeInput
        pixelCode={data?.pixel_code || ''}
        onSelect={(pixelCode) => {
          set(`cover.pixel_code`, { id: pixelCode.id, name: pixelCode.name });
        }}
        onDelete={() => {
          set(`cover.pixel_code`, null);
        }}
        onNew={() => {
          showCreateNewPixelCode();
        }}
      />
      <LeadInput
        type={'lead'}
        leadId={data?.lead?.id || ''}
        onSelect={(lead) => {
          set(`cover.lead.id`, lead.id);
        }}
        onDelete={() => {
          set(`cover.lead.id`, null);
        }}
        onNew={() => {
          showCreateNewLead();
        }}
      />
      <div className="space-y-2">
        <div className="space-y-2">
          <Label text={formatMessage(messages.LoadingNextQuestionLabel)} />
          <TextInput
            debounce={{ timeout: 1000 }}
            placeholder={formatMessage(messages.NextQuestion)}
            value={loading_next_question_label ?? ''}
            onChange={(value) =>
              set('options.loading_next_question_label', value)
            }
            description={formatMessage(messages.LoadingNextQuestionDesc)}
          />
        </div>
        <div className="space-y-2">
          <Label text={formatMessage(messages.LoadingResultLabel)} />
          <TextInput
            debounce={{ timeout: 1000 }}
            placeholder={formatMessage(messages.PreparingResult)}
            value={loading_result_label ?? ''}
            onChange={(value) => set('options.loading_result_label', value)}
            description={formatMessage(messages.LoadingResultDesc)}
          />
        </div>
      </div>
    </>
  );
};

export default injectIntl(CoverConf);
