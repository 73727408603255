type Props = {
  message: string | object;
};

export function NotificationMessageRender({ message }: Props) {
  return (
    <>
      {typeof message === 'string' ? message : null}
      {typeof message === 'object' ? (
        <pre>
          <code>{JSON.stringify(message, null, 2)}</code>
        </pre>
      ) : null}
    </>
  );
}
