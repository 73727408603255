export function validatePayload(payload = {}, validator = []) {
  // check if payload has every field in validator
  let k = 0;
  while (k < validator.length) {
    let comp = validator.at(k);

    if (!payload[comp]) {
      console.error(`PAYLOAD_INVALID:${comp}`, payload);
      throw Error(`PAYLOAD_INVALID:${comp}`);
    }

    k = k + 1;
  }
}
