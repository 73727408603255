import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Dashboard: {
    id: 'MenuItem.Dashbbard',
    defaultMessage: 'Dashboard',
  },
  Content: {
    id: 'MenuItem.Content',
    defaultMessage: 'Content',
  },
  Leads: {
    id: 'MenuItem.Leads',
    defaultMessage: 'Leads',
  },
  Integrations: {
    id: 'MenuItem.Integrations',
    defaultMessage: 'Integrations',
  },
  PixelCodes: {
    id: 'MenuItem.PixelCodes',
    defaultMessage: 'PixelCodes',
  },
  Webhooks: {
    id: 'MenuItem.Webhooks',
    defaultMessage: 'Webhooks',
  },
  Sheethooks: {
    id: 'MenuItem.Sheethooks',
    defaultMessage: 'Sheethooks',
  },
  GoogleTagManager: {
    id: 'MenuItem.GoogleTagManager',
    defaultMessage: 'Google Tag Manager',
  },
  Theme: {
    id: 'MenuItem.Theme',
    defaultMessage: 'Theme',
  },
  Reports: {
    id: 'MenuItem.Reports',
    defaultMessage: 'Reports',
  },
  DataSource: {
    id: 'MenuItem.DataSource',
    defaultMessage: 'Data Source',
  },
  NewProject: {
    id: 'MenuItem.NewProject',
    defaultMessage: 'New Project',
  },
  Settings: {
    id: 'MenuItem.Settings',
    defaultMessage: 'Settings',
  },
  AccountSettings: {
    id: 'MenuItem.AccountSettings',
    defaultMessage: 'Account Settings',
  },
  APITokens: {
    id: 'MenuItem.API Tokens',
    defaultMessage: 'API Tokens',
  },
  RequestDemo: {
    id: 'MenuItem.RequestDemo',
    defaultMessage: 'Request Demo',
  },
  Pin: {
    id: 'MenuItem.Pin',
    defaultMessage: 'Pin',
  },
  Unpin: {
    id: 'MenuItem.Unpin',
    defaultMessage: 'Unpin',
  },
  Expand: {
    id: 'MenuItem.Expand',
    defaultMessage: 'Expand',
  },
  Collepse: {
    id: 'MenuItem.Collepse',
    defaultMessage: 'Collepse',
  },
  Sources: {
    id: 'MenuItem.Sources',
    defaultMessage: 'Sources',
  },
  DynamicWidgets: {
    id: 'MenuItem.DynamicWidgets',
    defaultMessage: 'Dynamic Widgets',
  },
  Domains: {
    id: 'MenuItem.Domains',
    defaultMessage: 'Domains',
  },
  ContactUs: {
    id: 'MenuItem.ContactUs',
    defaultMessage: 'Contact Us',
  },
  BookADemo: {
    id: 'MenuItem.BookADemo',
    defaultMessage: 'Book A Demo',
  },
  Contact: {
    id: 'MenuItem.Contact',
    defaultMessage: 'Contact',
  },
});
