import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './core/localStoragePolyfill.js';

import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import { sentryConfig } from './core/sentryConfig.js';

import './styles/style.scss';

import App from './components/App';

import { IntlProvider } from 'react-intl';
import translations from './i18n/locales/index.js';
import { language, languageWithoutRegionCode } from './core/helper.js';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';

Sentry.init(sentryConfig);

const messages =
  translations[languageWithoutRegionCode] ||
  translations[language] ||
  translations.en;

dayjs.locale(language);
document.documentElement.setAttribute('lang', languageWithoutRegionCode);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <IntlProvider
    locale={languageWithoutRegionCode}
    key={languageWithoutRegionCode}
    messages={messages}
  >
    <App />
  </IntlProvider>
);
