import { useContext, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

import { widgetQuestionImagePath } from '../../../../../../core/constants';

import { QuestionTypes, ConfTypes } from '../../../../enums';
import { EditorCTX } from '../../../../context';
import messages from '../../messages';

import {
  Dropdown,
  Label,
  TextAreaInput,
  ImageInput,
} from '../../../../../Common/Form';
import Answers from './Answers';
import Button from '../../../../../Common/Button';
import AnswerForm from './AnswerForm';

const AnswerTypes = Object.freeze([
  {
    type: QuestionTypes.media,
    title: 'Normal',
  },
  {
    type: QuestionTypes.score,
    title: 'Score',
  },
  {
    type: QuestionTypes.free_text,
    title: 'Text Input',
  },
  {
    type: QuestionTypes.free_number,
    title: 'Number Input',
  },
  {
    type: QuestionTypes.refer,
    title: 'Import From Question',
  },
  {
    type: QuestionTypes.autocomplete,
    title: 'Autocomplete',
    selectable: false,
  },
]);

const QuestionEditor = ({ intl: { formatMessage } }) => {
  const {
    set,
    data,
    focus,
    activeQuestionIdx: qidx,
    addNewQuestion,
    setQuestionAnswerType: assignQuestionType,
  } = useContext(EditorCTX);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);

  const question = useMemo(() => {
    if (!data.questions) {
      return {};
    }
    if (data.questions.length === 0) {
      addNewQuestion(qidx);
      return {};
    }
    const q = data.questions[qidx];
    return q;
    // data.questions.length is neccessary to trigger update effect
    //   on cold start. Can maybe updated, sorted, after structure change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.questions, data.questions?.length, qidx, addNewQuestion]);

  const [qTypeIDX, setQTypeIDX] = useState(QuestionTypes.text.value);
  useEffect(() => {
    /** @type {String} */
    const answer_type = question?.answer_type ?? 'text';
    if (!answer_type) {
      return;
    }
    if (question?.use_answers) {
      setQTypeIDX(4);
    } else {
      const index = AnswerTypes.findIndex(
        (_at) => _at.type.value === QuestionTypes[answer_type].value
      );
      setQTypeIDX(index);
    }
  }, [question, question?.answer_type, question?.use_answers]);

  const assignQuestionTypeIDX = (index) => {
    setQTypeIDX(index);
    assignQuestionType(AnswerTypes[index].type ?? QuestionTypes.text);
  };

  const dropdownDisabled = (question?.answers ?? []).reduce((prev, answer) => {
    /** @type {boolean} */
    const hasId = !!answer?.id;

    return prev || hasId;
  }, false);

  const onFocus = () => {
    focus(ConfTypes.Question, `questions.${qidx}`, { save: false });
  };

  return (
    <div className="pt-1 pb-2">
      {question?.id ? (
        <div className="mb-5 space-y-2">{`Question ID: #${question?.id}`}</div>
      ) : null}
      <div className="mb-5 space-y-2">
        <TextAreaInput
          rows={3}
          debounce={{ timeout: 1000 }}
          value={question?.name || ''}
          onChange={(value) => set(`questions.${qidx}.name`, value)}
          maxLength={255}
          label={formatMessage(messages.Name)}
          onFocus={(event) => {
            onFocus(event);
          }}
        />
      </div>
      <div className="mb-5 space-y-2">
        <TextAreaInput
          id={`question-title-${question?.id}`}
          rows={3}
          name="question"
          debounce={{ timeout: 1000 }}
          value={question?.title || ''}
          onChange={(value) => set(`questions.${qidx}.title`, value)}
          maxLength={255}
          label={formatMessage(messages.QuestionTitle)}
          onFocus={(event) => {
            onFocus(event);
          }}
        />
      </div>
      <div className="mb-5 space-y-2">
        <TextAreaInput
          rows={3}
          name="question_desc"
          debounce={{ timeout: 1000 }}
          value={question?.desc || ''}
          onChange={(value) => set(`questions.${qidx}.desc`, value)}
          maxLength={255}
          label={formatMessage(messages.QuestionDescription)}
          onFocus={(event) => {
            onFocus(event);
          }}
        />
      </div>
      <div className="pt-3 mb-5 space-y-2">
        <Label text={formatMessage(messages.QuestionImage)} />
        <div className="h-40">
          <ImageInput
            title={formatMessage(messages.QuestionImage)}
            src={
              question?.background
                ? `${widgetQuestionImagePath}/${question.background}`
                : ''
            }
            callback={(value) => set(`questions.${qidx}.background`, value)}
            type="question"
            forceCrop
          />
        </div>
      </div>
      <div>
        <div className="pt-3 mb-5 space-y-2">
          <Button.Secondary
            className={'w-full'}
            onClick={() => setAnswerModalOpen(!answerModalOpen)}
          >
            {formatMessage(messages.AddMultipleAnswers)}
          </Button.Secondary>
          <AnswerForm
            open={answerModalOpen}
            onClose={() => setAnswerModalOpen(false)}
          />
        </div>
        <Dropdown
          data={AnswerTypes}
          selectedIdx={qTypeIDX}
          onChange={assignQuestionTypeIDX}
          disabled={dropdownDisabled}
        />
        <Answers />
      </div>
    </div>
  );
};

export default injectIntl(QuestionEditor);
