import { ConfTypes } from '../enums';
import {
  QuestionSettingsMockFields,
  ResultSettingsMockFields,
} from '../mockfields';

export const getMockData = (target, data, qidx, ridx, lang) => {
  //format relative dates manually
  let dateFormatted = data.end_date
    ? new Date(
        new Date().getTime() + data.end_date_day * 86400000
      ).toLocaleDateString(lang)
    : null;
  const relativeDate = new Intl.RelativeTimeFormat(lang, {
    style: 'short',
  }).format(data.end_date_day, 'day');

  if (target === ConfTypes.Cover) {
    return data && data.mock
      ? {
          ...data.mock(),
          end_date_relative: relativeDate,
          end_date_human: dateFormatted,
        }
      : {};
  }
  if (target === ConfTypes.Question || target === ConfTypes.Answer) {
    const question = data?.questions?.[qidx];

    return question && question?.mock
      ? {
          ...question?.mock(),
          ...Object.keys(data)
            .filter((key) => QuestionSettingsMockFields.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: data[key] }), {}),
          end_date: data.mock().end_date,
          end_date_relative: relativeDate,
          end_date_human: dateFormatted,
        }
      : {};
  }
  if (target === ConfTypes.Result) {
    const result = !(!data ? null : data.results) ? null : data.results[ridx];

    return result && result.mock
      ? {
          ...result.mock(),
          ...Object.keys(data)
            .filter((key) => ResultSettingsMockFields.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: data[key] }), {}),
        }
      : {};
  }
};
