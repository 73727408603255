import * as editor from '../../../../../api/editor';
import { New as NewResult } from '../classes/Result';

import type EditorDataHandler from '..';
import type { EditorDataHandlerProps, EditorDataHandlerState } from '..';

type TOptions = {
  isLoadingHook?: (isOk: boolean) => void;
  increment?: boolean;
};

export async function helper_getMoreResults(
  this: EditorDataHandler,
  options: TOptions = {}
) {
  if (
    options?.increment &&
    this.state.results_curr_page + 1 > this.state.results_last_page
  ) {
    //last page
    return;
  }

  if (!options?.increment && this.state.results_curr_page === 1) {
    //first page
    return;
  }

  options?.isLoadingHook?.(true);

  const {
    data: {
      data: results,
      last_page: results_last_page,
      current_page: results_curr_page,
    },
  } = await editor.result.getResults(
    this.state.content_id,
    this.state.results_curr_page + (options?.increment ? 1 : -1)
  );

  const _results = results.map((result: any) =>
    NewResult(result, this.state.content)
  );

  const post_fetch = () => {
    options?.isLoadingHook?.(false);
  };

  this.setState(
    (prev: EditorDataHandlerState, props: EditorDataHandlerProps) => ({
      ...prev,
      content: {
        ...prev.content,
        results: [...prev.content.results, ..._results],
      },
      results_curr_page: results_curr_page,
      results_last_page: results_last_page,
    }),
    post_fetch
  );
}
