// TODO: rename this file... wth is "ElementBasedQuestionGen"?

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';

import Button from '../../Common/Button';
import { DSAttributeTypes } from '../types';
import Loading from '../../Common/Loading';

type Props = {
  disabled?: boolean;
  elements: any[];
  onNew: (elementId: string | number) => void;
};
type CardState = 'none' | 'selecting';
function ElementBasedQuestionGen(props: Props) {
  const params = useParams<{ id: string }>();

  const [elements, setElements] = useState<any[]>([]);
  const [elementId, setElementId] = useState<string | number | null>(null);

  useEffect(() => {
    // fetch elements if not already fetched, and on selecting
    if (elements.length > 0) {
      return;
    }

    const dsId = params.id;
    if (!dsId || dsId === 'new') {
      return;
    }

    setElements((prev) => {
      if (prev === null) {
        return prev ?? [];
      }

      let next = [...prev];
      for (const element of props.elements) {
        const title = DSAttributeTypes.find(
          (t) => t.value === element.type
        )?.title;

        next.push({
          title: title,
          value: element.id,
          slug: element.slug,
          type: element.type,
        });
      }

      return next;
    });

    setElementId((prev) => {
      if (prev === null) {
        return props.elements[0]?.id ?? null;
      }

      return prev;
    });
  }, [props.elements, params.id]);

  const handleNew = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    props.onNew(elementId ?? '');
    // TODO: reset state afterwards...
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setElementId(event.target.value);
  };

  return (
    <div className="relative ">
      {elements.length > 0 ? (
        <div>
          <span className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-400 px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
            <span className="inline-flex items-center px-2 py-2">
              <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </span>
            <label htmlFor="message-type" className="sr-only">
              Select the element you want to create a question
            </label>
            <select
              id="message-type"
              name="message-type"
              className="-ml-px block w-full pl-3 pr-9 text-gray-900 sm:text-xs "
              onChange={handleSelect}
            >
              {elements.map((element, index) => (
                <option
                  key={index}
                  value={element.value}
                  selected={element.value === elementId}
                >
                  {element.slug}
                </option>
              ))}
            </select>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center px-3 py-2 text-xs text-gray-900focus:z-10"
              onClick={handleNew}
              disabled={props.disabled}
            >
              Question
            </button>
          </span>
        </div>
      ) : null}

      <p className="mt-2  text-sm text-gray-600">
        {
          'Let SAGE generate you a question based on one of your elements (xml/json elements or CSV columns) which you can use to manually filter results.'
        }
      </p>
    </div>
  );
}

const DropdownTitleRenderer = (data: any, index: any) => {
  return <span>{data.slug}</span>;
};

export default ElementBasedQuestionGen;
