import { ClipboardIcon } from '@heroicons/react/24/solid';
import { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sockette from 'sockette';
import {
  getTriviaInfo,
  getTriviaState,
  triviaAction,
  setRedirectUrl,
} from '../../../api';
import Loading from '../../Common/Loading';
import { injectIntl } from 'react-intl';
import messages from '../messages';
import { Link } from 'react-router-dom';

import { NotificationsCTX } from '../../../contexts/Notification';
import Button from '../../Common/Button';

const ManageTrivia = ({ intl: { formatMessage } }) => {
  const mounted = useRef(true);
  const socket = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { error, success } = useContext(NotificationsCTX);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [triviaState, setTriviaState] = useState({});
  const [socketData, setSocketData] = useState({});
  const [url, setUrl] = useState('');

  const getData = useCallback(async () => {
    try {
      const infoResponse = await getTriviaInfo(id);
      const stateResponse = await getTriviaState(id);
      setData(infoResponse.data);
      setTriviaState(stateResponse.data);
      setLoading(false);
    } catch (e) {
      if (e?.response?.status === 404) {
        error('Trivia is not active. Enter an active trivia id.');
      } else {
        error('Error occured!');
      }
      navigate('/manage-trivia');
    }
  }, [navigate, id, error]);

  const connectWS = useCallback(() => {
    socket.current = new Sockette(`wss://a.poltio.com/trivia-status/${id}`, {
      timeout: 5e3,
      maxAttempts: 10,
      onopen: () =>
        setInterval(function () {
          if (mounted.current) {
            socket.current.send('ping');
          }
        }, 1000),
      onmessage: (e) => {
        if (e.data !== 'ping' || e.data !== 'PING') {
          if (mounted.current) {
            const message = JSON.parse(e.data);
            setSocketData(message);
          }
        }
      },
      onreconnect: (e) => console.log('Reconnecting...', e),
      onmaximum: (e) => console.log('Stop Attempting!', e),
      onclose: (e) => console.log('Closed!', e),
      onerror: (e) => console.log('Error:', e),
    });
  }, [id]);

  useEffect(() => {
    getData();
    connectWS();
    return () => {
      mounted.current = false;
      socket.current.close();
    };
  }, [getData, connectWS]);

  const checkNewData = useCallback(async () => {
    if (data.questions && data.questions.length === 0) {
      if (
        socketData.status === 'active' ||
        socketData.seconds_to_start_date < 100
      ) {
        try {
          const response = await getTriviaInfo(id);
          setData(response.data);
        } catch (e) {
          window.location.reload();
        }
      }
    }
  }, [data.questions, id, socketData]);

  useEffect(() => {
    checkNewData();
  }, [checkNewData]);

  const performTriviaAction = async (e, action) => {
    try {
      if (socketData.timer_remaining > 0) {
        if (!window.confirm(formatMessage(messages.TimerActive))) {
          return;
        }
      }
      if (action === 'reset') {
        if (!window.confirm(formatMessage(messages.AreYouSure))) {
          return;
        }
      }
      const response = await triviaAction(id, action);
      setTriviaState(response.data);
      if (action === 'reset') {
        navigate('/new-trivia');
      }
    } catch (e) {
      console.log(e);
      error(formatMessage(messages.Error));
    }
  };

  const onSubmit = async (e) => {
    try {
      await setRedirectUrl(id, url);
      success(formatMessage(messages.RedirectUrlSet));
    } catch (e) {
      error(formatMessage(messages.Error));
    }
  };

  const copyBoardLink = () => {
    try {
      navigator.clipboard.writeText(
        `https://trivia.poltio.com/board/${triviaState.id}`
      );
      success(formatMessage(messages.BoardUrlCopied));
    } catch (e) {
      error(formatMessage(messages.Error));
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8 lg:px-6 lg:grid lg:grid-cols-12">
        <main className="col-span-6">
          <h1 className="text-2xl pb-6 font-semibold text-gray-600">
            {formatMessage(messages.ManageTrivia)}
          </h1>

          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{`Trivia #${triviaState.id}`}</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {formatMessage(messages.UsersCanJoin)}
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Url</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {triviaState.domain || 'trivia.poltio.com'}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {formatMessage(messages.LeaderboardUrl)}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 inline-flex items-center gap-3">
                    <Link
                      to={`https://trivia.poltio.com/board/${triviaState.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`trivia.poltio.com/board/${triviaState.id}`}
                    </Link>
                    <ClipboardIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                      onClick={copyBoardLink}
                      cursor="pointer"
                    />
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Code</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {triviaState.id}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Mode</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {triviaState.mode || 'normal'}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {formatMessage(messages.PeopleRegistered)}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {socketData.player_count}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    {formatMessage(messages.ConnectedPlayers)}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {socketData.connected_player}
                  </dd>
                </div>
                {triviaState.status === 'active' ? (
                  <>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {formatMessage(messages.CurrentQuestion)}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <b>{`#${socketData.current_question} ${
                          data.questions[socketData.current_question - 1]
                            ?.title || ''
                        }`}</b>
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {formatMessage(messages.NextQuestion)}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {`#${socketData.current_question + 1} ${
                          data.questions[socketData.current_question]?.title ||
                          ''
                        }`}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {formatMessage(messages.TotalQuestions)}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {socketData.total_questions}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {formatMessage(messages.TimeRemaining)}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="grid grid-cols-12">
                          <div className="col-span-1">
                            {socketData.timer_remaining}
                          </div>
                          <div className="bg-gray-200 rounded-full overflow-hidden col-span-11">
                            <div
                              className="h-6 bg-poltio-blue-600 rounded-full"
                              style={{
                                width: `${
                                  (socketData.timer_remaining * 100) /
                                  (socketData.current_question === 1
                                    ? socketData.time_per_question + 10
                                    : socketData.time_per_question + 5)
                                }%`,
                              }}
                            />
                          </div>
                        </div>
                      </dd>
                    </div>
                  </>
                ) : null}
                {socketData.current_question === socketData.total_questions &&
                socketData.timer_remaining === 0 ? (
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      {formatMessage(messages.RedirectUrl)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                          <input
                            type="text"
                            name="redirecturl"
                            id="redirecturl"
                            autoComplete="new-password"
                            className="focus:ring-poltio-blue-500 focus:border-poltio-blue-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                            placeholder="Enter url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            onKeyPress={(e) => {
                              if (url !== '' && e.key === 'Enter') {
                                onSubmit();
                              }
                            }}
                          />
                        </div>
                        <Button.Primary
                          type="button"
                          className="-ml-px relative space-x-2"
                          onClick={onSubmit}
                        >
                          {formatMessage(messages.Set)}
                        </Button.Primary>
                      </div>
                    </dd>
                  </div>
                ) : null}
              </dl>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-center gap-2">
              <Button.Primary
                type="button"
                onClick={(e) => performTriviaAction(e, 'start')}
                disabled={triviaState.status !== 'ready'}
              >
                {formatMessage(messages.Start)}
              </Button.Primary>
              <Button.Primary
                type="button"
                onClick={(e) => performTriviaAction(e, 'next')}
                disabled={
                  triviaState.status !== 'active' ||
                  socketData.current_question === socketData.total_questions
                }
              >
                {formatMessage(messages.Next)}
              </Button.Primary>
              <Button.Primary
                type="submit"
                className="poltio-btn poltio-btn-danger"
                onClick={(e) => performTriviaAction(e, 'reset')}
              >
                {socketData.current_question === socketData.total_questions
                  ? formatMessage(messages.Finish)
                  : formatMessage(messages.Reset)}
              </Button.Primary>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default injectIntl(ManageTrivia);
