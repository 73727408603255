import Question from '../../EditorDataHandler/classes/Question';
import Answer from '../../EditorDataHandler/classes/Answer';

export async function NewQuestion(atIndex, data, set, save, setActiveQuestion) {
  await save();

  const qidx = data.questions?.length || 0;
  // reject calls that demand insert instead of append
  if (atIndex && -1 < atIndex && atIndex !== qidx) {
    return;
  }

  const nextQ = Question.New(
    {
      title: `Question ${qidx + 1}`,
      answer_type: 'text',
      position: qidx,
    },
    data
  );
  set(`questions.${qidx}`, nextQ);
  set(
    `questions.${qidx}.answers.0`,
    Answer.New({ placeholder: 'Answer 1', position: 0 }, nextQ),
    {
      save: false,
    }
  );
  set(
    `questions.${qidx}.answers.1`,
    Answer.New({ placeholder: 'Answer 2', position: 1 }, nextQ),
    {
      save: false,
    }
  );

  // change active question index without focus
  setActiveQuestion(qidx, { save: false });
}
