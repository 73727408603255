import { NotificationMessageRender } from './NotificationMessageRender';

type Props = {
  message: string | object | (string | object)[];
  className?: string;
};

export function NotificationDisplay({ message, className }: Props) {
  let messages: (string | object)[] = Array.isArray(message)
    ? message
    : [message];

  return (
    <div className="mt-2 divide-y-1">
      {messages.map((msg, idx) => (
        <div
          key={`notification-message-${idx}`}
          className="text-sm poltio-notification-card-message"
        >
          <NotificationMessageRender message={msg} />
        </div>
      ))}
    </div>
  );
}
