import { generatePayload } from '../../../../../../api/helpers/generatePayload';
import { validatePayload } from '../../../../../../api/helpers/validatePayload';

type PayloadFields = {
  required?: string[];
  allowed: { [K: string]: string };
};

abstract class QuestionSaveMixin {
  _payloadFields?: PayloadFields;
  _clone_answers_from_ref: any;

  public makePayload() {
    if (!this._payloadFields) {
      return null;
    }

    try {
      validatePayload(this, this._payloadFields?.required ?? []);
    } catch (error) {
      console.error(error);
      return;
    }

    return generatePayload(this, this._payloadFields.allowed);
  }

  public payload() {
    let payload = this.makePayload();

    if (!!this._clone_answers_from_ref) {
      payload.answer_type = this._clone_answers_from_ref.answer_type;
    }

    return payload;
  }

  public abstract save(): void;
}

export default QuestionSaveMixin;
