import React, { type FC } from 'react';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

type MenuItemProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  isFirst?: boolean;
  className: string;
};

export const MenuItem: FC<MenuItemProps> = ({
  onClick,
  children,
  isFirst,
  className,
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'poltio-menu-item',
        isFirst ? ' rounded-l-md' : '-ml-px',
        className
      )}
      onClick={onClick}
    >
      {!!children && children}
    </button>
  );
};

type SubMenuItemProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  className: string;
};

export const SubMenuItem: FC<SubMenuItemProps> = ({
  onClick,
  children,
  className,
}) => {
  return (
    <button
      type="button"
      className={classNames('poltio-menu-item', 'w-full', className)}
      onClick={onClick}
    >
      {!!children && children}
    </button>
  );
};
