import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Button from '../../Common/Button';
import { deleteSubscription } from '../../../api/subscription';
import { NotificationsCTX } from '../../../contexts/Notification';
import messages from './messages';

const SubscriptionInfo = ({ user, intl: { formatDate, formatMessage } }) => {
  const { alert, success } = useContext(NotificationsCTX);
  const { subscription } = user;
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const cancelSub = async () => {
    try {
      if (window.confirm(formatMessage(messages.Sure))) {
        setButtonDisabled(true);
        await deleteSubscription();
        success(formatMessage(messages.SuccessCancel));

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      setButtonDisabled(false);
      alert(formatMessage(messages.ErrorCancel));
    }
  };

  return (
    <div className="divide-y divide-white/5">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {formatMessage(messages.Subs)}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            {formatMessage(messages.YourSubs)}
          </p>
        </div>

        <div className="md:col-span-2">
          {subscription?.id ? (
            <>
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-800 ">
                    {formatMessage(messages.Subs)}
                  </label>
                  <label className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {subscription.name}
                  </label>
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-800 ">
                    {formatMessage(messages.SubsStatus)}
                  </label>
                  <label className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {subscription?.status === 'active' ? 'Active' : null}
                  </label>
                </div>
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-800 ">
                    {formatMessage(messages.SubsDate)}
                  </label>
                  <label className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {formatDate(subscription.created_at)}
                  </label>
                </div>

                {subscription.cancelled_at ? (
                  <div className="col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-800 ">
                      {formatMessage(messages.CancelledAt)}
                    </label>
                    <label className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {formatDate(subscription.cancelled_at)}
                    </label>
                  </div>
                ) : null}

                {subscription.renews_at && !subscription.cancelled_at ? (
                  <div className="col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-800 ">
                      {formatMessage(messages.RenewalDate)}
                    </label>
                    <label className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {formatDate(subscription.renews_at)}
                    </label>
                  </div>
                ) : null}

                {subscription.ends_at ? (
                  <div className="col-span-full">
                    <label className="block text-sm font-medium leading-6 text-gray-800 ">
                      {formatMessage(messages.ExpDate)}
                    </label>
                    <label className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {formatDate(subscription.ends_at)}
                    </label>
                  </div>
                ) : null}
              </div>

              {subscription.cancelled_at ? null : (
                <div className="mt-8 flex">
                  <Button.Danger
                    type="button"
                    disabled={buttonDisabled}
                    onClick={cancelSub}
                  >
                    {formatMessage(messages.CancelSubs)}
                  </Button.Danger>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-800 ">
                    {formatMessage(messages.HaveSubs)}
                  </label>
                </div>
              </div>
              <div className="mt-8">
                <Button.Primary
                  type="button"
                  onClick={() => navigate('/subscribe')}
                >
                  {formatMessage(messages.SubsNow)}
                </Button.Primary>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SubscriptionInfo);
