import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const HealthButton = ({ health }) => (
  <button
    type="button"
    className={classNames(
      health === 'red'
        ? 'bg-red-600'
        : health === 'green'
        ? 'bg-green-600'
        : 'bg-gray-600',
      'inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white'
    )}
  >
    {health || 'N/A'}
  </button>
);

export default HealthButton;
