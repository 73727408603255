import { Fragment, useRef } from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
  ArrowsUpDownIcon,
} from '@heroicons/react/24/solid';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl.macro';
import messages from './messages';
import { Listbox, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Pagination = ({
  last,
  active,
  size,
  handlePageChange,
  pageSizes = [12, 50, 100],
  totalSize,
  showTotal,
  showTotalDraft,
  noTotal,
  intl: { formatMessage },
}) => {
  last = parseInt(last, 10);
  active = parseInt(active, 10);

  const container = useRef(null);
  const upwards = useRef(false);

  const isUpwards = () => {
    if (container && container.current) {
      const bottomClearence =
        window.innerHeight - container.current.getBoundingClientRect().bottom;
      upwards.current = window.innerHeight / 3 > bottomClearence;
    }

    return upwards.current;
  };

  let links = [];
  let left = active - 2 <= 0 ? 1 : active - 2;
  if (left + 4 >= last) {
    left = last - 4 > 0 ? last - 4 : 1;
  }

  for (let i = left; i <= left + 4 && i <= last; i++) {
    links.push(i);
  }

  return (
    <div className="row">
      <div className="flex items-center justify-between">
        <div
          className={classNames(
            noTotal
              ? 'sm:flex-1 sm:flex sm:items-end sm:justify-center'
              : showTotal
                ? 'sm:flex flex-col-reverse w-full sm:items-end'
                : 'sm:flex-1 sm:flex sm:items-end sm:justify-between'
          )}
        >
          {noTotal ? null : (
            <div>
              {showTotal ? (
                <div className="hidden sm:block">
                  <p className="text-xs font-medium text-gray-700 dark:text-gray-400 mt-2">
                    <FormattedMessage
                      id="Pagination.TotalCount"
                      defaultMessage="Showing {first} to {last} of {total} contents"
                      values={{
                        first: (active - 1) * size + 1,
                        last: active * size,
                        total: totalSize,
                      }}
                    />
                  </p>
                </div>
              ) : showTotalDraft ? (
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700 dark:text-gray-400">
                    <FormattedMessage
                      id="Pagination.TotalCountDraft"
                      defaultMessage="Showing {first} to {last} of {total} draft contents"
                      values={{
                        first: (active - 1) * size + 1,
                        last: active * size,
                        total: totalSize,
                      }}
                    />
                  </p>
                </div>
              ) : (
                <div
                  className="text-sm text-gray-700 dark:text-gray-400"
                  ref={container}
                >
                  <Listbox
                    value={size}
                    onChange={(e) => {
                      handlePageChange(1, e);
                    }}
                  >
                    {({ open }) => (
                      <>
                        <div className="mt-5 relative">
                          <Listbox.Button className="bg-white dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-100 relative w-full border border-gray-300 rounded-md shadow-sm pl-4 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm">
                            <span className="block truncate">{size}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <ArrowsUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options
                              className={classNames(
                                'absolute z-10 w-full bg-white dark:bg-gray-700 dark:text-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm',
                                isUpwards() ? 'bottom-10 mb-1' : 'mt-1'
                              )}
                            >
                              {pageSizes.map((s, i) => (
                                <Listbox.Option
                                  key={i}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-poltio-blue-600 dark:hover:bg-gray-900'
                                        : 'text-gray-900 dark:text-gray-100',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={s}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {s}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-poltio-blue-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              )}
            </div>
          )}

          <div>
            <nav
              className="mt-5 relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <a
                style={{ cursor: 'pointer' }}
                className="relative inline-flex items-center px-1 py-1 sm:px-2 sm:py-2 rounded-l-md border border-gray-300 bg-white dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-100 text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  handlePageChange(1, size);
                }}
              >
                <span className="sr-only">
                  {formatMessage(messages.Previous)}
                </span>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              <a
                style={{ cursor: 'pointer' }}
                className="relative inline-flex items-center px-1 py-1 sm:px-2 sm:py-2 border border-gray-300 dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-100 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  handlePageChange(Math.max(active - 1, 1), size);
                }}
              >
                <span className="sr-only">
                  {formatMessage(messages.Previous)}
                </span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>

              {links.map((link, i) => (
                <a
                  style={{ cursor: 'pointer' }}
                  key={i}
                  aria-current="page"
                  className={`${
                    active === link
                      ? 'z-10 bg-indigo-50 border-poltio-blue-500 text-poltio-blue-600 dark:bg-gray-800 dark:border-gray-100 dark:text-gray-100 dark:font-bold'
                      : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-100'
                  } relative inline-flex items-center px-2 py-1 sm:px-4 sm:py-2 border text-sm font-medium`}
                  onClick={() => {
                    handlePageChange(link, size);
                  }}
                >
                  {link}
                </a>
              ))}

              <a
                style={{ cursor: 'pointer' }}
                className="relative inline-flex items-center px-1 py-1 sm:px-2 sm:py-2 border border-gray-300 dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-100 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  handlePageChange(Math.min(active + 1, last), size);
                }}
              >
                <span className="sr-only">{formatMessage(messages.Next)}</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
              <a
                style={{ cursor: 'pointer' }}
                className="relative inline-flex items-center px-1 py-1 sm:px-2 sm:py-2 rounded-r-md border dark:bg-gray-700 dark:hover:bg-gray-900 dark:text-gray-100 border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  handlePageChange(last, size);
                }}
              >
                <span className="sr-only">{formatMessage(messages.Next)}</span>
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Pagination);
