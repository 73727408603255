import {
  PencilIcon,
  TrashIcon,
  ClipboardDocumentIcon,
  GiftIcon,
  ChatBubbleBottomCenterIcon,
} from '@heroicons/react/24/solid';
import Tooltip from '../Button/Tooltip';

const THead = ({ cols = [] }) => {
  return (
    <thead className="bg-gray-50">
      <tr>
        {cols.map((col, idx) => (
          <th
            key={`thead-idx-${idx}`}
            scope="col"
            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-r first:hidden lg:first:flex"
          >
            {col.name}
          </th>
        ))}
        <th
          scope="col"
          className="w-1 text-xs font-medium tracking-wider text-left text-gray-500 uppercase first:hidden lg:first:flex"
        />
      </tr>
    </thead>
  );
};

const TBody = ({
  data = [],
  cols = [],
  openEdit,
  handleDeleteClick,
  incomingLeads,
  couponCodes,
  logs,
  messages,
  formatMessage,
}) => {
  return (
    <tbody>
      {data.map((row, row_idx) =>
        row ? (
          <tr
            key={`tbody-idx-${row_idx}`}
            className={row_idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
          >
            {cols
              .filter((value) => !!value.accessor)
              .map((col, col_idx) => (
                <td
                  key={`tbody-col-idx-${col_idx}`}
                  className="px-6 py-6 text-sm text-gray-500 whitespace-nowrap border-r first:hidden lg:first:flex"
                >
                  <div className="text-sm font-medium text-gray-900">
                    {col.id === 0
                      ? row[`${col.accessor}`]
                      : row.counters
                        ? row.counters[`${col.accessor}`]
                        : null}
                  </div>
                </td>
              ))}
            <td className="px-6 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap text-right">
              <div className="z-0 w-full py-2">
                <span className="relative inline-flex">
                  {row.type === 'input' ? (
                    <a
                      href={''}
                      target="_blank"
                      rel="noreferrer"
                      className="group cursor-pointer relative inline-block border-gray-400 text-center"
                      onClick={(e) => {
                        e.preventDefault();
                        incomingLeads(row);
                      }}
                    >
                      <ChatBubbleBottomCenterIcon
                        className="w-5 h-5 mr-4 -ml-1 text-gray-400"
                        aria-hidden="true"
                      />
                      <Tooltip title={formatMessage(messages.IncomingLeads)} />
                    </a>
                  ) : null}
                  {row.lead_count > 0 ? (
                    <a
                      href={''}
                      target="_blank"
                      rel="noreferrer"
                      className="group cursor-pointer relative inline-block border-gray-400 text-center"
                      onClick={(e) => {
                        e.preventDefault();
                        logs(row);
                      }}
                    >
                      <ClipboardDocumentIcon
                        className="w-5 h-5 mr-4 -ml-1 text-gray-400"
                        aria-hidden="true"
                      />
                      <Tooltip title={formatMessage(messages.Logs)} />
                    </a>
                  ) : null}
                  {row.has_codes ? (
                    <a
                      href={''}
                      target="_blank"
                      rel="noreferrer"
                      className="group cursor-pointer relative inline-block border-gray-400 text-center"
                      onClick={(e) => {
                        e.preventDefault();
                        couponCodes(row);
                      }}
                    >
                      <GiftIcon
                        className="w-5 h-5 mr-4 -ml-1 text-gray-400"
                        aria-hidden="true"
                      />
                      <Tooltip title={formatMessage(messages.CouponCodes)} />
                    </a>
                  ) : null}
                  <a
                    href={''}
                    target="_blank"
                    rel="noreferrer"
                    className="group cursor-pointer relative inline-block border-gray-400 text-center"
                    onClick={(e) => {
                      e.preventDefault();
                      openEdit(row);
                    }}
                  >
                    <PencilIcon
                      className="w-5 h-5 mr-4 -ml-1 text-gray-400"
                      aria-hidden="true"
                    />
                    <Tooltip title={formatMessage(messages.Edit)} />
                  </a>
                  <a
                    href={''}
                    target="_blank"
                    rel="noreferrer"
                    className="group cursor-pointer relative inline-block border-gray-400 text-center"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteClick(row.id);
                    }}
                  >
                    <TrashIcon
                      className="w-5 h-5 mr-2 -ml-1 text-red-400"
                      aria-hidden="true"
                    />
                    <Tooltip title={formatMessage(messages.Delete)} />
                  </a>
                </span>
              </div>
            </td>
          </tr>
        ) : null
      )}
    </tbody>
  );
};

const Table = ({
  data,
  cols,
  openEdit,
  handleDeleteClick,
  incomingLeads,
  couponCodes,
  logs,
  messages,
  formatMessage,
}) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <THead cols={cols} />
              <TBody
                messages={messages}
                formatMessage={formatMessage}
                data={data}
                cols={cols}
                openEdit={openEdit}
                handleDeleteClick={handleDeleteClick}
                incomingLeads={incomingLeads}
                couponCodes={couponCodes}
                logs={logs}
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
