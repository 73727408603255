import { Listbox } from '@headlessui/react';

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(' ');
}

type TDropdownRowData =
  | {
      title: string;
    }
  | string;

type TDropdownRowsOptions = {
  renderer?: any;
  modal?: boolean;
  onOpenCallback?: (index: number) => void;
};

function DefaultModalRenderer(
  item: TDropdownRowData,
  index: number,
  options: TDropdownRowsOptions | undefined
): JSX.Element {
  return (
    <li
      key={`dropdown-li-${index}`}
      className={classNames(
        'bg-white first:rounded-t-md last:rounded-b-md',
        'text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-4',
        'hover:text-poltio-blue-100 hover:bg-poltio-blue-500'
      )}
      onClick={(event) => options?.onOpenCallback?.(index)}
    >
      {typeof item === 'string' ? item : item?.title}
    </li>
  );
}

function DefaultListboxRenderer(
  item: TDropdownRowData,
  index: number,
  options: TDropdownRowsOptions | undefined
): JSX.Element {
  let _title;
  switch (typeof item) {
    case 'number':
    case 'string':
      _title = item;
      break;
    case 'object':
    default:
      _title = item?.title ?? '';
      break;
  }

  return (
    <Listbox.Option
      key={index}
      value={index}
      className={({ active }) =>
        classNames(
          'bg-white first:rounded-t-md last:rounded-b-md',
          active ? 'text-poltio-blue-100 bg-poltio-blue-500' : 'text-gray-900',
          'hover:text-poltio-blue-100 hover:bg-poltio-blue-500',
          'cursor-pointer select-none relative py-2 pl-3 pr-4'
        )
      }
    >
      {_title}
    </Listbox.Option>
  );
}

type DropdownRowsProps = {
  data: Array<any>;
  options?: TDropdownRowsOptions;
};
export function DropdownRows(props: DropdownRowsProps): JSX.Element {
  const { data, options } = props;

  if (options?.renderer) {
    return (
      <>
        {data.map((value, index, array) =>
          options.renderer(value, index, array, options)
        )}
      </>
    );
  }

  return (
    <>
      {data.map((value, index) =>
        options?.modal
          ? DefaultModalRenderer(value, index, options)
          : DefaultListboxRenderer(value, index, options)
      )}
    </>
  );
}
