import { injectIntl } from 'react-intl';
import ContentSearch from '../SearchField/ContentSearch';
import messages from './messages';

const _ContentInput = ({ contentId, onSelect, intl: { formatMessage } }) => {
  return (
    <div className="flex flex-row whitespace-nowrap items-center gap-x-2 text-md text-gray-600 font-medium">
      {formatMessage(messages.SearchContent)}
      <div className="flex items-center pt-1 pb-2 w-3/4">
        <div className="w-full">
          <ContentSearch contentId={contentId} onSelect={onSelect} />
        </div>
      </div>
    </div>
  );
};

const ContentInput = injectIntl(_ContentInput);

export { ContentInput };
export default ContentInput;
