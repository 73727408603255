import { EditorState } from '../../../enums';
import Change from '../classes/Change';

export function helper_set(key, value, options = { save: true }) {
  let content = this.state.content;

  let change = new Change();
  change.field = key;
  change.apply(content, value);

  if (options.save) {
    this.change_list.push(change);
  }

  this.setState(
    (prev, props) => ({
      ...prev,
      content: content,
      save_state: EditorState.Dirty,
    }),
    () => {}
  );
}
