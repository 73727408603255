export const appPrefix = import.meta.env.VITE_PREFIX;
export const apiURL = import.meta.env.VITE_API_URL;
export const embedUrlPrefix = import.meta.env.VITE_EMBED_URL;
export const stripeKey = import.meta.env.VITE_STRIPE_KEY;
export const captchaKey = import.meta.env.VITE_CAPTCHA_KEY;
export const pexelsApiKey = import.meta.env.VITE_PEXELS_API_KEY;

export const clientId = import.meta.env.VITE_CLIENT_ID;
export const clientSecret = import.meta.env.VITE_CLIENT_SECRET;

export const cdnPath = `https://cdn0.poltio.com`;

export const feedDailyImagePathRetina = `${cdnPath}/560x380`;
export const feedAvatarPathRetina = `${cdnPath}/160x160`;
export const feedAnswerImagePathRetina = `${cdnPath}/800x800`;
export const feedImagePathRetina = `${cdnPath}/1600x748`;

export const widgetImagePath = `${cdnPath}/1104x690`;
export const widgetCoverImagePath = `${cdnPath}/1104x690`;
export const widgetQuestionImagePath = `${cdnPath}/1104x690`;
export const widgetAnswerImagePath = `${cdnPath}/230x230`;
export const widgetResultImagePath = `${cdnPath}/1604x1604`;
export const widgetLeadImagePath = `${cdnPath}/1104x690`;

export const iconTypes = {
  error:
    'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z',
  success:
    'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z',
  warning:
    'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z',
  cross:
    'M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z',
};

export const defaultAVATAR = 'placeholder/profile/6.png';
export const defaultCOVER = 'placeholder/cover.jpg';

export const appVersion = import.meta.env.VITE_VERSION ?? 'development';

export const isDev = import.meta.env.DEV;
