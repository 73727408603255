import axios from 'axios';
import { apiURL } from '../core/constants';
import { languageWithoutRegionCode } from '../core/helper';

const api = axios.create({
  baseURL: apiURL,
});

const apiWithoutToken = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': languageWithoutRegionCode,
};

try {
  let token = null;
  token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    api.defaults.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': languageWithoutRegionCode,
    };
  } else {
    localStorage.removeItem('token');
    api.defaults.headers = apiWithoutToken;
  }
} catch (e) {
  api.defaults.headers = apiWithoutToken;
}

const sleepRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(api(originalRequest)), milliseconds);
  });
};

api.interceptors.response.use(
  function (response) {
    // reject promise if the status code is 0
    if (response.status === 0) {
      return Promise.reject(response);
    }

    return response;
  },
  function (error) {
    const errorStatus = error?.response?.status;
    switch (errorStatus) {
      case 503:
        window.location.href = `/errorpage?error=503`;
        break;
      case 502:
        sleepRequest(2000, error.config);
        break;
      case undefined:
        sleepRequest(2000, error.config);
        break;
      case 429:
        if (window.location.href.includes('/login-link-request')) {
          return Promise.reject(error);
        }
        window.location.href = `/errorpage?error=429`;
        break;
      case 401:
        if (
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/signup'
        ) {
          window.localStorage.removeItem('user');
          window.localStorage.removeItem('token');
          window.location.href = `/errorpage?error=401`;
        }
        return Promise.reject(error);
      default:
        return Promise.reject(error);
    }
  }
);

export default api;
