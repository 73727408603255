const SKIP_KEYS = ['', 'options', 'cover'];

const isArrayProperty = (field) => {
  return ['questions', 'answers', 'results', 'content_results'].includes(field);
};

export function lookup(ref, argv) {
  const _lookup = (ref, head, ...tail) => {
    if (SKIP_KEYS.includes(head)) {
      return _lookup(ref, ...tail);
    }

    if (tail.length > 0) {
      // there is more items in tail, recurse

      if (!ref[head]) {
        // HEAD to recurse is undefined! You cannot recurse into
        //   an undefined object.
        // define HEAD to recurse into.
        ref[head] = isArrayProperty(head) ? [] : {};
      }

      return _lookup(ref[head], ...tail);
    }

    return [ref, head];
  };

  let args = argv;
  if (args.slice(0)[0] === 'properties' && args.slice(-1)[0] === 'order') {
    // order change set
    args = args.slice(1, -1);
  }

  return _lookup(ref, ...args);
}
