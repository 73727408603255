import React, { useContext } from 'react';
import {
  DocumentIcon,
  ArrowPathIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import { CloudCheck } from '../../../../images';

import { EditorCTX } from '../../context';
import { EditorState } from '../../enums';
import messages from './messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SaveButton = ({ intl: { formatMessage } }) => {
  const { save, editorStatus } = useContext(EditorCTX);

  return (
    <div className="relative flex justify-end w-full overflow-hidden">
      <button
        className={classNames(
          'flex flex-grow poltio-btn-xs justify-end max-w-[6rem]',
          editorStatus === EditorState.Dirty ||
            editorStatus === EditorState.Draft
            ? 'bg-white hover:bg-gray-50'
            : '',
          editorStatus === EditorState.Syncing ? 'bg-gray-300' : '',
          editorStatus === EditorState.Saved
            ? 'bg-poltio-blue-500 text-white'
            : '',
          editorStatus === EditorState.Error ? 'bg-red-500 text-white' : ''
        )}
        disabled={
          editorStatus === EditorState.Syncing ||
          editorStatus === EditorState.Saved
        }
        onClick={() => save({ force: true })}
      >
        {editorStatus === EditorState.Dirty ||
        editorStatus === EditorState.Draft ? (
          <>
            <span className="mr-1">{formatMessage(messages.Unsaved)}</span>
            <DocumentIcon className="h-5 w-5" />
          </>
        ) : null}
        {editorStatus === EditorState.Syncing ? (
          <>
            <span className="mr-1">{formatMessage(messages.Syncing)}</span>
            <ArrowPathIcon className="h-5 w-5" />
          </>
        ) : null}
        {editorStatus === EditorState.Saved ? (
          <>
            <span className="mr-1">{formatMessage(messages.Saved)}</span>
            <CloudCheck className="h-5 w-5" />
          </>
        ) : null}
        {editorStatus === EditorState.Error ? (
          <>
            <span className="mr-1">{formatMessage(messages.Error)}</span>
            <XMarkIcon className="h-5 w-5" />
          </>
        ) : null}
      </button>
    </div>
  );
};

export default injectIntl(SaveButton);
