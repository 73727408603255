type APICaller = (
  publicId: string,
  page: number,
  sizePerPage: number
) => Promise<any>;

async function fetchPaginatedData(
  publicId: string,
  currentPage: number,
  sizePerPage: number,
  apiCaller: APICaller,
  isLoadingHook: (isLoading: boolean) => void,
  setDataHook: (data: any) => void
) {
  isLoadingHook(true);

  const response = await apiCaller(publicId, currentPage, sizePerPage);
  const data = response?.data;

  if (data === undefined) {
    // quitely fail
    isLoadingHook(false);
    return;
  }

  setDataHook(data);

  isLoadingHook(false);
}

export default fetchPaginatedData;
