import * as editor from '../../../../../../api/editor';
import { saveAnswerOrders } from '../../../requests/saveAnswerOrder';
import { getAnswer } from '../getAnswer';
import { getQuestion } from '../getQuestion';
import { getReducedChanges } from '../getReducedChanges';

export async function processProperties(content, changes) {
  const content_id = content?.public_id;
  if (content_id === undefined) {
    console.error('Content `public_id` is undefined at PROPERTIES save');
    return;
  }

  //#region Save Answer-Result Relation Configuration
  const content_results = getReducedChanges(
    changes?.answer_content_results ?? []
  );
  for (const key in content_results) {
    const change = content_results[key];

    const question = getQuestion(content, change);
    const answer = getAnswer(question, change);

    answer?.content_results?.forEach((cr, index) => {
      editor.answer.saveAnswerResultRelation(
        content_id,
        question.id,
        answer.id,
        cr
      );
    });
  }
  //#endregion

  //#region Save Question Order
  const questions_order = getReducedChanges(
    changes?.properties_questions_order ?? []
  );
  if (Object.keys(questions_order).length > 0) {
    (async () => {
      const { data } = await editor.content.saveContentQuestionOrder(
        content_id,
        content
      );
      content?.updateQuestionOrder(data);
    })();
  }
  //#endregion

  saveAnswerOrders(content, changes);
}
