import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  ImageButtonMessage: {
    id: 'ImageInput.ImageButtonMessage',
    defaultMessage: 'Upload Image',
  },
  SizeErrorMessage: {
    id: 'ImageInput.SizeErrorMessage',
    defaultMessage: `File Upload ERROR, File size cannot be bigger than 5MB!`,
  },
  UploadFile: {
    id: 'ImageInput.UploadFile',
    defaultMessage: 'Upload File',
  },
  Confirm: {
    id: 'ImageInput.Confirm',
    defaultMessage: 'Confirm',
  },
  PanelTitle: {
    id: 'ImageInput.PanelTitle',
    defaultMessage: 'Panel Title',
  },
  ErrorWhileProcessingImage: {
    id: 'ImageInput.ErrorWhileProcessingImage',
    defaultMessage: 'Could not process this image, please try a different one',
  },
  ServerError: {
    id: 'ImageInput.ServerError',
    defaultMessage: "Server can't process image at the moment",
  },
  ServerErrorDesc: {
    id: 'ImageInput.ServerErrorDesc',
    defaultMessage:
      'Our servers are busy, please try uploading the image again later.',
  },
  NotAnImage: {
    id: 'ImageInput.NotAnImage',
    defaultMessage: 'Uploaded file is not an image',
  },
  NotAnImageDesc: {
    id: 'ImageInput.NotAnImageDesc',
    defaultMessage:
      'The file you have uploaded is not an image type. Please try again with an compatible file type. i.e.: jpeg',
  },
  ErrorWhileUploading: {
    id: 'ImageInput.ErrorWhileUploading',
    defaultMessage: 'Unknown error while uploading image',
  },
  Done: {
    id: 'ImageInput.Done',
    defaultMessage: 'Done',
  },
  UploadImage: {
    id: 'ImageInput.UploadImage',
    defaultMessage: 'Upload an image',
  },
  ConfirmSelection: {
    id: 'ImageInput.ConfirmSelection',
    defaultMessage: 'Confirm selection',
  },
  Remove: {
    id: 'ImageInput.Remove',
    defaultMessage: 'Remove image',
  },
  CropImage: {
    id: 'ImageInput.CropImage',
    defaultMessage: 'Crop image',
  },
  PhotosProvide: {
    id: 'ImageInput.PhotosProvide',
    defaultMessage: 'Photos provided by Pexels',
  },
  RateLimit: {
    id: 'ImageInput.RateLimit',
    defaultMessage: 'Rate Limit Exceeded',
  },
  TryAgain: {
    id: 'ImageInput.TryAgain',
    defaultMessage: 'Please try again later.',
  },
  SearchImage: {
    id: 'ImageInput.SearchImage',
    defaultMessage: 'Search for an image',
  },
  SearchDesc: {
    id: 'ImageInput.SearchDesc',
    defaultMessage: 'You can only search in English',
  },
  Use: {
    id: 'ImageInput.Use',
    defaultMessage: 'Use',
  },
});
