import { useEffect, useState } from 'react';

export const SliderInput = ({
  title,
  onChange = () => {},
  value = 0,
  min = 0,
  max = 10,
  step = 1,
}) => {
  const [_value, _setValue] = useState(0);

  useEffect(() => _setValue(value), [value]);

  const _onChange = (event) => {
    onChange(event.target.value);
    _setValue(event.target.value);
  };

  const style = {
    backgroundSize: `${((_value - min) * 100) / (max - min)}% 100%`,
  };

  return (
    <div className="my-1">
      <p className="text-sm font-semibold text-gray-900 truncate">{title}</p>
      <div className="flex w-full justify-between items-center">
        <input
          type="range"
          className="slider flex h-1"
          style={style}
          min={min}
          max={max}
          step={step}
          value={_value}
          onChange={_onChange}
        />
        <span className="flex mx-4 align-middle">{_value}</span>
      </div>
    </div>
  );
};
