import React, { useCallback, useEffect, useState } from 'react';
import SearchField from '.';

import { getContent, getContents } from '../../../api';

const ContentSearchRowRenderer = ({ item }) => {
  return (
    <span className="flex">
      <span className="truncate">{item.name}</span>
    </span>
  );
};

const LeadSearch = ({ contentId, onSelect = (value) => {} }) => {
  const [name, setName] = useState();
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 50,
    lastPage: null,
  });

  const fetchData = useCallback(
    async (isDirty, setDisplayContents, setDisplayText) => {
      try {
        const response = await getContents(pageData.page, pageData.sizePerPage);
        const leads = response.data.data;

        setDisplayContents(leads);
        setPageData((prev) => ({
          page: prev.page,
          sizePerPage: prev.sizePerPage,
          totalSize: response.data.total,
          lastPage: response.data.last_page,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    [pageData.page, pageData.sizePerPage]
  );

  useEffect(() => {
    (async () => {
      if (contentId) {
        try {
          const response = await getContent(contentId);
          const content = response.data.content;

          setName(content.name);
        } catch (error) {
          console.error(error);
        }
        return;
      }

      setName('');
    })();
  }, [contentId]);

  const onItemChange = (lead, setDisplayText) => {
    setDisplayText(lead.name);
    onSelect(lead);
  };

  const onInputChange = (value, setDisplayText) => {
    setDisplayText(value);
  };

  return (
    <SearchField
      defaultMessage={''}
      displayValue={name}
      fetchData={fetchData}
      onItemChange={onItemChange}
      onInputChange={onInputChange}
      rowRenderer={ContentSearchRowRenderer}
    />
  );
};

export default LeadSearch;
