import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  RequestDemo: {
    id: 'RequestDemo.RequestDemo',
    defaultMessage: 'Request Demo',
  },
  Great: {
    id: 'RequestDemo.Great',
    defaultMessage: "Great! Let's get you sorted.",
  },
  LeaveDetail: {
    id: 'RequestDemo.LeaveDetail',
    defaultMessage:
      "Leave your details, and we'll arrange a call to walk you through what we have to offer.",
  },
  Success: {
    id: 'RequestDemo.Success',
    defaultMessage: 'You have requested a demo. You will be contacted shortly.',
  },
  Error: {
    id: 'RequestDemo.Error',
    defaultMessage: 'Error occurred!',
  },
  Email: {
    id: 'RequestDemo.Email',
    defaultMessage: 'Email Address',
  },
  YourMessage: {
    id: 'RequestDemo.YourMessage',
    defaultMessage: 'Your Message',
  },
  Send: {
    id: 'RequestDemo.Send',
    defaultMessage: 'Send',
  },
  EmailHelpText: {
    id: 'RequestDemo.EmailHelpText',
    defaultMessage:
      'If you want us to contact you with a different email then the one you registered with us.',
  },
  NameHelpText: {
    id: 'RequestDemo.NameHelpText',
    defaultMessage: 'So we know what to call you before hand',
  },
  CompanyHelpText: {
    id: 'RequestDemo.CompanyHelpText',
    defaultMessage:
      'If you want to share your company name with us so we can better prepare for your call',
  },
  PhoneHelpText: {
    id: 'RequestDemo.PhoneHelpText',
    defaultMessage: 'If you prefer to get a call instead of an email',
  },
  YourMessageHelpText: {
    id: 'RequestDemo.YourMessageHelpText',
    defaultMessage:
      'If you have any other input you want to share with us before our demo session',
  },
  Optional: {
    id: 'RequestDemo.Optional',
    defaultMessage: 'Optional',
  },
  OnboardingMsg: {
    id: 'RequestDemo.OnboardingMsg',
    defaultMessage: 'I need a meeting about using Poltio',
  },
  SubmitSuccess: {
    id: 'RequestDemo.SubmitSuccess',
    defaultMessage: 'Thank You for Reaching Out!',
  },
  SubmitSuccessDesc: {
    id: 'RequestDemo.SubmitSuccessDesc',
    defaultMessage: `We've received your details and are excited to connect with you. One of our team members will be in touch soon at the email address you provided to schedule your personalized demo. In the meantime, feel free to explore more of our offerings on our platform. Looking forward to showcasing what we can do for you!`,
  },
  YourEmail: {
    id: 'RequestDemo.YourEmail',
    defaultMessage: 'Your Contact Email:',
  },
  EmailDesc: {
    id: 'RequestDemo.EmailDesc',
    defaultMessage:
      'Enter your email address so we can reach out to you to arrange a convenient time for your demo.',
  },
  MsgDesc: {
    id: 'RequestDemo.MsgDesc',
    defaultMessage:
      "Let us know if there are specific aspects you're interested in or any other details that can help us tailor the demo to your needs.",
  },
});
