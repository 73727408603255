import { type Dispatch, type SetStateAction, type FC } from 'react';
import StatList from '../Common/StatList';

type Props = {
  intl: any;
  messages: any;
  datesChanged: boolean;
  setDatesChanged: Dispatch<SetStateAction<boolean>>;
  startDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  endDate: Date;
  setEndDate: Dispatch<SetStateAction<Date>>;
  dashboardStats: any;
  hasTooltip: false;
};

export const Stats: FC<Props> = ({
  intl,
  messages,
  datesChanged,
  setDatesChanged,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dashboardStats,
  hasTooltip,
}) => {
  return (
    <StatList
      title={intl.formatMessage(messages.Highlights)}
      messages={messages}
      stats={dashboardStats}
      setDatesChanged={setDatesChanged}
      setStartDate={setStartDate}
      startDate={startDate}
      setEndDate={setEndDate}
      endDate={endDate}
      intl={intl}
      datesChanged={datesChanged}
      statsPage={false}
      hasTooltip={hasTooltip}
    />
  );
};

export default Stats;
