import * as editor from '../../../../../../api/editor';
import { saveContent } from '../../../requests/saveContent';
import { getReducedChanges } from '../getReducedChanges';

export async function processContent(content, changes) {
  let content_id = content?.public_id;

  //#region Save Content
  try {
    content_id = await saveContent(content, changes);
  } catch (error) {
    console.error(error?.message ?? error);
  }
  //#endregion

  if (content_id === undefined) {
    console.error('Content `public_id` is undefined at CONTENT save');
    return;
  }

  //#region Save Content Lead
  const lead_changes = getReducedChanges(changes?.base_lead ?? []);
  if (Object.keys(lead_changes).length > 0) {
    editor.content
      .saveContentLead(content_id, content?.lead?.id)
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion

  //#region Save Content PixelCode
  const px_changes = getReducedChanges(changes?.base_pixelcode ?? []);
  if (Object.keys(px_changes).length > 0) {
    editor.content
      .saveContentPixelCode(content_id, content?.pixel_code?.id)
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion
}
