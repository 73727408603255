import React from 'react';
import { EditorCTX } from './context';

type AnswerTypes = {
  default: 'text' | 'media';
  text: 'text';
  media: 'media';
  free_text: 'free_text';
  free_number: 'free_number';
  autocomplete: 'autocomplete';
};

export function useAnswerTypes(): AnswerTypes {
  const {
    data: { questions },
    activeQuestionIdx: qidx,
  } = React.useContext(EditorCTX);

  const _default = React.useMemo(
    () =>
      (questions?.[qidx]?.answers || []).some(
        (answer: any) => answer?.background === ''
      )
        ? 'text'
        : 'media',
    [questions, qidx]
  );

  return {
    default: _default,

    text: 'text',
    media: 'media',

    free_text: 'free_text',
    free_number: 'free_number',
    autocomplete: 'autocomplete',
  };
}

export const ConfTypes = Object.freeze({
  Cover: 'cover',
  Options: 'options',
  Question: 'question',
  Result: 'result',
  Answer: 'question',
});

export const QuestionTypes = Object.freeze({
  yesno: {
    value: 0,
    listable: true,
  },
  text: {
    value: 1,
    listable: true,
    draggable: true,
    dynamic: true,
  },
  media: {
    value: 1,
    listable: true,
    draggable: true,
    dynamic: true,
  },
  score: {
    value: 2,
    listable: true,
  },
  refer: {
    value: 3,
    listable: true,
    dynamic: false,
  },
  free_text: {
    value: 4,
    listable: false,
  },
  free_number: {
    value: 5,
    listable: false,
  },
  autocomplete: {
    value: 6,
    listable: true,
    draggable: true,
    dynamic: true,
  },
});

export const EditorState = Object.freeze({
  Error: -2,
  Draft: -1,
  Dirty: 0,
  Syncing: 1,
  Saved: 2,
});
