import { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { EditorCTX } from '../../../../context';
import { Label, SwitchInput } from '../../../../../Common/Form';
import { saveMultipleAnswers } from '../../../../../../api/editor/answer';
import Button from '../../../../../Common/Button';
import messages from '../../messages';
import { injectIntl } from 'react-intl';

const AnswerForm = ({ open, onClose, intl: { formatMessage } }) => {
  const {
    set,
    get,
    activeQuestionIdx: qidx,
    data,
    save,
    fetch,
  } = useContext(EditorCTX);

  //save on modal open
  useEffect(() => {
    if (open) {
      save();
      const q = get(`questions.${qidx}`);
      setActiveQuestion(q);
    }
  }, [open, save, get, qidx]);

  //assign active question to state and update it as index changes
  const q = get(`questions.${qidx}`);
  const [activeQuestion, setActiveQuestion] = useState(q);
  useEffect(() => {
    const q = get(`questions.${qidx}`);
    setActiveQuestion(q);
  }, [qidx, get]);

  //modal related state and effect updates
  const [answersValue, setAnswersValue] = useState('');
  const [preserveExistingAnswers, setPreserveExistingAnswers] = useState(true);
  const [allowMult, setAllowMult] = useState(
    activeQuestion?.allow_multiple_answers
  );
  const [autocomp, setAutoComp] = useState(
    activeQuestion?.answer_type === 'autocomplete'
  );
  const [initialSettings, setInitialSettings] = useState({
    allow_multiple_answers: activeQuestion?.allow_multiple_answers,
    autocomplete: activeQuestion?.answer_type === 'autocomplete',
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    setInitialSettings({
      allow_multiple_answers: activeQuestion?.allow_multiple_answers,
      autocomplete: activeQuestion?.answer_type === 'autocomplete',
    });
    setAllowMult(!!activeQuestion?.allow_multiple_answers);
    setAutoComp(activeQuestion?.answer_type === 'autocomplete');
  }, [activeQuestion, open]);

  const saveAnswers = async () => {
    try {
      setButtonDisabled(true);
      const newAnswers = answersValue.length ? answersValue.split('\n') : [];

      await saveMultipleAnswers(
        data.public_id,
        activeQuestion.id,
        newAnswers.join('\n'),
        !preserveExistingAnswers
      );

      //save options
      set(`questions.${qidx}.allow_multiple_answers`, allowMult);
      set(`questions.${qidx}.max_multi_punch_answer`, newAnswers.length);
      set(
        `questions.${qidx}.answer_type`,
        autocomp ? 'autocomplete' : get(`questions.${qidx}`).answer_type
      );
      await save();

      setTimeout(() => {
        fetch();
        onClose();

        //restore defaults
        setPreserveExistingAnswers(true);
        setAnswersValue('');
        setButtonDisabled(false);
      }, 2000);
    } catch (e) {
      setButtonDisabled(false);
    }
  };

  const changeAllowMult = (val) => {
    setAllowMult(val);
  };

  const changeAutoComp = (val) => {
    setAutoComp(val);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="flex row-auto items-baseline justify-between">
                    <Label
                      htmlFor={'answersinput'}
                      text={formatMessage(messages.Answers)}
                      font={'small'}
                    />
                    <div className="text-xs mb-2 mr-4 text-gray-400">
                      {' '}
                      {formatMessage(messages.EveryLine)}
                    </div>
                  </div>
                  <textarea
                    name={'answersinput'}
                    rows={5}
                    className="block w-full shadow-sm sm:text-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 border-gray-300 rounded-md resize-none"
                    defaultValue={answersValue}
                    onChange={(e) => setAnswersValue(e.target.value)}
                  />
                </div>
                <div className="flex items-center mt-2 justify-between">
                  <SwitchInput
                    value={preserveExistingAnswers}
                    onSwitch={() =>
                      setPreserveExistingAnswers(!preserveExistingAnswers)
                    }
                  >
                    {formatMessage(messages.Preserve)}
                    <div className="text-xs mb-2 mr-4 text-gray-400">
                      {formatMessage(messages.PreserveDesc)}
                    </div>
                  </SwitchInput>
                </div>
                {initialSettings?.allow_multiple_answers ? null : (
                  <>
                    <div className="flex items-center mt-2  justify-between">
                      <SwitchInput value={allowMult} onSwitch={changeAllowMult}>
                        {formatMessage(messages.AllowMp)}
                        <div className="text-xs mb-2 mr-4 text-gray-400">
                          {formatMessage(messages.AllowMpDesc)}
                        </div>
                      </SwitchInput>
                    </div>
                  </>
                )}
                {initialSettings?.autocomplete ? null : (
                  <div className="flex items-center mt-2  justify-between">
                    <SwitchInput value={autocomp} onSwitch={changeAutoComp}>
                      {formatMessage(messages.Autocomplete)}
                      <div className="text-xs mb-2 mr-4 text-gray-400">
                        {formatMessage(messages.AutocompleteDesc)}
                      </div>
                    </SwitchInput>
                  </div>
                )}
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <Button.Secondary type="submit" onClick={onClose}>
                    {formatMessage(messages.Cancel)}
                  </Button.Secondary>
                  <Button.Primary
                    type="submit"
                    disabled={answersValue.length < 1 || buttonDisabled}
                    onClick={saveAnswers}
                    showSpinner={buttonDisabled}
                  >
                    {formatMessage(messages.Save)}
                  </Button.Primary>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default injectIntl(AnswerForm);
