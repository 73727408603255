import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { useIntl } from 'react-intl';

import api from '../../../api/client';

import DSConfigureSteps from './Steps';
import CreatePanel from './CreatePanel';
import ConfigPanel from './ConfigPanel';
import PublishPanel from './PublishPanel';
import Heading from '../../Common/Page/Heading';
import messages from '../messages';

import type {
  DataSourceElement,
  DataSourceStatus,
  DataSourceViewResponse,
} from '../types';

function apiDSGet(id: string | number) {
  return api.get<DataSourceViewResponse>(`/platform/data-sources/${id}`);
}

type TDSCTXStatus = DataSourceStatus | 'loading';
interface IDSConfigCTX {
  step: number;
  setStep: (index: number) => void;
  status: TDSCTXStatus;
  elements?: DataSourceElement[];
}
const DSConfigCTXDefaultState = {
  step: 0,
  setStep: (index: number) => {},
  status: 'loading' as TDSCTXStatus,
  elemets: null,
};
export const DSConfigCTX = createContext<IDSConfigCTX>(DSConfigCTXDefaultState);

export type TDSConfigRouteParams = {
  value?: 'new' | string;
};
function Page() {
  const { step, setStep } = useContext(DSConfigCTX);
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.DataSources)}
          body={formatMessage(messages.DataSourcesDesc)}
        />
      </div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 bg-white rounded-lg">
          <div className="mt-4">
            <Tab.Group selectedIndex={step} onChange={setStep}>
              <DSConfigureSteps activeStep={step} />
              <Tab.Panels className="mt-5">
                <Tab.Panel>
                  <CreatePanel />
                </Tab.Panel>
                <Tab.Panel>
                  <ConfigPanel />
                </Tab.Panel>
                <Tab.Panel>
                  <PublishPanel />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </>
  );
}

function DataSourceConfigurePage() {
  const params = useParams<TDSConfigRouteParams>();
  const location = useLocation();
  const navigate = useNavigate();

  const [step, setActiveStep] = useState(0);

  const [elements, setElements] = useState<DataSourceElement[]>([]);
  const [status, setStatus] = useState<TDSCTXStatus>('loading');

  useEffect(() => {
    const dsId = params.value;

    if (!dsId || dsId === 'new') {
      return;
    }

    (async () => {
      try {
        const response = await apiDSGet(dsId);
        const data = response.data;

        setStatus(data.status);
        if (data.status !== 'draft') {
          navigate(`/data-source/${dsId}#view`);
        }

        setElements((prev) => [...(data.data_source_item_elements ?? [])]);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  function setStep(index: number) {
    // TODO: prevent navigation conditionally

    if (index === 0 && params.value === 'new') {
      navigate('/data-source/new');
    }

    if (!(params.value && params.value !== 'new')) {
      return;
    }

    switch (index) {
      case 1:
        navigate(`/data-source/${params.value}#configure`);
        break;
      case 2:
        navigate(`/data-source/${params.value}#publish`);
        break;

      case 0:
      default:
        break;
    }
  }

  useEffect(() => {
    if (params.value === 'new') {
      setActiveStep(0);
      return;
    }

    if (params.value && params.value !== 'new') {
      switch (location.hash) {
        case '#view':
        case '#publish':
          setActiveStep(2);
          break;

        case '#configure':
        default:
          setActiveStep(1);
          break;
      }
    }
  }, [params.value, location.hash]);

  const ctx = {
    step,
    setStep,
    status,
    elements,
  };

  return (
    <DSConfigCTX.Provider value={ctx}>
      <Page />
    </DSConfigCTX.Provider>
  );
}

export default DataSourceConfigurePage;
