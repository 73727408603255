import { useEffect, useState } from 'react';
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

import Loading from '../../Common/Loading';

import CardDisplayExplanation from './CardDisplayExplanation';
import CardErrorDisplay from './CardErrorDisplay';
import LoadingArticleMessageDisplay from './LoadingArticleMessageDisplay';

import generateMockURI from './generateMockURI';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type SageQuestionCardProps = {
  index: number | string;
  question: any;
  onReplace: (elementId?: number | string, options?: any) => void;
  onDelete: () => void;
  [key: string]: any;
};
type TCardState = 'none' | 'loading' | 'empty' | 'ok' | 'error';
const CardContent = (props: SageQuestionCardProps) => {
  const { index, question, onDelete, onReplace } = props;

  const [cardState, setCardState] = useState<TCardState>('none');
  const [iframeSRC, setIframeSRC] = useState<string | null>(null);

  const [thumbState, setThumbState] = useState<'up' | 'down' | 'none'>('none');

  useEffect(() => {
    if (Object.keys(props.question).length === 0) {
      setCardState('empty');
      setIframeSRC(null);
      return;
    }

    if (question?.loading) {
      return;
    }

    // if state there is a question, the state can be ok or error
    //   error can only be set after a failure by the request handler
    //   if there is data, the state is ok

    setCardState('ok');

    const src = generateMockURI(question);

    setIframeSRC(src);
  }, [question]);

  const onThumbsUp = () => {
    setThumbState('up');
  };

  const handleThumbsDown = () => {
    setThumbState('down');
    setTimeout(() => {
      setThumbState('none');

      setCardState('loading');
      onReplace(question?.elementId ?? '', { showLoading: false });
    }, 1000);
  };

  const isError = cardState === 'error';
  const isLoading = cardState === 'loading' || question?.loading;

  if (isLoading) {
    return (
      <div
        className="
        flex flex-col w-full h-full items-center align-middle justify-center
        bg-gray-50/5 border-2 border-poltio-blue-500/60 rounded-lg
      "
      >
        <div className="-mt-24">
          <Loading style={{ padding: '0' }} />
          <p className="h-5">
            <LoadingArticleMessageDisplay />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        isError
          ? 'relative border-2 border-red-500/60 rounded-lg w-full'
          : 'w-full'
      }
    >
      {isError ? (
        <CardErrorDisplay
          onThumbsDown={handleThumbsDown}
          onThumbsUp={onThumbsUp}
        />
      ) : null}
      {props.question?.explanation ? (
        <CardDisplayExplanation explanation={props.question.explanation} />
      ) : null}
      <div className="max-w-xl">
        <div className="relative w-full h-[33rem] shadow-inner ring-1 ring-black/5 rounded-lg mt-4">
          {['ok', 'error'].includes(cardState) ? (
            <>
              {iframeSRC === null ? (
                <Loading />
              ) : (
                <iframe
                  id={`poltio-embed-mock-${question.id ?? index}`}
                  className="
                    aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]
                    outline-none poltio-widget ring-0 h-full
                  "
                  src={iframeSRC}
                  width={'100%'}
                  frameBorder="0"
                  allowFullScreen={true}
                  scrolling="yes"
                  title="Embed"
                />
              )}
            </>
          ) : null}
          {cardState === 'empty' ? (
            <div className="flex flex-col w-full h-full items-center align-middle justify-center gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mx-auto h-12 w-12 text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                />
              </svg>

              <span className="mt-2 block text-sm font-semibold text-gray-900">
                Create a new question
              </span>
            </div>
          ) : null}
        </div>

        {['ok', 'error'].includes(cardState) ? (
          <div className="relative mt-4 flex justify-end gap-x-1 text-gray-400">
            <button onClick={() => setThumbState('up')}>
              <HandThumbUpIcon
                className={classNames(
                  'h-6 hover:text-poltio-blue-500 -scale-x-[1]',
                  thumbState === 'up'
                    ? 'text-poltio-blue-500 fill-poltio-blue-400'
                    : ''
                )}
              />
            </button>
            <button onClick={() => handleThumbsDown()}>
              <HandThumbDownIcon
                className={classNames(
                  'h-6 hover:text-rose-500 -scale-x-[1]',
                  thumbState === 'down' ? 'text-rose-500 fill-rose-400' : ''
                )}
              />
            </button>
            <button onClick={() => onDelete()}>
              <XCircleIcon className="h-6 hover:text-rose-500" />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const CardWrapper = (props: SageQuestionCardProps) => {
  const { index, question } = props;

  return (
    <article
      key={question?.id ?? index}
      className="flex flex-col items-start justify-start min-h-[33rem]"
    >
      <CardContent {...props} />
    </article>
  );
};

const SageQuestionCard = CardWrapper;

export default SageQuestionCard;
