import { defaultScoreAnswers, defaultTextAnswers } from '../../../constants';
import { QuestionTypes } from '../../../enums';
import { isQuestionMediaType } from '../../../helpers/isQuestionMediaType';
import { overrideAnswers } from '../../../helpers/overrideAnswers';

// -------------------- Question Answer Type Control --------------------

// set question answer type of active question if not set
//   do not register to API call queue, as we are not
//   going to save answers unless they go out of focus.

export function setQuestionAnswerType(_next, data, qidx, set, del) {
  const question = data.questions[qidx];

  if (QuestionTypes[question?.answer_type]?.value === _next.value) {
    return;
  }

  if (!_next.value) {
    return;
  }

  /** @type {Array} */
  let next_answers = [];

  if (_next.value === QuestionTypes.autocomplete.value) {
    // do nothing
    return;
  }

  // if 'TEXT'|'MEDIA' type
  if (_next.value === QuestionTypes.text.value) {
    const _mt = isQuestionMediaType(question?.answers) ? 'media' : 'text';

    set(`questions.${qidx}.answer_type`, _mt);
    set(`questions.${qidx}.use_answers`, null);
    set(`questions.${qidx}.clone_answers_from`, null);

    next_answers = [...defaultTextAnswers];
  }

  // if 'SCORE' type
  if (_next.value === QuestionTypes.score.value) {
    set(`questions.${qidx}.answer_type`, 'score');
    set(`questions.${qidx}.use_answers`, null);
    set(`questions.${qidx}.clone_answers_from`, null);

    next_answers = [...defaultScoreAnswers];
  }

  if (_next.value === QuestionTypes.refer.value) {
    set(`questions.${qidx}.use_answers`, 1);

    next_answers = [];
  }

  if (_next.value === QuestionTypes.free_text.value) {
    set(`questions.${qidx}.answer_type`, 'free_text');
    set(`questions.${qidx}.use_answers`, null);
    set(`questions.${qidx}.clone_answers_from`, null);

    next_answers = [];
  }

  if (_next.value === QuestionTypes.free_number.value) {
    set(`questions.${qidx}.answer_type`, 'free_number');
    set(`questions.${qidx}.use_answers`, null);
    set(`questions.${qidx}.clone_answers_from`, null);

    next_answers = [];
  }

  overrideAnswers(question, next_answers, qidx, set, del);
}
