import * as editor from '../../../../api/editor';
import { getReducedChanges } from '../EditorDataHandler/helpers/getReducedChanges';
import { getQuestion } from '../EditorDataHandler/helpers/getQuestion';

import type { Content, EditorAPIRequestHandler } from '.';
import type { Question } from '../EditorDataHandler/classes/Question';

type SaveAnswerOrderReturnType = void;
type SaveAnswerOrder = EditorAPIRequestHandler<SaveAnswerOrderReturnType>;

const logic = async (content: Content, question: Question) => {
  const contentId = content?.public_id;
  if (!contentId) {
    console.error('Content ID cannot be undefined');
    return;
  }

  try {
    await question.save();
  } catch (error) {
    console.error('Error while saving question\n', error);
    return;
  }

  for (const answer of question.answers) {
    try {
      await answer.save();
    } catch (error) {
      console.error('Error while saving answer\n', error);
      return;
    }
  }

  try {
    await editor.question.saveQuestionAnswerOrder(contentId, question);
  } catch (error) {
    console.error('Error while saving answer order\n', error);
    return;
  }
};

export const saveAnswerOrders: SaveAnswerOrder = async (content, changes) => {
  const _changes = getReducedChanges(changes?.properties_answers_order ?? []);
  if (Object.keys(_changes).length <= 0) {
    return;
  }

  for (const key in _changes) {
    const change = _changes[key];
    const question = getQuestion(content, change);
    logic(content, question as Question);
  }
};
