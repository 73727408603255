import { useState } from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';

import ConfigurationSection from './ConfigurationSection';
import NavigationSection from './NavigationSection';
import QuestionList from './NavigationSection/Questions';
import ResultList from './NavigationSection/Results';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Settings = () => {
  const [show, setShow] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div
      className={classNames(
        'sm:h-full flex flex-shrink-0 mb-4 sm:mb-0',
        show ? 'w-full' : '',
        'max-w-[100%] md:max-w-[25rem]'
      )}
    >
      <button
        className="hover:bg-gray-300 w-4 mr-1"
        onClick={() => setShow((prev) => !prev)}
      >
        <div className="fixed top-1/2">
          <ChevronDoubleRightIcon
            className={classNames('h-4 w-4', show ? '' : 'rotate-180')}
          />
        </div>
      </button>
      <div className={classNames('flex flex-col', show ? 'w-full' : 'w-0')}>
        <div className="sm:relative flex flex-col flex-1 overflow-y-auto fancy-scrollbar">
          <div
            className={classNames(
              'sm:absolute inset-0',
              show ? 'w-full' : 'sm:hidden'
            )}
          >
            <div className="hidden sm:block h-8 mt-10 mb-1 mx-2 sm:ml-0 sm:mr-5" />
            <div className="mx-2 sm:ml-0 sm:mr-5 space-y-4">
              <NavigationSection
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
              <QuestionList />
              <ResultList modalOpen={modalOpen} setModalOpen={setModalOpen} />
              <ConfigurationSection />
            </div>
            <div className="hidden sm:block h-8 mt-10 mb-1 mx-2 sm:ml-0 sm:mr-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
