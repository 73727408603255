import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EditorState } from '../../enums';

import { helper_get } from './helpers/get';
import { helper_set } from './helpers/set';
import { helper_del } from './helpers/del';
import { helper_save } from './helpers/save';
import { helper_getMoreResults } from './helpers/getMoreResults';
import { helper_fetch } from './helpers/fetch';
import { usePrompt } from '../../../../core/navigation';
import { New as NewContent } from './classes/Content';
import { NotificationsCTX } from '../../../../contexts/Notification';
import api from '../../../../api/client';
import { useIntl } from 'react-intl';
import { errorToHumanReadable } from './helpers/errorToHumanReadable';

const defaultContentValue = {
  title: 'New Content',
  type: 'set',
  hide_counter: 1,
  hide_results: 1,
};

class EditorDataHandler extends React.Component {
  constructor(props) {
    super(props);

    const { id } = props?.router?.params;
    const content_id = id === 'new' ? '' : id;

    this.error_notification = props?.notification?.error;
    this.format_message = props?.intl?.formatMessage;

    this.state = {
      save_state: EditorState.Draft,
      content_id: content_id,
      content: NewContent({ ...defaultContentValue, id: content_id }),
      results_curr_page: 1,
      results_last_page: 1,
    };

    this.change_list = [];
    this.changes_cursor = 0;

    this.clear = this.clear.bind(this);
    this.fetch = helper_fetch.bind(this);
    this.get = helper_get.bind(this);
    this.set = helper_set.bind(this);
    this.del = helper_del.bind(this);
    this.save = helper_save.bind(this);

    this.getMoreResults = helper_getMoreResults.bind(this);
  }

  componentDidMount() {
    const err_prompt = this.error_notification;
    const fmt_message = this.format_message;

    const onRejected = (error) => {
      /** @type string */
      const path = error?.response?.config?.url ?? '';

      if (path.startsWith('/platform/')) {
        const messages = [
          error?.message ?? '',
          ...errorToHumanReadable(fmt_message, error?.response?.data),
        ];

        this?.setState?.((prev, props) => ({
          ...prev,
          save_state: EditorState.Error,
        }));
        err_prompt?.('Error while saving content.', messages, 5000);
      }

      return Promise.reject(error);
    };

    const onFulfilled = (response) => {
      return response;
    };

    this.interceptor = api.interceptors.response.use(onFulfilled, onRejected);
  }

  componentWillUnmount() {
    api.interceptors.response.eject(this.interceptor);
  }

  clear() {
    this.setState({
      save_state: EditorState.Draft,
      content_id: null,
      content: NewContent({ ...defaultContentValue }),
    });

    this.change_list = [];
    this.changes_cursor = 0;
  }

  render() {
    const isEditorDirty = this.change_list.length > this.changes_cursor;

    const editor = {
      clear: this.clear,
      fetch: async (...args) => {
        await this.fetch(...args);
      },
      get: this.get,
      set: this.set,
      del: this.del,
      save: this.save,
      content: this.state.content,
      content_id: this.state.content_id,
      status: isEditorDirty ? EditorState.Dirty : this.state.save_state,
      getMoreResults: async (...args) => {
        await this.getMoreResults(...args);
      },
      hasMoreResults:
        this.state.results_last_page > this.state.results_curr_page,
      hasLessResults: this.state.results_curr_page > 1,
    };

    const isNewContent = this.props?.router?.params?.id === 'new';
    const shouldPrompt = isEditorDirty && !isNewContent;

    return (
      <>
        <Prompt shouldPrompt={shouldPrompt} />
        {React.cloneElement(this.props.children, { editor: editor })}
      </>
    );
  }
}

function Prompt({ children, shouldPrompt = false }) {
  usePrompt('You have unsaved changes! Are you sure?', shouldPrompt);

  return children;
}

function withContext(Component) {
  function ComponentWithContextProps(props) {
    let { error } = useContext(NotificationsCTX);

    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    const { formatMessage } = useIntl();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        notification={{ error }}
        intl={{ formatMessage }}
      />
    );
  }

  return ComponentWithContextProps;
}

export default withContext(EditorDataHandler);
