import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

import { iconTypes } from '../../core/constants';
import { NotificationDisplay } from './NotificationMessageDisplay';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

type CardBodyProps = {
  type: 'error' | 'success' | 'warning';
  title?: string;
  message?: string | object | (string | object)[];
  close?: () => void;
  timeout?: number;
  hasProgressBar?: boolean;
};

const CardBody: React.FC<CardBodyProps> = ({
  type,
  title = '',
  message = '',
  close = () => {},
  timeout = 2500,
  hasProgressBar = false,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval: any;

    if (timeout > 0) {
      interval = setInterval(() => {
        setProgress((prev) => {
          const next = prev + 1;

          if (next === 100) {
            clearInterval(interval);
          }

          return next;
        });
      }, timeout / 100);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timeout]);

  useEffect(() => {
    if (progress === 100) close();
  }, [close, progress]);

  return (
    <div
      className={classNames(
        type,
        'rounded-md shadow-md ring-1 ring-black ring-opacity-5 border-l-4 pt-4 m-4',
        hasProgressBar ? '' : 'pb-1'
      )}
    >
      <div className="mx-4 mb-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 poltio-notification-card-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fillRule="evenodd" d={iconTypes[type]} clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            {title && (
              <h3 className="text-sm font-medium poltio-notification-card-title">
                {title}
              </h3>
            )}
            <NotificationDisplay message={message} />
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="poltio-notification-card-close-btn"
                onClick={close}
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d={iconTypes['cross']}
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      {hasProgressBar && (
        <div className="poltio-notification-card-progress-bar">
          <div style={{ width: `${progress}%` }} />
        </div>
      )}
    </div>
  );
};

type NotificationCardProps = {
  close?: () => void;
} & CardBodyProps;

export const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const [isShowing, setIsShowing] = React.useState(true);

  const onClose = () => {
    // hide
    setIsShowing(false);

    // detach
    setTimeout(() => props?.close?.(), 300);
  };

  return (
    <Transition
      show={isShowing}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <CardBody {...props} close={onClose} />
    </Transition>
  );
};
