import {
  ChevronRightIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { injectIntl } from 'react-intl';

import { SliderInput, Expander } from '../../../../../../Common/Form';
import { EditorCTX } from '../../../../../context';
import messages from '../../../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// TODO: paginate results list
const AnswerResultRelation = ({ intl: { formatMessage } }) => {
  const {
    confField,
    get,
    set,
    activeQuestionIdx: qidx,
    hasMoreResults,
    hasLessResults,
    getMoreResults,
    getLessResults,
  } = useContext(EditorCTX);

  // answer index is not stored directly, get it from FOCUS data
  const aidx = confField.split('.').slice(-1)[0];

  const results = get('.results');
  const answer = get(`.questions.${qidx}.answers.${aidx}`);
  const crs = answer?.content_results ?? [];

  return (
    <Expander>
      <Expander.Button className="flex w-full pb-2 pt-1 px-2 hover:bg-gray-50 border-b border-[#D1D5DB]">
        {({ open }) => (
          <div className="flex w-full justify-between">
            <div className="flex w-3/4">
              <h2 className="flex text-xl font-semibold">
                {formatMessage(messages.AnswerResultRelation)}
              </h2>
            </div>
            <div className="flex min-w-[1.25rem]">
              <ChevronRightIcon
                className={classNames('w-5 mt-1', open ? 'rotate-90' : '')}
              />
            </div>
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="flex flex-col divide-y divide-[#D1D5DB] px-2 pb-1 mb-1">
          <button
            className="w-full h-3 p-0 m-0 mt-1 flex justify-center bg-poltio-blue-500 hover:bg-poltio-blue-600 disabled:bg-poltio-blue-400 disabled:pointer-events-none"
            disabled={!hasLessResults}
            onClick={getLessResults}
          >
            <ChevronDoubleUpIcon className="h-3 text-white" />
          </button>
          {results?.map((result, index) => {
            const cri = crs.findIndex((value) => value.id === result.id);

            const cridx = cri >= 0 ? cri : crs.length;
            const point =
              cri >= 0 ? crs[cri]?.point || crs[cri]?.pivot?.point || 0 : 0;

            return (
              <SliderInput
                key={`answer-result-relation-${index}`}
                title={result.title}
                value={point}
                onChange={(value) =>
                  set(
                    `questions.${qidx}.answers.${aidx}.content_results.${cridx}`,
                    { id: result.id, point: value }
                  )
                }
              />
            );
          })}
          <button
            className="w-full h-3 p-0 m-0 flex justify-center bg-poltio-blue-500 hover:bg-poltio-blue-600 disabled:bg-poltio-blue-400 disabled:pointer-events-none"
            disabled={!hasMoreResults}
            onClick={getMoreResults}
          >
            <ChevronDoubleDownIcon className="h-3 text-white" />
          </button>
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(AnswerResultRelation);
