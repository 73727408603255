import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Themes: {
    id: 'Theme.Themes',
    defaultMessage: 'Themes',
  },
  ThemesDesc: {
    id: 'Theme.ThemesDesc',
    defaultMessage: 'Themes allows you to customize widgets look and feel.',
  },
  Error: {
    id: 'Theme.Error',
    defaultMessage: 'Error occured!',
  },
  Saved: {
    id: 'Theme.Saved',
    defaultMessage: 'Changes saved.',
  },
  Theme: {
    id: 'Theme.Theme',
    defaultMessage: 'Theme',
  },
  RestoreDefaults: {
    id: 'Theme.RestoreDefaults',
    defaultMessage: 'Restore Defaults',
  },
  name: {
    id: 'Theme.Name',
    defaultMessage: 'Name',
  },
  primary_color: {
    id: 'Theme.ButtonBackground',
    defaultMessage: 'Button Background',
  },
  primary_color_selected: {
    id: 'Theme.SelectedButtonBackground',
    defaultMessage: 'Selected Button Background',
  },
  secondary_color: {
    id: 'Theme.UnselectedButtonBackground',
    defaultMessage: 'Unselected Button Background',
  },
  primary_text_color: {
    id: 'Theme.ButtonTextColor',
    defaultMessage: 'Button Text Color',
  },
  primary_text_color_selected: {
    id: 'Theme.SelectedButtonTextColor',
    defaultMessage: 'Selected Button TextColor',
  },
  secondary_background_color: {
    id: 'Theme.UnselectedButtonTextColor',
    defaultMessage: 'Unselected Button TextColor',
  },
  font_option: {
    id: 'Theme.FontOption',
    defaultMessage: 'Font Options',
  },
  font_family: {
    id: 'Theme.FontFamily',
    defaultMessage: 'Font Family',
  },
  font_family_css: {
    id: 'Theme.GoogleFontFamily',
    defaultMessage: 'Font Family CSS',
  },
  title_font_weight: {
    id: 'Theme.TitleFontWeight',
    defaultMessage: 'Title Font Weight',
  },
  button_font_weight: {
    id: 'Theme.ButtonFontWeight',
    defaultMessage: 'Button Font Weight',
  },
  button_border_radius: {
    id: 'Theme.PrimaryButtonBorderRadius',
    defaultMessage: 'Primary Button Border Radius',
  },
  answer_border_radius: {
    id: 'Theme.AnswerBorderRaduius',
    defaultMessage: 'Answer Border Radius',
  },
  textinput_border_radius: {
    id: 'Theme.TextInputBorderRadius',
    defaultMessage: 'Text Input Border Radius',
  },
  font_size_title: {
    id: 'Theme.TitleFontSize',
    defaultMessage: 'Title Font Size',
  },
  line_height_title: {
    id: 'Theme.TitleLineHeight',
    defaultMessage: 'Title Line Height',
  },
  font_size_answer: {
    id: 'Theme.AnswerFontSize',
    defaultMessage: 'Answer Font Size',
  },
  line_height_answer: {
    id: 'Theme.AnswerLineHeight',
    defaultMessage: 'Answer Line Height',
  },
  main_text_color: {
    id: 'Theme.BodyColor',
    defaultMessage: 'Main Text Color',
  },
  background_color: {
    id: 'Theme.BackgroundColor',
    defaultMessage: 'Background Color',
  },
  cover_background_color: {
    id: 'Theme.CoverBackgroundColor',
    defaultMessage: 'Cover Background Color',
  },
  cover_text_color: {
    id: 'Theme.CoverTextColor',
    defaultMessage: 'Cover Text Color',
  },
  cover_desc_color: {
    id: 'Theme.CoverDescriptionColor',
    defaultMessage: 'Cover Description Color',
  },
  question_background_color: {
    id: 'Theme.QuestionBackgroundColor',
    defaultMessage: 'Question Background Color',
  },
  question_text_color: {
    id: 'Theme.QuestionTextColor',
    defaultMessage: 'Question Text Color',
  },
  result_background_color: {
    id: 'Theme.ResultBackgroundColor',
    defaultMessage: 'Result Background Color',
  },
  result_text_color: {
    id: 'Theme.ResultTextColor',
    defaultMessage: 'Result Text Color',
  },
  result_desc_color: {
    id: 'Theme.ResultDescriptionColor',
    defaultMessage: 'Result Description Color',
  },
  card_shadow: {
    id: 'Theme.CardShadow',
    defaultMessage: 'Card Shadow',
  },
  card_border_radius: {
    id: 'Theme.CardBorderRadius',
    defaultMessage: 'Card Border Radius',
  },
  mp_info_color: {
    id: 'Theme.MpInfoColor',
    defaultMessage: 'Multipunch Info Color',
  },
  footer_background_color: {
    id: 'Theme.FooterBackgroundColor',
    defaultMessage: 'Footer Background Color',
  },
  background_img: {
    id: 'Theme.background_img',
    defaultMessage: 'Embed Background Image',
  },
  EmbedFooterImage: {
    id: 'Theme.EmbedFooterImage',
    defaultMessage: 'Embed Footer Image',
  },
  SaveChanges: {
    id: 'Theme.SaveChanges',
    defaultMessage: 'Save Changes',
  },
  Preview: {
    id: 'Theme.Preview',
    defaultMessage: 'Preview',
  },
  MobileView: {
    id: 'Theme.MobileView',
    defaultMessage: 'Mobile View',
  },
  ItMayTake: {
    id: 'Theme.ItMayTake',
    defaultMessage:
      '*It may take up to 5 minutes for the theme changes to take place.',
  },
  ID: {
    id: 'Theme.ID',
    defaultMessage: 'ID',
  },
  Name: {
    id: 'Theme.Name',
    defaultMessage: 'Name',
  },
  Success: {
    id: 'Theme.Success',
    defaultMessage: 'Success',
  },
  SureDelete: {
    id: 'Theme.SureDelete',
    defaultMessage: 'Are u sure delete this theme ?',
  },
  AddNewTheme: {
    id: 'Theme.AddNewTheme',
    defaultMessage: 'Add New Theme',
  },
  positive_color: {
    id: 'Theme.PositiveColor',
    defaultMessage: 'Positive Color',
  },
  negative_color: {
    id: 'Theme.NegativeColor',
    defaultMessage: 'Negative Color',
  },
  primary_color_desc: {
    id: 'Theme.PrimaryColorDesc',
    defaultMessage: 'Background color for inputs and selected options.',
  },
  primary_color_selected_desc: {
    id: 'Theme.PrimaryColorSelectedDesc',
    defaultMessage:
      'Primary background color for action buttons and selected options outer shells',
  },
  secondary_background_color_desc: {
    id: 'Theme.SecondaryBackgroundColorDesc',
    defaultMessage: 'Background color for non-selected options.',
  },
  primary_text_color_desc: {
    id: 'Theme.PrimaryTextColorDesc',
    defaultMessage: 'Text color for options.',
  },
  secondary_color_desc: {
    id: 'Theme.SecondaryColorDesc',
    defaultMessage: 'Text color for unselected options',
  },
  primary_text_color_selected_desc: {
    id: 'Theme.PrimaryTextColorSelectedDesc',
    defaultMessage: 'Text color for primary action buttons',
  },
  font_option_default_desc: {
    id: 'Theme.FontOptionDefaultDesc',
    defaultMessage: 'Optimized for widgets',
  },
  font_option_custom_desc: {
    id: 'Theme.FontOptionCustomDesc',
    defaultMessage: 'Google Fonts or other font hosting services',
  },
  button_border_radius_desc: {
    id: 'Theme.ButtonBorderRadiusDesc',
    defaultMessage: 'Border Radius of primary buttons',
  },
  answer_border_radius_desc: {
    id: 'Theme.AnswerBorderRadiusDesc',
    defaultMessage: 'Border Raduis of options',
  },
  textinput_border_radius_desc: {
    id: 'Theme.TextInputBorderRadiusDesc',
    defaultMessage: 'Border Raduis of text inputs',
  },
  font_size_title_desc: {
    id: 'Theme.FontSizeTitleDesc',
    defaultMessage: 'Title and title sized font sizes. You can use px or rem.',
  },
  line_height_title_desc: {
    id: 'Theme.LineHeightTitleDesc',
    defaultMessage: 'Line height  for titles. You can use px, rem or pt.',
  },
  font_size_answer_desc: {
    id: 'Theme.FontSizeAnswerDesc',
    defaultMessage:
      'Font Size of Description and Answer Button Text, you can use px or rem.',
  },
  line_height_answer_desc: {
    id: 'Theme.LineHeightAnswerDesc',
    defaultMessage: 'Line height for options. ',
  },
  main_text_color_desc: {
    id: 'Theme.MainTextColorDesc',
    defaultMessage:
      "Default font color for the page. All texts that doesn't have a specific field can be adjusted by this.",
  },
  background_color_desc: {
    id: 'Theme.BackgroundColorDesc',
    defaultMessage: 'Default background color for the widget.',
  },
  cover_background_color_desc: {
    id: 'Theme.CoverBackgroundColorDesc',
    defaultMessage: 'Background Color of Cover Card',
  },
  cover_text_color_desc: {
    id: 'Theme.CoverTextColorDesc',
    defaultMessage: 'Font Color of Cover Card Title ',
  },
  cover_desc_color_desc: {
    id: 'Theme.CoverDescColorDesc',
    defaultMessage: 'Font Color of Cover Card Description Section',
  },
  question_background_color_desc: {
    id: 'Theme.QuestionBackgroundColorDesc',
    defaultMessage: 'Background Color of Question Cards',
  },
  question_text_color_desc: {
    id: 'Theme.QuestionTextColorDesc',
    defaultMessage: 'Font Color of Question Cards',
  },
  result_background_color_desc: {
    id: 'Theme.ResultBackgroundColorDesc',
    defaultMessage: 'Background Color of Result Card',
  },
  result_text_color_desc: {
    id: 'Theme.ResultTextColorDesc',
    defaultMessage: 'Font Color of Result Card',
  },
  result_desc_color_desc: {
    id: 'Theme.ResultDescColorDesc',
    defaultMessage: 'Font Color of Result Card Description Section ',
  },
  mp_info_color_desc: {
    id: 'Theme.MpInfoColorDesc',
    defaultMessage: 'Multipunch Info Color ',
  },
  positive_color_desc: {
    id: 'Theme.PositiveColorDesc',
    defaultMessage: 'Succes lead message and true answer color',
  },
  negative_color_desc: {
    id: 'Theme.NegativeColorDesc',
    defaultMessage: 'Error lead message and false answer color',
  },
  footer_background_color_desc: {
    id: 'Theme.FooterBackgroundColorDesc',
    defaultMessage: 'Background color of Footer ',
  },
  card_shadow_desc: {
    id: 'Theme.CardShadowDesc',
    defaultMessage: 'Main Card Shadow Options',
  },
  card_border_radius_desc: {
    id: 'Theme.CardBorderRadiusDesc',
    defaultMessage: 'Main Card Border Radius',
  },
  bar_thickness: {
    id: 'Theme.BarThickness',
    defaultMessage: 'Progress Bar Thickness',
  },
  question_load_time: {
    id: 'Theme.QuestionLoadTime',
    defaultMessage: 'Question Load Time (ms)',
  },
  question_load_time_desc: {
    id: 'Theme.QuestionLoadTimeDesc',
    defaultMessage:
      'Time to Next Question (ms). It must be between 1000 and 10000',
  },
  background_size: {
    id: 'Theme.BackgroundSize',
    defaultMessage: 'Background Size',
  },
  background_size_desc: {
    id: 'Theme.BackgroundSizeDesc',
    defaultMessage: 'Size of the background image',
  },
  background_repeat: {
    id: 'Theme.BackgroundRepeat',
    defaultMessage: 'Background Repeat',
  },
  background_repeat_desc: {
    id: 'Theme.BackgroundRepeatDesc',
    defaultMessage: 'How background images are repeated',
  },
  gtm_id: {
    id: 'Theme.GtmId',
    defaultMessage: 'Gtm Id',
  },
  gtm_id_desc: {
    id: 'Theme.GtmIdDesc',
    defaultMessage:
      'If you want to use Google Tag Manager on your widgets, you can enter your GTM ID here and we will handle the code injection for you.',
  },
  gtm_dl: {
    id: 'Theme.GtmDl',
    defaultMessage: 'Gtm DataLayer Name',
  },
  gtm_dl_desc: {
    id: 'Theme.GtmDlDesc',
    defaultMessage:
      'If you want to use Custum GTM function name you can enter here.',
  },
  disc_msg: {
    id: 'Theme.DiscMsg',
    defaultMessage: 'Disclamimer Message',
  },
  disc_msg_desc: {
    id: 'Theme.DiscMsg',
    defaultMessage: 'You can change disclaimer text from here',
  },
  disc_display_option: {
    id: 'Theme.DiscDisplayOption',
    defaultMessage: 'Disclaimer Display Option',
  },
  disc_display_option_desc: {
    id: 'Theme.DiscDisplayOptionDesc',
    defaultMessage: 'Show Disclaimer Buttons (Accept and Reject buttons)',
  },
  disc_switch_desc: {
    id: 'Theme.DiscSwitchDesc',
    defaultMessage: 'Show Disclaimer Buttons',
  },
  DefaultTheme: {
    id: 'Theme.DefaultTheme',
    defaultMessage: 'Default Theme',
  },
  Custom: {
    id: 'Theme.Custom',
    defaultMessage: 'Custom',
  },
  show_pb_on_bottom: {
    id: 'Theme.ShowPbOnBottom',
    defaultMessage: 'Progress Bar On Bottom',
  },
  show_pb_on_bottom_desc: {
    id: 'Theme.ShowPbOnBottomDesc',
    defaultMessage: 'Show Progress Bar on Bottom',
  },
  show_pb_on_top: {
    id: 'Theme.ShowPbOnTop',
    defaultMessage: 'Progress Bar On Top',
  },
  show_pb_on_top_desc: {
    id: 'Theme.ShowPbOnTopDesc',
    defaultMessage: 'Show Progress Bar on Top',
  },
});
