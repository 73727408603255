import { useEffect, useMemo, useState } from 'react';
import isLocalStorageAvailable from './isLocalStorageAvailable';

function usePersistentStore(initialValue: any, key: string) {
  const [value, setValue] = useState(() => {
    if (!isLocalStorageAvailable()) {
      return initialValue;
    }

    const storedValue = localStorage.getItem(key);
    if (storedValue === null || storedValue === undefined) {
      localStorage.setItem(key, initialValue);
      return initialValue;
    }
    return storedValue;
  });
  const canUseLocalStorage = useMemo<boolean>(
    () => isLocalStorageAvailable(),
    []
  );

  useEffect(() => {
    let storedValue = canUseLocalStorage
      ? localStorage.getItem(key)
      : initialValue;

    if (storedValue === null || storedValue === undefined) {
      // if no stored value, set it to the initial value
      localStorage.setItem(key, initialValue);
      storedValue = initialValue;
    }

    if (storedValue) {
      setValue(storedValue);
    }
  }, [key]);

  function handleChange(newValue: any) {
    setValue((prev: any) => {
      if (prev === newValue) {
        return prev;
      }

      if (canUseLocalStorage) {
        localStorage.setItem(key, newValue ?? '');
      }

      return newValue;
    });
  }

  return [value, handleChange];
}

export default usePersistentStore;
