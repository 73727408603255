export function ensureARGs(...args: any[]) {
  const errormsg: number[] = args.reduce((acc, val, idx: number) => {
    if (val === undefined || val === null) {
      return [...acc, idx];
    }

    return acc;
  }, []);

  if (errormsg.length === 0) {
    return;
  }

  throw Error(
    `Following positional args cannot be empty: ${errormsg.join(', ')}`
  );
}
