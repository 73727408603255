import { injectIntl } from 'react-intl';
import { TrashIcon } from '@heroicons/react/24/solid';
import messages from './messages';

const TokenTable = ({ tokens, handleDeleteClick, intl: { formatMessage } }) => {
  return (
    <div className="flex flex-col py-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase lg:flex"
                  >
                    {formatMessage(messages.Name)}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.ExpiresAt)}
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    {formatMessage(messages.TokenIndentifer)}
                  </th>
                  <th scope="col" className="relative px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {tokens.map((t, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
                      <div className="text-sm font-medium text-gray-900">
                        {t.client.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {t.expires_at}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {t.token}
                      </div>
                    </td>
                    <td className="px-6 pl-4 pr-2 text-md text-gray-500 whitespace-nowrap text-right">
                      <div className="z-0 w-full py-2">
                        <span className="relative inline-flex">
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteClick(t.id);
                            }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <TrashIcon
                              className="w-5 h-5 mr-2 -ml-1 text-red-400"
                              aria-hidden="true"
                            />
                          </a>
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TokenTable);
