import React, { useContext } from 'react';

import { injectIntl } from 'react-intl';
import {
  SwitchInput,
  TextInput,
  LeadInput,
  PixelCodeInput,
} from '../../../../Common/Form';
import { EditorCTX } from '../../../context';
import messages from './messages';
const ResultConf = ({ intl: { formatMessage } }) => {
  const {
    data,
    confField,
    activeResultIdx: ridx,
    set,
    showCreateNewLead,
    showCreateNewPixelCode,
  } = useContext(EditorCTX);

  const result = data?.results?.[ridx];

  return (
    <>
      <div className="pb-2">
        <TextInput
          key={`${confField}-min-answers`}
          name="min_c"
          label={formatMessage(messages.MinScore)}
          type="number"
          value={result?.min_c || 0}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`results.${ridx}.min_c`, value);
          }}
          description={formatMessage(messages.MinScoreDesc)}
        />
      </div>
      <div className="pb-2">
        <TextInput
          key={`${confField}-max-answers`}
          name="max_c"
          label={formatMessage(messages.MaxScore)}
          type="number"
          value={result?.max_c || 0}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`results.${ridx}.max_c`, value);
          }}
          description={formatMessage(messages.MaxScoreDesc)}
        />
      </div>
      <div className="pb-2">
        <TextInput
          key={`${confField}-url`}
          name="url"
          label={'URL'}
          value={result?.url || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`results.${ridx}.url`, value);
          }}
          description={formatMessage(messages.UrlDesc)}
        />
      </div>
      <div className="pb-2">
        <TextInput
          key={`${confField}-url_text`}
          name="url_text"
          label={formatMessage(messages.UrlText)}
          value={result?.url_text || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`results.${ridx}.url_text`, value);
          }}
          description={formatMessage(messages.UrlTextDesc)}
        />
      </div>
      <div className="pb-2">
        <TextInput
          key={`${confField}-luv`}
          name="luv"
          label={formatMessage(messages.LeadUrlVariable)}
          value={result?.luv || ''}
          debounce={{ timeout: 1000 }}
          onChange={(value) => {
            set(`results.${ridx}.luv`, value);
          }}
          description={formatMessage(messages.LeadUrlVariableDesc)}
        />
      </div>
      <SwitchInput
        key={`${confField}-dont_shorten`}
        value={result?.dont_shorten || 0}
        onSwitch={(value) => {
          set(`results.${ridx}.dont_shorten`, value ? 1 : 0);
        }}
        description={formatMessage(messages.DontShortenDesc)}
      >
        {formatMessage(messages.DontShorten)}
      </SwitchInput>
      <PixelCodeInput
        pixelCode={result?.pixel_code ?? ''}
        onSelect={(pixelCode) => {
          set(`results.${ridx}.pixel_code`, {
            id: pixelCode.id,
            name: pixelCode.name,
          });
        }}
        onDelete={() => {
          set(`results.${ridx}.pixel_code`, null);
        }}
        fromResult
      />
      <div className="">
        <PixelCodeInput
          pixelCode={result?.click_pixel_code ?? ''}
          onSelect={(pixelCode) => {
            set(`results.${ridx}.click_pixel_code`, {
              id: pixelCode.id,
              name: pixelCode.name,
            });
          }}
          onDelete={() => {
            set(`results.${ridx}.click_pixel_code`, null);
          }}
          onNew={() => {
            showCreateNewPixelCode();
          }}
          clickPixelCode
          fromResult
        />
      </div>
      <LeadInput
        leadId={result?.lead?.id ?? ''}
        onSelect={(lead) => {
          set(`results.${ridx}.lead.id`, lead.id);
        }}
        onDelete={() => {
          set(`results.${ridx}.lead.id`, null);
        }}
        onNew={() => {
          showCreateNewLead();
        }}
      />
      <SwitchInput
        key={`${confField}-is_default`}
        value={result?.is_default || 0}
        onSwitch={(value) => {
          set(`results.${ridx}.is_default`, value ? 1 : 0);
        }}
        description={formatMessage(messages.DefaultResultDesc)}
      >
        {formatMessage(messages.DefaultResult)}
      </SwitchInput>
    </>
  );
};

export default injectIntl(ResultConf);
