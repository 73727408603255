import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  MinScore: {
    id: 'Editor.Conf.MinScore',
    defaultMessage: 'Min Score',
  },
  MaxScore: {
    id: 'Editor.Conf.MaxScore',
    defaultMessage: 'Max Score',
  },
  UrlText: {
    id: 'Editor.Conf.UrlText',
    defaultMessage: 'Url Text',
  },
  LeadUrlVariable: {
    id: 'Editor.Conf.LeadUrlVariable',
    defaultMessage: 'Lead Url Variable',
  },
  DontShorten: {
    id: 'Editor.Conf.DontShorten',
    defaultMessage: `Don't Shorten Link`,
  },
  AllowMP: {
    id: 'Editor.Question.Conf.AllowMP',
    defaultMessage: 'Allow Multiple Answers',
  },
  MaxNumber: {
    id: 'Editor.Question.Conf.MaxNumber',
    defaultMessage: 'Max number of answers',
  },
  Autocomplete: {
    id: 'Editor.Question.Conf.Autocomplete',
    defaultMessage: 'Autocomplete',
  },
  AutocompleteHelp: {
    id: 'Editor.Question.Conf.AutocompleteHelp',
    defaultMessage: 'Autocomplete Help',
  },
  AutocompletePlaceholder: {
    id: 'Editor.Question.Conf.AutocompletePlaceholder',
    defaultMessage: 'Autocomplete Placeholder',
  },
  ShuffleRow: {
    id: 'Editor.Question.Conf.SuffleRow',
    defaultMessage: 'Shuffle Row',
  },
  ShuffleExcept: {
    id: 'Editor.Question.Conf.ShuffleExcept',
    defaultMessage: 'Shuffle Except Last Answer',
  },
  LeadUrlVar: {
    id: 'Editor.Question.Conf.LeadUrlVar',
    defaultMessage: 'Lead URL Variable',
  },
  DefaultCalVal: {
    id: 'Editor.Question.Conf.DefaultCalVal',
    defaultMessage: 'Default Calculator Value',
  },
  NextQuestion: {
    id: 'Editor.Question.Conf.NextQuestion',
    defaultMessage: 'Next Question',
  },
  PreparingResult: {
    id: 'Editor.Question.Conf.PreparingResult',
    defaultMessage: 'Preparing Result',
  },
  LoadingNextQuestionLabel: {
    id: 'Editor.Question.Conf.LoadingNextQuestionLabel',
    defaultMessage: 'Loading Next Question Label',
  },
  LoadingResultLabel: {
    id: 'Editor.Question.Conf.LoadingResultLabel',
    defaultMessage: 'Loading Result Label',
  },
  SkipQuestion: {
    id: 'Editor.Question.Conf.SkipQuestion',
    defaultMessage: 'Skip Question',
  },
  DefaultResult: {
    id: 'Editor.Conf.DefaultResult',
    defaultMessage: 'Default Result',
  },
  DefaultResultDesc: {
    id: 'Editor.Conf.DefaultResultDesc',
    defaultMessage:
      'If you mark a result as default, it will be shown to users if there are no results found to shown via default way (eg: no results matched the search filters or no results targetting the score.)',
  },
  PopularAnswersCount: {
    id: 'Editor.Question.Conf.PopularAnswersCount',
    defaultMessage: 'Popular Answers Count',
  },
  MPDesc: {
    id: 'Editor.Question.Conf.MPDesc',
    defaultMessage: 'Use allows voting on multiple answers.',
  },
  MPCountDesc: {
    id: 'Editor.Question.Conf.MPCountDesc',
    defaultMessage: 'Maximum number of answers that can be voted.',
  },
  AutocompleteDesc: {
    id: 'Editor.Question.Conf.AutocompleteDesc',
    defaultMessage:
      'Displays an input where we recommend the pre-defined answers while the user is typing, useful if you have between 15 to 1000 answers.',
  },
  PopularAnswersDesc: {
    id: 'Editor.Question.Conf.PopularAnswersDesc',
    defaultMessage:
      'Number of answers to be shown to the user from the answers with the most votes.',
  },
  AutocompleteHelpDesc: {
    id: 'Editor.Question.Conf.AutoCompleteHelpDesc',
    defaultMessage: 'Helper text that the user will see.',
  },
  AutocompletePlaceholderDesc: {
    id: 'Editor.Question,Conf.AutocompletePlaceholderDesc',
    defaultMessage: 'Placeholder of answer input.',
  },
  SkipQuestionDesc: {
    id: 'Editor.Question.Conf.SkipQuestionDesc',
    defaultMessage:
      'The user can skip on to the next question without answering the question.',
  },
  ShuffleRowDesc: {
    id: 'Editor.Question.Conf.ShuffleRowDesc',
    defaultMessage: 'Answers display in random order for each user.',
  },
  ShuffleExceptDesc: {
    id: 'Editor.Question.Conf.ShuffleExceptDesc',
    defaultMessage: 'Answers display in reverse order.',
  },
  LeadUrlVarDesc: {
    id: 'Editor.Question.Conf.LeadUrlVarDesc',
    defaultMessage: 'The parameter to be added to the result url.',
  },
  DefaultCalValDesc: {
    id: 'Editor.Question.Conf.DefaultCalValDesc',
    defaultMessage:
      'If you are using a calculator set, the default calculator value that will be added to all answers in this question.',
  },
  LoadingNextQuestionDesc: {
    id: 'Editor.Conf.LoadingNextQuestionDesc',
    defaultMessage:
      'The message the user will see when moving to the next question',
  },
  LoadingResultDesc: {
    id: 'Editor.Conf.LoadingResultDesc',
    defaultMessage:
      'The loading message end users will see while we process the result screen.',
  },
  MinScoreDesc: {
    id: 'Editor.Conf.MinScoreDesc',
    defaultMessage:
      'The result is shown to users who have received the minimum score you set.',
  },
  MaxScoreDesc: {
    id: 'Editor.Conf.MaxScoreDesc',
    defaultMessage:
      'The result is shown to users who have received the maximum score you set.',
  },
  UrlDesc: {
    id: 'Editor.Conf.UrlDesc',
    defaultMessage:
      'As a result, the button url to which you want to redirect the user.',
  },
  UrlTextDesc: {
    id: 'Editor.Conf.UrlTextDesc',
    defaultMessage: 'The text of the button on the result screen.',
  },
  LeadUrlVariableDesc: {
    id: 'Editor.Conf.LeadUrlVariableDesc',
    defaultMessage:
      'Query string to be added to the result url after the user click for this result',
  },
  DontShortenDesc: {
    id: 'Editor.Conf.DontShortenDesc',
    defaultMessage:
      'We use our short link service by default to make links appear shorter and track clicks. Activate this if you do not want to use this service or want to use your own service. If you disable our short link service we will not be able to track links.',
  },
  CannotUse: {
    id: 'Editor.Conf.CannotUse',
    defaultMessage: 'This feature cannot be used if the content type is quiz.',
  },
});
