import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  ErrorOccured: {
    id: 'DynWidget.ErrorOccured',
    defaultMessage: 'Error occured!',
  },
  SureDelete: {
    id: 'DynWidget.SureDelete',
    defaultMessage: 'Are you sure you want to delete this dynamic widget?',
  },
  Close: {
    id: 'DynWidget.Close',
    defaultMessage: 'Close',
  },
  Search: {
    id: 'DynWidget.Search',
    defaultMessage: 'Search',
  },
  ErrorSaving: {
    id: 'DynWidget.ErrorSaving',
    defaultMessage: 'Error occured while saving!',
  },
  ErrorNameEmpty: {
    id: 'DynWidget.ErrorNameEmpty',
    defaultMessage: 'Name can not be empty.',
  },
  EnterValidUrl: {
    id: 'DynWidget.EnterValidUrl',
    defaultMessage: 'Please enter a valid url!',
  },
  ClosePanel: {
    id: 'DynWidget.ClosePanel',
    defaultMessage: 'Close Panel',
  },
  Name: {
    id: 'DynWidget.Name',
    defaultMessage: 'Name',
  },
  Cancel: {
    id: 'DynWidget.Cancel',
    defaultMessage: 'Cancel',
  },
  Save: {
    id: 'DynWidget.Save',
    defaultMessage: 'Save',
  },
  ContentId: {
    id: 'DynWidget.ContentId',
    defaultMessage: 'Content ID',
  },
  DynWidgetDesc: {
    id: 'DynWidget.DynWidgetDesc',
    defaultMessage: 'Dynamic Widgets Desc',
  },
  SelectContent: {
    id: 'DynWidget.SelectContent',
    defaultMessage: 'Select your content for DynWidget',
  },
  Edit: {
    id: 'DynWidget.Edit',
    defaultMessage: 'Edit',
  },
  Deleted: {
    id: 'DynWidget.Deleted',
    defaultMessage: 'Dynamic Widget Deleted.',
  },
  DynWidget: {
    id: 'DynWidget.DynWidget',
    defaultMessage: 'Dynamic Widgets',
  },
  Saved: {
    id: 'DynWidget.Saved',
    defaultMessage: 'Dynamic Widget Saved.',
  },
  UrlHas: {
    id: 'DynWidget.UrlHas',
    defaultMessage: 'Url already has a widget',
  },
  Update: {
    id: 'DynWidget.Update',
    defaultMessage: 'Update Dynamic Widget',
  },
  CreateNew: {
    id: 'DynWidget.CreateNew',
    defaultMessage: 'Create New Dynamic Widget',
  },
  New: {
    id: 'DynWidget.New',
    defaultMessage: 'New Dynamic Widget',
  },
  AllPages: {
    id: 'DynWidget.AllPages',
    defaultMessage: 'For All Pages',
  },
  SpecificPage: {
    id: 'DynWidget.SpecificPage',
    defaultMessage: 'Specific Page',
  },
  MoreInfo: {
    id: 'DynWidget.MoreInfo',
    defaultMessage: 'For More Information',
  },
  Docs: {
    id: 'DynWidget.Docs',
    defaultMessage: 'Documentation',
  },
  NameDesc: {
    id: 'DynWidget.NameDesc',
    defaultMessage:
      'Give your dynamic widget a friendly name so you can find it easier later. This will have no effect in the working of dynamic widget.',
  },
  ContentDesc: {
    id: 'DynWidget.ContentDesc',
    defaultMessage:
      'Select the content you want to display dynamically on your page.',
  },
  UrlDesc: {
    id: 'DynWidget.UrlDesc',
    defaultMessage:
      'The URL of your page where you want the Dynamic Widget to be displayed.',
  },
});
