import React from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../Common/Button';

const Param = ({ onClick, label, help, example }) => {
  return (
    <div className="py-2 max-w-md px-4">
      <Button.Success type="button" className="self-end" onClick={onClick}>
        <PlusCircleIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
        {label}
      </Button.Success>
      <p className="mt-2 text-xs text-gray-400">{help}</p>
      <p className="p-2 mt-2 text-xs text-gray-300 bg-gray-600 bg-opacity-80 rounded-lg">
        https://youradtrackingsoftware.com/?
        <span className="font-bold text-green-400 ">{example}</span>
      </p>
    </div>
  );
};

export default Param;
