import React, { useCallback, useEffect, useState } from 'react';
import SearchField from '.';

import { getLead, getLeadsPaginated } from '../../../api/leads';

const LeadSearchRowRenderer = ({ item }) => {
  return (
    <span className="flex">
      <span className="truncate">{item.name}</span>
    </span>
  );
};

const LeadSearch = ({ leadId, leadName, onSelect = (value) => {} }) => {
  const [name, setName] = useState(leadName);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 100,
    lastPage: null,
  });

  const fetchData = useCallback(
    async (isDirty, setDisplayContents, setDisplayText) => {
      try {
        const response = await getLeadsPaginated(
          pageData.page,
          pageData.sizePerPage
        );
        const leads = response.data.data;

        setDisplayContents(leads);
        setPageData((prev) => ({
          page: prev.page,
          sizePerPage: prev.sizePerPage,
          totalSize: response.data.total,
          lastPage: response.data.last_page,
        }));
      } catch (err) {
        console.log(err);
      }
    },
    [pageData.page, pageData.sizePerPage]
  );

  useEffect(() => {
    (async () => {
      if (leadName) {
        setName(leadName);
        return;
      }
      if (leadId) {
        try {
          const response = await getLead(leadId);
          const lead = response.data;

          setName(lead.name);
        } catch (error) {
          console.error(error);
        }
        return;
      }

      setName('');
    })();
  }, [leadName, leadId]);

  const onItemChange = (lead, setDisplayText) => {
    setDisplayText(lead.name);
    onSelect(lead);
  };

  const onInputChange = (value, setDisplayText) => {
    setDisplayText(value);
  };

  return (
    <SearchField
      defaultMessage={''}
      displayValue={name}
      fetchData={fetchData}
      onItemChange={onItemChange}
      onInputChange={onInputChange}
      rowRenderer={LeadSearchRowRenderer}
    />
  );
};

export default LeadSearch;
