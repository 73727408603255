import api from '../client';

export type DataSourceType = 'csv' | 'json' | 'xml';
export type DataSourceStatus =
  | 'draft'
  | 'review'
  | 'removed'
  | 'rejected'
  | 'pending'
  | 'processing'
  | 'failed'
  | 'synced';
export type DataSourceElementType =
  | 'generic'
  | 'id'
  | 'gtin'
  | 'name'
  | 'condition'
  | 'description'
  | 'price'
  | 'sale_price'
  | 'image'
  | 'url'
  | 'brand'
  | 'product_type';
export type DataSourceElement = {
  id: number;
  data_source_id: number;
  data_source_item_namespace_id: number | null;
  element: string;
  slug: string;
  type: DataSourceElementType;
  created_at: string;
  deleted_at: string;
  is_array: boolean | 0 | 1;
  save_all_matched_arrays: boolean | 0 | 1;
  array_first_level: any | null;
  array_first_path: any | null;
  array_first_key: any | null;
  array_first_value: any | null;
  attribute: any | null;
  attribute_value_match: any | null;
};
export type DataSource = {
  id: string;
  name: string;
  type: DataSourceType;
  source: string;
  status: DataSourceStatus;
  created_at: string;
  updated_at: string;
  data_source_item_elements: DataSourceElement[];
};
export function get(id: string | number) {
  return api.get<DataSource>(`/platform/data-sources/${id}`);
}
