import * as editor from '../../../../../../api/editor';
import { applyMixins } from '../../../../../../core/mixins';

import { merge } from '../../helpers/merge';
import { proxyHandler } from '../../helpers/proxyHandler';
import Entity from '../Entity';

import { QuestionTypes } from '../../../../enums';
import QuestionMockMixin from './QuestionMockMixin';
import type { Answer } from '../Answer';
import { New as NewAnswer } from '../Answer';
import QuestionDuplicateMixin from './QuestionDuplicateMixin';
import QuestionSaveMixin from './QuestionSaveMixin';

import type { Content } from '../Content';
import type Dict from '../types/Dict';

const QuestionPayloadFields = {
  required: ['answer_type', 'title'],
  allowed: {
    answer_type: 'string',
    title: 'string',
    background: 'string',
    allow_multiple_answers: 'boolean',
    max_multi_punch_answer: 'number',
    recommended_popular_answer: 'number',
    rotate_answers: 'boolean',
    rotate_answers_last: 'boolean',
    luv: 'string',
    is_searchable: 'boolean',
    cal_val_default: 'string',
    autocomplete_help: 'string',
    autocomplete_placeholder: 'string',
    position: 'number',
    conditions: 'string',
    condition_reverse: 'boolean',
    is_skippable: 'boolean',
    // clone_answers_from: 'number',
  },
};

const QuestionDuplicatableFields = [
  'answer_type',
  'title',
  'background',
  'allow_multiple_answers',
  'max_multi_punch_answer',
  'rotate_answers',
  'rotate_answers_last',
  'luv',
  'is_searchable',
  'cal_val_default',
  'autocomplete_help',
  'autocomplete_placeholder',
  'position',
  'condition_reverse',
  'is_skippable',
  'clone_answers_from',
];

const isQuestion = (question: any): question is Question =>
  question instanceof Question;

interface Question
  extends Entity,
    QuestionMockMixin,
    QuestionDuplicateMixin<Question>,
    QuestionSaveMixin {}

class Question {
  _duplicatableFields: string[];
  _payloadFields: any;

  // reference to parent instance
  _content: Content;

  // changes list
  _changes: Dict = {};
  // errors list
  _errors: Dict = {};

  // answers
  _answers: Answer[] = [];

  // auxiliary fields
  _clone_answers_from: any | undefined = undefined;
  _clone_answers_from_ref: Question | undefined = undefined;
  _clone_answers_from_idx: number | undefined = undefined;
  _clone_answers_from_uid: string | undefined = undefined;

  // BEGIN: data properties
  id?: number;
  user_id?: number;
  title?: string;
  tag_id?: number;
  vote_count?: number;
  // Total view count for the content, only accurate if the requester owns the content. Otherwise 0 will return
  view_count?: number;
  // Unique voter count. Only available when the requester is also owner of the question.
  unique_voter?: number;

  comment_count?: number;
  sub_count?: number;
  privacy?: 'public' | 'private';
  status?: 'draft' | 'published' | 'unpublished';
  answer_type: 'text' | 'media' | 'score' | 'yesno' = 'text';

  slug?: string;
  // calculates if the end_date is in the past on the server
  is_ended?: number;
  is_suggested?: number;

  // if 1 this question can allow multiple votes
  // TODO: could this be a boolean? Enum not stated in docs
  allow_multiple_answers?: number;

  // How many answers can a user vote for a multi punch question
  max_multi_punch_answer?: number;
  recommended_popular_answer?: number;
  link_html?: string;

  // If 1, user needs to login before voting.
  // TODO: could this be a boolean? Enum not stated in docs
  require_login?: number;

  has_leads?: number;

  // If 1, this content must only be visible on mobile devices.
  // TODO: could this be a boolean? Enum not stated in docs
  mobile_only?: number;

  // N minutes for changing answers allowed
  answer_time_limit?: number;

  // If 1, vote percentages wont be displayed after voting.
  // TODO: could this be a boolean? Enum not stated in docs
  hide_results?: number;

  // If 1, total number of people voted will not be displayed.
  // TODO: could this be a boolean? Enum not stated in docs
  hide_counter?: number;

  auto_reports_enabled?: number;
  post_type?: 'poll' | 'test' | 'quiz';

  // If 1, after voting for this question, it will give some feedback. (ex: Right/Wrong answer.)
  // TODO: could this be a boolean? Enum not stated in docs
  gives_feedback?: number;

  // If 1, while displaying answers, it should be in random order.
  // TODO: could this be a boolean? Enum not stated in docs
  rotate_answers?: number;

  // If 1, while displaying answers, it should be in random order except the last answer
  // TODO: could this be a boolean? Enum not stated in docs
  rotate_answers_last?: number;

  lang?: string;

  // indicates if the requester already voted for this question or not.
  // TODO: could this be a boolean? Enum not stated in docs
  voted?: number;

  share_count_facebook?: number; // deprecated?
  share_count_twitter?: number; // deprecated?
  display_ads?: number; // TODO: deprecated? // Decides whether to show ad_code on site.
  embed_ads?: number; // TODO: deprecated? // Decides whether to show ad_code on embeds.

  play_once?: number; // TODO: deprecated? boolean? // If 1, same token can't get the voted/played content twice.
  play_once_strategy?: 'result' | 'start'; // TODO: deprecated? // Defines which method should be used while deciding play once.
  play_once_msg?: string; // TODO: deprecated? // Custom play once message.
  play_once_link?: string; // TODO: deprecated? // Custom play once link.
  play_once_img?: string; // TODO: deprecated? // Custom play once image.
  play_once_btn?: string; // TODO: deprecated? // Custom play once button value

  // Remaining time in days to the end date. Only available to content owner in edit calls.
  end_date_day?: number; // TODO: deprecated?
  // Remaining time in hours to the end date. Only available to content owner in edit calls.
  end_date_hour?: number; // TODO: deprecated?
  // Remaining time in minutes to the end date. Only available to content owner in edit calls.
  end_date_minute?: number; // TODO: deprecated?

  cal_val_default?: number; // Default calculator value for this question if no answer is voted

  is_searchable?: boolean;
  is_skippable?: boolean;
  is_connected?: boolean;

  autocomplete_help?: string; // Autocomplete answer type help text
  autocomplete_placeholder?: string; // Autocomplete answer type placeholder text.

  // following are not in the docs, but are in the payload
  position?: number;

  created_at?: string;
  updated_at?: string;
  // END

  use_answers = false;

  constructor(question: any, content: Content) {
    this._payloadFields = QuestionPayloadFields;
    this._duplicatableFields = QuestionDuplicatableFields;

    this._content = content;

    merge(this, question);
  }

  // TODO: resolve this better...
  public create(data: any, ref: Content): Question {
    return new Question(data, ref);
  }

  public get clone_answers_from() {
    return this._clone_answers_from_ref?.id ?? this?._clone_answers_from;
  }
  public set clone_answers_from(id: number | undefined) {
    // value is the ID of the source question where
    //   answers will be cloned from

    this._clone_answers_from = id;
    this._clone_answers_from_idx = id;
    this._clone_answers_from_ref = id
      ? this._content?.questions?.[id]
      : undefined;
    this._clone_answers_from_uid = this._clone_answers_from_ref?.__uid;
  }

  public get clone_answers_from_uid(): string | undefined {
    return this._clone_answers_from_uid;
  }
  public set clone_answers_from_uid(uid: string | undefined) {
    // value is the UID of the source question where
    //   answers will be cloned from

    this._clone_answers_from_uid = uid;
    this._clone_answers_from_idx = this._content?.questions?.findIndex(
      (q) => q.__uid === uid
    );
    this._clone_answers_from_ref =
      this._content?.questions?.[this._clone_answers_from_idx];
    this._clone_answers_from = this._clone_answers_from_ref?.id;
  }

  public getCloneAnswersFromIdx(): number {
    if (!this._clone_answers_from) {
      return -1;
    }

    return this._content?.questions?.findIndex(
      (q) => q.id === this._clone_answers_from
    );
  }

  public get answers(): Answer[] {
    // if this is a 'clone_answers_from_ref' question and no 'answers'
    if (
      !!this._clone_answers_from_ref &&
      (!this._answers || this._answers.length === 0)
    ) {
      // clone answers from ref
      this._answers = this._clone_answers_from_ref.answers.map((answer) =>
        answer.duplicate()
      );
    }

    return this._answers;
  }
  public set answers(answers: Answer[]) {
    this._answers = answers.map((answer) =>
      NewAnswer(answer, this as Question)
    );
  }

  public get display_answer_type(): string {
    return this?._clone_answers_from_ref
      ? this._clone_answers_from_ref?.answer_type
      : this.answer_type;
  }
  public getDisplayAnswerType() {
    return QuestionTypes[
      (this?.display_answer_type ?? 'media') as keyof typeof QuestionTypes
    ];
  }
  public getAnswerType() {
    return QuestionTypes[
      (this?.answer_type ?? 'media') as keyof typeof QuestionTypes
    ];
  }
  public isUseAnswers() {
    return (this?.use_answers || this?.clone_answers_from) ?? false;
  }
  public hasAnswerIdx(index: number) {
    return !!this._answers?.[index];
  }

  _conditionUIDs: string[] = [];
  _conditions: string = '';

  private constructConditionIDArray(): string[] {
    const ids = this._content.questions.reduce((qacc: any[], question) => {
      const aids = question.answers.reduce((aacc: any[], answer: Answer) => {
        if (this._conditionUIDs.includes(answer.__uid)) {
          aacc.push(answer.id);
        }
        return aacc;
      }, []);
      return [...qacc, ...aids];
    }, []);
    return ids;
  }
  private constructConditionIDString(): string {
    const ids = this.constructConditionIDArray();
    return ids.join(',');
  }

  public get conditionUIDs() {
    if (this._conditions && this._conditionUIDs.length === 0) {
      this.conditions = this._conditions;
    }

    return this._conditionUIDs.join(',');
  }
  public set conditionUIDs(value: string | null | undefined) {
    this._conditionUIDs = (value ?? '').split(',');

    // construct condition public ids from conditionUIDs
    const ids = this.constructConditionIDString();
    this._conditions = ids;
  }

  public get conditions() {
    if ((this._conditionUIDs ?? []).length === 0) {
      return '';
    }

    // reconstruct condition public ids from conditionUIDs
    const ids = this.constructConditionIDString();
    return (this._conditions = ids);
  }
  public set conditions(value) {
    this._conditions = (value ?? '').trim();

    // clear conditions
    if (this._conditions.length === 0) {
      this._conditionUIDs = [];
      return;
    }

    // construct conditionUIDs from condition public ids
    const ids = value.split(',');
    const uids = this._content.questions.reduce((qacc: any[], question) => {
      const auids = question.answers.reduce((aacc: any[], answer: Answer) => {
        if (ids.includes(`${answer.id}`)) {
          aacc.push(answer.__uid);
        }
        return aacc;
      }, []);
      return [...qacc, ...auids];
    }, []);

    // set conditionUIDs
    this._conditionUIDs = uids;
  }

  public async save() {
    const isCreate = !this.id;

    if (isCreate || this._isDirty) {
      try {
        const response = await editor.question.saveQuestion(
          this,
          this._content.public_id,
          this.id
        );

        // TODO: Find a better  way for this
        // pop 'clone_answers_from' from response, as it overrides
        //   change to this field if a change exists to it.
        if (this?._changes?.['clone_answers_from_uid']) {
          delete response.data['clone_answers_from'];
        }

        // merge with response
        merge(this, response.data);

        // unmark changes
        Object.keys(this)
          .filter((key) => key in this._payloadFields.allowed)
          .forEach((field) => {
            this._changes[field] = false;
          });
      } catch (error) {
        console.error(error);
      }
    }

    if (this?._changes?.['clone_answers_from_uid']) {
      try {
        const response = await editor.question.saveCloneAnswersFrom(
          this._content.public_id,
          this.clone_answers_from,
          this.id
        );

        // merge with response
        merge(this, { answers: response.data });

        // unmark change
        this._changes['clone_answers_from_uid'] = false;
      } catch (error) {
        console.error(error);
      }
    }

    this._isDirty = false;
  }
}

applyMixins(Question, [
  Entity,
  QuestionMockMixin,
  QuestionDuplicateMixin<Question>,
  QuestionSaveMixin,
]);

export function New(question: any, content: Content): Question {
  if (question instanceof Question) {
    return question;
  }
  if (isQuestion(question)) {
    return question;
  }

  if (!content) {
    throw new Error('Content Ref is required');
  }

  const klass = new Question(question, content);
  const proxy = new Proxy<Question>(klass, proxyHandler);

  return proxy;
}

const _exports = { New };

export type { Question };
export default _exports;
