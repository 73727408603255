import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DndContext, useDroppable } from '@dnd-kit/core';
import { DragEndEvent, DragMoveEvent } from '@dnd-kit/core/dist/types';
import { select } from 'd3-selection';
import { ZoomTransform, zoomIdentity, zoom } from 'd3-zoom';
import Xarrow, { useXarrow } from 'react-xarrows';

import type { Arrow, Card, Content, Operation } from '../../core/types';
import Draggable from './Draggable';
import Loading from '../Common/Loading';
import { generateRandomColor } from '../../core/helper';
import { addCondition, removeCondition } from '../../api';
import Button from '../Common/Button';
import { NotificationsCTX } from '../../contexts/Notification';
import { ArrowUturnLeftIcon, CheckIcon } from '@heroicons/react/24/outline';
import { CloudCheck } from '../../images';
import { Link } from 'react-router-dom';

const getExistingArrows = (cards: Card[]) => {
  let tempArrowArray: Arrow[] = [];

  cards?.forEach((c) => {
    c.conditions?.forEach((cond: string) => {
      tempArrowArray.push({
        start: `${cond}_answer_temp`,
        end: `${c.id}_question`,
        color: generateRandomColor(),
      });
    });
  });

  return tempArrowArray;
};

const Canvas = ({
  cards,
  setCards,
  publicId,
  data,
}: {
  cards: Card[];
  setCards: (cards: Card[]) => void;
  publicId: string;
  data: Content | undefined;
}) => {
  const { error } = useContext(NotificationsCTX);

  const [transform, setTransform] = useState(zoomIdentity);
  const updateXarrow = useXarrow();
  const [tempArrow, setTempArrow] = useState<Arrow>({
    start: `0`,
    end: `0`,
    color: 'sky',
  });
  const [isDraggingArrow, setIsDraggingArrow] = useState<boolean>(false);
  const [existingArrows, setExistingArrows] = useState<Array<Arrow>>(
    getExistingArrows(cards)
  );
  const [saving, setSaving] = useState(false);
  const [undoOperation, setUndoOperation] = useState<Operation | null>(null);
  const [isDraggedAnswer, setIsDraggedAnswer] = useState<boolean>(false);
  const [isDraggedQuestionIndex, setIsDraggedQuestionIndex] =
    useState<number>(0);

  const updateDraggedCardPosition = async ({
    delta,
    active,
    collisions,
  }: DragEndEvent) => {
    if (!delta.x && !delta.y) return;

    setCards(
      cards.map((card) => {
        if (card.id === active.id) {
          return {
            ...card,
            coordinates: {
              x: card.coordinates.x + delta.x / transform.k,
              y: card.coordinates.y + delta.y / transform.k,
            },
          };
        }
        return card;
      })
    );

    if (collisions?.length && active.id.toString().includes('answer')) {
      setExistingArrows((s) => [
        ...s,
        {
          start: `${active.id}_temp`,
          end: `${collisions[0].id}`,
          color: generateRandomColor(),
        },
      ]);
      setTempArrow({ start: '0', end: '0', color: 'sky' });
      setSaving(true);

      try {
        const questionId = collisions[0].id.toString().split('_')[0];
        const answerId = active.id.toString().split('_')[0];
        await addCondition(publicId, questionId, parseInt(answerId, 10));

        setSaving(false);
        setUndoOperation({
          type: 'delete',
          publicId,
          questionId: parseInt(questionId, 10),
          answerId: parseInt(answerId, 10),
        });
      } catch (e) {
        setSaving(false);
        error('Error occured adding condition!');
        //remove the drawn arrow
        setExistingArrows((s) =>
          s.filter(
            (a) =>
              a.start !== `${active.id}_temp` && a.end !== `${collisions[0].id}`
          )
        );
      }
    }

    setIsDraggingArrow(false);
  };

  const { setNodeRef } = useDroppable({
    id: 'canvas',
  });

  const canvasRef = useRef<HTMLDivElement | null>(null);

  const updateAndForwardRef = (div: HTMLDivElement) => {
    canvasRef.current = div;
    setNodeRef(div);
  };

  // create the d3 zoom object, and useMemo to retain it for rerenders
  const zoomBehavior = useMemo(() => zoom<HTMLDivElement, unknown>(), []);

  // update the transform when d3 zoom notifies of a change
  const updateTransform = useCallback(
    ({ transform }: { transform: ZoomTransform }) => {
      setTransform(transform);
    },
    [setTransform]
  );

  useLayoutEffect(() => {
    if (!canvasRef.current) return;

    // get transform change notifications from d3 zoom
    zoomBehavior.on('zoom', updateTransform);

    // attach d3 zoom to the canvas div element, which will handle
    // mousewheel, gesture and drag events automatically for pan / zoom
    select<HTMLDivElement, unknown>(canvasRef.current).call(zoomBehavior);
  }, [zoomBehavior, canvasRef, updateTransform]);

  const dragMove = ({ delta, active, collisions }: DragMoveEvent) => {
    const answerId = active.id.toString().split('_')[0];
    const questionIndex = cards.findIndex((c) =>
      c.answers.map((a) => a.id).includes(parseInt(answerId, 10))
    );

    if (active.id.toString().includes('answer')) {
      setIsDraggedAnswer(true);
      setIsDraggedQuestionIndex(questionIndex);
      setIsDraggingArrow(true);

      setTempArrow({
        start: `${active.id}_temp`,
        end: `${active.id}`,
        color: 'sky',
      });
      updateXarrow();
    } else {
      setIsDraggedAnswer(false);
    }
  };

  const deleteCondition = async (questionId: number, answerId: number) => {
    if (window.confirm('Are you sure you want to delete this condition?')) {
      setExistingArrows((s) =>
        s.filter(
          (c) =>
            !(
              c.start === `${answerId}_answer_temp` &&
              c.end === `${questionId}_question`
            )
        )
      );

      setSaving(true);

      try {
        await removeCondition(publicId, questionId, answerId);
        setSaving(false);

        setUndoOperation({
          type: 'add',
          publicId,
          questionId,
          answerId,
        });
      } catch (e) {
        setSaving(false);
        error('Error occured removing condition!');
      }
    }
  };

  const undo = async () => {
    if (!undoOperation) return;

    const { type, publicId, questionId, answerId } = undoOperation;

    try {
      setSaving(true);

      switch (type) {
        case 'add':
          setExistingArrows((s) => [
            ...s,
            {
              start: `${answerId}_answer_temp`,
              end: `${questionId}_question`,
              color: generateRandomColor(),
            },
          ]);
          await addCondition(publicId, questionId, answerId);
          setUndoOperation(null);
          break;
        case 'delete':
          setExistingArrows((s) =>
            s.filter(
              (c) =>
                !(
                  c.start === `${answerId}_answer_temp` &&
                  c.end === `${questionId}_question`
                )
            )
          );
          await removeCondition(publicId, questionId, answerId);
          setUndoOperation(null);
          break;
        default:
          break;
      }

      setSaving(false);
    } catch (e) {
      setSaving(false);
      error('Error occured during operation!');
    }
  };

  return (
    <>
      <div className="py-4 flex justify-end mx-auto max-w-full mr-8">
        <Button.Secondary
          className="rounded-r-none"
          onClick={undo}
          disabled={!undoOperation || saving}
        >
          <ArrowUturnLeftIcon className="flex w-5 mr-2" />
          {'Undo'}
        </Button.Secondary>
        <Button.Secondary
          className="pointer-events-none rounded-none border-l-0 border-r-0"
          showSpinner={saving}
          disabled={!saving}
        >
          {!saving ? <CloudCheck className="flex w-5 mr-2" /> : null}
          {saving ? 'Saving' : 'Saved'}
        </Button.Secondary>
        <Link
          className="rounded-l-none poltio-btn poltio-btn-secondary flex"
          to={`/preview/${data?.public_id}`}
        >
          {!saving ? <CheckIcon className="flex w-5 mr-1" /> : null}
          {'Done'}
        </Link>
      </div>
      <div
        ref={updateAndForwardRef}
        className="w-full overflow-hidden px-4 mx-auto max-w-full rounded-sm bg-gray-200"
      >
        {saving ? (
          <Loading
            style={{
              position: 'absolute',
              backgroundColor: 'black',
              opacity: '0.7',
              height: '100%',
              zIndex: '1000',
            }}
          />
        ) : null}
        <div
          className="w-full h-screen"
          style={{
            // apply the transform from d3
            transformOrigin: 'top left',
            transform: `translate3d(${transform.x}px, ${transform.y}px, ${transform.k}px)`,
            position: 'relative',
          }}
        >
          <DndContext
            onDragMove={dragMove}
            onDragEnd={updateDraggedCardPosition}
          >
            {cards.map((card, i) => (
              <Draggable
                card={card}
                index={i}
                key={card.id}
                canvasTransform={transform}
                isDraggedAnswer={isDraggedAnswer}
                questionIndex={isDraggedQuestionIndex}
              />
            ))}
          </DndContext>
          {isDraggingArrow ? (
            <Xarrow
              start={tempArrow.start}
              end={tempArrow.end}
              strokeWidth={6}
            />
          ) : null}

          {existingArrows?.map((a, i) => (
            <Xarrow
              key={`arrow${i}`}
              start={a.start}
              end={a.end}
              color={a.color}
              strokeWidth={6}
              passProps={{
                onClick: () =>
                  deleteCondition(
                    parseInt(a.end.split('_')[0], 10),
                    parseInt(a.start.split('_')[0], 10)
                  ),
                cursor: 'pointer',
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Canvas;
