import { injectIntl } from 'react-intl';
import messages from '../messages';

const RegisterFields = ({
  fields = [],
  setTriviaState,
  intl: { formatMessage },
}) => (
  <div>
    <table className="w-full">
      <thead>
        <tr className="text-left">
          <th>{formatMessage(messages.Field)}</th>
          <th>{formatMessage(messages.Required)}</th>
          <th>{formatMessage(messages.DisplayName)}</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, i) => (
          <tr key={i}>
            <td>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    id={`checkbox${field.name}`}
                    name={field.name}
                    value={field.name}
                    checked={field.active}
                    onChange={(e) =>
                      setTriviaState((s) => ({
                        ...s,
                        register_fields: fields.map((f) => {
                          if (
                            e.target.name !== 'username' &&
                            f.name === e.target.name
                          ) {
                            return {
                              ...f,
                              active: !f.active,
                            };
                          }
                          return f;
                        }),
                      }))
                    }
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor={`checkbox${field.name}`}
                    className="font-medium text-gray-700"
                  >
                    {field.label}
                  </label>
                </div>
              </div>
            </td>
            <td style={{ textAlign: 'left', width: '25%' }}>
              {field.name !== 'username' ? (
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      id={`checkbox${field.name}optional`}
                      className="disabled:opacity-50"
                      name={field.name}
                      value={field.name}
                      checked={!field.is_optional && field.active}
                      disabled={!field.active}
                      onChange={(e) =>
                        setTriviaState((s) => ({
                          ...s,
                          register_fields: fields.map((f) => {
                            if (
                              e.target.name !== 'username' &&
                              f.name === e.target.name
                            ) {
                              return {
                                ...f,
                                is_optional: e.target.checked ? 0 : 1,
                              };
                            }
                            return f;
                          }),
                        }))
                      }
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={`checkbox${field.name}optional`}
                      className={`font-medium ${
                        field.active ? 'text-gray-700' : 'text-gray-400'
                      }`}
                    ></label>
                  </div>
                </div>
              ) : null}
            </td>
            <td>
              <input
                placeholder={field.label}
                name={field.name}
                type="text"
                autoComplete="new-password"
                onChange={(e) =>
                  setTriviaState((s) => ({
                    ...s,
                    register_fields: fields.map((f) => {
                      if (
                        e.target.name !== 'username' &&
                        f.name === e.target.name
                      ) {
                        return {
                          ...f,
                          label: e.target.value,
                        };
                      }
                      return f;
                    }),
                  }))
                }
                disabled={!field.active}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default injectIntl(RegisterFields);
