import * as editor from '../../../../../../api/editor';
import { getReducedChanges } from '../getReducedChanges';
import { getResult } from '../getResult';

export async function processResults(content, changes) {
  const content_id = content?.public_id;
  if (content_id === undefined) {
    console.error('Content `public_id` is undefined at RESULTS save');
    return;
  }

  //#region Save Result
  const result_changes = getReducedChanges(changes?.result ?? []);
  for (const key in result_changes) {
    const change = result_changes[key];
    const result = getResult(content, change);

    try {
      const { data } = await editor.result.saveResult(
        result,
        content_id,
        result?.id
      );
      Object.assign(result, data);
    } catch (error) {
      console.error(error?.message ?? error);
    }
  }
  //#endregion

  //#region Save Result Lead
  const rl_changes = getReducedChanges(changes?.result_lead ?? []);
  for (const key in rl_changes) {
    const change = rl_changes[key];
    const result = getResult(content, change);

    editor.result
      .saveResultLeads(content_id, result?.id, result?.lead?.id)
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion

  //#region Save Result PixelCode
  const rpx_changes = getReducedChanges(changes?.result_pixelcode ?? []);
  for (const key in rpx_changes) {
    const change = rpx_changes[key];
    const result = getResult(content, change);

    editor.result
      .saveResultPixelCode(content_id, result?.id, result?.pixel_code?.id)
      .catch((error) => console.error(error?.message ?? error));
  }

  const rcpx_changes = getReducedChanges(
    changes?.result_click_pixel_code ?? []
  );
  for (const key in rcpx_changes) {
    const change = rcpx_changes[key];
    const result = getResult(content, change);

    editor.result
      .saveResultClickPixelCode(
        content_id,
        result?.id,
        result?.click_pixel_code?.id
      )
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion
}
