import type { SVGProps } from 'react';

export default function PoltioLogoSM(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 36" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-13.60426,-0.63410392)"
      >
        <g id="g36">
          <path
            d="m 39.3999,7.0999 c -0.8,0.5 -1.1,1.5 -0.6,2.3 1.4,2.3 1.5,5.1 0.5,7.6 v 0.1 l -0.1,0.1 c -0.9,2 -2.532419,3.381643 -4.6,4.3 -3.566396,1.584086 -4.474534,4.441994 -4.9,5.4 -0.4,0.9 0.1,1.9 0.9,2.2 0.1,0.1 0.3,0.1 0.4,0.1 0.8,0.1 1.5,-0.3 1.8,-1 0.3,-0.7 0.942654,-2.753245 3,-3.6 2.9,-1.1 5.1,-3.3 6.4,-6.1 0,-0.1 0,-0.1 0.1,-0.2 0,-0.1 0.1,-0.1 0.1,-0.2 1.5,-3.3 1.2,-7.2 -0.7,-10.4 -0.5,-0.8 -1.5,-1.1 -2.3,-0.6 z"
            id="Fill-3"
            fill="#ffc000"
          />
          <path
            d="m 27.1999,24.7996 c -0.1,0 -0.3,-0.1 -0.4,-0.1 -3.5,-1.5 -6,-4.4 -6.9,-8.1 -0.2,-0.9 0.3,-1.8 1.2,-2.1 0.9,-0.2 1.8,0.3 2.1,1.2 0.7,2.6 2.5,4.701 5,5.8 0.9,0.401 1.3,1.401 0.9,2.2 -0.4,0.8 -1.2,1.201 -1.9,1.1"
            id="Fill-5"
            fill="#ff6644"
          />
          <path
            d="m 21.4001,12.1999 c -0.1,0 -0.2,0 -0.3,-0.1 -0.8,-0.2 -1.3,-1.2 -1,-2.1 0.1,-0.3 0.2,-0.6 0.4,-1 2.1,-5.1 7.4,-8 12.9,-7.2 0.9,0.1 1.9,0.4 2.7,0.8 0.3,0.1 0.6,0.3 0.9,0.4 0.8,0.4 1.2,1.5 0.7,2.3 -0.4,0.8 -1.5,1.2 -2.3,0.7 -0.2,-0.1 -0.4,-0.2 -0.7,-0.3 -0.6,-0.3 -1.3,-0.5 -2,-0.6 -3.9,-0.6 -7.7,1.5 -9.2,5.1 -0.1,0.2 -0.2,0.5 -0.3,0.7 -0.2,1 -1,1.5 -1.8,1.3"
            id="Fill-9"
            fill="#d66ddd"
          />
          <path
            d="m 33.599998,33.099998 c 0,1.375 -1.125,2.5 -2.499999,2.5 -1.375,0 -2.5,-1.125 -2.5,-2.5 0,-1.374999 1.125,-2.499999 2.5,-2.499999 1.374999,0 2.499999,1 2.499999,2.499999"
            id="Fill-13-Copy"
            fill="#009eec"
          />
        </g>
      </g>
    </svg>
  );
}
