import * as editor from '../../../../api/editor';
import { getReducedChanges } from '../EditorDataHandler/helpers/getReducedChanges';

import type { Content, EditorAPIRequestHandler } from '.';

const logic = async (content: Content) => {
  const contentId = content?.public_id;

  let data;
  try {
    const response = await editor.content.saveContent(content, contentId);
    data = response.data;
  } catch (error) {
    console.error('Error while saving content\n', error);
    return;
  }

  if (!data) {
    console.error('No data received from server');
    return;
  }

  try {
    Object.assign(content, data);
  } catch (error) {
    console.error('Error while assigning data to content\n', error);
    return;
  }

  return content.public_id;
};

type SaveContentReturnType = string | undefined;
type SaveContent = EditorAPIRequestHandler<SaveContentReturnType>;

export const saveContent: SaveContent = async (content, changes) => {
  const _changes = getReducedChanges(changes?.base ?? []);
  if (Object.keys(_changes).length <= 0) {
    return content.public_id;
  }

  return logic(content);
};
