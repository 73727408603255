import { createContext, createRef } from 'react';
import type { Context, ReactNode, FunctionComponent } from 'react';

import { Container, ContainerRef } from './Container';
import { NotificationType } from './enums';

type TToast = (
  type: NotificationType,
  title: string,
  message?: string | object | (string | object)[],
  timeout?: number
) => void;
type TNotif = (
  title: string,
  message?: string | object | (string | object)[],
  timeout?: number
) => void;

type TContext = {
  toast: TToast;
  alert: TNotif;
  error: TNotif;
  success: TNotif;
  warning: TNotif;
};
export const NotificationsCTX: Context<TContext> = createContext({
  toast: (type, title, message, timeout = 2500) => {},
  alert: (title, message, timeout = 2500) => {},
  error: (title, message, timeout = 2500) => {},
  success: (title, message, timeout = 2500) => {},
  warning: (title, message, timeout = 2500) => {},
});

type Props = {
  children?: ReactNode;
};
export const NotificationsContainer: FunctionComponent<Props> = ({
  children,
}) => {
  let container = createRef<ContainerRef>();

  const toast: TToast = (type, title, message, timeout = 2500) => {
    if (container.current === undefined || container.current === null) {
      return;
    }
    if (container.current.add === undefined) {
      return;
    }

    container.current.add(type, title ?? '', message ?? '', timeout);
  };

  const error: TNotif = (title, message, timeout = 2500) => {
    toast(NotificationType.ERROR, title, message, timeout);
  };

  const success: TNotif = (title, message, timeout = 2500) => {
    toast(NotificationType.SUCCESS, title, message, timeout);
  };

  const warning: TNotif = (title, message, timeout = 2500) => {
    toast(NotificationType.WARNING, title, message, timeout);
  };

  const ctx = {
    toast: toast,
    error: error,
    alert: error,
    success: success,
    warning: warning,
  };

  return (
    <NotificationsCTX.Provider value={ctx}>
      {!!children && children}
      <Container ref={container} />
    </NotificationsCTX.Provider>
  );
};
