import React, { useEffect } from 'react';
import {
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import { SixDotsVertical } from '../../../../../../images';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const _Item = (props, ref) => {
  const {
    data: value,
    index,
    activeIdentifier,
    onDelete,
    onDuplicate,
    setActive,
    deleteEnabled = false,
    handle = true,

    transform,
    dragging,
    transition,
    listeners,
    color,
    handleProps,
  } = props;

  useEffect(() => {
    if (!dragging) {
      return;
    }

    document.body.style.cursor = 'grabbing';

    return () => {
      document.body.style.cursor = '';
    };
  }, [dragging]);

  const _style = {
    transition: [transition].filter(Boolean).join(', '),
    '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
    '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
    '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
    '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
    '--index': index,
    '--color': color,
  };

  const active = index === activeIdentifier;

  return (
    <li
      className={classNames(
        'flex flex-row flex-wrap w-full border-2 border-dashed rounded-md items-center hover:bg-gray-200',
        dragging ? 'border-poltio-blue' : 'border-transparent',
        active ? 'bg-blue-100' : ''
      )}
      style={_style}
      ref={ref}
    >
      <div className="flex flex-row w-full h-9">
        {handle ? (
          <button
            className="flex items-center cursor-move"
            {...handleProps}
            {...listeners}
          >
            <SixDotsVertical className="h-5 w-3" />
          </button>
        ) : null}
        <div
          className={classNames(
            'w-full text-sm pl-2 pr-4 py-2 truncate justify-start select-none',
            dragging ? 'opacity-10' : ''
          )}
          onClick={() => setActive(index)}
        >
          <span className="truncate">{value.title}</span>
        </div>
        {onDelete ? (
          <button
            className={classNames(
              'poltio-btn text-red-500 px-2 rounded-r-none border-r-0',
              deleteEnabled
                ? 'hover:text-white hover:bg-red-500 focus:ring-red-500 focus:ring-offset-1'
                : ''
            )}
            disabled={!deleteEnabled}
            onClick={() => onDelete(index)}
          >
            <TrashIcon className="h-5" />
          </button>
        ) : null}
        {onDuplicate ? (
          <button
            className="poltio-btn text-blue-500 px-2 rounded-none border-x-0 hover:text-white hover:bg-blue-500 focus:ring-blue-500 focus:ring-offset-1"
            onClick={() => onDuplicate(index)}
          >
            <DocumentDuplicateIcon className="h-5" />
          </button>
        ) : null}
        {setActive ? (
          <button
            className="poltio-btn text-yellow-500 px-2 rounded-l-none border-l-0 hover:text-white hover:bg-yellow-500 focus:ring-yellow-500 focus:ring-0 focus:ring-offset-0"
            onClick={() => setActive(index)}
          >
            <PencilIcon className="h-5" />
          </button>
        ) : null}
      </div>
    </li>
  );
};

export const Item = React.memo(React.forwardRef(_Item));
