import { useState, useEffect, useCallback, FC } from 'react';
import { getVoteSources } from '../../../../api';
import Loading from '../../../Common/Loading';
import Pagination from '../../../Common/Pagination';
import { injectIntl } from 'react-intl';
import messages from '../messages';
import fetchPaginatedData from './fetchData';

type Props = {
  public_id: string;
  intl: any;
};

type Source = {
  session_count: number;
  url: string;
};

const VoteSourcesReport: FC<Props> = ({
  public_id,
  intl: { formatMessage },
}) => {
  const [data, setData] = useState<Source[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 12,
    lastPage: 1,
  });

  const fetchData = useCallback(async () => {
    await fetchPaginatedData(
      public_id,
      pageData.page,
      pageData.sizePerPage,
      getVoteSources,
      setLoading,
      (data) => {
        setData(() => [...(data?.data ?? [])]);
        setPageData((prev) => ({
          ...prev,
          page: prev.page,
          sizePerPage: prev.sizePerPage,
          totalSize: data.total,
          lastPage: data.last_page,
        }));
      }
    );
  }, [public_id, pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div>
        <div className="max-w-7xl mx-auto ml-4">
          <Pagination
            last={pageData.lastPage}
            active={pageData.page}
            size={pageData.sizePerPage}
            handlePageChange={handlePageChange}
            totalSize={pageData.totalSize}
            showTotal
          />
        </div>
        {loading ? (
          <div className="max-w-7xl mx-auto px-2">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-col pt-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase "
                        >
                          {formatMessage(messages.VoteCount)}
                        </th>
                        <th
                          scope="col"
                          className=" px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase "
                        >
                          {formatMessage(messages.Source)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .sort((a, b) => b.session_count - a.session_count)
                        .map((d, i) => {
                          let source = d.url;
                          if (source?.startsWith('https://poltio.com')) {
                            source = source.substring(18);
                          }

                          return (
                            <tr
                              key={i}
                              className={
                                i % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              }
                            >
                              <td className="px-6 py-2 text-sm text-gray-500">
                                <div className="text-sm font-medium text-gray-900">
                                  {d.session_count}
                                </div>
                              </td>
                              <td className="px-6 py-2 text-sm text-gray-500">
                                <div className="text-sm font-medium text-gray-900">
                                  <a
                                    href={`${d.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {source?.substring(0, 100)}
                                    {source?.length > 100 ? '...' : null}
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(VoteSourcesReport);
