import React from 'react';

import EditorContentDetail from './ContentDetail';
import EditorPreview from './Preview';
import Settings from './Settings';
import { Helmet } from 'react-helmet';

const EditorContent = ({ title }) => (
  <div className="grid h-full">
    <Helmet>
      <title>
        {title
          ? `Edit: ${title} | Poltio Platform`
          : 'Creating new content | Poltio Platform'}
      </title>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    <div className="sm:flex sm:flex-row h-full">
      <EditorContentDetail />

      <div className="sm:flex sm:flex-col w-full sm:h-full mb-4 sm:mb-0">
        <EditorPreview />
      </div>

      <Settings />
    </div>
  </div>
);

export default EditorContent;
