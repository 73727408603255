import * as editor from '../../../../../../api/editor';
import { getAnswer } from '../getAnswer';
import { getQuestion } from '../getQuestion';
import { getReducedChanges } from '../getReducedChanges';

export async function processAnswers(content, changes) {
  const content_id = content?.public_id;
  if (content_id === undefined) {
    console.error('Content `public_id` is undefined at ANSWER save');
    return;
  }

  //#region Save Answer
  const qa_changes = getReducedChanges(changes?.answer ?? []);
  for (const key in qa_changes) {
    const change = qa_changes[key];

    const question = getQuestion(content, change);
    const answer = getAnswer(question, change);
    if (answer === undefined || answer === null) {
      continue;
    }

    try {
      await answer.save(content_id, question.id);
    } catch (error) {
      console.error(error?.message ?? error);
    }
  }
  //#endregion

  //#region Save Answer Lead
  const qal_changes = getReducedChanges(changes?.answer_lead ?? []);
  for (const key in qal_changes) {
    const change = qal_changes[key];

    const question = getQuestion(content, change);
    const answer = getAnswer(question, change);
    if (answer === undefined || answer === null) {
      continue;
    }

    // do non-blocking request
    editor.answer
      .saveAnswerLeads(content_id, question.id, answer.id, answer?.lead?.id)
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion

  //#region Save Answer PixelCode
  const qapx_changes = getReducedChanges(changes?.answer_pixelcode ?? []);
  for (const key in qapx_changes) {
    const change = qapx_changes[key];

    const question = getQuestion(content, change);
    const answer = getAnswer(question, change);
    if (answer === undefined || answer === null) {
      continue;
    }

    // do non-blocking request
    editor.answer
      .saveAnswerPixelCode(
        content_id,
        question.id,
        answer.id,
        answer?.pixel_code?.id
      )
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion
}
