export function helper_get(key) {
  // tail recurse and return last accessor
  const lookup = (ref, head, ...tail) => {
    if (ref === undefined) {
      // ref is undefined, further calls will be undefined
      return ref;
    }
    if (tail.length === 0) {
      // end of the tail, return this
      return ref?.[head];
    }

    // `.a`  -> ['', 'a']
    // `a.b` -> ['a', 'b']
    return head === '' ? lookup(ref, ...tail) : lookup(ref[head], ...tail);
  };
  const args = key.split('.');

  return lookup(this.state.content, ...args);
}
