import * as editor from '../../../../../../api/editor';

import { applyMixins } from '../../../../../../core/mixins';
import { merge } from '../../helpers/merge';
import { proxyHandler } from '../../helpers/proxyHandler';

import Entity from '../Entity';
import AnswerDuplicateMixin from './AnswerDuplicateMixin';
import AnswerMockMixin from './AnswerMockMixin';
import AnswerSaveMixin from './AnswerSaveMixin';

import type { Question } from '../Question';
import type ResultAnswer from '../types/ResultAnswer';
import type Lead from '../types/Lead';
import type PixelCode from '../types/PixelCode';
import type Dict from '../types/Dict';
import { isAxiosError } from 'axios';

const AnswerPayloadFields = {
  required: [],
  allowed: {
    title: 'string',
    background: 'string',
    luv: 'string',
    is_right_answer: 'boolean',
    search_query: 'string',
    search_filter: 'string',
    cal_val: 'number',
    position: 'number',
    is_mutually_exclusive: 'boolean',
  },
};

const AnswerDuplicatableFields = [
  'title',
  'background',
  'luv',
  'is_right_answer',
  'search_query',
  'search_filter',
  'cal_val',
  'is_mutually_exclusive',
];

export type AnswerRequestBody = {
  title?: string;
  background?: string;
  luv?: string; // Lead URL variable for answer

  // If you want to give feedback based on users choice as wrong or right
  has_right_answer?: boolean;
  // If you want to mark this answer as the right answer
  is_right_answer?: boolean;

  // If you want to query a search index with this answer
  search_query?: string;
  // If you want to filter a search index with the answer
  search_filter?: string;
};

const isAnswer = (x: any): x is Answer => x?._uid !== undefined;

interface Answer
  extends Entity,
    AnswerMockMixin,
    AnswerDuplicateMixin<Answer>,
    AnswerSaveMixin {}

class Answer {
  _duplicatableFields;

  // reference to parent instance
  _question: Question;

  // changes list
  _changes: Dict = {};
  // errors list
  _errors: Dict = {};

  // BEGIN: data properties
  id?: number;
  question_id?: number;
  background?: string;
  vote_count?: number;
  created_at?: string;
  updated_at?: string;
  triggers_lead?: boolean;
  triggers_display?: boolean;

  // If you want to give feedback based on users choice as wrong or right
  // TODO: could this be a boolean?
  has_right_answer?: number;
  // If you want to mark this answer as the right answer
  // TODO: could this be a boolean?
  is_right_answer?: number;

  // boolean indicating if the requester already voted for this answer or not
  // this field is inconsequential for editor.
  voted?: number;

  // Defines if an answer is clone of some other answer
  // this was marked as boolean in the docs, but is number in the payload.
  is_clone?: boolean;

  // if this answer is a clone, this field refers to source answer id.
  clone_source?: number | null;

  // Answer calculator set value
  cal_val?: number | null;

  content_results?: ResultAnswer[] | null;

  // following are not in the docs, but are in the payload

  title?: string;
  link?: string;
  cm_question_id?: number;
  pixel_code_id?: number;
  lead_id?: number;
  webhook_id?: number;
  luv?: string; // Lead URL variable for answer
  // If you want to query a search index with this answer
  search_query?: string;
  // If you want to filter a search index with the answer
  search_filter?: string;
  position?: number;

  lead?: Lead | null;
  pixel_code?: PixelCode | null;

  deleted_at?: string | null; // readonly, inconsequential for editor
  // END

  constructor(data: any, parent: Question) {
    this._duplicatableFields = AnswerDuplicatableFields;
    this._payloadFields = AnswerPayloadFields;

    this._question = parent;

    merge(this, data);
  }

  // TODO: resolve this better...
  public create(data: any, parent?: Question): Answer {
    return new Answer(data, parent ?? this._question) as Answer;
  }

  public async save() {
    // TODO: add a referance to content & question to retrieve this data
    const isCreate = this.id === undefined || this.id === null;
    if (!isCreate && !this._isDirty) {
      return;
    }

    if (this.title === undefined || this.title === null) {
      if (this?._question?.answer_type === 'text') {
        this.title = this.placeholder;
      }
    }

    try {
      const response = await editor.answer.save(
        this as Answer,
        this._question!._content!.public_id!,
        this._question!.id!,
        this.id
      );

      // merge with response
      merge(this, response.data);

      // unmark changes
      Object.keys(this)
        .filter((key) => key in (this._payloadFields?.allowed ?? []))
        .forEach((field) => {
          this._changes[field] = false;
        });
    } catch (error) {
      // TODO: handle errors
      if (isAxiosError(error)) {
        this._errors['save'] = error.response?.data?.errors;
      }

      console.error(error);
    }
  }
}

applyMixins(Answer, [
  Entity,
  AnswerMockMixin,
  AnswerDuplicateMixin<Answer>,
  AnswerSaveMixin,
]);

export function New(answer: Answer, question: Question): Answer {
  if (answer instanceof Answer) {
    return answer;
  }
  if (isAnswer(answer)) {
    return answer;
  }

  if (!question) {
    throw new Error('Question Ref for Answer cannot be falsy!');
  }

  const klass = new Answer(answer, question);
  const proxy = new Proxy<Answer>(klass, proxyHandler);

  return proxy as Answer;
}

const _exports = { New };

export type { Answer };
export default _exports;
