import { useMemo } from 'react';
import badge1 from '../../images/badge1.png';
import badge2 from '../../images/badge2.png';
import badge3 from '../../images/g2_hp_fall2023.png';
import badge4 from '../../images/users-love-us.png';
import { StarIcon } from '@heroicons/react/20/solid';
import {
  PuzzlePieceIcon,
  CodeBracketIcon,
  IdentificationIcon,
  SwatchIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Reviews = [
  {
    name: 'Cemre C.',
    title: 'Digital Data & Growth Marketing Manager @ Pepsico',
    review:
      "They have a really productive team, they are highly motivated to drive value to their consumers. They are aware of our business problems and expectations and always suggesting to the point solutions and alternatives. Platform is easy to use and easy to embed into companies' digital assets. This tool helped us our in-app user engagement and user data enrichment KPIs successfully. ",
    initials: 'CC',
  },
  {
    name: 'L Daniel S.',
    title: 'Founder of Sincere--Studio',
    review:
      "Essential tool for engagement. As a creative agency, we are constantly looking for approaches to engage current and future users. Poltio's flexible embedding solutions allow us to use this for any project.",
    initials: 'DS',
  },
  {
    name: 'Emre K.',
    title: 'Data Team Leader @ Decathlon',
    review:
      'Using Poltio for our gamification saved us time on technical development. Also, for the content creation part, they shared their know-how, making it easy for us. Through out the process, they were eager to help.',
    initials: 'EK',
  },
];

const Testimonials = () => {
  const rev = useMemo(
    () => Reviews[Math.floor(Math.random() * Reviews.length)],
    []
  );

  return (
    <div className="relative isolate pb-12 xl:pb-36 pt-12 xl:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <Tst review={rev} />

        <div className="mx-auto mt-6 grid place-items-center w-full gap-8 text-sm leading-6 text-gray-900 xl:mt-20 sm:grid-cols-2 md:grid-cols-4 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <img
            className="h-20 w-20 xl:h-28 xl:w-28 flex-none"
            src={badge1}
            alt="Capterra Best Ease of Use 2023"
          />
          <img
            className="h-20 w-20 xl:h-28 xl:w-28 flex-none"
            src={badge2}
            alt="Capterra Best Value 2023"
          />

          <img
            className="h-20 w-20 xl:h-28 xl:w-24 flex-none"
            src={badge4}
            alt="g2 Users Love Us"
          />
          <img
            className="h-20 w-20 xl:h-28 xl:w-24 flex-none"
            src={badge3}
            alt="g2 High Performer Summer 2023"
          />
        </div>
        <Incentives />
      </div>
    </div>
  );
};

export default Testimonials;

const Tst = ({ review }) => (
  <section className="px-6 py-12 sm:py-12 lg:px-8">
    <figure className="mx-auto max-w-2xl">
      <p className="sr-only">5 out of 5 stars</p>
      <div className="flex gap-x-1 text-poltio-blue">
        <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
        <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
        <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
        <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
        <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
      </div>
      <blockquote className="mt-10 text-sm font-semibold leading-8 tracking-tight text-gray-300 sm:leading-9">
        <p>{review.review}</p>
      </blockquote>
      <figcaption className="mt-10 flex items-center gap-x-6">
        <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-poltio-blue-200 rounded-full ">
          <span className="font-medium text-poltio-blue-700">
            {review.initials}
          </span>
        </div>
        <div className="text-sm leading-6">
          <div className="font-semibold text-gray-200">{review.name}</div>
          <div className="mt-0.5 text-gray-400">{review.title}</div>
        </div>
      </figcaption>
    </figure>
  </section>
);

const incentives = [
  {
    name: 'No Code',
    description: 'Customize and create without any coding',
    icon: CodeBracketIcon,
    color: 'text-poltio-purple',
  },
  {
    name: 'Embeddable',
    description: 'Integrate with your app, site or social media in minutes',
    icon: PuzzlePieceIcon,
    color: 'text-poltio-yellow',
  },
  {
    name: 'White-Label',
    icon: SwatchIcon,
    color: 'text-poltio-red',
  },
  {
    name: 'Zero-Party Data',
    description: 'Get data from your users directly',
    icon: IdentificationIcon,
    color: 'text-poltio-blue',
  },
];

const Incentives = () => (
  <div className="mt-8 xl:mt-16">
    <h2 className="sr-only">Why you should buy from us</h2>
    <div className="flex overflow-x-auto justify-center">
      <div className="xl:mx-auto grid grid-cols-2 xl:flex justify-items-start gap-x-24 gap-y-5 xl:gap-x-0 xl:space-x-12 whitespace-nowrap px-4 py-3">
        {incentives.map((incentive) => (
          <div
            key={incentive.name}
            className="flex items-center text-sm font-medium text-poltio-blue-300"
          >
            <incentive.icon
              className={classNames(
                `${
                  incentive.color ? incentive.color : 'text-poltio-blue'
                } mr-2 h-6 w-6 flex-none`
              )}
              aria-hidden="true"
            />
            <p>{incentive.name}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);
