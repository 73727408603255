import React, { useContext, useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { injectIntl } from 'react-intl';

import { widgetResultImagePath } from '../../../../../core/constants';
import {
  Label,
  TextAreaInput,
  ImageInput,
  Expander,
} from '../../../../Common/Form';

import { EditorCTX } from '../../../context';
import { ConfTypes } from '../../../enums';
import messages from '../messages';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const _ResultsSection = ({
  results,
  clickAddResults = () => {},
  intl: { formatMessage },
}) => {
  const {
    focus,
    set,
    activeResultIdx: ridx,
    addNewResult,
  } = useContext(EditorCTX);

  const [result, setResult] = useState({});
  useEffect(() => {
    const _r = results?.[ridx];
    if (_r) {
      setResult({ ..._r });
    } else {
      // no result present, create one
      addNewResult();
    }
  }, [results, addNewResult, ridx]);

  return (
    <div className="space-y-2 ">
      <div className="w-full pt-1 pb-2 text-left text-gray-700 border-gray-300 col-span-1">
        <div className="mb-3">
          <TextAreaInput
            debounce={{ timeout: 1000 }}
            rows={3}
            value={result?.title || ''}
            onChange={(value) => set(`results.${ridx}.title`, value)}
            onFocus={() => focus(ConfTypes.Result, `results.${ridx}`)}
            maxLength={255}
            label={formatMessage(messages.Result)}
          />
        </div>
        <div className="pt-1 mb-3">
          <Label text={formatMessage(messages.ResultDesc)} />
          <TextAreaInput
            rows={6}
            debounce={{ timeout: 1000 }}
            value={result?.desc || ''}
            onChange={(value) => set(`results.${ridx}.desc`, value)}
            onFocus={() => focus(ConfTypes.Result, `results.${ridx}`)}
          />
        </div>
        <div className="pt-1">
          <Label text={formatMessage(messages.ResultImage)} />
          <div className="flex justify-center mt-2">
            <div className="w-full h-48">
              <ImageInput
                title={formatMessage(messages.ResultImage)}
                src={
                  result?.background
                    ? `${widgetResultImagePath}/${result.background}`
                    : ''
                }
                onClick={() => focus(ConfTypes.Result, `results.${ridx}`)}
                callback={(value) => set(`results.${ridx}.background`, value)}
                type="result"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResultsSection = injectIntl(_ResultsSection);

const ResultForm = ({ intl: { formatMessage } }) => {
  const {
    data,
    focus,
    activeResultIdx: ridx,
    confType,
  } = useContext(EditorCTX);

  return (
    <Expander
      open={confType === ConfTypes.Result}
      as="div"
      className="overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <Expander.Button
        className="flex w-full px-2 py-2 sm:px-2"
        onClick={() => {
          focus(ConfTypes.Result, `results.${ridx}`);
        }}
      >
        {({ open }) => (
          <div className="flex justify-between w-full mx-1 my-1">
            <span className="flex text-xl">
              {formatMessage(messages.Results)}
            </span>
            <ChevronRightIcon
              className={classNames('flex w-5 mt-1', open ? 'rotate-90' : '')}
            />
          </div>
        )}
      </Expander.Button>
      <Expander.Panel>
        <div className="px-4 py-3 sm:p-6">
          <ResultsSection results={data?.results} />
        </div>
      </Expander.Panel>
    </Expander>
  );
};

export default injectIntl(ResultForm);
