import { FC } from 'react';
import { Label } from './Label';

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  name?: string;
  text?: string;
  label: string;
  description?: string;
  font?: string;
  required?: boolean;
  checked: boolean;
  onChange: (e: any) => void;
  className: string;
};

const CheckBoxInput: FC<Props> = ({
  name,
  text,
  label,
  description,
  font = 'normal',
  required = false,
  checked,
  onChange = () => {},
  className = '',
}) => (
  <>
    <div className={classNames('flex items-center mb-1', className)}>
      <div className="flex items-center h-5 mt-1">
        <input
          name={name}
          type="checkbox"
          className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-poltio-blue-300 rounded cursor-pointer
          "
          required={required}
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div className="ml-2 truncate text-ellipsis">
        <Label htmlFor={name} text={text || label} font={font} />
      </div>
    </div>
    {description && (
      <div className="text-base text-gray-600">{description}</div>
    )}
  </>
);

export { CheckBoxInput };
export default CheckBoxInput;
