import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
  Sumbitted: {
    id: 'Reports.Submitted',
    defaultMessage: 'Report request submitted.',
  },
  File: {
    id: 'Reports.File',
    defaultMessage: 'File',
  },
  Status: {
    id: 'Reports.Status',
    defaultMessage: 'Status',
  },
  RunTime: {
    id: 'Reports.RunTime',
    defaultMessage: 'Run Time (s)',
  },
  CreatedAt: {
    id: 'Reports.CreatedAt',
    defaultMessage: 'Created At',
  },
  UpdatedAt: {
    id: 'Reports.UpdatedAt',
    defaultMessage: 'Updated At',
  },
  ContentSession: {
    id: 'Reports.ContentSession',
    defaultMessage: 'Content Sessions',
  },
  ContentVoters: {
    id: 'Reports.ContentVoters',
    defaultMessage: 'Content Voters',
  },
  InputLeads: {
    id: 'Reports.InputLeads',
    defaultMessage: 'Input Leads',
  },
  Type: {
    id: 'Reports.Type',
    defaultMessage: 'Type',
  },
  Select: {
    id: 'Reports.Select',
    defaultMessage: 'Select',
  },
  Download: {
    id: 'Reports.Download',
    defaultMessage: 'Download',
  },
  Reports: {
    id: 'Reports.Reports',
    defaultMessage: 'Reports',
  },
  ReportsDesc: {
    id: 'Reports.ReportsDesc',
    defaultMessage:
      'Reports allows you to download user interactions in bulk as CSV.',
  },
  ReportInputDesc: {
    id: 'Reports.ReportInputDesc',
    defaultMessage:
      'If you want to download a new report, you can enter the id of your content and request a new report.',
  },
});

export default messages;
