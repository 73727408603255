import { Dialog } from '@headlessui/react';
import { useEffect, useState, useRef } from 'react';
import Loading from '.';

const LoadingDialog = ({ show }) => {
  const [isOpen, setIsOpen] = useState(false);
  let divRef = useRef(null);

  useEffect(() => {
    if (show !== undefined) {
      setIsOpen(show);
    }
  }, [show]);

  return (
    <Dialog open={isOpen} onClose={() => {}} initialFocus={divRef}>
      <Dialog.Overlay />
      <div className="absolute w-full h-full top-0 flex items-center justify-center">
        <div className="absolute bg-black opacity-50 w-full h-full z-40" />
        <div className="z-50" ref={divRef}>
          <Loading />
        </div>
      </div>
    </Dialog>
  );
};

export default LoadingDialog;
