import * as editor from '../../../../../api/editor';

import type { EditorDataHandlerProps, EditorDataHandlerState } from '..';

import { EditorDataHandler } from '..';
import { EditorState } from '../../../enums';
import { New as NewContent } from '../classes/Content';

type fetchOptions = {
  isLoadingHook?: (isOk: boolean) => void;
};

export async function helper_fetch(
  this: EditorDataHandler,
  options: fetchOptions = {}
) {
  if (!this.state.content_id) {
    // content_id is not present. there is nothing to save.
    return;
  }

  options?.isLoadingHook?.(true);

  let contentResponse = undefined;
  if (this.state.content_id) {
    contentResponse = await editor.content.getContent(this.state.content_id);
  }

  if (!contentResponse) {
    options?.isLoadingHook?.(false);
    return;
  }

  let content = NewContent(contentResponse.data);

  let resultData;
  if (this.state.content_id) {
    resultData = await editor.result.getResults(
      this.state.content_id,
      this.state.results_curr_page
    );
  }

  const results = resultData?.data?.data ?? [];
  const results_last_page = resultData?.data?.last_page ?? 0;
  const results_curr_page = resultData?.data?.current_page ?? 0;

  content.results = [...results];

  const post_fetch = () => {
    options?.isLoadingHook?.(false);
  };

  this.setState(
    (prev: EditorDataHandlerState, props: EditorDataHandlerProps) => ({
      ...prev,
      content: content,
      results_curr_page: results_curr_page,
      results_last_page: results_last_page,
      save_state: EditorState.Saved,
    }),
    post_fetch
  );
}
