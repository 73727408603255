import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  NotVerify: {
    id: 'TcWarning.NotVerify',
    defaultMessage: 'Your account is not verified!',
  },
  PleaseVerify: {
    id: 'TcWarning.PleaseVerify',
    defaultMessage:
      "In order to use all of Poltio APIs please click the verification link in the email we sent you.If you didn't received the verification email,",
  },
  ReSend: {
    id: 'TcWarning.ReSend',
    defaultMessage: 'click here so we can send you a new one.',
  },
  Success: {
    id: 'TcWarning.Success',
    defaultMessage: 'Email sent successfully',
  },
});
