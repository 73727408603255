import * as editor from '../../../../../../api/editor';
import { getQuestion } from '../getQuestion';
import { getReducedChanges } from '../getReducedChanges';

export async function processQuestions(content, changes) {
  const content_id = content?.public_id;
  if (content_id === undefined) {
    console.error('Content `public_id` is undefined at QUESTION save');
    return;
  }

  //#region Save Question
  const q_changes = getReducedChanges(changes?.question ?? []);
  for (const key in q_changes) {
    const question = getQuestion(content, q_changes[key]);
    try {
      if (question) {
        await question.save();
      }
    } catch (error) {
      console.error(error?.message ?? error);
    }
  }
  //#endregion

  //#region Save Question Lead
  const ql_changes = getReducedChanges(changes?.question_lead ?? []);
  for (const key in ql_changes) {
    const change = ql_changes[key];

    const question = getQuestion(content, change);

    // do non-blocking request
    editor.question
      .saveQuestionLeads(content_id, question?.id, question?.lead?.id)
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion

  //#region Save Question PixelCode
  const qpx_changes = getReducedChanges(changes?.question_pixelcode ?? []);
  for (const key in qpx_changes) {
    const change = qpx_changes[key];

    const question = getQuestion(content, change);

    // do non-blocking request
    editor.question
      .saveQuestionPixelCode(content_id, question?.id, question?.pixel_code?.id)
      .catch((error) => console.error(error?.message ?? error));
  }
  //#endregion
}
