import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  GameDetails: {
    id: 'Trivia.GameDetails',
    defaultMessage: 'Game Details:',
  },
  BacktoGameList: {
    id: 'Trivia.BacktoGameList',
    defaultMessage: 'Back to Game List',
  },
  Actions: {
    id: 'Trivia.Actions',
    defaultMessage: 'Actions',
  },
  Action: {
    id: 'Trivia.Action',
    defaultMessage: 'Action',
  },
  CreatedAt: {
    id: 'Trivia.CreatedAt',
    defaultMessage: 'Created At',
  },
  PlayerList: {
    id: 'Trivia.PlayerList',
    defaultMessage: 'Player List',
  },
  ShowWinnersOnly: {
    id: 'Trivia.ShowWinnersOnly',
    defaultMessage: 'Show Winners Only',
  },
  UserID: {
    id: 'Trivia.UserID',
    defaultMessage: 'User ID',
  },
  UserName: {
    id: 'Trivia.UserName',
    defaultMessage: 'User Name',
  },
  Points: {
    id: 'Trivia.Points',
    defaultMessage: 'Points',
  },
  Email: {
    id: 'Trivia.Email',
    defaultMessage: 'Email',
  },
  Phone: {
    id: 'Trivia.Phone',
    defaultMessage: 'Phone',
  },
  Comment: {
    id: 'Trivia.Comment',
    defaultMessage: 'Comment',
  },
  TCAccepted: {
    id: 'Trivia.TCAccepted',
    defaultMessage: 'TC Accepted',
  },
  QuizID: {
    id: 'Trivia.QuizID',
    defaultMessage: 'Quiz ID',
  },
  PlayerCount: {
    id: 'Trivia.PlayerCount',
    defaultMessage: 'Player Count',
  },
  GameType: {
    id: 'Trivia.GameType',
    defaultMessage: 'Game Type',
  },
  Tier: {
    id: 'Trivia.Tier',
    defaultMessage: 'Tier',
  },
  Cost: {
    id: 'Trivia.Cost',
    defaultMessage: 'Cost',
  },
  Elimination: {
    id: 'Trivia.Elimination',
    defaultMessage: 'Elimination',
  },
  Status: {
    id: 'Trivia.Status',
    defaultMessage: 'Status',
  },
  TimerActive: {
    id: 'Trivia.TimerActive',
    defaultMessage: 'Timer is still active. Are you sure you want to continue?',
  },
  AreYouSure: {
    id: 'Trivia.AreYouSure',
    defaultMessage:
      'Are you sure you want to reset all player progress and everything about this trivia?',
  },
  Error: {
    id: 'Trivia.Error',
    defaultMessage: 'Error!',
  },
  RedirectUrlSet: {
    id: 'Trivia.RedirectUrlSet',
    defaultMessage: 'Redirect url set.',
  },
  BoardUrlCopied: {
    id: 'Trivia.BoardUrlCopied',
    defaultMessage: 'Board url copied.',
  },
  ManageTrivia: {
    id: 'Trivia.ManageTrivia',
    defaultMessage: 'Manage Trivia',
  },
  UsersCanJoin: {
    id: 'Trivia.UsersCanJoin',
    defaultMessage: 'Users can now join trivia.',
  },
  LeaderboardUrl: {
    id: 'Trivia.LeaderboardUrl',
    defaultMessage: 'Leaderboard Url',
  },
  PeopleRegistered: {
    id: 'Trivia.PeopleRegistered',
    defaultMessage: 'People Registered',
  },
  ConnectedPlayers: {
    id: 'Trivia.ConnectedPlayers',
    defaultMessage: 'Connected Players',
  },
  CurrentQuestion: {
    id: 'Trivia.CurrentQuestion',
    defaultMessage: 'Current Question',
  },
  NextQuestion: {
    id: 'Trivia.NextQuestion',
    defaultMessage: 'Next Question',
  },
  TotalQuestions: {
    id: 'Trivia.TotalQuestions',
    defaultMessage: 'Total Questions',
  },
  TimeRemaining: {
    id: 'Trivia.TimeRemaining',
    defaultMessage: 'Time Remaining',
  },
  RedirectUrl: {
    id: 'Trivia.RedirectUrl',
    defaultMessage: 'Redirect Url',
  },
  Set: {
    id: 'Trivia.Set',
    defaultMessage: 'Set',
  },
  Start: {
    id: 'Trivia.Start',
    defaultMessage: 'Start',
  },
  Next: {
    id: 'Trivia.Next',
    defaultMessage: 'Next',
  },
  Finish: {
    id: 'Trivia.Finish',
    defaultMessage: 'Finish Trivia',
  },
  Reset: {
    id: 'Trivia.Reset',
    defaultMessage: 'Reset',
  },
  QuizNotYours: {
    id: 'Trivia.QuizNotYours',
    defaultMessage: 'Quiz not yours!',
  },
  QuizNotFound: {
    id: 'Trivia.QuizNotFound',
    defaultMessage: 'Quiz not found!',
  },
  NewTrivia: {
    id: 'Trivia.NewTrivia',
    defaultMessage: 'New Trivia',
  },
  Options: {
    id: 'Trivia.Options',
    defaultMessage: 'Options',
  },
  BasicTriviaOptions: {
    id: 'Trivia.BasictriviaOptions',
    defaultMessage: 'Basic trivia options. Click start for a new trivia game.',
  },
  EnterQuiz: {
    id: 'Trivia.EnterQuiz',
    defaultMessage: 'Enter Quiz ID',
  },
  SelectYourQuiz: {
    id: 'Trivia.SelectYourQuiz',
    defaultMessage: 'Select Your Quiz',
  },
  AvailableCredits: {
    id: 'Trivia.AvailableCredits',
    defaultMessage: 'Available Credits',
  },
  Paid: {
    id: 'Trivia.Paid',
    defaultMessage: 'Paid',
  },
  UncheckThis: {
    id: 'Trivia.UncheckThis',
    defaultMessage: 'Uncheck this to create a demo game (max 5 players)',
  },
  ShowBoard: {
    id: 'Trivia.ShowBoard',
    defaultMessage: 'Show Leaderboard',
  },
  ShowBoardDesc: {
    id: 'Trivia.ShowBoardDesc',
    defaultMessage: 'Show leaderboard in the player screen',
  },
  EliminationDesc: {
    id: 'Trivia.EliminationDesc',
    defaultMessage: 'Wrong answer eliminates player from the game.',
  },
  SetStartDate: {
    id: 'Trivia.SetStartDate',
    defaultMessage: 'Set Start Date',
  },
  SetStartDateDesc: {
    id: 'Trivia.SetStartDateDesc',
    defaultMessage: 'Set a future start date for trivia.',
  },
  StartDate: {
    id: 'Trivia.StartDate',
    defaultMessage: 'Start Date',
  },
  StartDateDesc: {
    id: 'Trivia.StartDateDesc',
    defaultMessage:
      'This only shows a countdown to players. Trivia will not be started automatically.',
  },
  RegisterFields: {
    id: 'Trivia.RegisterFileds',
    defaultMessage: 'Register Fields',
  },
  RegisterFieldsDesc: {
    id: 'Trivia.RegisterFieldDesc',
    defaultMessage:
      'You can change which fields participants have to submit before joining trivia.',
  },
  Field: {
    id: 'Trivia.Field',
    defaultMessage: 'Field',
  },
  Required: {
    id: 'Trivia.Required',
    defaultMessage: 'Required',
  },
  DisplayName: {
    id: 'Trivia.DisplayName',
    defaultMessage: 'Display Name',
  },
  Trivia: {
    id: 'Trivia.Trivia',
    defaultMessage: 'Trivia',
  },
  QuizUnpublished: {
    id: 'Trivia.QuizUnpublished',
    defaultMessage: 'Quiz Unpublished.',
  },
});
