import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Removed: {
    id: 'Tokens.Removed',
    defaultMessage: 'Token successfully deleted',
  },
  ErrorRemoved: {
    id: 'Tokens.ErrorRemoved',
    defaultMessage: 'An error occurred while deleting the token',
  },
  Sure: {
    id: 'Tokens.Sure',
    defaultMessage: 'Are you sure to delete this token',
  },
  Title: {
    id: 'Tokens.Title',
    defaultMessage: 'Account Wide API Access Tokens',
  },
  Subtitle: {
    id: 'Tokens.Subtitle',
    defaultMessage:
      'Tokens you have generated that can be used to access the Poltio API',
  },
  CreateNew: {
    id: 'Tokens.CreateNew',
    defaultMessage: 'Create New Token',
  },
  Name: {
    id: 'Tokens.Name',
    defaultMessage: 'Name',
  },
  ExpiresAt: {
    id: 'Tokens.ExpiresAt',
    defaultMessage: 'Expires At',
  },
  TokenIndentifer: {
    id: 'Tokens.TokenIndentifer',
    defaultMessage: 'Token Indentifer',
  },
  CreateTitle: {
    id: 'Tokens.CreateTitle',
    defaultMessage: 'New API access token',
  },
  CreateSubtitle: {
    id: 'Tokens.CreateSubtitle',
    defaultMessage:
      'API Tokens helps you identify yourself to Poltio API and can take all actions on your behalf.',
  },
  Note: {
    id: 'Tokens.Note',
    defaultMessage: 'Note',
  },
  NoteDesc: {
    id: 'Tokens.NoteDesc',
    defaultMessage:
      'Make sure to copy your API access token now. You won’t be able to see it again!',
  },
  ExpiresIn: {
    id: 'Tokens.ExpiresIn',
    defaultMessage: 'Expires in',
  },
  Create: {
    id: 'Tokens.Create',
    defaultMessage: 'Create',
  },
  Cancel: {
    id: 'Tokens.Cancel',
    defaultMessage: 'Cancel',
  },
  Day: {
    id: 'Tokens.Day',
    defaultMessage: 'Day',
  },
  Week: {
    id: 'Tokens.Week',
    defaultMessage: 'Week',
  },
  Month: {
    id: 'Tokens.Month',
    defaultMessage: 'Month',
  },
  Months: {
    id: 'Tokens.Months',
    defaultMessage: 'Months',
  },
  Year: {
    id: 'Tokens.Year',
    defaultMessage: 'Year',
  },
  Never: {
    id: 'Tokens.Never',
    defaultMessage: 'Never',
  },
  Copied: {
    id: 'Tokens.Copied',
    defaultMessage: 'Token copied successfully',
  },
  ErrorCopy: {
    id: 'Tokens.ErrorCopy',
    defaultMessage: 'An error occurred while copying the token',
  },
  ErrorCreate: {
    id: 'Tokens.ErrorCreate',
    defaultMessage: 'An error occurred while creating the token',
  },
});
