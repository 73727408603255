import api from '../../client';

export function get(templateId?: number) {
  return api.get(
    templateId
      ? `/platform/content/templates/${templateId}`
      : '/platform/content/templates'
  );
}

export function use(public_id?: string) {
  return api.get(`/platform/content/templates/${public_id}/use`);
}
