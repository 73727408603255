import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';

import { widgetAnswerImagePath } from '../../../../../../../core/constants';
import {
  TextAreaInput,
  ImageInput,
  SwitchInput,
} from '../../../../../../Common/Form';

import { ConfTypes, QuestionTypes } from '../../../../../enums';
import { SixDotsVertical } from '../../../../../../../images';
import messages from '../../../messages';
import { TrashIcon } from '@heroicons/react/24/outline';
import Button from '../../../../../../Common/Button';
import genuid from '../../../../../../../core/genuid';
import { EditorCTX } from '../../../../../context';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const textTypes = [QuestionTypes.text.value, QuestionTypes.autocomplete.value];

const _AnswerContents = ({
  dragging,
  answer,
  answer_type,
  onFocus,
  onSetImage,
  onTextChange,
  setIsDraggable,
  delAnswerAt,
  length,
  index,
  intl: { formatMessage },
  contentType,
  set,
  qidx,
  answers,
}) => {
  const toggleCorrectAnswer = (nextValue) => {
    if (nextValue) {
      // if answer is to be correct, unset previous correct if present
      // there can be only one correct answer at the moment
      const padix = answers?.findIndex((el) => el?.is_right_answer);
      if (padix >= 0) {
        set(`questions.${qidx}.answers.${padix}.is_right_answer`, 0);
      }
    }
    // set `this` answer's `is_right_answer` value
    set(
      `questions.${qidx}.answers.${index}.is_right_answer`,
      nextValue ? 1 : 0
    );
  };
  return (
    <div
      className={classNames(
        'flex flex-col flex-grow pr-1 py-4',
        dragging ? 'opacity-30 pointer-events-none' : ''
      )}
    >
      {answer_type.value === QuestionTypes.score.value ? (
        <div className="flex content-center justify-center flex-grow font-semibold text-center">
          {answer.title}
        </div>
      ) : null}
      {textTypes.includes(answer_type.value) ? (
        <div className="flex flex-grow">
          <div className="w-16 h-16 my-7">
            <ImageInput
              title={formatMessage(messages.AnswerImage)}
              src={
                answer.background
                  ? `${widgetAnswerImagePath}/${answer.background}`
                  : ''
              }
              aspectRatio={1}
              forceCrop
              onClick={onFocus}
              callback={onSetImage}
              type="answer"
            />
          </div>
          <div className="flex flex-col flex-grow ml-2">
            <div className="flex flex-col w-full">
              <TextAreaInput
                // autogrow
                debounce={{
                  timeout: 1000,
                  options: { leading: false, trailing: true },
                }}
                rows={3}
                placeholder={answer?.placeholder}
                value={answer?.title || ''}
                onChange={onTextChange}
                onFocus={(event) => {
                  // focusing text area remove draggable
                  setIsDraggable(false);

                  onFocus(event);
                }}
                onBlur={() => {
                  // item out of focus, re-enable draggable
                  setIsDraggable(true);
                }}
                maxLength={255}
                label={formatMessage(messages.AnswerTitle)}
              />
            </div>

            <div className="absolute right-0">
              <Button
                type="button"
                className="-m-2 inline-flex p-2 text-red-400 hover:text-red-600 border-0 focus:ring-0"
                onClick={() => delAnswerAt(index)}
                disabled={length <= 2}
                tooltip={length <= 2}
                message="A question must have at least 2 answers"
              >
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {contentType === 'quiz' ? (
        <div className="">
          <SwitchInput
            value={answer?.is_right_answer || 0}
            onSwitch={toggleCorrectAnswer}
            description={formatMessage(messages.CorrectAnswerDesc)}
          >
            {formatMessage(messages.CorrectAnswer)}
          </SwitchInput>
        </div>
      ) : null}{' '}
    </div>
  );
};

const AnswerContents = injectIntl(_AnswerContents);

const _Answer = (props, ref) => {
  const {
    data: answer,
    index,
    answer_type,
    qidx,
    set,
    focus,
    onSaveImage,
    length,
    delAnswerAt,

    dragging,
    transform,
    transition,
    listeners,
    color,
    handleProps,
    isDragOverlay,
  } = props;
  const { data, get } = useContext(EditorCTX);

  const uid = React.useMemo(() => genuid(), []);

  const onFocus = () => {
    focus(ConfTypes.Answer, `questions.${qidx}.answers.${index}`);
  };

  const onTextChange = (value) => {
    set(`questions.${qidx}.answers.${index}.title`, value);
  };

  const onSetImage = (value) => {
    onSaveImage(value, qidx, index);
  };

  const answers = get(`questions.${qidx}`).answers;

  // HEADS UP!
  // https://bugzilla.mozilla.org/show_bug.cgi?id=800050
  // Draggable property has an issue for Firefox!

  const [isDraggable, setIsDraggable] = useState(false);

  const _style = {
    transition: [transition].filter(Boolean).join(', '),
    '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
    '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
    '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
    '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
    '--index': index,
    '--color': color,
  };

  return (
    <li
      key={uid}
      className={classNames(
        'flex flex-row w-full text-left text-gray-700 col-span-1 pb-2',
        isDraggable || (!isDragOverlay && dragging)
          ? 'bg-white shadow-inner rounded'
          : '',
        isDragOverlay ? 'bg-poltio-blue-100 rounded shadow-md' : ''
      )}
      style={_style}
      ref={ref}
    >
      {answer_type?.draggable ? (
        <div
          className="flex items-center mb-12 cursor-move"
          onPointerOver={() => {
            // draggable toggle handling for Firefox
            // console.log('on pointer over', event);
            setIsDraggable(true);
          }}
          onPointerLeave={() => {
            // draggable toggle handling for Firefox
            // console.log('on pointer leave', event);
            setIsDraggable(false);
          }}
          {...handleProps}
          {...listeners}
        >
          <SixDotsVertical className="w-5 h-5" />
        </div>
      ) : null}
      <AnswerContents
        dragging={dragging}
        answer_type={answer_type}
        answer={answer}
        onFocus={onFocus}
        onSetImage={onSetImage}
        onTextChange={onTextChange}
        setIsDraggable={setIsDraggable}
        length={length}
        delAnswerAt={delAnswerAt}
        index={index}
        contentType={data.type}
        set={set}
        qidx={qidx}
        answers={answers}
      />
    </li>
  );
};

/**
 * React.memo:
 *  "memo lets you skip re-rendering a component when its props are unchanged."
 * React.forwardRef:
 *  "forwardRef lets your component expose a DOM node to parent component with a ref."
 *  Needed for drag-and-drop.
 */
export const Answer = React.memo(React.forwardRef(_Answer));
export default Answer;
