import { InformationCircleIcon } from '@heroicons/react/24/outline';

type CardDisplayExplanationProps = {
  explanation: string;
};
const CardDisplayExplanation = (props: CardDisplayExplanationProps) => {
  const { explanation } = props;

  return (
    <div className="group relative">
      <div className="mt-2 flex items-start gap-x-2">
        <div>
          <InformationCircleIcon className="w-6 text-gray-400 group-hover:text-gray-500" />
        </div>
        <div>
          <p className="text-sm leading-6 text-gray-600">{explanation}</p>
        </div>
      </div>
    </div>
  );
};

export default CardDisplayExplanation;
