import { useState, type FC, useContext } from 'react';
import { sendDemoRequest } from '../../api';

import Heading from '../Common/Page/Heading';
import { NotificationsCTX } from '../../contexts/Notification';
import { Helmet } from 'react-helmet';

const SagePreview: FC<any> = ({ user }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { alert, success } = useContext(NotificationsCTX);

  const sendRequest = async () => {
    setButtonDisabled(true);
    try {
      await sendDemoRequest({
        email: user.email,
        message: 'I want to use SAGE',
      });

      setSubmitSuccess(true);
      setButtonDisabled(false);
      success('Success');
    } catch (error: any) {
      setButtonDisabled(false);
      alert('Error');
    }
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Helmet>
          <title>SAGE | Poltio Platform</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Heading
          title={'Poltio SAGE'}
          body={`SAGE is Poltio's own AI Model trained over thousands of hand crafted surveys, product finders and quizzes created by Poltio's Content team over a decade that helps you find the perfect question to ask.`}
        />
      </div>

      <div className="mx-auto py-4 max-w-7xl">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-poltio-blue-400 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <p className="mx-auto mt-2 max-5w-xl text-center text-lg leading-8 text-white font-semibold">
              Currently our AI Creation assistant SAGE is only available when
              you connect your external data source (XML, JSON, CSV, Shopify
              API, etc...){' '}
              <a
                className="text-white after:content-['_↗'] underline"
                href={'/data-source'}
              >
                via Data Sources.
              </a>{' '}
              After connecting your data source, you can use SAGE to analyze
              your products and recommend you questions.
            </p>
            <p className="mx-auto mt-2 max-5w-xl text-center text-lg leading-8 text-white font-semibold">
              If you want to use SAGE creating any content without a Data
              Source, you can request beta access by clicking the button.
            </p>
            <div className="mx-auto mt-10 grid max-w-md gap-x-4">
              {submitSuccess ? (
                <p className="mx-auto mt-2 max-5w-xl text-center text-lg leading-8 text-white font-semibold">
                  You are on the queue, we will send a notification to you once
                  SAGE is activated for your account.
                </p>
              ) : (
                <button
                  onClick={sendRequest}
                  disabled={buttonDisabled}
                  type="submit"
                  className="flex-none rounded-md max-h-12 h-12 bg-white px-3.5 py-2.5 text-md font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  {buttonDisabled ? (
                    <div className="max-h-12 h-12">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlinkHref="http://www.w3.org/1999/xlink"
                        style={{
                          margin: 'auto',
                          display: 'block',
                        }}
                        width="4em"
                        height="3em"
                        viewBox="0 20 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <circle cx="17" cy="46.1078" r="5" fill="#ffc000">
                          <animate
                            attributeName="cy"
                            calcMode="spline"
                            keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                            repeatCount="indefinite"
                            values="56;44;56;56"
                            keyTimes="0;0.3;0.6;1"
                            dur="1s"
                            begin="-0.6s"
                          ></animate>
                        </circle>{' '}
                        <circle cx="39" cy="56" r="5" fill="#ff6644">
                          <animate
                            attributeName="cy"
                            calcMode="spline"
                            keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                            repeatCount="indefinite"
                            values="56;44;56;56"
                            keyTimes="0;0.3;0.6;1"
                            dur="1s"
                            begin="-0.45s"
                          ></animate>
                        </circle>{' '}
                        <circle cx="61" cy="56" r="5" fill="#009eec">
                          <animate
                            attributeName="cy"
                            calcMode="spline"
                            keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                            repeatCount="indefinite"
                            values="56;44;56;56"
                            keyTimes="0;0.3;0.6;1"
                            dur="1s"
                            begin="-0.3s"
                          ></animate>
                        </circle>{' '}
                        <circle cx="83" cy="56" r="5" fill="#d66ddd">
                          <animate
                            attributeName="cy"
                            calcMode="spline"
                            keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
                            repeatCount="indefinite"
                            values="56;44;56;56"
                            keyTimes="0;0.3;0.6;1"
                            dur="1s"
                            begin="-0.15s"
                          ></animate>
                        </circle>
                      </svg>
                    </div>
                  ) : (
                    'Request Beta Access'
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SagePreview;
