import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PlusCircleIcon,
  DocumentIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl.macro';
import { injectIntl } from 'react-intl';
import * as editor from '../../api/editor';
import { feedDailyImagePathRetina } from '../../core/constants';
import Button from '../Common/Button';
import messages from './messages';
import { Link } from 'react-router-dom';
import { embedUrlPrefix } from '../../core/constants';
import LoadingDialog from '../Common/Loading/LoadingDialog';
import { Helmet } from 'react-helmet';

const EditorPage = ({ goToDrafts, total, intl: { formatMessage } }) => {
  const navigate = useNavigate();

  const [templates, setTempalates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const respose = await editor.templates.get();
      const data = respose?.data?.data ?? [];

      setTempalates(() => [
        ...data.map((entry) => ({
          id: entry.id,
          title: entry.title,
          name: entry.name,
          background: entry.background,
          public_id: entry.public_id,
          onClick: async () => {
            setLoading(true);

            const content = await editor.templates.use(entry.public_id);
            navigate(`/editor/${content.data.public_id}`);
          },
        })),
      ]);
      setLoading(false);
    })();
  }, [navigate]);

  const actions = [
    {
      name: formatMessage(messages.CreateEmpty),
      title: formatMessage(messages.CreateStratch),
      icon: PlusCircleIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
      buttonText: formatMessage(messages.CreateEmpty),
      onClick: () => navigate('/editor/new'),
    },
    {
      name:
        total === 0 ? (
          formatMessage(messages.NoDraft)
        ) : (
          <FormattedMessage
            id="Edittor.DraftMessage"
            defaultMessage={`
                You have {count, plural,
                    one {{count} Draft, Continue on them}
                    other {{count} Drafts, Continue on them}
                }
            `}
            values={{
              count: total,
            }}
          />
        ),
      title: formatMessage(messages.Continue),
      icon: DocumentIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      buttonText: formatMessage(messages.GoToDrafts),
      onClick: () => goToDrafts(),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Create new content | Poltio Platform</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <LoadingDialog show={loading} />
      <div className="grid grid-cols-1 max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8">
        {[...actions, ...templates].map((action, actionIdx) => {
          return (
            <div
              className="flex flex-col items-center text-center bg-white rounded-lg shadow col-span-1"
              key={actionIdx}
            >
              <div className="flex flex-col flex-1 w-full p-1 pt-4 pl-3 pr-3">
                <div className="rounded-lg overflow-y-hidden max-h-60 ">
                  {action.icon ? (
                    <div className="flex h-60 w-full items-center">
                      <action.icon className="h-20 w-full text-poltio-blue-500"></action.icon>
                    </div>
                  ) : (
                    <img
                      className="flex-shrink-0 w-full mx-auto"
                      src={
                        action.background
                          ? `${feedDailyImagePathRetina}/${action.background}`
                          : `${feedDailyImagePathRetina}/placeholder/question/1.png`
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className="mt-4 px-4 text-sm font-medium text-gray-600 h-8 truncate">
                  {action.title}
                </div>
                <div className="px-4 text font-medium text-gray-900 h-8 truncate">
                  {action.name}
                </div>
              </div>
              <div className="relative inline-flex">
                <Button.Primary className="mb-5" onClick={action.onClick}>
                  {action.buttonText
                    ? action.buttonText
                    : formatMessage(messages.UseThisTemplete)}
                </Button.Primary>
                {action.public_id ? (
                  <Button.Secondary className="mb-5 ml-2">
                    <Link
                      className="text-sm font-medium text-gray-900 flex flex-row"
                      to={`//${embedUrlPrefix}/widget/${action.public_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkIcon
                        className="w-5 h-5 text-gray-400 mr-2"
                        aria-hidden="true"
                      />
                      {formatMessage(messages.TryThis)}
                    </Link>
                  </Button.Secondary>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default injectIntl(EditorPage);
