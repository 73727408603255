import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Logout: {
    id: 'Settings.Logout.Logout',
    defaultMessage: 'Log out other sessions',
  },
  PleaseEnter: {
    id: 'Settings.Logout.PleaseEnter',
    defaultMessage:
      'Please enter your password to confirm you would like to log out of your other sessions across all of your devices.',
  },
  YourPw: {
    id: 'Settings.Logout.YourPw',
    defaultMessage: 'Your password',
  },
  LogoutOther: {
    id: 'Settings.Logout.LogoutOther',
    defaultMessage: 'Log out other sessions',
  },
  Sure: {
    id: 'Settings.Logout.Sure',
    defaultMessage: 'Are you sure you want log out from all other devices?',
  },
  Success: {
    id: 'Settings.Logout.Success',
    defaultMessage: 'You have successfully logged out of all other sessions.',
  },
  IncoPw: {
    id: 'Settings.Logout.IncoPw',
    defaultMessage: 'Incorrect password!',
  },
  Error: {
    id: 'Settings.Logout.Error',
    defaultMessage: 'Error occured!',
  },
});
