import messages from './messages';
import { injectIntl } from 'react-intl';
import { appPrefix } from '../../../core/constants';
import { WarningAlert } from '../../Common/Alert';
import { Link } from 'react-router-dom';
import type { FC } from 'react';

type Props = {
  onYesClick: () => void;
  intl: any;
};

const TcInfo: FC<Props> = ({ onYesClick, intl: { formatMessage } }) => {
  return (
    <WarningAlert
      title={formatMessage(messages.Attention)}
      body={
        <p>
          <Link
            to={`//${appPrefix}poltio.app/terms-and-conditions`}
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage(messages.Desc)}
          </Link>
        </p>
      }
      footer={
        <button
          type="button"
          className="bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
          onClick={onYesClick}
        >
          {formatMessage(messages.Approve)}
        </button>
      }
    />
  );
};

export default injectIntl(TcInfo);
