import type { Answer } from '../Answer';

const QuestionMockFields: string[] = [
  'background',
  'title',
  't',
  'answer_type',
  'desc',
  'end_date',
  'end_date_relative',
  'end_date_human',
  'vote_count',
  'url',
  'url_text',
  'autocomplete_help',
  'autocomplete_placeholder',
  'allow_multiple_answers',
  'max_multi_punch_answer',
  'is_skippable',
];

class QuestionMockMixin {
  // Properties of mixins are ignored during mixing.
  // They are only used for type checking here.
  answers: Answer[] = [];
  _clone_answers_from_ref: any;

  public mock() {
    let _mock: any = {
      ...Object.keys(this)
        .filter((key) => QuestionMockFields.includes(key))
        .reduce(
          (acc, field) => ({ ...acc, [field]: (this as any)[field] }),
          {}
        ),
      ...this?.answers?.reduce(
        (acc, answer, idx) => ({ ...acc, ...answer?.mock(idx) }),
        {}
      ),
    };

    if (!!this._clone_answers_from_ref) {
      _mock.answer_type = this._clone_answers_from_ref.answer_type;
    }

    return _mock;
  }
}

export default QuestionMockMixin;
