import { type FC } from 'react';
import ContentCard from '../Common/ContentCard';
import { Content } from '../../core/types';

type Props = {
  contents: Array<Content>;
  fetchContents: () => {};
};

const Contents: FC<Props> = ({ contents, fetchContents }) => (
  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 ">
    {contents.map((content) => {
      return (
        <ContentCard
          key={content.public_id}
          content={content}
          fetchContents={fetchContents}
        />
      );
    })}
  </div>
);

export default Contents;
