import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  PageSize: {
    id: 'Pagination.PageSize',
    defaultMessage: 'PageSize',
  },
  Previous: {
    id: 'Pagination.Previous',
    defaultMessage: 'Previous',
  },
  Next: {
    id: 'Pagination.Next',
    defaultMessage: 'Next',
  },
});
