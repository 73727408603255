type Dict<T = any> = { [key: string]: T };
type TAllowedObj = Dict<string | string[] | Dict>;
type TAllowed = string[] | TAllowedObj;

function generateFromArray(data: any, allowed: string[]): Dict {
  return {
    ...Object.keys(data)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        if (data?.[key] === undefined || data?.[key] === null) {
          return obj;
        }

        return {
          ...obj,
          [key]: data[key],
        };
      }, {}),
  };
}

function generateFromDict(data: any, allowed: TAllowedObj): Dict {
  return {
    ...Object.keys(allowed).reduce((obj, key) => {
      // check if the key is in the data, if not, return the object
      if (data[key] === undefined || data[key] === null) {
        return obj;
      }

      const rule = allowed[key];
      let value;

      // nested rule, recurse into that
      if (typeof rule === 'object') {
        value = data[key] ? generatePayload(data[key], rule) : null;
        return { ...obj, [key]: value };
      }

      let rules: string[];
      if (typeof rule === 'string') {
        rules = rule.split(',');
      } else {
        rules = rule; // rule: never
      }

      if (rules.includes('nullable')) {
        // field can be nullable
        if (data?.[key] === undefined || data?.[key] === null) {
          // field is nullable & null, so no need to work it
          return { ...obj, [key]: null };
        }
      }

      // not nullable, so we need to work it
      if (data?.[key] === undefined || data?.[key] === null) {
        // field is not nullable, but null, so we skip it
        return obj;
      }

      if (rules.includes('boolean')) {
        // we transfer boolean fields as 1 or 0
        value = data[key] ? 1 : 0;
      }
      if (rules.includes('string')) {
        value = String(data[key]);
      }
      if (rules.includes('number')) {
        value = Number(data[key]);
      }

      return { ...obj, [key]: value };
    }, {}),
  };
}

function generatePayload(data: any, allowed: TAllowed): Dict {
  if (Array.isArray(allowed)) {
    return generateFromArray(data, allowed);
  }

  // othwerwise, allowed is an object
  return generateFromDict(data, allowed);
}

export { generatePayload, generatePayload as default };
