import { Chart } from 'react-google-charts';
import DetailCard from '../../Common/DetailCard/index';
import messages from './messages';
import { injectIntl } from 'react-intl';

const PollStats = ({ intl: { formatMessage }, content }) => {
  return (
    <div>
      <DetailCard
        title={formatMessage(messages.ResultStats)}
        subtitle={formatMessage(messages.ResultRates)}
      >
        <div className="grid grid-cols-12 bg-white">
          <div className="col-span-7">
            <Chart
              chartType="PieChart"
              columns={[{ type: 'string' }, { type: 'number', label: '' }]}
              rows={content.questions[0]?.answers.map((a) => [
                a.title,
                a.vote_count,
              ])}
              graph_id={`total_votes_${content.original_id}`}
              width="100%"
              height="500px"
              options={{
                title: 'Total Vote',
                is3D: true,
              }}
            />
          </div>
          <div className="col-span-5">
            <div className="geo-location-container">
              <iframe
                src={`//geo.poltio.com/?id=${content.original_id}&v2.3`}
                width="100%"
                height="450"
                scrolling="no"
                frameBorder="0"
                title={`iframechart${content.original_id}`}
              />
            </div>
          </div>
        </div>
      </DetailCard>
    </div>
  );
};

export default injectIntl(PollStats);
