/**
 *
 * @param {object} question
 * @param {*} change
 * @returns {(object|undefined)}
 */
export function getAnswer(question, change) {
  if (question === undefined) {
    console.info(
      `Could not find question with UID in answer lookup \`${change.q_uid}\`.`
    );
    return undefined;
  }
  /** @type {Array<object>} */
  const answers = question.answers;
  const answer = answers?.find((value) => value.__uid === change.a_uid);
  if (answer === undefined) {
    console.info(`Could not find answer with UID \`${change.a_uid}\`.`);
    return undefined;
  }

  return answer;
}
