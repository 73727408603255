export const BigButton = ({ onClick, children, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`relative block w-full rounded-lg  p-12 py-24 text-center  bg-poltio-blue focus:outline-none focus:ring-2 focus:ring-poltio-blue focus:ring-offset-2 hover:bg-poltio-blue-400 text-sm font-semibold text-white ${className}`}
    >
      {children}
    </button>
  );
};
