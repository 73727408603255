import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  UsersByAnswer: {
    id: 'ContentDetail.UsersByAnswer',
    defaultMessage: 'Users By Answer',
  },
  GetReport: {
    id: 'ContentDetail.GetReport',
    defaultMessage: 'Get Report',
  },
  CodeGenerator: {
    id: 'ContentDetail.CodeGenerator',
    defaultMessage: 'Code Generator',
  },
  Width: {
    id: 'ContentDetail.Width',
    defaultMessage: 'Width',
  },
  Height: {
    id: 'ContentDetail.Height',
    defaultMessage: 'Height',
  },
  DisplayShare: {
    id: 'ContentDetail.DisplayShare',
    defaultMessage: 'Display Share Buttons',
  },
  DisplayDisclaimer: {
    id: 'ContentDetail.DisplayDisclaimer',
    defaultMessage: 'Display Disclaimer',
  },
  ResultFit: {
    id: 'ContentDetail.ResultFit',
    defaultMessage: 'Result Fit',
  },
  Off: {
    id: 'ContentDetail.Off',
    defaultMessage: 'Off',
  },
  ResultImageCrop: {
    id: 'ContentDetail.ResultImageCrop',
    defaultMessage: 'Result Image Crop',
  },
  Fit: {
    id: 'ContentDetail.Fit',
    defaultMessage: 'Fit',
  },
  Alignment: {
    id: 'ContentDetail.Alignment',
    defaultMessage: 'Horizontal Alignment',
  },
  Left: {
    id: 'ContentDetail.Left',
    defaultMessage: 'Left',
  },
  Center: {
    id: 'ContentDetail,Center',
    defaultMessage: 'Center',
  },
  Right: {
    id: 'ContentDetail.Right',
    defaultMessage: 'Right',
  },
  VerticalAlignment: {
    id: 'ContentDetail.VerticalAlignment',
    defaultMessage: 'Vertical Alignment',
  },
  Top: {
    id: 'ContentDetail.Top',
    defaultMessage: 'Top',
  },
  Bottom: {
    id: 'ContentDetail.Bottom',
    defaultMessage: 'Bottom',
  },
  Language: {
    id: 'ContentDetail.Language',
    defaultMessage: 'Language',
  },
  DeviceLanguage: {
    id: 'ContentDetail,DeviceLanguage',
    defaultMessage: 'Device Language',
  },
  English: {
    id: 'ContentDetail.English',
    defaultMessage: 'English',
  },
  Turkish: {
    id: 'ContentDetail.Turkish',
    defaultMessage: 'Turkish',
  },
  German: {
    id: 'ContentDetail.German',
    defaultMessage: 'German',
  },
  French: {
    id: 'ContentDetail.French',
    defaultMessage: 'French',
  },
  Italian: {
    id: 'ContentDetail.Italian',
    defaultMessage: 'Italian',
  },
  Spanish: {
    id: 'ContentDetail.Spanish',
    defaultMessage: 'Spanish',
  },
  Hebrew: {
    id: 'ContentDetail.Hebrew',
    defaultMessage: 'Hebrew',
  },
  Romanian: {
    id: 'ContentDetail.Romanian',
    defaultMessage: 'Romanian',
  },
  Dutch: {
    id: 'ContentDetail.Dutch',
    defaultMessage: 'Dutch',
  },
  Portuguese: {
    id: 'ContentDetail.Portuguese',
    defaultMessage: 'Portuguese',
  },
  Thai: {
    id: 'ContentDetail.Thai',
    defaultMessage: 'Thai',
  },
  Arabic: {
    id: 'ContentDetail.Arabic',
    defaultMessage: 'Arabic',
  },
  Hungarian: {
    id: 'ContentDetail.Hungarian',
    defaultMessage: 'Hungarian',
  },
  Austrian: {
    id: 'ContentDetail.Austrian',
    defaultMessage: 'Austrian',
  },
  Algerian: {
    id: 'ContentDetail.Algerian',
    defaultMessage: 'Algerian',
  },
  Egyptian: {
    id: 'ContentDetail.Egyptian',
    defaultMessage: 'Egyptian',
  },
  Moroccan: {
    id: 'ContentDetail.Moroccan',
    defaultMessage: 'Moroccan',
  },
  UtmSource: {
    id: 'ContentDetail.UtmSource',
    defaultMessage: 'Utm Source',
  },
  UtmDesc: {
    id: 'ContentDetaul.UtmDesc',
    defaultMessage:
      'Identify the advertiser, site, publication, etc. that is sending traffic to your property, for example: google, newsletter4, billboard.',
  },
  UtmMedium: {
    id: 'ContentDetail.UtmMedium',
    defaultMessage: 'Utm Medium',
  },
  UtmMDesc: {
    id: 'ContentDetail.UtmMDesc',
    defaultMessage:
      'The advertising or marketing medium, for example: cpc, banner, email newsletter.',
  },
  UtmCampaign: {
    id: 'ContentDetail.UtmCampaign',
    defaultMessage: 'Utm Campaign',
  },
  UtmCDesc: {
    id: 'ContentDetail.UtmCDesc',
    defaultMessage:
      'The individual campaign name, slogan, promo code, etc. for a product.',
  },
  EmbedCode: {
    id: 'ContentDetail.EmbedCode',
    defaultMessage: 'Embed Code',
  },
  SaveSettings: {
    id: 'ContentDetail.SaveSettings',
    defaultMessage: 'Save Settings',
  },
  CopyCode: {
    id: 'ContentDetail.CopyCode',
    defaultMessage: 'Copy Code',
  },
  Close: {
    id: 'ContentDetail.Close',
    defaultMessage: 'Close',
  },
  MobileView: {
    id: 'ContentDetail.MobileView',
    defaultMessage: 'Mobile View',
  },
  Options: {
    id: 'ContentDetail.Options',
    defaultMessage: 'Options',
  },
  TotalVote: {
    id: 'ContentDetail.TotalVote',
    defaultMessage: 'Total Vote',
  },
  UniqueVoter: {
    id: 'ContentDetail.UniqueVoter',
    defaultMessage: 'Unique Voter',
  },
  Start: {
    id: 'ContentDetail.Start',
    defaultMessage: 'Session',
  },
  Finish: {
    id: 'ContentDetail.Finish',
    defaultMessage: 'Finish',
  },
  Questions: {
    id: 'ContentDetail.Questions',
    defaultMessage: '# of Questions',
  },
  CompletionRate: {
    id: 'ContentDetail.CompletionRate',
    defaultMessage: 'Completion Rate',
  },
  ViewCount: {
    id: 'ContentDetail.ViewCount',
    defaultMessage: 'View Count',
  },
  Average: {
    id: 'ContentDetail.Average',
    defaultMessage: 'Average Play Per User',
  },
  VotesVs: {
    id: 'ContentDetail.VotesVs',
    defaultMessage: 'Votes (This week vs last week)',
  },
  VotersVs: {
    id: 'ContentDetail.VotersVs',
    defaultMessage: 'Voters (This week vs last week)',
  },
  CompletionVs: {
    id: 'ContentDetail.CompletionVs',
    defaultMessage: 'Completion (today vs yesterday)',
  },
  TQStats: {
    id: 'ContentDetail.TQStats',
    defaultMessage: 'Test/Quiz Stats',
  },
  SplitResult: {
    id: 'ContentDetail.SplitResult',
    defaultMessage: '% Split of results',
  },
  ResultUrls: {
    id: 'ContentDetail.ResultUrls',
    defaultMessage: 'Results Detail',
  },
  Title: {
    id: 'ContentDetail.Title',
    defaultMessage: 'Title',
  },
  ResultCounter: {
    id: 'ContentDetail.ResultCounter',
    defaultMessage: 'Result Counter',
  },
  ResultUrl: {
    id: 'ContentDetail.ResultUrl',
    defaultMessage: 'Result Url',
  },
  UrlCounter: {
    id: 'CounterDetail.UrlCounter',
    defaultMessage: 'Url Counter',
  },
  Click: {
    id: 'ContentDetail.Click',
    defaultMessage: 'Click %',
  },
  Flow: {
    id: 'ContentDetail.Flow',
    defaultMessage: 'Flow',
  },
  Users: {
    id: 'ContentDetail.Users',
    defaultMessage: 'Users',
  },
  UserID: {
    id: 'ContentDetail.UserID',
    defaultMessage: 'UserID',
  },
  UUID: {
    id: 'ContentDetail.UUID',
    defaultMessage: 'UUID',
  },
  Daily: {
    id: 'ContentDetail.Daily',
    defaultMessage: 'Daily',
  },
  Weekly: {
    id: 'ContentDetail.Weekly',
    defaultMessage: 'Weekly',
  },
  Monthly: {
    id: 'ContentDetail.Montly',
    defaultMessage: 'Monthly',
  },
  Yearly: {
    id: 'ContentDetail.Yearly',
    defaultMessage: 'Yearly',
  },
  NoData: {
    id: 'ContentDetail.NoData',
    defaultMessage: 'No Data',
  },
  VoteStats: {
    id: 'ContentDetail.VoteStats',
    defaultMessage: 'Traffic Overview',
  },
  CompletionStats: {
    id: 'ContentDetail.CompletionStats',
    defaultMessage: 'Completion Overview',
  },
  VoteSources: {
    id: 'ContentDetail.VoteSources',
    defaultMessage: 'Session Urls',
  },
  SetSummary: {
    id: 'ContentDetail.SetSummary',
    defaultMessage: 'Content Summary',
  },
  Voters: {
    id: 'ContentDetail.Voters',
    defaultMessage: 'Voters',
  },
  Question: {
    id: 'ContentDetail.Question',
    defaultMessage: 'Question',
  },
  TotalVoteCount: {
    id: 'ContentDetail.TotalVoteCount',
    defaultMessage: 'Total Vote Count',
  },
  Answer: {
    id: 'ContentDetail.Answer',
    defaultMessage: 'Answer',
  },
  VoteCount: {
    id: 'ContentDetail.VoteCounth',
    defaultMessage: 'Session Count',
  },
  Connected: {
    id: 'ContentDetail.Connected',
    defaultMessage: 'Connected',
  },
  MultiPunch: {
    id: 'ContentDetail.MultiPunch',
    defaultMessage: 'Multi Punch',
  },
  VoteID: {
    id: 'ContentDetail.VoteID',
    defaultMessage: 'VoteID',
  },
  VoteDate: {
    id: 'ContentDetail.VoteDate',
    defaultMessage: 'VoteDate',
  },
  PublisherSourceID: {
    id: 'ContentDetail.PublisherSourceID',
    defaultMessage: 'PublisherSourceID',
  },
  QuestionID: {
    id: 'ContentDetail.QuestionID',
    defaultMessage: 'QuestionID',
  },
  Type: {
    id: 'ContentDetail.Type',
    defaultMessage: 'Type',
  },
  Parent: {
    id: 'ContentDetail.Parent',
    defaultMessage: 'Parent',
  },
  AnswerID: {
    id: 'ContentDetail.AnswerID',
    defaultMessage: 'AnswerID',
  },
  Source: {
    id: 'ContentDetail.Source',
    defaultMessage: 'Source',
  },
  FriendQuizMetrics: {
    id: 'ContentDetail.FriendQuizMetrics',
    defaultMessage: 'Friend Quiz Metrics',
  },
  FriendSessionCount: {
    id: 'ContentDetail.FriendSessionCount',
    defaultMessage: 'Session Count (Source)',
  },
  FriendPlayerCount: {
    id: 'ContentDetail.FriendPlayerCount',
    defaultMessage: 'Player Count (Friend)',
  },
  FriendAvgPlay: {
    id: 'ContentDetail.FriendAvgPlay',
    defaultMessage: 'Avg Play (Friend)',
  },
  SuccesFail: {
    id: 'ContentDetail.SuccesFail',
    defaultMessage: 'Correct / Wrong Answer Split',
  },
  QuizPercentage: {
    id: 'ContentDetail.QuizPercentage',
    defaultMessage: 'Split of votes by correct and wrong answers.',
  },
  VotesbyAnswers: {
    id: 'ContentDetail.VotesbyAnswers',
    defaultMessage: 'Votes Percentage Split',
  },
  VotePercentage: {
    id: 'ContentDetail.VotePercentage',
    defaultMessage:
      'Split of each answer by percentage against total votes for that question.  For ex:  A question has a total of 500 voters and 1.000 votes. (Users can select more than 1 answer.)  100 users select Answer A.  Answer A percentage is displayed as 10%.',
  },
  VoteNumbers: {
    id: 'ContentDetail.VoteNumbers',
    defaultMessage:
      'Number of votes for each answer option and the total vote count for the question.',
  },
  ResultStats: {
    id: 'ContentDetail.ResultStats',
    defaultMessage: 'Completion & Results',
  },
  ResultRates: {
    id: 'ContentDetail.ResultRates',
    defaultMessage:
      'Completion rate (percentage of users that start and finish the content) and the percentage split of different result options. Result percentage is calculated over users that finish the content. For ex: 1000 users start the content, 800 finish and 200 see result option A.  The percentage for option A is 25%.',
  },
  UrlDesc: {
    id: 'ContentDetail.UrlDesc',
    defaultMessage:
      'Number of times each result option has been displayed, the redirect link and the number of clicks. Redirect link is the adress that users are redirected to when they click on the button on the result page.',
  },
  SankeyDiagram: {
    id: 'ContentDetail.SankeyDiagram',
    defaultMessage: 'Sankey Diagram',
  },
  SankeyDesc: {
    id: 'ContentDetail.SankeyDesc',
    defaultMessage: 'A sankey diagram to analyze and compare user journeys.',
  },
  VoteStatsDesc: {
    id: 'ContentDetail.VoteStatsDesc',
    defaultMessage: 'No of total votes and unique voters based on time.',
  },
  CompletionStatsDesc: {
    id: 'ContentDetail.CompletionStatsDesc',
    defaultMessage:
      'No of times users start and finish the content based on time.',
  },
  VoteSourcesDesc: {
    id: 'ContentDetail.VoteSourcesDesc',
    defaultMessage: 'The urls that the content is being voted on.',
  },
  SetSummaryDesc: {
    id: 'ContentDetail.SetSummaryDesc',
    defaultMessage: 'Overall summary and votes in your content',
  },
  VoterReport: {
    id: 'ContentDetail.VoterReport',
    defaultMessage: 'Votes Table',
  },
  VoterReportDesc: {
    id: 'ContentDetail.VoterReportDesc',
    defaultMessage:
      'A report displaying all votes. Each line represents an individual vote.',
  },
  ContentStats: {
    id: 'ContentDetail.ContentStats',
    defaultMessage: 'Content Stats',
  },
  AggregatedVotesView: {
    id: 'ContentDetail.AggregatedVotesView',
    defaultMessage: 'Aggregated Votes View',
  },
  ClickForAnswers: {
    id: 'ContentDetail.ClickForAnswers',
    defaultMessage: 'Click For Answers',
  },
  AnswerType: {
    id: 'ContentDetail.AnswerType',
    defaultMessage: 'Answer Type',
  },
  FreeTypeAnswers: {
    id: 'ContentDetail.FreeTypeAnswers',
    defaultMessage: 'Free Type Answers',
  },
  VoterID: {
    id: 'ContentDetail.VoterID',
    defaultMessage: 'Voter ID',
  },
  SessionID: {
    id: 'ContentDetail.SessionID',
    defaultMessage: 'Session ID',
  },
  Ip: {
    id: 'ContentDetail.Ip',
    defaultMessage: 'IP',
  },
  CreatedAt: {
    id: 'ContentDetail.CreatedAt',
    defaultMessage: 'Created At',
  },
  VerticalFit: {
    id: 'ContentDetail.VerticalFit',
    defaultMessage: 'Vertical Fit',
  },
  DontUseCookie: {
    id: 'ContentDetail.DontUseCookie',
    defaultMessage: `Don't Use Cookie`,
  },
  SameWindow: {
    id: 'ContentDetail.SameWindow',
    defaultMessage: 'Open the result link in the same window',
  },
  SessionReport: {
    id: 'ContentDetail.SessionReport',
    defaultMessage: 'Sessions',
  },
  Score: {
    id: 'ContentDetail.Score',
    defaultMessage: 'Score',
  },
  RightAnswer: {
    id: 'ContentDetail.RightAnswer',
    defaultMessage: 'Right Answer',
  },
  PlayTime: {
    id: 'ContentDetail.PlayTime',
    defaultMessage: 'Play Time (ms)',
  },
  StartedAt: {
    id: 'ContentDetail.StartedAt',
    defaultMessage: 'Started At',
  },
  FinishedAt: {
    id: 'ContentDetail.FinishedAt',
    defaultMessage: 'Finished At',
  },
  NotFinished: {
    id: 'ContentDetail.NotFinished',
    defaultMessage: 'Not Finished',
  },
  ClickCount: {
    id: 'ContentDetail.ClickCount',
    defaultMessage: 'Click Count',
  },
  StartDate: {
    id: 'ContentDetail.StartDate',
    defaultMessage: 'Start Date',
  },
  EndDate: {
    id: 'ContentDetail.EndDate',
    defaultMessage: 'End Date',
  },
  MetricsOverview: {
    id: 'ContentDetail.MetricsOverview',
    defaultMessage: 'Metrics Overview',
  },
  MetricsDesc: {
    id: 'ContentDetail.MetricsDesc',
    defaultMessage:
      'You can explore and compare different metrics for your content.',
  },
  ResultView: {
    id: 'ContentDetail.ResultView',
    defaultMessage: 'Result View',
  },
  ResultClick: {
    id: 'ContentDetail.ResultClick',
    defaultMessage: 'Result Click',
  },
  UniqueResultClick: {
    id: 'ContentDetail.UniqueResultClick',
    defaultMessage: 'Unique Click (Result)',
  },
  Vote: {
    id: 'ContentDetail.Vote',
    defaultMessage: 'Vote',
  },
  View: {
    id: 'ContentDetail.View',
    defaultMessage: 'View',
  },
  Voter: {
    id: 'ContentDetail.Voter',
    defaultMessage: 'Voter',
  },
  Undo: {
    id: 'ContentDetail.Undo',
    defaultMessage: 'Undo',
  },
  StartVS: {
    id: 'ContentDetail.StartVS',
    defaultMessage: 'Start',
  },
  Select: {
    id: 'ContentDetail.Select',
    defaultMessage: 'Select',
  },
  MetricsType: {
    id: 'ContentDetail.MetricsType',
    defaultMessage: 'Metrics Type',
  },
  MetricsVsGraph: {
    id: 'ContentDetail.MetricsVsGraph',
    defaultMessage: 'Metrics VS Graph',
  },
  DontUseResize: {
    id: 'ContentDetail.EmbedSModal.DontUseResize',
    defaultMessage: "Don't use auto resize",
  },
  CustomID: {
    id: 'ContentDetail.EmbedModal.CustomID',
    defaultMessage: 'Custom ID',
  },
  DirectLink: {
    id: 'ContentDetail.EmbedModal.DirectLink',
    defaultMessage: 'Direct Link',
  },
  Day: {
    id: 'ContentDetail.Day',
    defaultMessage: 'Day',
  },
  Week: {
    id: 'ContentDetail.Week',
    defaultMessage: 'Week',
  },
  Month: {
    id: 'ContentDetail.Month',
    defaultMessage: 'Month',
  },
  Year: {
    id: 'ContentDetail.Year',
    defaultMessage: 'Year',
  },
  ResultSwipe: {
    id: 'ContentDetail.ResultSwipe',
    defaultMessage: 'Result Swipe',
  },
  CRTooltipMessage: {
    id: 'ContentDetail.CRTooltipMessage',
    defaultMessage: `Your content’s completion rate is below the optimal 70%. Consider reviewing your question flow for any that might cause drop-offs, perhaps due to being abstract, challenging, or missing response options. Small adjustments can improve engagement and completion rates.`,
  },
});
