import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Subs: {
    id: 'Settings.Subs.Subs',
    defaultMessage: 'Subscription',
  },
  YourSubs: {
    id: 'Settings.Subs.YourSubs',
    defaultMessage: 'Your subscription and billing information.',
  },
  SubsStatus: {
    id: 'Settings.Subs.SubsStatus',
    defaultMessage: 'Subscription Status',
  },
  SubsDate: {
    id: 'Settings.Subs.SubsDate',
    defaultMessage: 'Subscription Date',
  },
  CancelledAt: {
    id: 'Settings.Subs.CancelledAt',
    defaultMessage: 'Cancelled At',
  },
  RenewalDate: {
    id: 'Settings.Subs.RenewalDate',
    defaultMessage: 'Renewal Date',
  },
  ExpDate: {
    id: 'Settings.Subs.ExpDate',
    defaultMessage: 'Subscription Expiration Date',
  },
  CancelSubs: {
    id: 'Settings.Subs.CancelSubs',
    defaultMessage: 'Cancel Subscription',
  },
  SubsNow: {
    id: 'Settings.Subs.SubsNow',
    defaultMessage: 'Subscribe Now',
  },
  HaveSubs: {
    id: 'Settings.Subs.HaveSubs',
    defaultMessage: 'You do not currently have a subscription',
  },
  ErrorCancel: {
    id: 'Settings.Subs.ErrorCancel',
    defaultMessage: 'Error occured cancelling subscription!',
  },
  SuccessCancel: {
    id: 'Settings.Subs.SuccessCancel',
    defaultMessage: 'Cancellation is successfull.',
  },
  Sure: {
    id: 'Settings.Subs.Sure',
    defaultMessage: 'Are you sure you want to cancel your subscription?',
  },
});
