import { useContext, useEffect, useMemo } from 'react';
import { Dropdown } from '../../Common/Form';
import { ContentTypePresetsInitialLoad } from '../constants';
import { EditorCTX } from '../context';
import { isObject } from '../helpers/isObject';

function _match_content_preset(_content, _preset) {
  for (const pk in _preset) {
    let expected = _preset[pk];
    let actual = _content[pk];

    let ok;
    if (isObject(actual)) {
      ok = _match_content_preset(actual, expected);
    } else {
      if (typeof expected === 'boolean') {
        // always ensure that actual is of type boolean.
        //   since we cannot guarentee it's type, ALWAYS convert to bool.
        actual = !!actual;
      }
      ok = expected === actual;
    }

    if (!ok) {
      return false;
    }
  }

  return true;
}

/**
 * Find the last matching preset.
 *
 * @param {*} _content
 * @returns
 */
function _get_preset_idx(_content) {
  let select = -1;

  let index = 0;
  for (const key in ContentTypePresetsInitialLoad) {
    const preset = ContentTypePresetsInitialLoad[key];

    const is_match = _match_content_preset(_content, preset);
    if (is_match) {
      // keep updating 'select' for last matching
      select = index;
      // 'return' for first matching instance
      // return select;
    }

    index++;
  }

  return select;
}

export function SwitchContentType({ formatMessage, messages }) {
  const { data, setContentType } = useContext(EditorCTX);

  /**
   * Below are the human-readable to preset mappings.
   *   `title` part is the value displayed to UI.
   *   `type` part is the value matching the preset name.
   * */
  const ContentTypes = useMemo(
    () => [
      { title: formatMessage(messages.Survey), type: 'survey' },
      { title: 'Quiz', type: 'quiz' },
      { title: 'Test', type: 'test' },
      { title: formatMessage(messages.CalculatorSet), type: 'calculator_set' },
      { title: formatMessage(messages.ProductFinder), type: 'searchable_set' },
    ],
    [formatMessage, messages]
  );

  const handleOnChange = (nextIdx) => {
    const nextType = ContentTypes[nextIdx];

    setContentType(nextType.type, false);
  };

  useEffect(() => {
    if (data.type_identifier === '') {
      const idd = Math.max(0, _get_preset_idx(data));
      setContentType(ContentTypes[idd].type, true);
    }
  }, [setContentType, data, ContentTypes]);

  const selectedItem = ContentTypes.findIndex(
    (c) => c.type === data?.type_identifier
  );

  return (
    <div>
      <label>{formatMessage(messages.ContentType)}</label>
      <Dropdown
        selectedIdx={selectedItem}
        defaultTitle={'Custom'}
        data={ContentTypes}
        onChange={handleOnChange}
      />
    </div>
  );
}
