import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  LimitErrorNVMessage: {
    id: 'ErrorPage.LimitErrorNVMessage',
    defaultMessage: 'Please verify your account to open your limits.',
  },
  LimitErrorMessage: {
    id: 'ErrorPage.LimitErrorMessage',
    defaultMessage:
      'You passed API limits for your account Please wait for couple of minutes and try again.',
  },
  Upgrade: {
    id: 'ErrorPage.Upgrade',
    defaultMessage: 'Or you can upgrade your subscription.',
  },
  GoBack: {
    id: 'ErrorPage.GoBack',
    defaultMessage: 'Go Back',
  },
  ReturnHome: {
    id: 'ErrorPage.ReturnHome',
    defaultMessage: 'Return Home',
  },
  GenericErrorMessage: {
    id: 'ErrorPage.GenericErrorMessage',
    defaultMessage:
      'We are currently unable to you due to an error. We logged it and reported back to our tech team, please wait a couple of minutes and try again.',
  },
  ErrorMessage401: {
    id: 'ErrorPage.ErrorMessage401',
    defaultMessage:
      'Your sessions has expired, please log back in, if you thing there is a mistake, please contact support@poltio.com',
  },
});
