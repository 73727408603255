import EditorCTXHandler from './EditorAPI/EditorCTXHandler';
import EditorDataHandler from './EditorAPI/EditorDataHandler';

const Editor = () => {
  return (
    <EditorDataHandler>
      <EditorCTXHandler />
    </EditorDataHandler>
  );
};

export default Editor;
