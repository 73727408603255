import { AxiosResponse } from 'axios';
import api from '../../client';

import type {
  Answer,
  AnswerRequestBody,
} from '../../../components/Editor/EditorAPI/EditorDataHandler/classes/Answer';

// create & update call
export function saveAnswer(
  answer: Answer,
  contentId: number | string,
  questionId: number | string,
  answerId: number | string | undefined
): Promise<AxiosResponse<Answer>> {
  if (!contentId || !questionId) {
    console.error(answer, contentId, questionId, answerId);
    throw Error('REQUEST_MISSING_REQUIRED');
  }

  const payload: AnswerRequestBody = answer.payload();

  if (Object.keys(payload).length === 0) {
    throw Error('PAYLOAD_EMPTY');
  }

  if (!answerId) {
    return api.post<Answer>(
      `/platform/content/${contentId}/question/${questionId}/answer`,
      payload
    );
  }

  return api.put<Answer>(
    `/platform/content/${contentId}/question/${questionId}/answer/${answerId}`,
    payload
  );
}

export const save = saveAnswer;

export default saveAnswer;
