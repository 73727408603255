export const focusReducer = (state, action) => {
  if (action.field === 'tab') {
    return state;
  }
  if (state.type === action.type && state.field === action.field) {
    return state;
  }

  let ret = null;
  switch (action.type) {
    case 'cover':
      ret = { type: 'cover', field: '', options: { ...action.options } };
      break;
    case 'options':
      ret = { type: 'options', field: '', options: { ...action.options } };
      break;
    case 'question':
      ret = {
        type: 'question',
        field: action?.field || '',
        options: { ...action.options },
      };
      break;
    case 'result':
      ret = {
        type: 'result',
        field: action?.field || '',
        options: { ...action.options },
      };
      break;
    default:
      ret = state;
      break;
  }

  return ret;
};
