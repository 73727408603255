import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Attention: {
    id: 'Terms.Attention',
    defaultMessage: 'Attention needed',
  },
  Desc: {
    id: 'Term.Desc',
    defaultMessage:
      'We have updated our terms and conditions. To see please click. You can access Poltio by agreeing terms and conditions.',
  },
  Approve: {
    id: 'Term.Approve',
    defaultMessage: 'Approve',
  },
});
